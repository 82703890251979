import React from 'react';
import { Link } from 'react-router-dom';
import Hamburger from '../../components/HamburgerMenu';
import profile from '../../assets/images/profile.svg';
// import hospital from '../../assets/images/vagus-logo.png';
import clustrex from '../../assets/images/Clustrex1.png';
import logout from '../../assets/images/logoutIcon.svg';
import useAuth from '../../hooks/useAuth';
import { ROLE } from '../../utils/roles';
import { AsyncLocalStorage } from '../../utils/AsyncLocalStorage';

const Header = (props) => {
    const { auth, setAuth } = useAuth();

    const logoutHandler = () => {
        AsyncLocalStorage.removeItem('userCreds')
            .then(() => setAuth({}));
    };
    return (
        <nav className="z-10 fixed w-full flex flex-row flex-wrap items-center justify-between pr-2 bg-white">
            <div className="flex flex-row items-center">
                <div className="flex items-center w-[13rem]">
                    {auth.user.role === 'doctor'
                        ? <div className="h-full">
                            <Hamburger action={props.action} />
                        </div>
                        : null}
                    <Link to={ROLE?.[auth?.user?.role]?.rootPath}>
                        {/* <img src={hospital} alt="logo" /> */}
                        <img src={clustrex} alt="logo" />
                    </Link>
                </div>
            </div>
            <div className="flex flex-row items-center space-x-4">
                <div className="p-3 rounded-full bg-white">
                    <img src={profile} alt="profile" />
                </div>
                <span className="text-lg font-bold font-roboto">
                    {auth.user.name}
                </span>
                <div className="group flex relative">
                    <button >
                        <img src={logout} className="w-5 h-7" onClick={logoutHandler} alt="logout" />
                    </button>
                    <span className="group-hover:opacity-100 transition-opacity bg-white shadow-xl px-1 text-sm text-col font-bold rounded-md absolute left-auto
           -translate-x-[2rem] translate-y-full opacity-0 m-8 mt-2 mx-auto">Logout</span>
                </div>
            </div>
        </nav>
    );
};

export default Header;
