import React, { useState } from 'react';
import Select from 'react-select';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import route from '../.././../utils/json/Route.json';
import reg from '../.././../utils/json/Regimen.json';
import med from '../.././../utils/json/Med.json';
import dur from '../../../utils/json/Duration.json';
import res from '../../../utils/json/restriction.json';
import close from '../../../assets/images/close.svg';

function Popup ({ prefillData, ...props }) {
    const [duration, setDuration] = useState();
    const [regim, setRegim] = useState();
    const [end, setEnd] = useState('');
    const [endFormat, setEndFormat] = useState('');
    const dateFormat = (date) => {
        return new Date(date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };
    const date1 = new Date();
    const currentDate = dateFormat(date1);
    const [popUpvalues, setPopupValues] = useState({
        Status: 'in-progress',
        Medication: '',
        Manufacturer: '',
        Route: '',
        Dosage: '',
        Unit: 'mg',
        Days: '',
        Regimen: '',
        FoodRestriction: '',
        StartDate: currentDate,
        StartFormat: date1.toISOString()

    });

    const dateFormatEnd = (data) => {
        const date = new Date();
        const durationString = data.split(' ');
        if (durationString[1].includes('Day')) {
            return addDay(parseInt(durationString[0]), date);
        } else if (durationString[1].includes('Month')) {
            return addMonth(parseInt(durationString[0]), date);
        } else if (durationString[1].includes('Week')) {
            return addWeek(parseInt(durationString[0]), date);
        } else if (durationString[1].includes('Year')) {
            return addYear(parseInt(durationString[0]), date);
        }
    };
    const addDay = (value, date) => {
        date.setDate(date.getDate() + value);
        const end = dateFormat(date);
        setEnd(end);
        setPopupValues({ ...popUpvalues, End: end });
        setEndFormat(date.toISOString());
    };
    const addWeek = (value, date) => {
        date.setDate(date.getDate() + (value * 7));
        const end = dateFormat(date);
        setEnd(end);
        setEndFormat(date.toISOString());
    };
    const addMonth = (value, date) => {
        date.setMonth(date.getMonth() + value);
        const end = dateFormat(date);
        setEnd(end);
        setEndFormat(date.toISOString());
    };
    const addYear = (value, date) => {
        date.setFullYear(date.getFullYear() + value);
        const end = dateFormat(date);
        setEnd(end);
        setEndFormat(date.toISOString());
    };

    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };

    const HandleInputValues = (name, value) => {
        setPopupValues({ ...popUpvalues, [name]: value });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        popUpvalues.End = end;
        popUpvalues.EndFormat = endFormat;
        popUpvalues.QuantityPending = duration * regim;
        popUpvalues.Quantity = duration * regim;
        props.sendValue(popUpvalues);
    };
    const handleUpdate = (e) => {
        e.preventDefault();
        props.updateData(popUpvalues);
    };
    return (
        <Dialog open={props.openPopup} maxWidth="xl">
            <DialogTitle>
                <div className="flex flex-row justify-between">
                    <span className='text-black font-roboto text-lg font-medium'>Medication List</span>
                    <button type='button' className="p-[10px] font-bolder" id="close" name="close" onClick={(e) => {
                        props.setOpenPopup(false);
                    }}> <img className="h-5" src={close} alt="close" /></button>
                </div>
            </DialogTitle>
            <DialogContent>
                <form autoComplete='off' className='relative'>
                    <div className="w-[1000px] h-[300px]">
                        <div className='flex'>
                            <div className="w-[50%]">
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Medicine<span className='text-[crimson]'>*</span>:</div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <Select
                                                name={'Medication'}
                                                isSearchable={true}
                                                onChange={(value) => {
                                                    HandleInputValues('Medication', value.value);
                                                }}
                                                defaultValue={
                                                    med.filter(option =>
                                                        option.label === prefillData.Medication)

                                                }
                                                isDisabled = {props.isDisabled}
                                                options={med}
                                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[38px] font-[400] text-[13px] leading-[15px]'
                                                styles={colourStyles}
                                            >
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Manufacturer : </div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <div className='flex w-full justify-between'>
                                                <input
                                                    className='h-[38px] w-full text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                                    id={'Manufacturer'}
                                                    name={'Manufacturer'}
                                                    type='text'
                                                    placeholder='Write here'
                                                    disabled = {props.isDisabled}
                                                    onChange={(event) =>
                                                        HandleInputValues(event.target.name, event.target.value)
                                                    }
                                                    defaultValue={prefillData.Manufacturer}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Route<span className='text-[crimson]'>*</span>:</div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <div className='flex w-full justify-between'>
                                                <Select
                                                    name={'Route'}
                                                    isSearchable={true}
                                                    onChange={(value) => {
                                                        HandleInputValues('Route', value.value);
                                                    }}

                                                    options={route}
                                                    defaultValue={
                                                        route.filter(option =>
                                                            option.label === prefillData.Route)
                                                    }
                                                    className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[38px] font-[400] text-[13px] leading-[15px]'
                                                    styles={colourStyles}
                                                    isDisabled = {props.isDisabled}

                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Food Restriction :</div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <div className='flex w-full justify-between'>
                                                <Select
                                                    name={'FoodRestriction'}
                                                    isSearchable={true}
                                                    onChange={(value) => {
                                                        HandleInputValues('FoodRestriction', value.value);
                                                    }}
                                                    defaultValue={
                                                        res.filter(option =>
                                                            option.label === prefillData.FoodRestriction)

                                                    }
                                                    options={res}
                                                    className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[38px] font-[400] text-[13px] leading-[15px] absolute z-200'
                                                    styles={colourStyles}
                                                    isDisabled = {props.isDisabled}

                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[50%]">
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Dosage<span className='text-[crimson]'>*</span>:</div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <div className='flex w-full justify-between gap-x-2'>
                                                <input required
                                                    className='h-[38px] w-[65%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                                    id={'Dosage'}
                                                    name={'Dosage'}
                                                    type='text'
                                                    placeholder='Write here'
                                                    onChange={(event) =>
                                                        HandleInputValues(event.target.name, event.target.value)
                                                    }
                                                    defaultValue={prefillData.Dosage}
                                                    disabled = {props.isDisabled}
                                                />
                                                <select
                                                    className='outline-none px-4 px-4border-[1px] border-[#E3E3E3]'
                                                    name={'Unit'}
                                                    id={'Unit'}
                                                    onChange={(event) =>
                                                        HandleInputValues(event.target.name, event.target.value)
                                                    }
                                                    defaultValue={prefillData.Unit}
                                                    disabled = {props.isDisabled}
                                                >
                                                    <option value="mg">mg</option>
                                                    <option value="ml">ml</option>
                                                    <option value="mcg">mcg</option>
                                                    <option value="dose">dose</option>
                                                    <option value="%">%</option>
                                                    <option value="IU">IU</option>
                                                    <option value="KU">KU</option>
                                                    <option value="vial">vial</option>
                                                    <option value="units">units</option>
                                                    <option value="As licenced">As licenced</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Medication<br />Duration</div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <div className='flex w-full justify-between'>
                                                <Select
                                                    name={'Days'}
                                                    isSearchable={true}
                                                    onChange={(value) => {
                                                        setDuration(value.condition);
                                                        dateFormatEnd(value.value);
                                                        HandleInputValues('Days', value.value);
                                                    }}
                                                    defaultValue={
                                                        dur.filter(option =>
                                                            option.label === prefillData.Days)

                                                    }
                                                    options={dur}
                                                    className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[38px] font-[400] text-[13px] leading-[15px]'
                                                    styles={colourStyles}
                                                    isDisabled = {props.isDisabled}

                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white px-3 py-[5px]'>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Dose Regimen<span className='text-[crimson]'>*</span>:</div>
                                        <div className='w-[calc(100%-160px)] justify-between flex'>
                                            <div className='flex w-full justify-between relative z-200'>
                                                <Select
                                                    name={'Regimen'}
                                                    isSearchable={true}
                                                    onChange={(value) => {
                                                        setRegim(value.condition);
                                                        HandleInputValues('Regimen', value.value);
                                                    }}
                                                    defaultValue={
                                                        reg.filter(option =>
                                                            option.label === prefillData.Regimen)
                                                    }
                                                    options={reg}
                                                    className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[38px] font-[400] text-[13px] leading-[15px]'
                                                    styles={colourStyles}
                                                    isDisabled = {props.isDisabled}

                                                >
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full text-center'>
                            {prefillData.state === 1
                                ? <></>
                                : prefillData.state === 2
                                    ? < button type='submit' className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' onClick={handleUpdate} >Update</button>
                                    : <button type='submit' className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' onClick={handlerSubmit} >Submit</button>}

                        </div>
                    </div>
                </form>
            </DialogContent>
        </Dialog >
    );
}

export default Popup;
