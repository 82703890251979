import React from 'react';
import Check from '../../containers/InitialAssessment/Check';

function HandoverReports (props) {
    return (
        <div>
            <div className='h-[45px] bg-iat flex w-full items-center px-[1%] font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
               Handed over reports to Attendant / Patient
            </div>
            <div className='flex flex-row bg-white'>
                <div className='flex flex-col gap-y-6 px-16 py-2.5'>
                    <Check formikState={props.formikState} id='Handover.Ecg.Ecg' val='Yes' label='ECG' name='Handover.Ecg.Ecg' checked={props.formikState.values.Handover.Ecg.Ecg === 'Yes' ? 'Yes' : null} />
                    <Check formikState={props.formikState} id='Handover.Lab.Lab' val='Yes' label='Lab Investigation' name='Handover.Lab.Lab' checked={ props.formikState.values.Handover.Lab.Lab === 'Yes' ? 'Yes' : null} />
                    <Check formikState={props.formikState} id='Handover.Usg.Usg' val='Yes' label='USG' name='Handover.Usg.Usg' checked={ props.formikState.values.Handover.Usg.Usg === 'Yes' ? 'Yes' : null} />
                    <Check formikState={props.formikState} id='Handover.Xray.Xray' val='Yes' label='X-Rays' name='Handover.Xray.Xray' checked={ props.formikState.values.Handover.Xray.Xray === 'Yes' ? 'Yes' : null} />
                    <Check formikState={props.formikState} id='Handover.Echo.Echo' val='Yes' label='ECHO' name='Handover.Echo.Echo' checked={ props.formikState.values.Handover.Echo.Echo === 'Yes' ? 'Yes' : null} />
                    <Check formikState={props.formikState} id='Handover.Other.Other' val='Yes' label='Other reports' name='Handover.Other.Other' checked={ props.formikState.values.Handover.Other.Other === 'Yes' ? 'Yes' : null} />
                </div>
                <div className='flex flex-col gap-y-1'>

                    <input
                        className='h-[36px] mt-[10px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                        id='Handover.Ecg.EcgInput'
                        name='Handover.Ecg.EcgInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Handover.Ecg.EcgInput}
                    />

                    <input
                        className='h-[36px] mt-[10px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                        id='Handover.Lab.Lab'
                        name='Handover.Lab.LabInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Handover.Lab.LabInput}
                    />

                    <input
                        className='h-[36px] mt-[10px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                        id='Handover.Usg.UsgInput'
                        name='Handover.Usg.UsgInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Handover.Usg.UsgInput}
                    />
                    <input
                        className='h-[36px] mt-[10px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                        id='Handover.Xray.XrayInput'
                        name='Handover.Xray.XrayInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Handover.Xray.XrayInput}
                    />
                    <input
                        className='h-[36px] mt-[10px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                        id='Handover.Echo.EchoInput'
                        name='Handover.Echo.EchoInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Handover.Echo.EchoInput}
                    />
                    <input
                        className='h-[36px] mt-[10px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                        id='Handover.Other.OtherInput'
                        name='Handover.Other.OtherInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Handover.Other.OtherInput}
                    />

                </div>

            </div>
            <div className='flex flex-row bg-white gap-x-3'>
                <label className='px-20 font-"roboto" text-[14px] font-[500] leading-[14px] text-[#000000] pt-6'>Remarks If any</label>
                <input
                    className='h-[36px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                    id='Handover.Other.OtherInput'
                    name='Handover.Other.OtherInput'
                    type='text'
                    placeholder='Add here'
                    onChange={props.formikState.handleChange}
                    value={props.formikState.values.Handover.Other.OtherInput}
                />

            </div>
        </div>
    );
}

export default HandoverReports;
