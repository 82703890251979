import React from 'react';

const Checkbox = ({ label, value, onChange, name, id, onBlur, checked, disabled, onClick }) => {
    return (
        <label className='flex gap-x-2'>
            <input type="checkbox" value={value} name={name} id={id} onChange={onChange} onBlur={onBlur} onClick = {onClick} defaultChecked={checked} disabled={disabled}/>
            <p className='text-xs'>{label}</p>
        </label>
    );
};

export default Checkbox;
