/* eslint-disable no-console */
import React, { useState } from 'react';
// import React from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryCare from '../../containers/NurseFunctions/PrimaryCare';
import { convertToFhir } from '../../fhir-evolver';
import { addBundle } from '../../api/bundleRequest';
import Loader from '../../components/Loader';

const PrimaryCareAdd = () => {
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const [snackbarOpen] = useSnackbar(options);
    const params = useParams();

    const onAdd = (obj) => {
        console.log('inputprimary', obj);
        convertToFhir('PrimaryCare', obj).then((output) => {
            addBundle(output).then((httpResponse) => {
                setSpinner(true);
                snackbarOpen('PrimaryCare Created Successfully');
                navigate(`/nurse/assessment/${params.id}`);
            });
        }).catch((e) => console.log(e));
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            <PrimaryCare handleSubmit={onAdd} isDisabled={false} isAdd={true} type='add'/>
        </>
    );
};

export default PrimaryCareAdd;
