import React from 'react';

function Ifield ({ isDisabled, ...props }) {
    return (
        <div className='w-[47.5%] px-[14px] p-2'>
            <div className="flex flex-row justify-between items-center py-[6px]">
                <label className='flex flex-col w-[calc(100%-440px)] justify-around font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>{props.title}</label>
                <input
                    className='h-[38px] border-[1px] text-[#757575] border-[#E3E3E3] text-input w-3/5 bg-gray text-center outline-none'
                    id={`${props.id}.data`}
                    name={`${props.id}.data`}
                    type='text'
                    onChange={props.formikState.handleChange}
                    value={props.val.data}
                    disabled={isDisabled}
                />
            </div>
            <div className="flex flex-row justify-between items-center py-[6px]">
                <label className='flex flex-col w-[calc(100%-440px)] justify-around font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Time :</label>
                <input
                    className='h-[38px] border-[1px] border-[#E3E3E3] text-[#757575] text-input w-3/5 bg-gray text-center outline-none'
                    id={`${props.id}.time`}
                    name={`${props.id}.time`}
                    type='time'
                    onChange={props.formikState.handleChange}
                    value={props.val.time}
                    disabled={isDisabled}
                />
            </div>
            <div className="flex flex-row justify-between items-center py-[5px]">
                <label className='flex flex-col w-[calc(100%-440px)] justify-around font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Date :</label>
                <input
                    className='h-[38px] border-[1px] text-[#757575] border-[#E3E3E3] text-input w-3/5 bg-gray text-center outline-none'
                    id={`${props.id}.date`}
                    name={`${props.id}.date`}
                    type='date'
                    onChange={props.formikState.handleChange}
                    value={props.val.date}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
}

export default Ifield;
