/* eslint-disable no-console */
import React, { useState } from 'react';
import { FaPlusSquare } from 'react-icons/fa';
function Medications (props) {
    // console.log('props.formikState.values.Medication ', props.formikState.values.Medication);
    const [val, setVal] = props.formikState.values.Medication ? useState(props.formikState.values.Medication) : useState([]);
    const [expanded, setExpanded] = useState(true);
    const [refreshFlag, setRefreshFlag] = useState();

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const addSubrow = (i) => {
        const TempList = val;
        const Regimen = TempList[i].Regimen;
        console.log(TempList);
        const data = Regimen.split('-');
        for (const regimen of data) {
            // eslint-disable-next-line eqeqeq
            if (regimen != 0) {
                if (TempList[i].subRow !== undefined && TempList[i].subRow.length > 0) {
                    TempList[i].subRow.push({
                        regimeshift: TempList[i].Regimen,
                        Dosage: TempList[i].Dosage,
                        Type: TempList[i].dosageRange,
                        status: TempList[i].ShiftStatus
                    });
                } else {
                    TempList[i].subRow = [{
                        regimeshift: TempList[i].Regimen,
                        Dosage: TempList[i].Dosage,
                        Type: TempList[i].dosageRange,
                        status: TempList[i].ShiftStatus
                    }];
                }
            }
        }

        setVal(TempList);
        setRefreshFlag(!refreshFlag);
    };
    const HandleInputValues = (name, value, i, j) => {
        val[i].subRow[j][name] = value;
        setVal([...val]);
    };
    return (
        <div>
            <div className='h-[45px] items-center bg-tgray justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Medication List</div>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <table className='w-full border-[#B9B9B9] border-[1px]'>
                    <thead>
                        <tr>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Tablet Name</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Regime</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Dosage</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Type</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Start Date</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>End Date</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Administered</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            val.length > 0
                                ? val.map((data, i) => (
                                    <>
                                        <tr key={i}>
                                            {console.log('ssdsdssddd', val[i].Status)}
                                            {console.log('fssgsg', props.formikState.values.Medication[i].Status)}
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' rowSpan={data.subRow ? data.subRow.length + 1 : null} onClick={toggleExpanded} value={data.Medication}>{data.Medication}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Regimen}>{data.Regimen}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Dosage}>{data.Dosage + ' ' + data.Unit}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Route}>{data.Route}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.StartDate}>{new Date(data.StartDate).toString().substring(0, 15)}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.End}>{data.End === '' ? '-' : new Date(data.End).toString().substring(0, 15)}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                <select
                                                    onChange={(event) => {
                                                        console.log('event', event.currentTarget.value);
                                                        val[i].Status = event.currentTarget.value;
                                                        props.formikState.values.Medication[i].Status = val[i].Status;
                                                        setVal(val);
                                                        console.log('valll', val);
                                                    }
                                                    }
                                                    className={val[i].Status === 'not-done' ? '!text-[#FF0000]' : '!text-[#429C0A]'} defaultValue={data.Status} disabled={props.isDisabled}
                                                >
                                                    <option className='!text-[#FF0000]' value='not-done'>Yet to be Administered</option>
                                                    <option className='!text-[#429C0A]' value='completed'>Administered</option>
                                                </select>
                                            </td>
                                            <td className='border-[#B9B9B9] w-[100px] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                <div className='flex justify-around items-center'>
                                                    <button className="px-1" type='button' onClick={() => addSubrow(i)}>
                                                        <FaPlusSquare/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        {expanded && (
                                            data.subRow
                                                ? data.subRow.map((items, j) => (
                                                    <>
                                                        {console.log('items', items)}
                                                        <tr>
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' ><input id="regimeshift"
                                                                name="regimeshift"
                                                                type="text"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                value={items.regimeshift}
                                                                disabled={props.isDisabled}
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'><input id="Dosage"
                                                                name="Dosage"
                                                                type="text"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                disabled={props.isDisabled}
                                                                value={items.Dosage}
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' colSpan='3'><input id="Type"
                                                                name="Type"
                                                                type="text"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                disabled={props.isDisabled}
                                                                value={items.Type}
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                            {/* <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' colSpan='2'><input id="Date"
                                                                name="Date"
                                                                type="date"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                value={items.Date}
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full text-sm text-col text-center"/></td> */}
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' >
                                                                <select
                                                                    onChange={(event) => {
                                                                        data.subRow[j].status = event.currentTarget.value;
                                                                        props.formikState.values.Medication[i].subRow[j].status = items.status;
                                                                        setVal(val);
                                                                    }
                                                                    }
                                                                    disabled={props.isDisabled}
                                                                    className={val[i].Status === 'in-progress' ? '!text-[#FF0000]' : '!text-[#429C0A]'} defaultValue={items.status}
                                                                >
                                                                    <option className='!text-[#FF0000]' value='in-progress'>Yet to be Administered</option>
                                                                    <option className='!text-[#429C0A]' value='completed'>Administered</option>
                                                                </select>
                                                            </td>
                                                            <td className='border-[#B9B9B9] w-[100px] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                                {/* <div className='flex flex-row'>
                                                                <button
                                                                    type="button"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="edit"
                                                                    className="px-1"
                                                                >
                                                                    <img className="h-3 w-4" src={Edit} alt="Edit" />
                                                                </button>
                                                                <button className="px-1" type='button'>
                                                                    <img className="h-3 w-4" src={Delete} alt="Delete" />
                                                                </button>
                                                            </div> */}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                                : <></>
                                        )
                                        }
                                    </>
                                ))
                                : <tr><td></td><td></td><td></td><td className=' text-end  font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>No Data Available</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Medications;
