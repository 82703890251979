export const patientExtensionMap = {
    Guardianship: 'guardianName',
    GuardianType: 'guardianType',
    'patient-religion': 'religion',
    'patient-nationality': 'nationality',
    Occupation: 'occupation',
    Bloodgroup: 'bloodGroup',
    Education: 'education',
    Povertylineno: 'BPLNo',
    Rationcardno: 'rationCard',
    Aadharno: 'aadharNumber',
    Annualincome: 'annualIncome',
    Email: 'email'
};

export const encounterExtensionMap = {
    Category: 'category',
    Mlc: 'Mlc',
    Mlcno: 'MlcText',
    TreatingDepartment: 'department',
    OperativeProcedure: 'procedure'
};

export const locationMap = {
    si: 'hospital',
    lvl: 'floorNo',
    wa: 'wardType',
    ro: 'roomNo',
    bd: 'bedNo'
};

export const observationExtensionMap = {
    GeneralCondition: 'generalcondition',
    Temperature: 'temperature',
    Coherence: 'Coherence',
    PatientAttitude: 'PatientAttitude',
    Orientation: 'Orientation',
    Disorientation: 'Disorientation',
    ThyroidExamination: 'ThyroidExamination',
    BreastExamination: 'BreastExamination'
};

export const parseExtension = (extension) => {
    const eurl = extension.url;
    const ename = eurl.substring(eurl.lastIndexOf('/') + 1, eurl.length);
    const evalue = Object.values(extension)[1];
    return Promise.resolve([ename, evalue]);
};
