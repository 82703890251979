/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { addEncounter } from '../../api/encounter';
import { convertToFhir, convertFromFhirList } from '../../fhir-evolver';
import { useSnackbar } from 'react-simple-snackbar';
import Admission from '../../forms/Admission';
import Arrow1 from '../../assets/images/arrow1.svg';
import { SnackbarStyles } from '../../components/SnackbarStyles';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { config } from '../../utils/config';
import { getPatientData } from '../../api/patient';

const PatientAdmission = () => {
    const [spinner, setSpinner] = useState(false);
    const [open] = useSnackbar(SnackbarStyles);
    const navigate = useNavigate();
    const params = useParams();
    const [patientDetail, setPatientDetail] = useState('');
    console.log(patientDetail);

    useEffect(() => {
        setSpinner(true);
        getPatientData(`organization=${config.organizationID}&_count=100`).then((response) => {
            setSpinner(false);
            if (response.data.entry !== undefined) {
                convertFromFhirList('Patient', response.data.entry).then((res) => {
                    const patient = res.filter(data => data.id === params.id);
                    setPatientDetail(patient);
                }).catch(e => {
                    return e;
                });
            }
        });
    }, []);
    const addAdmission = (obj) => {
        convertToFhir('Encounter', obj).then(res => {
            addEncounter(res).then(res => {
                setSpinner(true);
                open('Admission created successfully');
                navigate('/patient/admission-list');
            })
                .catch(e => {
                    return e;
                });
        });
    };

    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className="bg-gray h-auto w-full sm:w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center">
                        <Link to='/home'>
                            <span className="text-base font-normal pl-5 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow"/>
                        <span className="text-black font-normal text-base px-2 py-2">Patient Admission Form</span>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <span className='text-2xl font-bold font-montserrat mx-auto pb-2 text-black '>
                        Patient Admission Form
                    </span>
                </div>
                <div className='bg-white ml-6 mr-6 flex flex-row justify-between'>
                    <Admission type={'add'} handleSubmit={addAdmission} patientData={patientDetail?.[0]}/>
                </div>
            </div>
        </div>
    );
};

export default PatientAdmission;
