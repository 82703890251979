import React from 'react';

const RadioButton = ({ label, value, onChange, name, id, onBlur, checked, disabled, data }) => {
    return (
        <label className='flex'>
            <input type="radio" value={value} name={name} id={id} onChange={onChange} onBlur={onBlur} checked={checked} disabled={disabled}/>
            {data === 'yes' ? <p className='pt-2 pl-0.5 text-xs'>{label}</p> : <p className='p-0.5 text-xs'>{label}</p> }
        </label>
    );
};

export default RadioButton;
