import React from 'react';
import './App.css';
import Index from './pages/index.js';
import SnackbarProvider from 'react-simple-snackbar';
import { AuthProvider } from './context/AuthProvider.js';

function App () {
    return (
        <AuthProvider>
            <div className="App">
                <SnackbarProvider>
                    <Index />
                </SnackbarProvider>
            </div>
        </AuthProvider>
    );
}

export default App;
