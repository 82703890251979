import React from 'react';
import { NavLink } from 'react-router-dom';

function Nav () {
    return (
        <div className='pl-5 pt-2 flex bg-bgray border-b-2 border-b-slate-300'>
            {/* <NavLink to="/functions/bard" className='w-[338px] h-[38px] flex items-center justify-center rounded-t-lg border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white'>Barden risk assessments scale for bed sore</NavLink> */}
            <NavLink to="/functions/primary-care" className='w-[196px] h-[38px] flex items-center justify-center rounded-t-lg border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white'>Primary Care</NavLink>
            <NavLink to="/functions/station" className='w-[196px] h-[38px] flex items-center justify-center rounded-t-lg border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white'>Secondary Care</NavLink>
        </div>
    );
}

export default Nav;
