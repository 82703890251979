/* eslint-disable no-console */
import React, { useState } from 'react';
import Popup from './Popup';
import { GoPencil } from '@react-icons/all-files/go/GoPencil';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';
// import Edit from '../../../assets/images/edit.svg';
// import Delete from '../../../assets/images/delete.svg';
import { FaPlusSquare } from 'react-icons/fa';
function Medications (props) {
    const [openPopup, setOpenPopup] = useState(false);
    const [medicationPrefillValue, setMedicationPrefillValue] = useState({ state: 0 });
    const [editingIndex, setEditingIndex] = useState();
    // const [val, setVal] = useState([]);
    const [val, setVal] = props.formikState.values.Medication ? useState(props.formikState.values.Medication) : useState([]);
    const [expanded, setExpanded] = useState(true);
    const [refreshFlag, setRefreshFlag] = useState();

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    let ind = 0;

    // const getMedicationData = (data) => {
    //     console.log('data', data);
    //     const abc = [...val, {}];
    //     setVal(abc);
    //     props.formikState.values.Medication.push(data);
    //     setOpenPopup(false);
    //     // eslint-disable-next-line no-console
    //     console.log('data', props.formikState.values.Medication, val, data);
    // };

    const getMedicationData = (data) => {
        // console.log('data', data);
        const finalData = val;
        finalData.push(data);
        props.updateData(finalData);
        setOpenPopup(false);
        // console.log('data', props.formikState.values.Medication, val, data);
    };

    const handleDelete = (i) => {
        props.formikState.values.Medication.splice(i, 1);
        const deletVal = [...val];
        deletVal.splice(i, 1);
        setVal(deletVal);
    };

    const handleUpdate = (data, i) => {
        val[i] = data;
        props.updateData(val);
        setOpenPopup(false);
    };

    const addSubrow = (i) => {
        const TempList = val;
        // console.log('teeeemp', TempList);
        const Regimen = TempList[i].Regimen;
        const data = Regimen.split('-');
        for (const regimen of data) {
            // console.log('red', regimen);
            // eslint-disable-next-line eqeqeq
            if (regimen != 0) {
                // console.log('regcheck', regimen);
                if (TempList[i].subRow !== undefined && TempList[i].subRow.length > 0) {
                    TempList[i].subRow.push({
                        regimeshift: TempList[i].Regimen,
                        Dosage: TempList[i].Dosage,
                        Type: TempList[i].Route,
                        // Date: '',
                        status: TempList[i].ShiftStatus
                    });
                } else {
                    TempList[i].subRow = [{
                        regimeshift: TempList[i].Regimen,
                        Dosage: TempList[i].Dosage,
                        Type: TempList[i].Route,
                        // Date: '',
                        status: TempList[i].ShiftStatus
                    }];
                }
            }
        }

        setVal(TempList);
        setRefreshFlag(!refreshFlag);
    };

    const HandleInputValues = (name, value, i, j) => {
        val[i].subRow[j][name] = value;
        setVal([...val]);
    };

    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Medication List<span className='text-[crimson] text-[20px]'><pre>*</pre></span></div>
                {/* <button type="button" onClick={() => { setOpenPopup(true); setMedicationPrefillValue({}); setMedicationPrefillValue({ ...medicationPrefillValue, state: 0 }); }}>Add New</button> */}
            </div>
            <div className='flex justify-end px-6'><button className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[4rem] my-4 text-[16px] border text-white font-[500] h-12' type="button" onClick={() => { setOpenPopup(true); setMedicationPrefillValue({}); setMedicationPrefillValue({ ...medicationPrefillValue, state: 0 }); }} disabled={props.isDisabled}>+ Add Medication</button></div>
            <div className='bg-white px-3 py-[20px]'>
                <table className='w-full border-[#B9B9B9] border-[1px]'>
                    <thead>
                        <tr>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Tablet Name</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Regime</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Dosage</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Type</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Start Date</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>End Date</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Administered</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.formikState.values.Medication.length > 0
                                ? props.formikState.values.Medication.map((data, i) => (
                                    <>
                                        {/* {console.log('medicationdata', data)} */}
                                        <tr key={ind++}>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' rowSpan={data.subRow ? data.subRow.length + 1 : null} value={data.Medication} onClick={toggleExpanded}>{data.Medication}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Regimen}>{data.Regimen}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Dosage}>{data.Dosage + ' ' + data.Unit}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Route}>{data.Route}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.StartDate}>{new Date(data.StartDate).toString().substring(0, 15)}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.End}>{data.End === '' ? '-' : new Date(data.End).toString().substring(0, 15)}</td>
                                            {/* <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Medication}></td> */}
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                <select
                                                    id={`Medication[${i}].Status`}
                                                    name={`Medication[${i}].Status`}
                                                    onChange={props.formikState.handleChange}
                                                    className={props.formikState.values.Medication[i].Status !== 'completed' ? '!text-[#FF0000] outline-none' : '!text-[#429C0A] outline-none'}
                                                    value={
                                                        props.formikState.values.Medication[i].Status
                                                    }
                                                    disabled={props.isDisabled}
                                                >
                                                    <option className='!text-[#FF0000]' value='in-progress'>Yet to be Administered</option>
                                                    <option className='text-[#429C0A]' value='completed'>Administered</option>
                                                </select>
                                            </td>

                                            <td className='border-[#B9B9B9] w-[100px] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                <div className='flex justify-around items-center'>
                                                    <button
                                                        type="button"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="view"
                                                        onClick={
                                                            () => {
                                                                data.state = 1;
                                                                setMedicationPrefillValue(data);
                                                                setOpenPopup(true);
                                                            }
                                                        }
                                                    >
                                                        <AiFillEye />
                                                    </button>
                                                    <button data-bs-toggle="tooltip" data-bs-placement="top" title="edit" disabled = {props.isDisabled} onClick={() => {
                                                        setEditingIndex(i);
                                                        data.state = 2;
                                                        setMedicationPrefillValue(data);
                                                        setOpenPopup(true);
                                                    }} >
                                                        <GoPencil />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="delete"
                                                        onClick={() => { handleDelete(i); }}
                                                        disabled = {props.isDisabled}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                    <button className="px-1" type='button' onClick={() => addSubrow(i)}>
                                                        <FaPlusSquare/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        {expanded && (
                                            data.subRow
                                                ? data.subRow.map((items, j) => (
                                                    <>
                                                        <tr>
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' ><input id="regimeshift"
                                                                name="regimeshift"
                                                                type="text"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                value={items.regimeshift}
                                                                disabled
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'><input id="Dosage"
                                                                name="Dosage"
                                                                type="text"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                value={items.Dosage}
                                                                disabled
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' colSpan='3'><input id="Type"
                                                                name="Type"
                                                                type="text"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                value={items.Type}
                                                                disabled
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                            {/* <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' colSpan='2' ><input id="Date"
                                                                name="Date"
                                                                type="date"
                                                                onChange={(event) =>
                                                                    HandleInputValues(event.target.name, event.target.value, i, j)
                                                                }
                                                                value={items.Date}
                                                                className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full text-sm text-col text-center"/></td> */}
                                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' >
                                                                {/* <select
                                                                    onChange={(event) => {
                                                                        data.subRow[j].status = event.currentTarget.value;
                                                                        props.formikState.values.Medication[i].status = data.subRow[j].status;
                                                                        setVal(val);
                                                                    }
                                                                    }
                                                                    className={val[i].Status === 'in-progress' ? '!text-[#FF0000]' : '!text-[#429C0A]'} defaultValue={data.Status} disabled={props.disable}
                                                                >
                                                                    <option className='!text-[#FF0000]' value='in-progress'>Yet to be Administered</option>
                                                                    <option className='text-[#429C0A]' value='completed'>Administered</option>
                                                                </select> */}
                                                                <input id="status"
                                                                    name="status"
                                                                    type="text"
                                                                    disabled
                                                                    onChange={(event) =>
                                                                        HandleInputValues(event.target.name, event.target.value, i, j)
                                                                    }
                                                                    value={items.status}
                                                                    className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/>
                                                            </td>
                                                            <td className='border-[#B9B9B9] w-[100px] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                                {/* <div className='flex flex-row'>
                                                                    <button
                                                                        type="button"
                                                                        data-bs-toggle="tooltip" data-bs-placement="top" title="edit"
                                                                        className="px-1"
                                                                    >
                                                                        <img className="h-3 w-4" src={Edit} alt="Edit" />
                                                                    </button>
                                                                    <button className="px-1" type='button'>
                                                                        <img className="h-3 w-4" src={Delete} alt="Delete" />
                                                                    </button>
                                                                </div> */}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                                : <></>
                                        )
                                        }
                                    </>
                                ))
                                : <tr><td></td><td></td><td></td><td className=' text-end  font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>No Data Available</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                <Popup
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    prefillData={medicationPrefillValue}
                    quantityVal={1}
                    updateData={(data) => handleUpdate(data, editingIndex)}
                    sendValue={(data) => getMedicationData(data)}
                    isDisabled = {props.isDisabled}
                ></Popup>
            }
        </div>
    );
}

export default Medications;
