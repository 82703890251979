import React from 'react';
import { Route } from 'react-router-dom';

import UserRole from '../containers/User/UserRole';
import Hospital from '../containers/Hospital/Hospital';
import Action from '../containers/User/Action';
import Application from '../containers/User/Application';
import PrivateRoute from '../hooks/PrivateRoute';
import User from '../containers/User/User';
import { ROLE } from '../utils/roles';

const AdminRoutes = (
    <Route element={<PrivateRoute roles={[ROLE.admin.name]} />} >
        <Route path="user" element={<User />} />
        <Route path="user/role" element={<UserRole />} />
        <Route path="user/hospital" element={<Hospital />} />
        <Route path="user/action" element={<Action />} />
        <Route path="user/application" element={<Application />} />
    </Route>
);

export default AdminRoutes;
