import axios from 'axios';
import { config } from './config';

const Getapi = () => {
    return config.api.qa;
};

export default axios.create({
    mode: 'no-cors',
    baseURL: Getapi(),
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')

    }
});
