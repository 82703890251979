import React, { useState } from 'react';
import ProgressNav from './ProgressNav';
import Station from '../NurseFunctions/Station';

const ProgressSecondaryCare = () => {
    const [secondary] = useState([]);
    return (
        <div className='h-screen'>
            <ProgressNav />
            { secondary ? <div className='flex items-center'><span className="text-2xl pt-4 font-bold font-montserrat mx-auto pb-2 text-black ">No Data Available</span></div> : <Station isDoctor={true}/>}
        </div>
    );
};

export default ProgressSecondaryCare;
