import React from 'react';
import close from '../assets/images/close.svg';

const Modal = (props) => {
    return (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50  outline-none focus:outline-none">
            <div className="border-0 rounded-lg shadow-lg relative bg-white  flex flex-col w-full max-w-3xl h-minbg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 rounded-t ">
                    <h4 className="h-5 text-lg font-bold">{props.heading}</h4>
                    <button
                        type='button'
                        className="bg-transparent border-0 text-black float-right"
                        onClick={() => props.handleModalClose(false)}
                    >
                        <img className="h-5" src={close} alt="close"/>

                    </button>
                </div>
                <div className="relative p-6 pb-1.5 pt-0.5 flex-auto">
                    {props.body}
                </div>
            </div>
        </div>
    );
};
export default Modal;
