/* eslint-disable no-console */

// import React, { useEffect } from 'react';
import React from 'react';
import HemoDynamicParams from './HemoDynamicParams';
import VentilationParams from './VentilationParams';
import ArterialBloodGas from './ArterialBloodGas';
import Handover from './Handover';
// import Medicine from './medicine';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import IntakeOutput from './IntakeOutput';
// import { convertToFhir } from '../../fhir-evolver';
// import { convertToFhir, convertFromFhir } from '../../fhir-evolver';
// import { addBundle } from '../../api/bundleRequest';
// import { getClinicalImpressionById } from '../../api/clinicalImpression';
import { generatePrimaryCare } from '../../utils/PrimaryCareFormik';
import Medications from './Medications';

const PrimaryCare = ({ viewPrimary, isView, isDisabled, ...props }) => {
    const location = useLocation();
    let encounterId = '';
    // checking whether location.state is an encounter or an clinical impression resource
    if (location?.state?.investigations) {
        encounterId = location?.state?.encounter?.id;
    } else {
        encounterId = location?.state?.id;
    }
    const patientId = location?.state?.patient?.id;
    if (!patientId || !encounterId) {
        // eslint-disable-next-line no-console
        console.log('Missing crucial params', ['patientId', patientId], ['encounterId', encounterId]);
    }
    const primaryCare = Object.assign({}, generatePrimaryCare());
    let inputData = '';
    if (isView || props.isEdit) {
        inputData = viewPrimary;
    } else {
        inputData = primaryCare;
    }
    inputData.Patient = patientId;
    inputData.Encounter = encounterId;
    const submitHandler = (data, { setSubmitting }) => {
        formikState.values.type = props.type;
        const val = props.type === 'add' ? data : [data, props.data];
        props.handleSubmit(val);
        setSubmitting(false);
    };
    const formikState = useFormik({
        // initialValues: primaryCare,
        initialValues: inputData,
        enableReinitialize: false,
        onSubmit: submitHandler
    });
    return (
        <div>
            <form className="overflow-hidden" onSubmit={formikState.handleSubmit}>
                <div className="flex flex-row justify-between">
                    <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                        Primary Care
                    </span>
                </div>
                <p className='text-black text-2xl font-bold mt-9 mb-3'>Nursing Intial Assessment</p>
                <p className='text-black text-2xl font-bold mt-9 mb-3'>Hemo-Dynamic Parameters</p>
                <HemoDynamicParams formikState={formikState} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
                <p className='text-black text-2xl font-bold mt-9 mb-3'>Ventilation Parameters</p>
                <VentilationParams formikState={formikState} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
                <p className='text-black text-2xl font-bold mt-9 mb-3'>Intake Output</p>
                <IntakeOutput formikState={formikState} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
                <p className='text-black text-2xl font-bold mt-9 mb-3'>ABG</p>
                <ArterialBloodGas formikState={formikState} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
                <p className='text-black text-2xl font-bold mt-9 mb-3'>Hand Over Notes</p>
                <Handover formikState={formikState} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
                <Medications formikState={formikState} isDisabled={isDisabled}/>
                {props.isDoctor
                    ? <div className='bg-iat h-[45px] items-center px-[14px] flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div>Special Instruction</div>
                    </div>
                    : <div className='bg-tgray h-[45px] items-center px-[14px] flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div>Special Instruction</div>
                    </div>}
                <div className='flex bg-white my-4'>
                    <div className='bg-white px-3 py-[5px]'>
                        <div className='flex items-center justify-between'>
                            <div className='w-[360px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Instruction : </div>
                            <textarea
                                className='h-[12vh] w-full text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                type='text'
                                id={'SpecialInstruction.SpecialInstruction'}
                                name={'SpecialInstruction.SpecialInstruction'}
                                onChange={formikState.handleChange}
                                value={formikState.values.SpecialInstruction.SpecialInstruction}
                                disabled={isDisabled}
                            />
                        </div>
                    </div>

                </div>
                {props.isDoctor
                    ? <div className='text-center flex justify-end'><button disabled={isDisabled} type='submit' className='w-[196px] mb-10 h-[38px] border-2 font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#00A2B7] background active:bg-iat active:text-white active'>Submit</button></div>
                    : <div className='text-center flex justify-end'><button disabled={isDisabled} type='submit' className='w-[196px] mb-10 h-[38px] border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white active'>Submit</button></div>}

            </form>
        </div>
    );
};
export default PrimaryCare;
