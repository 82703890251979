import axios from '../utils/axios';

export const getDiagnosticReports = async () => {
    return axios.get('/prod/fhir/DiagnosticReport?_include=*').then((response) => {
        return response.data;
    });
};

export const getDiagnosticReportWithQueryParams = (params) => {
    const url = `/prod/fhir/DiagnosticReport?${params}`;
    // if (params) {
    //     url += params;
    // }
    return axios.get(url).then((response) => {
        return response.data;
    });
};
