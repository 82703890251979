import React from 'react';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { TextField } from '../components/TextField';
import { Dropdown } from '../components/Dropdown';
import Button from '../components/Button';

const PatientDetailForm = (props) => {
    const formikState = useFormik({
        initialValues: {
            weight: props.editData?.weight || '',
            height: props.editData?.height || '',
            bmi: props.editData?.bmi || '',
            systolic: props.editData?.systolic || '',
            diastolic: props.editData?.diastolic || '',
            temperature: props.editData?.temperature || '',
            heartRate: props.editData?.heartRate || '',
            urineOutput: props.editData?.urineOutput || '',
            bloodSugar1: props.editData?.bloodSugar1 || '',
            bloodSugar2: props.editData?.bloodSugar2 || '',
            spo2: props.editData?.spo2 || '',
            avpu: props.editData?.avpu || '',
            trauma: props.editData?.trauma || '',
            mobility: props.editData?.mobility || '',
            oxygen: props.editData?.oxygen || '',
            intake: props.editData?.intake || '',
            output: props.editData?.output || '',
            vitalTakenTime: props.editData?.vitalTakenTime || '',
            comments: props.editData?.comments || ''
        },

        onSubmit: values => {
            // console.log(values);
            alert(JSON.stringify(values));
        }

    });

    return (
        <form className="w-full flex flex-col gap-y-4" onSubmit={formikState.handleSubmit}>
            <div className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] p-3 flex flex-row justify-between h-12">
                <span className="text-white text-lg font-roboto ">Vital Signs (History)</span>
            </div>

            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Weight (kg)"
                            id="weight"
                            name="weight"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.weight}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Height (cm)"
                            id="height"
                            name="height"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.height}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="BMI (kg/m2)"
                            id="bmi"
                            name="bmi"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.bmi}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                        {formikState.touched.birthDate && formikState.errors.birthDate
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.birthDate}</div>
                            )
                            : null}
                    </div>

                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Systolic B.P."
                            id="systolic"
                            name="systolic"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.systolic}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Diastolic B.P."
                            id="diastolic"
                            name="diastolic"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.diastolic}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Temperature (oC)"
                            id="temperature"
                            name="temperature"
                            type="text"
                            value={formikState.values.temperature}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}

                        />
                    </div>
                    <div className="flex flex-col w-[31%] px-6">
                        <TextField
                            label="Respiratory Rate (/Min)"
                            id="respiratory"
                            name="respiratory"
                            type="text"
                            value={formikState.values.respiratory}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Heart Rate (BPM)"
                            id="heartRate"
                            name="heartRate"
                            type="text"
                            value={formikState.values.heartRate}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Urine Output"
                            id="urineOutput"
                            name="urineOutput"
                            type="text"
                            value={formikState.values.urineOutput}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Blood Sugar (F)"
                            id="bloodSugar1"
                            name="bloodSugar1"
                            type="text"
                            value={formikState.values.bloodSugar1}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Blood Sugar (R)"
                            id="bloodSugar2"
                            name="bloodSugar2"
                            type="text"
                            value={formikState.values.bloodSugar2}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}

                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="SPO2"
                            id="spo2"
                            name="spo2"
                            type="text"
                            value={formikState.values.spo2}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <Dropdown
                            label="AVPU"
                            name="avpu"
                            value={formikState.values.avpu}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            style = {props.style}
                        >
                            {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[31%] px-6">
                        <Dropdown
                            label="Trauma"
                            name="trauma"
                            value={formikState.values.trauma}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            style = {props.style}
                        >
                            {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <Dropdown
                            label="Mobility"
                            name="mobility"
                            value={formikState.values.mobility}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            style = {props.style}
                        >
                            {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <Dropdown
                            label="Oxygen Supplementation"
                            name="oxygen"
                            value={formikState.values.oxygen}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            style = {props.style}
                        >
                            {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Intake"
                            id="intake"
                            name="intake"
                            type="text"
                            value={formikState.values.intake}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Output"
                            id="output"
                            name="output"
                            type="text"
                            value={formikState.values.output}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[31%]  px-6">
                        <TextField
                            label="Vital Taken Time"
                            id="vitalTakenTime"
                            name="vitalTakenTime"
                            type="datetime-local"
                            value={formikState.values.vitalTakenTime}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-row w-full px-6 gap-[5.3rem]">
                        <label className="font-roboto text-sm">Comments:</label>
                        <input
                            id="comments"
                            name="comments"
                            type="text"
                            value={formikState.values.comments}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-[84.5%] text-sm px-1"
                            disabled={props.isDisabled}
                            style = {props.style}
                        />
                    </div>
                </div>
            </div>

            <div className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] p-3 flex flex-row justify-between h-12">
                <span className="text-white text-lg font-roboto ">Rx</span>
            </div>

            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] py-[0.5rem] px-[4rem] my-8 text-sm border rounded-md text-white h-12"
                        disabled={props.isDisabled}
                    />
                    <Link to="/patient">
                        <Button
                            label="Cancel"
                            className="bg-gray border-graycol py-[0.5rem] px-[4rem] my-8 text-sm  border rounded-md text-col h-12"
                        />
                    </Link>
                </div>
            </div>

        </form>
    );
};

export default PatientDetailForm;
