/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { FaAngleDoubleDown, FaAngleDoubleUp } from 'react-icons/fa';

const ScrollWindowUpDown = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [scrollIcon, setScrollIcon] = useState(<FaAngleDoubleDown />);
    const [scrollAction, setScrollAction] = useState('up');
    useEffect(() => {
        window.addEventListener('scroll', HandleScroll, false);
    }, []);
    let pastScrollTopValue = 0;
    const HandleScroll = () => {
        if (Number(pastScrollTopValue) > document.documentElement.scrollTop) { setScrollIcon(<FaAngleDoubleUp />); setScrollAction('up'); } else { setScrollIcon(<FaAngleDoubleDown />); setScrollAction('down'); }
        pastScrollTopValue = document.documentElement.scrollTop;
    };

    const scrollDown = () => {
        window.scroll({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    const scrollUp = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <button type='button' onClick={() => scrollAction === 'down' ? scrollDown() : scrollUp()} className={props.isNurse ? 'border-[1px] px-3 py-2 text-[28px] bg-gradient text-white fixed right-[20px] bottom-[20px] z-50' : 'border-[1px] px-3 py-2 text-[28px] bg-iat text-white fixed right-[20px] bottom-[20px] z-50'}>{scrollIcon}</button>
        </>
    );
};
export default ScrollWindowUpDown;
