import React from 'react';
import { useLocation } from 'react-router-dom';
import doctor from '../../assets/images/doctor.svg';

const Doctor = () => {
    const location = useLocation();
    return (
        <div className="bg-start w-full h-screen flex flex-col justify-center items-center  bg-cover bg-no-repeat bg-center" >
            <h1 className="text-6xl font-extrabold font-Montserrat text-[#000000] text-center mb-10">
          Welcome!
            </h1>
            <div className="flex justify-center">
                <img src={doctor} alt="logo" />
            </div>
            <h1 className="text-5xl font-bold font-Montserrat text-[#000000] text-center mt-10">
                {location.state?.data.user.name}
            </h1>
        </div>

    );
};

export default Doctor;
