import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Table from '../../components/Table';
import '../../assets/css/Style.css';
import Modal from '../../components/Modal';
import ActionForm from '../../forms/Action';
import { patientCustomStyles } from '../../style';
import SideNavbar from '../SideNavbar/SideNavbar';

import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';
import View from '../../assets/images/view.svg';

const Action = () => {
    const [selectedObject, setSelectedObject] = useState({
        actionName: '',
        description: ''
    });
    const [showAddModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleCloseAddModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setShowEditModal(showAddModal);
        setSelectedObject({
            actionName: '',
            description: ''
        });
    }, [showAddModal]);

    const editHandler = (row) => {
        setSelectedObject(row);
        setShowEditModal(true);
    };

    const viewHandler = (row) => {
        setSelectedObject(row);
        setShowEditModal(true);
    };
    const handleCloseModal = () => {
        if (showAddModal) handleCloseAddModal();
        else {
            setShowEditModal(false);
        }
    };

    const columns = [
        {
            name: 'Action Id',
            selector: (row) => row.id,
            sortable: true
        },
        {
            name: 'Action Name',
            selector: (row) => row.actionName,
            sortable: true
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: true
        },
        {
            name: 'Actions',
            selector: (row) => (
                <div>
                    <button className='px-1'>
                        <img className='h-3 w-4' onClick={() => viewHandler(row)} src={View} alt='view'/>
                    </button>
                    <button className='px-1'>
                        <img className='h-3 w-4' onClick={() => editHandler(row)} src={Edit} alt='edit'/>
                    </button>

                    <button >
                        <img className='h-3 w-4' onClick={() => deleteHandler(row.id)} src={Delete} alt='delete' />
                    </button>
                </div>
            )
        }
    ];

    const rowData = [
        {
            id: 1,
            actionName: 'action1',
            description: 'general'
        },
        {
            id: 2,
            actionName: 'action2',
            description: 'general'
        }
    ];

    const [values, setValues] = useState(rowData);

    const onAdd = (data) => {
        data.id = values.length + 1;
        setValues([...values, data]);
        setShowModal(false);
    };

    const onEdit = (data) => {
        data.id = selectedObject.id;
        setValues(values.map(item => (item.id === data.id ? data : item)));
        setShowEditModal(false);
    };

    const deleteHandler = id => {
        setValues(values.filter(value => value.id !== id));
    };

    return (
        <div>
            <div className='fixed'> <SideNavbar /></div>
            <div className='ml-[12.5rem] h-screen'>
                <div className='bg-background h-full w-full'>
                    <div className='flex flex-row justify-between '>
                        <div className='flex items-center'>
                            <Link to={'/home'}>
                                <span className='text-base font-normal px-2 py-2 text-col'>
                              Home
                                </span>
                            </Link>
                            <img src={Arrow1} alt='arrow'/>
                            <span className='text-black font-normal text-base  font-roboto px-2 py-2'>Actions</span>
                        </div>
                        <div className='flex flex-col p-2'>
                            <button className='w-72 p-2 h-11 mt-4 bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base text-extrabold text-white' onClick={() => setShowModal(true)}>Add Action</button>
                            {showEditModal && <Modal
                                handleModalClose={handleCloseModal}
                                heading='Action'
                                body={<ActionForm
                                    handleModalClose={handleCloseModal}
                                    data={selectedObject}
                                    onAdd={onAdd}
                                    onEdit={onEdit}
                                    isEdit={!showAddModal}
                                />}
                            />}
                        </div>
                    </div>
                    <div className='flex flex-row justify-between'>
                        <span className='text-3xl font-bold px-2 py-5 text-black'>
                        Action List
                        </span>
                        <div className='flex flex-col p-2'>
                            <div className='pt-2 relative mx-auto text-col'>
                                <input
                                    className='shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none'
                                    type='search'
                                    name='search'
                                    placeholder='Search'
                                />
                                <button type='submit' className='absolute right-0 top-0 mt-6 mr-4'>
                                    <img src={Search} alt='search'/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between'>
                        <Table rows={values} columns={columns} customStyles={patientCustomStyles} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Action;
