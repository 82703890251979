/* eslint-disable no-console */
import React from 'react';
import Title from '../Title';
import status from '../.././../utils/json/NutritionalStatus.json';

function Nutstatus (props) {
    // const meterToInchesandCentimeters = (e) => {
    //     const meter = e.target.value;
    //     const inches = meter * 39.370;
    //     const centimeters = meter / 0.01;
    //     props.formikState.handleChange(e);
    //     props.formikState.values.NutritionalStatus.Parameter.HeightInch = inches.toFixed(1);
    //     props.formikState.values.NutritionalStatus.Parameter.HeightCentimeter = centimeters.toFixed(1);
    // };
    // const InchestoMeterandCentimeters = (e) => {
    //     const inches = e.target.value;
    //     const meter = inches / 39.370;
    //     const centimeters = inches / 0.39370;
    //     props.formikState.handleChange(e);
    //     props.formikState.values.NutritionalStatus.Parameter.HeightMeter = meter.toFixed(1);
    //     props.formikState.values.NutritionalStatus.Parameter.HeightCentimeter = centimeters.toFixed(1);
    // };

    // const CentimeterstoMetersandInches = (e) => {
    //     const centimeters = e.target.value;
    //     const meter = centimeters / 100;
    //     const inches = centimeters * 0.39370;
    //     props.formikState.handleChange(e);
    //     props.formikState.values.NutritionalStatus.Parameter.HeightMeter = meter.toFixed(1);
    //     props.formikState.values.NutritionalStatus.Parameter.HeightInch = inches.toFixed(1);
    // };
    const meterToInchesandCentimeters = (e) => {
        const meter = e.target.value;
        const inches = meter * 39.370;
        const centimeters = meter / 0.01;
        props.formikState.handleChange(e);
        props.formikState.values.NutritionalStatus.Parameter.HeightInch = inches.toFixed(1);
        props.formikState.values.NutritionalStatus.Parameter.HeightCentimeter = centimeters.toFixed(1);
    };
    const InchestoMeterandCentimeters = (e) => {
        const inches = e.target.value;
        const meter = inches / 39.370;
        const centimeters = inches / 0.39370;
        props.formikState.handleChange(e);
        props.formikState.values.NutritionalStatus.Parameter.HeightMeter = meter.toFixed(1);
        props.formikState.values.NutritionalStatus.Parameter.HeightCentimeter = centimeters.toFixed(1);
    };

    const CentimeterstoMetersandInches = (e) => {
        const centimeters = e.target.value;
        const meter = centimeters / 100;
        const inches = centimeters * 0.39370;
        props.formikState.handleChange(e);
        props.formikState.values.NutritionalStatus.Parameter.HeightMeter = meter.toFixed(1);
        props.formikState.values.NutritionalStatus.Parameter.HeightInch = inches.toFixed(1);
    };

    return (
        <div>
            <Title title={'Nutritional Status'} isNurse = {props.isNurse}/>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center mb-[20px] justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Nutritional Status :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="NutritionalStatus.NutritionalStatus.NutritionalStatusInput"
                                id="NutritionalStatus.NutritionalStatus.NutritionalStatusInput"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.NutritionalStatus.NutritionalStatus.NutritionalStatusInput}
                            >
                                {
                                    status.map((data, i) => (
                                        <option value={status[i].value} key={i}>{status[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='flex my-[20px] items-center h-[47px]'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Weight Loss in the last<br/>three months :</div>
                    <div className='flex justify-between w-[125px] gap-x-6'>
                        <label className='self-center flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] pl-7'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonths'
                                type='radio'
                                id='No'
                                onChange={props.formikState.handleChange}
                                value='No'
                                checked={props.formikState.values.NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonths === 'No'}
                                disabled={props.disabled}
                            />No
                        </label>
                        <label className='self-center flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonths'
                                id='Yes'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                value='Yes'
                                checked={props.formikState.values.NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonths === 'Yes'}
                                disabled={props.disabled}
                            />Yes
                        </label>
                    </div>
                    {props.formikState.values.NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonths === 'Yes' &&
                        <input
                            className='h-[47px] ml-[45px] text-[#8F8F8F] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none w-full'
                            id='NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonthsInput'
                            name='NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonthsInput'
                            type='text'
                            placeholder='approx. (Kgs)'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.NutritionalStatus.WtLossinThreeMonths.WtLossinThreeMonthsInput}
                            disabled={props.disabled}
                        />
                    }
                </div>
                <div className='flex my-[20px] items-center h-[47px]'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Any History of <br/>reduced food intake<br/>in the past weeks ? :</div>
                    <div className='flex justify-between w-[125px] gap-x-6'>
                        <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575] pl-7'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntake'
                                type='radio'
                                id='No'
                                onChange={props.formikState.handleChange}
                                value='No'
                                checked={props.formikState.values.NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntake === 'No'}
                                disabled={props.disabled}
                            />No
                        </label>
                        <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntake'
                                id='Yes'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                value='Yes'
                                checked={props.formikState.values.NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntake === 'Yes'}
                                disabled={props.disabled}
                            />Yes
                        </label>
                    </div>
                    {props.formikState.values.NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntake === 'Yes' &&
                    <input
                        className='h-[47px] ml-[45px] text-[15px] pl-[5px] text-[#8F8F8F] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none w-full'
                        id='NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntakeInput'
                        name='NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntakeInput'
                        type='text'
                        placeholder='Write here'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.NutritionalStatus.HistoryofReducedFoodIntake.HistoryofReducedFoodIntakeInput}
                        disabled={props.disabled}
                    />
                    }
                </div>
                <div className='flex mt-[40px] items-center h-[47px]'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'></div>
                    <div className='flex'>
                        <label className='self-center flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            {/* <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.HeightMeter'
                                name='NutritionalStatus.Parameter.HeightMeter'
                                type='number'
                                placeholder='Height'
                                onChange={props.formikState.handleChange}
                                value={props.isView ? props.formikState.values.NutritionalStatus.Parameter.HeightMeter : null}
                                disabled={props.disabled}
                            /> */}
                            <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.HeightMeter'
                                name='NutritionalStatus.Parameter.HeightMeter'
                                type='number'
                                placeholder='Height'
                                onChange={(e) => meterToInchesandCentimeters(e)}
                                value={props.formikState.values.NutritionalStatus.Parameter.HeightMeter}
                            />
                            <div className="font-'roboto' ml-[10px] font-[400] text-[13px] leading-[15px] text-[#8F8F8F]">meters</div>
                        </label>
                        <label className='self-center flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] ml-2'>
                            {/* <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.HeightCentimeter'
                                name='NutritionalStatus.Parameter.HeightCentimeter'
                                type='number'
                                placeholder='Height'
                                onChange={(e) => CentimeterstoMetersandInches(e)}
                                // value={props.isView ? props.formikState.values.NutritionalStatus.Parameter.HeightCentimeter : null}
                                disabled={props.disabled}
                            /> */}
                            <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.HeightCentimeter'
                                name='NutritionalStatus.Parameter.HeightCentimeter'
                                type='number'
                                placeholder='Height'
                                onChange={(e) => CentimeterstoMetersandInches(e)}
                                value={props.formikState.values.NutritionalStatus.Parameter.HeightCentimeter}
                            />
                            <div className="font-'roboto' ml-[10px] font-[400] text-[13px] leading-[15px] text-[#8F8F8F]">centimeters</div>
                        </label>
                        <label className='self-center flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] ml-2'>
                            {/* <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.HeightInch'
                                name='NutritionalStatus.Parameter.HeightInch'
                                type='number'
                                placeholder='Height'
                                onChange={(e) => InchestoMeterandCentimeters(e)}
                                // value={props.isView ? props.formikState.values.NutritionalStatus.Parameter.HeightInch : null}
                                disabled={props.disabled}
                            /> */}
                            <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.HeightInch'
                                name='NutritionalStatus.Parameter.HeightInch'
                                type='number'
                                placeholder='Height'
                                onChange={(e) => InchestoMeterandCentimeters(e)}
                                value={props.formikState.values.NutritionalStatus.Parameter.HeightInch}
                            />
                            <div className="font-'roboto' ml-[10px] font-[400] text-[13px] leading-[15px] text-[#8F8F8F]">inches</div>
                        </label>
                        <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            {/* <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.Weight'
                                name='NutritionalStatus.Parameter.Weight'
                                type='number'
                                placeholder='Weight'
                                onChange={props.formikState.handleChange}
                                // value={props.isView ? props.formikState.values.NutritionalStatus.Parameter.Weight : null}
                                disabled={props.disabled}
                            /> */}
                            <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.Weight'
                                name='NutritionalStatus.Parameter.Weight'
                                type='text'
                                placeholder='Weight'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.NutritionalStatus.Parameter.Weight}
                            />
                            <div className="font-'roboto' ml-[10px] font-[400] text-[13px] leading-[15px] text-[#8F8F8F]">kgs</div>
                        </label>
                        <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            {/* <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.BMI'
                                name='NutritionalStatus.Parameter.BMI'
                                type='number'
                                placeholder='BMI'
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={(props.formikState.values.NutritionalStatus.Parameter.Weight === '' || props.formikState.values.NutritionalStatus.Parameter.Height === '') ? props.formikState.values.NutritionalStatus.Parameter.BMI : (Number(props.formikState.values.NutritionalStatus.Parameter.Weight) / (Number(props.formikState.values.NutritionalStatus.Parameter.HeightMeter) * Number(props.formikState.values.NutritionalStatus.Parameter.HeightMeter))).toFixed(2)}
                            /> */}
                            <input
                                className='h-[47px] w-[112px] text-[15px] placeholder:text-center text-center self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                id='NutritionalStatus.Parameter.BMI'
                                name='NutritionalStatus.Parameter.BMI'
                                type='number'
                                placeholder='BMI'
                                onChange={props.formikState.handleChange}
                                value={(props.formikState.values.NutritionalStatus.Parameter.Weight === '' || props.formikState.values.NutritionalStatus.Parameter.Height === '') ? props.formikState.values.NutritionalStatus.Parameter.BMI : (Number(props.formikState.values.NutritionalStatus.Parameter.Weight) / (Number(props.formikState.values.NutritionalStatus.Parameter.HeightMeter) * Number(props.formikState.values.NutritionalStatus.Parameter.HeightMeter))).toFixed(2)}
                            />
                            <div className="font-'roboto' ml-[10px] font-[400] text-[13px] leading-[15px] text-[#8F8F8F]">kg/m2</div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nutstatus;
