// import { obsrvationExtensionMap } from '../../utils/common.js';

export const toFhir = (obj) => {
    const condition = {
        resourceType: 'Condition',
        code: {
            coding: [
                {
                    display: obj.Complaint
                }
            ]
        },
        onsetAge: {
            value: obj?.Duration || '',
            unit: obj?.DurationUnit || ''
        },
        subject: {
            reference: `Patient/${obj.patientId}`
        },
        encounter: {
            reference: `Encounter/${obj.encounterId}`
        }
    };
    if (obj.id) {
        condition.id = obj.id;
    }
    return Promise.resolve(condition);
};

export const fromFhir = (data) => {
    try {
        const condition = {
            complaints: data.code?.coding[0]?.display || '',
            Duration: data?.onsetAge?.value,
            DurationUnit: data?.onsetAge?.unit
        };

        data.identifier?.map((item) => {
            condition.inPatientId = item.system === 'inPatient' ? item?.value : '';
            condition.UHID = item.system === 'UHID' ? item?.value : '';
            return 0;
        });

        return Promise.resolve(condition);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
