import { parseExtension, observationExtensionMap } from '../../utils/common.js';
export const fromFhir = (data) => {
    try {
        const obj = {};
        data = data.resource;
        obj.id = data.id;
        obj.patient = data.subject.reference.split('/')[1];
        obj.encounter = data.encounter.reference.split('/')[1];
        obj.value = data?.valueString;
        obj.name = data.identifier?.[0]?.value;
        const promises = data.extension?.map(extensionObject => {
            parseExtension(extensionObject).then((extensionArray) => {
                let extensionName = extensionArray[0];
                const extensionVal = extensionArray[1];
                if (extensionName in observationExtensionMap) {
                    extensionName = observationExtensionMap[extensionName];
                }
                if ('extension' in extensionObject) {
                    obj[extensionName] = {};
                    for (const extension of extensionObject.extension) {
                        parseExtension(extension).then((extensionArray) => {
                            let subExtensionName = extensionArray[0];
                            const subExtensionVal = extensionArray[1];
                            if (subExtensionName in observationExtensionMap) {
                                subExtensionName = observationExtensionMap[subExtensionName];
                            }
                            obj[extensionName][subExtensionName] = subExtensionVal;
                        });
                    }
                } else {
                    obj[extensionName] = extensionVal;
                }
            });
            return Promise.resolve();
        });

        if (promises) {
            return Promise.all(promises)
                .then(() => Promise.resolve(obj));
        } else {
            return Promise.resolve(obj);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
