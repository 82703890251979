import React from 'react';
import Select from 'react-select';
import Relatives from '../../utils/json/Relatives.json';

const Prognosis = (props) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Px (Prognosis)</div>
            </div>
            <div className="flex flex-col w-full pl-6 pr-3.5 pt-2">
                {/* <div className="grid grid-cols-c3 gap-[1%] items-center"> */}
                <textarea
                    id="Prognosis.Prognosis"
                    name="Prognosis.Prognosis"
                    type="text"
                    onChange={props.formikState.handleChange}
                    value={props.formikState.values.Prognosis.Prognosis}
                    className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-24 w-full px-1 text-sm text-col"
                    placeholder='Write here'
                    disabled={props.isDisabled}
                />
                {/* </div> */}
            </div>
            <div className="flex flex-col w-full  pl-6 pr-7 pt-4">
                <div className="grid grid-cols-c3 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Explained to :</label>
                    </div>
                    <Select
                        name="Prognosis.ExplainedTo"
                        isSearchable={true}
                        onChange={(value) => {
                            props.formikState.setFieldValue('Prognosis.ExplainedTo', value.value);
                        }}
                        value={
                            Relatives
                                ? Relatives.find((option) => option.value === props.formikState.values.Prognosis.ExplainedTo)
                                : ''}
                        options={Relatives}
                        className="text-col font-roboto bg-gray  border-[#E3E3E3] border-[1px] focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default Prognosis;
