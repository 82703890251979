import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { useNavigate } from "react-router-dom";

import { TextField } from '../components/TextField';
import Button from '../components/Button';

const ActionForm = (props) => {
    const addFunc = (value) => {
        // console.log(value);
        props.onAdd(value);
    };

    const editFunc = (value) => {
        // console.log('data', value);
        props.onEdit(value);
    };

    // const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            actionName: props.data?.actionName || '',
            description: props.data?.description || ''
        },
        validationSchema: Yup.object({
            actionName: Yup.string().required('Required *'),
            description: Yup.string().required('Required *')

        }),
        // onSubmit: values => {
        //     console.log(values)
        //     window.location.href = "/user/role"
        // },
        onSubmit: props.isEdit ? editFunc : addFunc
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Action Name"
                            id="actionName"
                            name="actionName"
                            type="text"
                            value={formik.values.actionName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.actionName && formik.errors.actionName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.actionName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Description"
                            id="description"
                            name="description"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.description && formik.errors.description
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.description}</div>
                            )
                            : null}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[1rem] my-8 text-sm border rounded-md text-white h-10 w-16"
                    />
                    <Button
                        onClick={() => props.handleModalClose(false)}
                        label="Cancel"
                        className="bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col h-10 w-16"
                    />

                </div>
            </div>
        </form>

    );
};

export default ActionForm;
