import React, { useState, useEffect } from 'react';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import Arrow1 from '../../assets/images/arrow1.svg';
import VitalSignView from '../../forms/ViewVitals';
import { Tooltip } from 'react-tooltip';

const PatientHistory = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [rowValue, setRowValue] = useState('');

    const [selected, setSelected] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [sampleJson, setSampleJson] = useState([]);
    const [columns, setColumns] = useState([]);

    const handleChange = event => {
        setSelected(event.target.value);
    };

    const fromDateHandleChange = event => {
        setFromDate(event.target.value);
    };

    const toDateHandleChange = event => {
        setToDate(event.target.value);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const rowClickHandler = (row) => {
        setShowModal(true);
        setRowValue(row);
    };

    const searchHandler = () => {
        if (selected !== '' && fromDate !== '' && toDate !== '') {
            if (selected === 'Chief Complaints') {
                const sampleJson = [
                    {
                        PhysicianName: 'Dr.Karthi',
                        Date: '22-09-2022 09:45:34',
                        Text: 'Other key parts of your Insta bio and profile are your photo and your Stories Highlights. Choose a photo that stays true to your business brand. If you’re a solo business owner, choose a photo of yourself. This will help your potential clients that find you on Insta to put a face to your name. If you’re a business, you can use your logo.',
                        Type: 'Chief Complaints'
                    },
                    {
                        PhysicianName: 'Dr.Kumar',
                        Date: '30-09-2022 06:30:30',
                        Text: 'details',
                        Type: 'Chief Complaints'
                    }

                ];
                setSampleJson(sampleJson);

                const columns = [
                    {
                        name: 'Physician Name',
                        selector: (row) => row.PhysicianName,
                        sortable: true
                    },
                    {
                        name: 'Date',
                        selector: (row) => row.Date,
                        sortable: true
                    },
                    {
                        name: 'Details',
                        cell: (row) => { return <span data-tip={row.Text}>{row.Text.length <= 100 ? row.Text : row.Text.substring(0, 100) + '...'}</span>; },
                        sortable: true
                    }
                ];
                setColumns(columns);
            }
            if (selected === 'Vital Signs') {
                const sampleJson = [
                    {
                        PhysicianName: 'Dr.Karthi',
                        Date: '22-09-2022 09:45:34',
                        Height: '173 cm',
                        Weight: '60 kg',
                        Type: 'Vital Signs'
                    },
                    {
                        PhysicianName: 'Dr.Kumar',
                        Date: '30-09-2022 06:30:30',
                        Height: '173 cm',
                        Weight: '60 kg',
                        Type: 'Vital Signs'
                    }

                ];
                setSampleJson(sampleJson);

                const columns = [
                    {
                        name: 'Physician Name',
                        selector: (row) => row.PhysicianName,
                        sortable: true
                    },
                    {
                        name: 'Date',
                        selector: (row) => row.Date,
                        sortable: true
                    },
                    {
                        name: 'Height',
                        selector: (row) => row.Height,
                        sortable: true
                    },
                    {
                        name: 'Weight',
                        selector: (row) => row.Weight,
                        sortable: true
                    }
                ];
                setColumns(columns);
            }

            if (selected === 'Rx') {
                const sampleJson = [
                    {
                        PhysicianName: 'Dr.Karthi',
                        Date: '22-09-2022',
                        Height: '173 cm',
                        Weight: '60 kg',
                        Type: 'Rx'
                    },
                    {
                        PhysicianName: 'Dr.Kumar',
                        Date: '30-09-2022',
                        Height: '173 cm',
                        Weight: '60 kg',
                        Type: 'Rx'
                    }

                ];
                setSampleJson(sampleJson);

                const columns = [
                    {
                        name: 'Physician Name',
                        selector: (row) => row.PhysicianName,
                        sortable: true
                    },
                    {
                        name: 'Date',
                        selector: (row) => row.Date,
                        sortable: true
                    },
                    {
                        name: 'Height',
                        selector: (row) => row.Height,
                        sortable: true
                    },
                    {
                        name: 'Weight',
                        selector: (row) => row.Weight,
                        sortable: true
                    }
                ];
                setColumns(columns);
            }
        }
    };

    useEffect(() => {
        Tooltip.rebuild();
    });

    return (
        <div>
            <div className="bg-background h-full w-full">
                <div className="flex flex-row justify-between">
                    <div className="flex items-center">
                        <span className="text-base font-normal ml-2 px-2 py-2 text-col">
                    Home
                        </span>
                        <img src={Arrow1} alt="arrow"/>
                        <span className="text-black font-normal text-base  font-roboto px-2 py-2">Progress Notes</span>
                        <img src={Arrow1} alt="arrow"/>
                        <span className="text-black font-normal text-base  font-roboto px-2 py-2">History</span>
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col  mt-6 px-6">
                            <div className="flex flex-row justify-between">
                                <p className="mr-2 mt-2 pr-2">Type:</p>
                                <select
                                    label="Type:"
                                    name="type"
                                    className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none mb-4 h-10 w-full text-sm"
                                    value={selected}
                                    onChange={handleChange}
                                >
                                    <option value="" label="Select">Select</option>
                                    <option label="Progress Note" value="Progress Note">Progress Note</option>
                                    <option label="Chief Complaints" value="Chief Complaints">Chief Complaints</option>
                                    <option label="Vital Signs" value="Vital Signs">Vital Signs</option>
                                    <option label="Allergy" value="Allergy">Allergy</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex flex-col  mt-6 px-6">
                            <div className="flex flex-row justify-between">
                                <p className="mr-2 mt-2 pr-2">From:</p><input className="text-col ml-4 font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm" type="date" onChange={fromDateHandleChange}/>
                            </div>
                        </div>
                        <div className="flex flex-col  mt-6 px-6">
                            <div className="flex flex-row justify-between">
                                <p className="mr-2 mt-2 pr-2">To:</p><input className="text-col ml-4 font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm" type="date" onChange={toDateHandleChange}/>
                            </div>
                        </div>

                        <div className="flex flex-col px-6">
                            <button className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] px-[4rem] my-6 text-sm border rounded-md text-white h-10" onClick={searchHandler}>Search</button>
                        </div>
                    </div>

                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                    <Tooltip place="left" type="success" effect="solid" />
                    <Table rows={sampleJson} columns={columns} onRowClicked={rowClickHandler}/>
                    {showModal && <Modal handleModalClose={handleCloseModal} heading={rowValue.Type + ' ' + '(' + rowValue.Date + ')'} body={<VitalSignView data={rowValue}/>}/>}
                </div>
            </div>
        </div>

    );
};

export default PatientHistory;
