import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Table from '../../components/Table';
import '../../assets/css/Style.css';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import View from '../../assets/images/view.svg';
import Delete from '../../assets/images/delete.svg';
import { labCustomStyles } from '../../style';
import { FaArrowRight } from 'react-icons/fa';
import { ageCalculation, stringReplace } from '../..//utils/common';
import { useSnackbar } from 'react-simple-snackbar';
import { SnackbarStyles } from '../../components/SnackbarStyles';
import { getDiagnosticReports } from '../../api/diagnosticReport';
import { convertFromFhirList } from '../../fhir-evolver';
import Loader from '../../components/Loader';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const status = {
    registered: { label: 'Sample Initiated', value: 'registered' },
    partial: { label: 'Sample processing', value: 'partial' },
    final: { label: 'Report generated', value: 'partial' }
};

const LabPatient = () => {
    const [spinner, setSpinner] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [openSnackbar] = useSnackbar(SnackbarStyles);
    // eslint-disable-next-line no-unused-vars
    const [diagnosticReport, setDiagnosticReport] = useState([]);
    const currentDate = new Date();
    const dateFormat = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes();

    useEffect(() => {
        setSpinner(true);
        getDiagnosticReports().then((response) => {
            convertFromFhirList('DiagnosticReport', response.entry).then((res) => {
                // eslint-disable-next-line no-console
                console.log('labres', res);
                setDiagnosticReport(res);
                setSpinner(false);
            });
        }).catch((e) => {
            return e;
        });
    }, []);
    const columns = [
        {

            name: 'UHID',
            selector: (row) => row.patient.uhid[0],
            sortable: true
        },
        {
            name: 'Patient',
            selector: (row) => `${row.patient.firstName[0]} ${row.patient.surname}`,
            sortable: true
        },
        {
            name: 'Age/Gender',
            selector: (row) => ageCalculation(row.patient.birthDate) + ' ' + '/' + ' ' + stringReplace(row.patient.gender),
            sortable: true
        },
        {
            name: 'Tests Ordered',
            selector: (row) => row.investigation,
            sortable: true
            // cell: row => row.testcase === 'priority'
            //     ? <div data-tag="allowRowEvents"><span>{row.test}</span>
            //         <span style={{ backgroundColor: '#FF0000', marginLeft: '10px', color: 'white', padding: '4px' }} >{row.testcase}</span>
            //     </div>
            //     : <div data-tag="allowRowEvents"><span>{row.test}</span>
            //     </div>
        },
        {
            name: 'Date & Time',
            selector: (row) => new Date(row.date).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }),
            sortable: true
        },
        {
            name: 'Doctor',
            selector: (row) => row.practitioner ? `${row.practitioner.givenName[0]} ${row.practitioner.family}` : null,
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => <button className={`px-5 py-2 ${row.status === 'registered' ? 'bg-red-500' : row.status === 'partial' ? 'bg-blue' : 'bg-green-500'} text-white w-[10rem] rounded-lg text-sm`}>{status[row.status].label}</button>,
            sortable: true
        },
        {
            name: 'Actions',
            selector: (row, index) => (
                <div>
                    <Link to='/lab/history/view' state={row}>
                        <button className="px-1" id={`view${index}`}>
                            <img className="h-3 w-4" src={View} alt="view" />
                            <Tooltip place="bottom" type="success" anchorId={`view${index}`} effect="solid" content="View"/>
                        </button>
                    </Link>
                    <Link to='/lab/history/edit' state={row}>
                        <button className="px-1" id={`edit${index}`}>
                            <img className="h-3 w-4" src={Edit} alt="edit" />
                            <Tooltip place="bottom" type="success" anchorId={`edit${index}`} effect="solid" content="Edit"/>
                        </button>
                    </Link>
                    <button className="px-1" id={`delete${index}`}>
                        <img className="h-3 w-4" src={Delete} alt="delete" />
                        <Tooltip place="bottom" type="success" anchorId={`delete${index}`} effect="solid" content="Delete"/>
                    </button>
                    <button className="px-1" id={`history${index}`}>
                        <FaArrowRight />
                        <Tooltip place="bottom" type="success" anchorId={`history${index}`} effect="solid" content="History"/>
                    </button>
                </div>
            )
        }
    ];

    return (
        <><Loader showSpinner={spinner} />
            {/* <div className="h-screen"> */}
            <div className="bg-background h-full w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center">
                        <Link to={'/home'}>
                            <span className="text-base font-normal px-2 py-2 text-col">
                                    Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base  font-roboto px-2 py-2">Tests</span>
                    </div>

                    <div className="flex items-center">
                        <span className="text-sm font-normal px-2 py-2 text-col">
                                Time: {currentTime}
                        </span>
                        <span className="font-normal text-base  font-roboto px-2 py-2 text-sm text-col">Date: {dateFormat}</span>
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <span className="text-3xl font-bold px-2 py-5 text-black">
                            Prescribed tests
                    </span>
                    <div className="flex flex-col p-2">
                        <div className="pt-2 relative mx-auto text-col">
                            <input
                                className="shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none"
                                type="search"
                                name="search"
                                placeholder="Search"
                            />
                            <button type="submit" className="absolute right-0 top-0 mt-6 mr-4">
                                <img src={Search} alt="search" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                    <Table rows={diagnosticReport} columns={columns} isNurse={true} customStyles={labCustomStyles} />
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default LabPatient;
