/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import '../../assets/css/Style.css';
import Modal from '../../components/Modal';
import FloorForm from '../../forms/Floor';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';
import View from '../../assets/images/view.svg';
import { patientCustomStyles } from '../../style';
import { addLocation, getLocation, editLocation } from '../../api/location';
import { convertToFhir } from '../../fhir-evolver';

const Floor = () => {
    const [selectedObject, setSelectedObject] = useState({ name: '' });
    const [showAddModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleCloseAddModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setShowEditModal(showAddModal);
        setSelectedObject({
            name: ''
        });
    }, [showAddModal]);

    const editHandler = (row) => {
        setSelectedObject(row);
        setShowEditModal(true);
    };

    const viewHandler = (row) => {
        setSelectedObject(row);
        setShowEditModal(true);
    // setDisabled(true);
    };
    const handleCloseModal = () => {
        if (showAddModal) {
            handleCloseAddModal();
        } else {
            setShowEditModal(false);
        }
    };

    const columns = [
        {
            name: 'Floor Name',
            selector: (row) => row.resource.name,
            sortable: true
        },
        {
            name: 'Actions',
            selector: (row) => (
                <div>

                    <button className="px-1">
                        <img className="h-3 w-4" onClick={() => viewHandler(row)} src={View} alt="view"/>
                    </button>
                    <button className="px-1">
                        <img className="h-3 w-4" onClick={() => editHandler(row)} src={Edit} alt="edit"/>
                    </button>

                    <button >
                        <img className="h-3 w-4" onClick={() => deleteHandler(row.id)} src={Delete} alt="delete" />
                    </button>
                </div>
            )
        }
    ];

    useEffect(() => {
        getLocation('152').then((response) => {
            setValues(response.data.entry);
        });
    }, []);

    const [values, setValues] = useState();

    const onAdd = (obj) => {
        convertToFhir('Location', obj).then(res => {
            addLocation(res).then(res => {
                setValues(res.data.entry);
                setShowModal(false);
            });
        });
    };

    const onEdit = (obj) => {
        convertToFhir('Location', obj).then(res => {
            console.log('evolver', res);
            res.id = selectedObject.resource.id;
            console.log('selid', selectedObject);
            editLocation(selectedObject.resource.id, res)
                .then((response) => {
                    setShowEditModal(false);
                })
                .catch(e => {
                    return e;
                });
        });
    };

    const deleteHandler = id => {
    // console.log("delete", id)
    // console.log("val",values.filter(value => value.id !== id))
        setValues(values.filter(value => value.id !== id));
    };

    return (
        <div>
            <div className="bg-background h-full w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center px-4 py-2 gap-x-2">
                        <Link to={'/home'}>
                            <span className="text-base font-normal text-col">
                             Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow"/>
                        <span className="text-black font-normal text-base  font-roboto">Floor</span>
                    </div>
                    <div className="flex flex-col p-2">
                        <button className="w-72 p-2 h-11 mt-4 bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base text-extrabold text-white" onClick={() => setShowModal(true)}>Add Floor</button>
                        {showEditModal && <Modal
                            handleModalClose={handleCloseModal}
                            heading="Floor"
                            body={<FloorForm
                                handleModalClose={handleCloseModal}
                                data={selectedObject}
                                onAdd={onAdd}
                                onEdit={onEdit}
                                isEdit={!showAddModal}
                            />}
                        />}
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <span className="text-3xl font-bold px-4 py-2 text-black">
                          Floor
                    </span>
                    <div className="flex flex-col p-2">
                        <div className="pt-2 relative mx-auto text-col">
                            <input
                                className="shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none"
                                type="search"
                                name="search"
                                placeholder="Search"
                            />
                            <button type="submit" className="absolute right-0 top-0 mt-6 mr-4">
                                <img src={Search} alt="search"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                    <Table rows={values} columns={columns} customStyles={patientCustomStyles}/>
                </div>
            </div>
        </div>

    );
};

export default Floor;
