/* eslint-disable no-console */
import { generateProgressNotesFormik } from '../../../utils/ProgressNotesFormik.js';
import { convertFromFhir, convertToFhir } from '../../index.js';
import { getMedicationAdministration } from '../../../api/medicationAdministration.js';
import { getDiagnosticReportWithQueryParams } from '../../../api/diagnosticReport.js';
// commented out vital signs code
// import { getClinicalImpression } from '../../../api/clinicalImpression.js';
export const toProgressNotes = (data) => {
    // console.log('data', data);
    const clinicalStatus = data.clinicalStatus; // in-progress / active
    const BundleRequest = {};
    BundleRequest.type = 'transaction';
    BundleRequest.entry = [];
    const promises = [];
    const result = {};
    result.supportingInfo = [];
    result.status = clinicalStatus;
    result.resourceType = 'ClinicalImpression';
    result.date = new Date().toISOString();
    BundleRequest.resourceType = 'Bundle';
    // // eslint-disable-next-line no-console
    // console.log('Progress-notes', data);
    const existingMedicationArray = [];
    const putMedicationArray = [];
    const medicationAdministrationPromise = getMedicationAdministration(`context=${data.Encounter}&subject=${data.Patient}`).then((output) => {
        // // eslint-disable-next-line no-console
        // console.log('getmedication', output);
        if (output.data.entry) {
            for (const medication of output.data.entry) {
                // medicationArray.push(convertFromFhir('MedicationAdministration', medication.resource).then((resolved) => result.Medication.push(resolved)));
                existingMedicationArray.push(medication.resource.id);
            }
        }
    }).then(() => {
        const medicationPromiseArray = [];
        for (const [index, medication] of data.Medication.entries()) {
            medication.patient = data.Patient;
            medication.encounter = data.Encounter;
            // to change if check
            // if (medication.id && !existingMedicationArray.includes(medication.id)) {
            //     const transform = {
            //         request: {
            //             method: 'DELETE',
            //             url: `MedicationAdministration/${medication.id}`
            //         }
            //     };
            //     BundleRequest.entry.push(transform);
            // }
            if (medication.id && existingMedicationArray.includes(medication.id)) {
                const medicationAdminResource = convertToFhir('MedicationAdministration', medication).then((output) => {
                    const transform = {
                        request: {
                            method: 'PUT',
                            url: `MedicationAdministration/${medication.id}`
                        },
                        fullUrl: 'urn:uuid::MedicationAdministration' + index,
                        resource: output
                    };
                    putMedicationArray.push(medication.id);
                    BundleRequest.entry.push(transform);
                });
                medicationPromiseArray.push(medicationAdminResource);
            } else if (!medication.id) {
                const medicationAdminResource = convertToFhir('MedicationAdministration', medication).then((output) => {
                    const transform = {
                        request: {
                            method: 'POST',
                            url: 'MedicationAdministration'
                        },
                        fullUrl: 'urn:uuid::MedicationAdministration' + index,
                        resource: output
                    };
                    BundleRequest.entry.push(transform);
                });
                medicationPromiseArray.push(medicationAdminResource);
            }
        }
        promises.push(medicationPromiseArray);
        // return Promise.all(medicationPromiseArray).then(() => {
        // this if check was intended to check if a medication id was present before but isnt present in the present request.
        // if it wasnt present then we issue a delete request for that particular id.
        // this isnt working as expected and deletes all medication upon editing a progress note
    //         for (const id of existingMedicationArray) {
    //             if (putMedicationArray && !(putMedicationArray.includes(id))) {
    //                 const transform = {
    //                     request: {
    //                         method: 'DELETE',
    //                         url: `MedicationAdministration/${id}`
    //                     }
    //                 };
    //                 BundleRequest.entry.push(transform);
    //             }
    //         }
    //     });
    });
    promises.push(medicationAdministrationPromise);
    const conditionInput = {
        Complaint: data.CheifComplaints,
        patientId: data.Patient,
        encounterId: data.Encounter
    };
    result.investigation = {
        code: {
            coding: []
        },
        item: []
    };
    const conditionPromise = convertToFhir('Condition', conditionInput).then((output) => {
        const transform = {
            request: {
                method: 'POST',
                url: 'Condition'
            },
            fullUrl: 'urn:uuid::Condition',
            resource: output
        };
        result.problem = [];
        result.problem.push(
            {
                type: 'Condition',
                reference: 'urn:uuid::Condition'
            }
        );
        if (data.type && data.type === 'edit') {
            output.id = data.resourceIdMap.Condition.id;
            transform.request.method = 'PUT';
            transform.request.url = `Condition/${output.id}`;
        }
        BundleRequest.entry.push(transform);
    });
    promises.push(conditionPromise);
    let diagnosisArray = [];
    let investigationArray = [];
    if (data.type === 'edit') {
        diagnosisArray = data.resourceIdMap.DiagnosticReport.filter((el) => el.identifier[0].value === 'ProgressNotes' && el.identifier[0].system === 'Diagnosis');
        investigationArray = data.resourceIdMap.DiagnosticReport.filter((el) => el.identifier[0].value === 'ProgressNotes' && el.identifier[0].system === 'Investigation');
    }
    for (const [index, diagnosis] of data.Diagnosis.entries()) {
        // eslint-disable-next-line no-console
        // console.log('diagnosis', diagnosis);
        let dateTime = '';
        if (diagnosis.date && diagnosis.time) {
            dateTime = diagnosis.date + 'T' + diagnosis?.time + 'Z';
        } else {
            dateTime = new Date().toISOString();
        }
        const inputData = {
            date: dateTime,
            practitioner: data.Practitioner,
            investigation: diagnosis.diagnosis,
            status: diagnosis.reportStatus,
            patientId: data.Patient,
            encounterId: data.Encounter
        };
        const diagnosticPromise = convertToFhir('DiagnosticReport', inputData).then((output) => {
            output.identifier = [
                {
                    value: 'ProgressNotes',
                    system: 'Diagnosis'
                }
            ];
            if (diagnosis.subRow) {
                output.conclusion = JSON.stringify(diagnosis.subRow);
                // can use JSON.parse to fetch it later
            }
            const transform = {
                request: {
                    method: 'POST',
                    url: 'DiagnosticReport'
                },
                fullUrl: 'urn:uuid::DiagnosticReport' + index,
                resource: output
            };
            if (data.type && data.type === 'edit' && (index < diagnosisArray.length)) {
                output.id = diagnosisArray[index].id;
                transform.request.method = 'PUT';
                transform.request.url = `DiagnosticReport/${output.id}`;
            }
            result.investigation.item.push(
                {
                    type: 'DiagnosticReport',
                    reference: 'urn:uuid::DiagnosticReport' + index
                }
            );
            BundleRequest.entry.push(transform);
        });
        promises.push(diagnosticPromise);
    }
    result.identifier = [
        {
            system: 'identifier',
            value: 'ProgressNotes'
        }
    ];
    result.subject = {
        reference: 'Patient/' + data.Patient
    };
    result.encounter = {
        reference: 'Encounter/' + data.Encounter
    };
    result.assessor = {
        reference: 'Practitioner/' + data.Practitioner
    };
    // Special Instructions
    for (const [index, investigation] of data.Investigation.entries()) {
        // eslint-disable-next-line no-console
        // console.log(investigation);
        let dateTime = '';
        if (investigation.date && investigation.time) {
            dateTime = investigation.date + 'T' + investigation?.time + 'Z';
        } else {
            dateTime = new Date().toISOString();
        }
        const inputData = {
            date: dateTime,
            practitioner: data.Practitioner,
            investigation: investigation.investigation,
            status: investigation.reportStatus,
            patientId: data.Patient,
            encounterId: data.Encounter
        };
        const diagnosticPromise = convertToFhir('DiagnosticReport', inputData).then((output) => {
            output.identifier = [
                {
                    value: 'ProgressNotes',
                    system: 'Investigation'
                }
            ];
            if (investigation.subRow) {
                output.conclusion = JSON.stringify(investigation.subRow);
                // can use JSON.parse to fetch it later
            }
            const transform = {
                request: {
                    method: 'POST',
                    url: 'DiagnosticReport'
                },
                fullUrl: 'urn:uuid::InvestigationReport' + index,
                resource: output
            };
            result.investigation.item.push(
                {
                    type: 'DiagnosticReport',
                    reference: 'urn:uuid::InvestigationReport' + index
                }
            );
            if (data.type && data.type === 'edit' && (index < investigationArray.length)) {
                output.id = investigationArray[index].id;
                transform.request.method = 'PUT';
                transform.request.url = `DiagnosticReport/${output.id}`;
            }
            BundleRequest.entry.push(transform);
        });
        promises.push(diagnosticPromise);
    }
    result.note = [];
    for (const instruction of data.SpecialInstruction) {
        result.note.push(
            {
                authorString: 'SpecialInstruction',
                text: JSON.stringify(instruction)
            }
        );
    }
    for (const handoverNote of data.Handover) {
        result.note.push(
            {
                authorString: 'Handover',
                text: JSON.stringify(handoverNote)
            }
        );
    }

    result.prognosisCodeableConcept = {
        coding: [
            {
                code: data.Prognosis.Prognosis,
                display: data.Prognosis.ExplainedTo
            }
        ]
    };
    // questionnaire Handover
    // referral
    const referralInputData = data.Referral;
    referralInputData.date = new Date().toISOString();
    referralInputData.status = 'active';
    referralInputData.patientId = data.Patient;
    referralInputData.encounterId = data.Encounter;
    const serviceRequestPromise = convertToFhir('ServiceRequest', referralInputData).then((output) => {
        const transform = {
            request: {
                method: 'POST',
                url: 'ServiceRequest'
            },
            fullUrl: 'urn:uuid::ServiceRequest',
            resource: output
        };
        if (data.type && data.type === 'edit') {
            output.id = data.resourceIdMap.ServiceRequest.id;
            transform.request.method = 'PUT';
            transform.request.url = `ServiceRequest/${output.id}`;
        }
        BundleRequest.entry.push(transform);
        result.supportingInfo.push(
            {
                reference: 'urn:uuid::ServiceRequest',
                type: 'ServiceRequest'
            }
        );
    });
    promises.push(serviceRequestPromise);
    const progressNotes = Promise.resolve(result).then((output) => {
        const transform = {
            request: {
                method: 'POST',
                url: 'ClinicalImpression'
            },
            fullUrl: 'urn:uuid::ClinicalImpression',
            resource: output
        };
        if (data.type && data.type === 'edit') {
            output.id = data.resourceIdMap.ClinicalImpression.id;
            transform.request.method = 'PUT';
            transform.request.url = `ClinicalImpression/${output.id}`;
        }
        BundleRequest.entry.push(transform);
    });
    promises.push(progressNotes);
    return Promise.all(promises).then(() => Promise.resolve(BundleRequest));
};

export const fromProgressNotes = (data) => {
    // console.log('dataaaafromfhir', data);
    // eslint-disable-next-line no-console
    // console.log('progressNotes http get data', data);
    const promises = [];
    const entry = data.data.entry;
    const patient = entry.find((el) => el.resource.resourceType === 'Patient');
    const encounter = entry.find((el) => el.resource.resourceType === 'Encounter');
    const practitioner = entry.find((el) => el.resource.resourceType === 'Practitioner');
    const result = Object.assign({}, generateProgressNotesFormik(patient?.resource?.id, encounter?.resource?.id, practitioner?.resource?.id));
    // Chief Complaints
    const condition = entry.find((el) => el.resource.resourceType === 'Condition');
    if (condition) {
        const conditionPromise = convertFromFhir('Condition', condition.resource).then((output) => {
            result.CheifComplaints = output.complaints;
        });
        promises.push(conditionPromise);
    }
    // Diagnosis & investigations
    // const diagnosticReportArray = entry.filter((el) => el.resource.resourceType === 'DiagnosticReport');
    const diagnosticPromise = getDiagnosticReportWithQueryParams(`subject:Patient=${patient.resource.id}&encounter=${encounter.resource.id}`).then((output) => {
        // console.log('diagnosticPromise', output);
        // eslint-disable-next-line no-console
        // console.log('patientId', patient.resource.id);
        // // eslint-disable-next-line no-console
        // console.log('encountId', encounter.resource.id);
        // // eslint-disable-next-line no-console
        // console.log('diagnosis', output);
        const diagnosticReportArray = output.entry;
        if (diagnosticReportArray) {
            for (const element of diagnosticReportArray) {
                // console.log('element', diagnosticReportArray);
                const formikConvert = convertFromFhir('DiagnosticReport', element).then((output) => {
                    // console.log('output', output);
                    // eslint-disable-next-line no-console
                    const identifier = element.resource?.identifier?.[0]?.system;
                    if (identifier === 'Investigation') {
                        // console.log('identifier', identifier);
                        result.Investigation.push(output);
                    } else {
                    //  else if (identifier === 'Diagnosis') {
                        // console.log('identifier', identifier);
                        output.diagnosis = output.investigation;
                        delete output.investigation;
                        result.Diagnosis.push(output);
                    }
                });
                promises.push(formikConvert);
            }
        }
    });
    promises.push(diagnosticPromise);
    // referral
    const serviceRequest = entry.find((el) => el.resource.resourceType === 'ServiceRequest');
    if (serviceRequest) {
        const serviceRequestPromise = convertFromFhir('ServiceRequest', serviceRequest.resource).then((output) => {
        // eslint-disable-next-line no-console
            // console.log('serviceRequest', output);
            result.Referral = output;
        });
        promises.push(serviceRequestPromise);
    }
    // Prognosis
    const clinicalImpression = entry.find((el) => el.resource.resourceType === 'ClinicalImpression');
    result.Prognosis = {
        Prognosis: clinicalImpression.resource?.prognosisCodeableConcept?.[0]?.coding[0]?.code,
        ExplainedTo: clinicalImpression.resource?.prognosisCodeableConcept?.[0]?.coding[0]?.display
    };
    // Special Instruction
    for (const note of clinicalImpression.resource.note) {
        result[note.authorString].push(JSON.parse(note.text));
    }
    const medicationAdministrationPromise = getMedicationAdministration(`context=${encounter.resource.id}&subject=${patient.resource.id}`).then((output) => {
        if (output.data.entry) {
            const medicationArray = [];
            for (const medication of output.data.entry) {
                medicationArray.push(convertFromFhir('MedicationAdministration', medication.resource).then((resolved) => result.Medication.push(resolved)));
            }
            return medicationArray;
        }
    });
    promises.push(medicationAdministrationPromise);
    // for vital signs
    // const vitalSigns = getClinicalImpression('?_sort=-_id&identifier=PrimaryCare&_count=1&_include=*').then((primaryCareResource) => {
    //     convertFromFhir('PrimaryCare', primaryCareResource).then((resolved) => {
    //         // eslint-disable-next-line no-console
    //         console.log('resolved', resolved);
    //         result.vitalSigns = resolved;
    //     });
    // });
    // promises.push(vitalSigns);
    return Promise.all(promises).then(() => Promise.resolve(result));
};
