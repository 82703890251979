import React, { useState } from 'react';
import Modal from '../../components/Modal';
import InstructionPopup from './InstructionPopup';
// import View from '../../assets/images/view.svg';
// import { FaPlusSquare } from 'react-icons/fa';

const SpecialInstruction = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [medicationPrefillValue, setMedicationPrefillValue] = useState({ state: 0 });
    const [val, setVal] = props.formikState.values.SpecialInstruction ? useState(props.formikState.values.SpecialInstruction) : useState([]);

    const getInstructionData = (data) => {
        setVal(val => [...val, data]);
        const finalData = val;
        finalData.push(data);
        props.updateData(finalData);
        setShowModal(false);
    };
    const handleCloseModal = () => {
        if (showModal) {
            setShowModal(false);
        }
    };

    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Special Instruction</div>
            </div>
            <div className='flex justify-end px-6'><button type='button' className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[4rem] my-4 text-[16px] border text-white font-[500] h-12' onClick={() => { setShowModal(true); setMedicationPrefillValue({}); setMedicationPrefillValue({ ...medicationPrefillValue, state: 0 }); }} disabled={props.isDisabled}>+ Add Instruction</button></div>
            {showModal && <Modal
                heading="SpecialInstruction"
                handleModalClose={handleCloseModal}
                body={<InstructionPopup
                    handleModalClose={handleCloseModal}
                    formikState={props.formikState}
                    prefillData={medicationPrefillValue}
                    quantityVal={1}
                    sendValue={(data) => getInstructionData(data)}
                />}
            />}
            <div className='flex flex-col flex-wrap w-full px-4 py-4'>
                <table className="table-auto border border-slate-300 w-full">
                    <thead>
                        <tr>
                            <th className='border border-slate-300 text-left text-sm p-2'>S.No</th>
                            <th className='border border-slate-300 text-left text-sm p-2'>GivenBy</th>
                            <th className='border border-slate-300 text-left text-sm p-2'>Special Instruction</th>
                        </tr>
                    </thead>
                    <tbody>
                        { val.length > 0
                            ? val.map((item, index) => (
                                <tr key={index} className='border border-slate-300'>
                                    <td className='text-sm p-2 border border-slate-300 text-col'>{index + 1}</td>
                                    <td className='text-sm p-2 border border-slate-300 text-col'>{item.givenby}</td>
                                    <td className='text-sm p-2 border border-slate-300 text-col'>{item.instruction}</td>
                                    {/* <td className='text-sm p-2 border border-slate-300 text-col'> <div className='flex flex-row'>
                                        <button
                                            type="button"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="view"
                                            className="px-1"
                                        >
                                            <img className="h-3 w-4" src={View} alt="view" />
                                        </button>
                                        <button className="px-1" type='button'>
                                            <FaPlusSquare/>
                                        </button>
                                    </div></td> */}
                                </tr>
                            ))
                            : <tr><td></td><td></td><td className=' text-start  font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>No Data Available</td></tr> }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SpecialInstruction;
