import React from 'react';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const PeripheralLine = ({ formikState, onChange, onBlur, ...props }) => {
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Peripheral Line Observation Chart</span>
            </div>
            <div className="flex flex-col flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%]">
                        <div className="flex flex-row">
                            <span className="flex flex-row text-col font-roboto text-sm">1. Pain :</span>
                        </div>
                        <div className="flex flex-row flex-wrap text-col font-roboto text-sm gap-x-8 ">
                            <RadioButton
                                label="Yes"
                                name="peripheralLine.pain"
                                id="yes"
                                onChange={onChange}
                                value='yes'
                                onBlur={onBlur}
                                checked={formikState.values.peripheralLine.pain === 'yes'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="No"
                                id="no"
                                name="peripheralLine.pain"
                                onChange={onChange}
                                value='no'
                                onBlur={onBlur}
                                checked={formikState.values.peripheralLine.pain === 'no'}
                                disabled={props.isDisabled}
                            />
                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%]">
                        <div className="flex flex-row">
                            <span className="flex flex-row text-col font-roboto text-sm">2. Redness :</span>
                        </div>
                        <div className="flex flex-row flex-wrap text-col font-roboto text-sm gap-x-8 ">
                            <RadioButton
                                label="Yes"
                                name="peripheralLine.redness"
                                id="yes"
                                onChange={onChange}
                                value='yes'
                                onBlur={onBlur}
                                checked={formikState.values.peripheralLine.redness === 'yes'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="No"
                                id="no"
                                name="peripheralLine.redness"
                                onChange={onChange}
                                value='no'
                                onBlur={onBlur}
                                checked={formikState.values.peripheralLine.redness === 'no'}
                                disabled={props.isDisabled}
                            />
                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%]">
                        <div className="flex flex-row">
                            <span className="flex flex-row text-col font-roboto text-sm">3. Swelling :</span>
                        </div>
                        <div className="flex flex-row flex-wrap text-col font-roboto text-sm gap-x-8 ">
                            <RadioButton
                                label="Yes"
                                name="peripheralLine.swelling"
                                id="yes"
                                onChange={onChange}
                                value='yes'
                                onBlur={onBlur}
                                checked={formikState.values.peripheralLine.swelling === 'yes'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="No"
                                id="no"
                                name="peripheralLine.swelling"
                                onChange={onChange}
                                value='no'
                                onBlur={onBlur}
                                checked={formikState.values.peripheralLine.swelling === 'no'}
                                disabled={props.isDisabled}
                            />
                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-full  px-6">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Remarks if any :</label>
                        </div>
                        <input
                            id="peripheralLine.observationRemarks"
                            name="peripheralLine.observationRemarks"
                            disabled={props.isDisabled}
                            type="text"
                            onChange={onChange}
                            value={formikState.values.peripheralLine.observationRemarks}
                            className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PeripheralLine;
