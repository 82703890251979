import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import Admission from '../../forms/Admission';
import Arrow1 from '../../assets/images/arrow1.svg';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { editEncounter, getEncounter } from '../../api/encounter';
import { convertToFhir, convertFromFhirList } from '../../fhir-evolver/index';
import { SnackbarStyles } from '../../components/SnackbarStyles';
import Loader from '../../components/Loader';

const AdmissionUpdate = (props) => {
    const [spinner, setSpinner] = useState(false);
    const [open] = useSnackbar(SnackbarStyles);
    const navigate = useNavigate();
    const [encounter, setEncounter] = useState('');
    const location = useLocation();
    const params = useParams();
    useEffect(() => {
        if (!location.state && params.id) {
            getEncounter(`_id=${params.id}&_include=*`).then((response) => {
                convertFromFhirList('Encounter', response.data.entry).then((res) => {
                    setEncounter(res);
                }).catch(e => {
                    return e;
                });
            });
        }
    }, []);
    const onEdit = (obj) => {
        convertToFhir('Encounter', obj).then(res => {
            res.id = location.state.id;
            editEncounter(location.state.id, res)
                .then((response) => {
                    setSpinner(true);
                    open('Admission updated Successfully');
                    navigate('/patient/admission-list');
                })
                .catch(e => {
                    return e;
                });
        });
    };

    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className='bg-gray h-auto w-full'>
                <div className='flex flex-row justify-between '>
                    <div className='flex items-center'>
                        <Link to='/home'>
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt='arrow' />
                        <span className='text-black font-normal text-base px-2 py-2'>Edit Admission</span>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <span className='text-2xl font-bold font-montserrat mx-auto pb-2 text-black '>
                        Edit Admission Details
                    </span>
                </div>
                <div className='bg-white ml-6 mr-6 flex flex-row justify-between'>
                    <Admission
                        viewData={location.state ? location?.state : encounter?.[0]}
                        type={'edit'} handleSubmit={onEdit} isDisabled={false} isEdit={true}/>
                </div>
            </div>
        </div>
    );
};

export default AdmissionUpdate;
