import React from 'react';
import { Route } from 'react-router-dom';

import PatientDiagnosisView from '../containers/Lab/PatientDiagnosisView';
import LabPatient from '../containers/Lab/LabPatient';
import History from '../containers/Lab/History';
import PastHistory from '../containers/Lab/PastHistory';
import Lab from '../containers/Lab/Lab';
import PrivateRoute from '../hooks/PrivateRoute';
import { ROLE } from '../utils/roles';

const LabRoutes = (
    <Route element={<PrivateRoute roles={[ROLE.lab.name]} />} >
        <Route path="lab" element={<Lab />} />
        <Route path="lab/patient" element={<LabPatient />} />
        <Route path="lab/history">
            <Route path=':state' element={<History />} />
        </Route>
        <Route path="lab/pasthistory" element={<PastHistory />} />
        <Route path="lab/patient/view/:id" element={<PatientDiagnosisView />} />
    </Route>
);

export default LabRoutes;
