import React from 'react';
import { Route } from 'react-router-dom';

import PatientMedicationView from '../containers/Pharmacy/PatientMedicationView';
import PharmacyMedicationEdit from '../containers/Pharmacy/PharmacyMedicationEdit';
import PharmacyPatient from '../containers/Pharmacy/PharmacyPatient';
// import Pharmacy from '../containers/Pharmacy/Pharmacy';
import PrivateRoute from '../hooks/PrivateRoute';
import { ROLE } from '../utils/roles';
import PharmacyPatientHistory from '../containers/Pharmacy/PharmacyPatientHistory';

const PharmacyRoutes = (
    <Route element={<PrivateRoute roles={[ROLE.pharmacy.name]} />} >
        <Route path="pharmacy" element={<PharmacyPatient />} />
        {/* <Route path="pharmacy/patient" element={<PharmacyPatient />} /> */}
        <Route path="pharmacy/patient/history" element={<PharmacyPatientHistory />} />
        <Route path="pharmacy/patient/view/:id" element={<PatientMedicationView />} />
        <Route path="pharmacy/patient/edit/:id" element={<PharmacyMedicationEdit />} />
    </Route>
);

export default PharmacyRoutes;
