/* eslint-disable no-console */
import React from 'react';
import Select from 'react-select';
import shift from '../../utils/json/ShiftTimes.json';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';
const PainAssessment = ({ formikState, onChange, onBlur, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Pain Assessment</span>
            </div>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Shift :</label>
                            </div>
                            <Select
                                name="painAssessment.shift"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('painAssessment.shift', value.value);
                                }}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                options = {shift}
                                isDisabled={props.isDisabled}
                                value={
                                    shift
                                        ? shift.find((option) => option.value === formikState.values.painAssessment.shift)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Time :</label>
                            </div>
                            <input
                                id="painAssessment.shifttime"
                                name="painAssessment.shifttime"
                                type="time"
                                onChange={onChange}
                                value={formikState.values.painAssessment.shifttime}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col w-[49%] px-6'>
                        <div className='grid grid-cols-c4 gap-[1%] items-center'>
                            <span className='flex flex-row text-col font-roboto text-sm'>Pain Score :</span>
                            <div className="flex flex-row flex-wrap text-col font-roboto text-sm gap-x-4">
                                <RadioButton
                                    label="NO HURT"
                                    name="painAssessment.painscore"
                                    id="0"
                                    onChange={onChange}
                                    value='0'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.painscore === '0'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="HURTS LITTLE BIT"
                                    id="2"
                                    name="painAssessment.painscore"
                                    onChange={onChange}
                                    value='2'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.painscore === '2'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="HURTS LITTLE MORE"
                                    id="4"
                                    name="painAssessment.painscore"
                                    onChange={onChange}
                                    value='4'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.painscore === '4'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="HURTS EVEN MORE"
                                    id="6"
                                    name="painAssessment.painscore"
                                    onChange={onChange}
                                    value='6'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.painscore === '6'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="HURTS WHOLE LOT"
                                    id="10"
                                    name="painAssessment.painscore"
                                    onChange={onChange}
                                    value='10'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.painscore === '10'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="HURTS WORST"
                                    id="10"
                                    name="painAssessment.painscore"
                                    onChange={onChange}
                                    value='10'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.painscore === '10'}
                                    disabled={props.isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Location :</label>
                            </div>
                            <input
                                id="painAssessment.location"
                                name="painAssessment.location"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.painAssessment.location}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                placeholder='Write Here'
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Character :</label>
                            </div>
                            <Select
                                name="painAssessment.character"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('painAssessment.character', value.value);
                                }}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                options = {[{ value: 'Burning', label: 'Burning' }, { value: 'Dull Ache', label: 'Dull Ache' }, { value: 'Stabbing', label: 'Stabbing' }, { value: 'Sharp', label: 'Sharp' }, { value: 'Pricking', label: 'Pricking' }, { value: 'Throbbing', label: 'Throbbing' }, { value: 'Colicky', label: 'Colicky' }]}
                                isDisabled={props.isDisabled}
                                value={
                                    [{ value: 'Burning', label: 'Burning' }, { value: 'Dull Ache', label: 'Dull Ache' }, { value: 'Stabbing', label: 'Stabbing' }, { value: 'Sharp', label: 'Sharp' }, { value: 'Pricking', label: 'Pricking' }, { value: 'Throbbing', label: 'Throbbing' }, { value: 'Colicky', label: 'Colicky' }].find((option) => option.value === formikState.values.painAssessment.character)
                                }
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Duration :</label>
                            </div>
                            <input
                                id="painAssessment.duration"
                                name="painAssessment.duration"
                                type="date"
                                onChange={onChange}
                                value={formikState.values.painAssessment.duration}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Referral :</label>
                            </div>
                            <input
                                id="painAssessment.referralDoctor"
                                name="painAssessment.referralDoctor"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.painAssessment.referralDoctor}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Assessment :</label>
                            </div>
                            <input
                                id="painAssessment.assessment"
                                name="painAssessment.assessment"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.painAssessment.assessment}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Frequency :</label>
                            </div>
                            <Select
                                id="painAssessment.frequency"
                                name="painAssessment.frequency"
                                type="text"
                                onChange={(value) => {
                                    formikState.setFieldValue('painAssessment.frequency', value.value);
                                }}
                                defaultValue={formikState.values.painAssessment.frequency}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                options={[{ value: 'ONCE IN A DAY', label: 'ONCE IN A DAY' }, { value: 'TWICE IN A DAY', label: 'TWICE IN A DAY' }, { value: 'THRICE IN A DAY', label: 'THRICE IN A DAY' }, { value: 'FOUR TIMES IN A DAY', label: 'FOUR TIMES IN A DAY' }]}
                                isDisabled={props.isDisabled}
                                value={
                                    [{ value: 'ONCE IN A DAY', label: 'ONCE IN A DAY' }, { value: 'TWICE IN A DAY', label: 'TWICE IN A DAY' }, { value: 'THRICE IN A DAY', label: 'THRICE IN A DAY' }, { value: 'FOUR TIMES IN A DAY', label: 'FOUR TIMES IN A DAY' }].find((option) => option.value === formikState.values.painAssessment.frequency)
                                }
                            ></Select>
                        </div>
                    </div>
                    <div className='flex flex-col w-[49%] px-6'>
                        <div className='grid grid-cols-c4 gap-[1%] items-center'>
                            <span className='flex flex-row text-col font-roboto text-sm'>Intervention :</span>
                            <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                                <RadioButton
                                    label="Yes"
                                    name="painAssessment.intervention"
                                    id="yes"
                                    onChange={onChange}
                                    value='yes'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.intervention === 'yes'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="No"
                                    id="no"
                                    name="painAssessment.intervention"
                                    onChange={onChange}
                                    value='no'
                                    onBlur={onBlur}
                                    checked={formikState.values.painAssessment.intervention === 'no'}
                                    disabled={props.isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Treatment For Relief :</label>
                            </div>
                            <input
                                id="painAssessment.treatment"
                                name="painAssessment.treatment"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.painAssessment.treatment}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PainAssessment;
