/* eslint-disable no-console */
import React from 'react';
const Diagnosis = (props) => {
    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Dx (Diagnosis)</div>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <table className='w-full border-[#B9B9B9] border-[1px]'>
                    <thead>
                        <tr>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>S.No</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Diagnosis</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>By doctor</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Date & Time</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Report Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.progress?.Diagnosis
                                ? props.progress?.Diagnosis?.map((data, i) => (
                                    <>
                                        <tr key={i}>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' rowSpan={data.subRow ? data.subRow.length + 1 : null}>{ i + 1}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.diagnosis}>{data.diagnosis}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.doctor}>{data.doctor}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={
                                                new Date(data.dateTime).toLocaleString('en-GB', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    hourCycle: 'h12'

                                                })
                                            }>{new Date(data.dateTime).toLocaleString('en-GB', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                    hourCycle: 'h12'

                                                })
                                                }</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.status}>{data.status}</td>
                                        </tr>
                                    </>
                                ))
                                : <tr><td></td><td></td><td className=' text-end  font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>No Data Available</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>

    );
};

export default Diagnosis;
