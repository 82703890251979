/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
// import AutosizeInput from 'react-input-autosize';
import Check from '../Check';

function Hisofill (props) {
    // console.log('gen', props.patient?.gender);
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
                History Of Present  Illness<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between mb-[20px]'>
                    <div className='w-[180px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>History of present illness:<span className='text-[crimson]'>*</span></div>
                    <div className='w-[calc(100%-180px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <div
                                className='font-family-"roboto" font-[400] text-[14px] leading-[20px] text-[#8F8F8F]'
                            >My patient <span className='text-[#000000] font-[500]'>{props.patient?.name}</span>, of
                                <span className='text-[#000000] font-[500]'> {props.patient?.age}</span> old
                                <span className='text-[#000000] font-[500]'> {props.patient?.gender}</span> apparently well
                                <input required
                                    id="HistoryOfPresentIllness.Detail.Duration"
                                    name="HistoryOfPresentIllness.Detail.Duration"
                                    className='w-[75px] border-b-[2px] text-center outline-none'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.Duration}
                                    disabled={props.disabled}
                                />
                                {/* <select
                                    className='outline-none mx-2 border-[1px] border-[#E3E3E3] text-[14px]'
                                    name="HistoryOfPresentIllness.Detail.DMY"
                                    id="HistoryOfPresentIllness.Detail.DMY"
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.DMY}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="days">days</option>
                                    <option value="months">months</option>
                                    <option value="years">years</option>
                                </select> back, then */}
                                <input required id="days" className="peer/days appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} checked={props.formikState.values.HistoryOfPresentIllness.Detail.DMY === 'days'} name="HistoryOfPresentIllness.Detail.DMY" value='days' disabled={props.disabled}/>
                                <label htmlFor="days" className="italic cursor-pointer peer-checked/days:text-[#000000] peer-checked/days:font-[500] peer-checked/days:no-underline line-through decoration-black">days</label>/
                                <input required id="months" className="peer/months appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.DMY" value='months' checked={props.formikState.values.HistoryOfPresentIllness.Detail.DMY === 'months'} disabled={props.disabled}/>
                                <label htmlFor="months" className="italic cursor-pointer peer-checked/months:text-[#000000] peer-checked/months:font-[500] peer-checked/months:no-underline line-through decoration-black">months</label>/
                                <input required id="years" className="peer/years appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.DMY" value='years' checked={props.formikState.values.HistoryOfPresentIllness.Detail.DMY === 'years'} disabled={props.disabled}/>
                                <label htmlFor="years" className="italic cursor-pointer peer-checked/years:text-[#000000] peer-checked/years:font-[500] peer-checked/years:no-underline line-through decoration-black">years</label> back, then
                                <input required id="he" className="he appearance-none w-[0px]" type="text" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.Pronoun" value="he" disabled={props.disabled}/>
                                {props.patient?.gender === 'MALE' ? <><label htmlFor="he" className=" font-bold text-sm text-black font-roboto"> he</label><span>/</span></> : <label htmlFor="he" className="line-through decoration-black"> he /</label>}
                                <input required id="she" className="she appearance-none w-[0px]" type="text" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.Pronoun" value="she" disabled={props.disabled}/>
                                {props.patient?.gender === 'FEMALE' ? <label htmlFor="she" className="font-bold text-sm text-black font-roboto">she</label> : <label htmlFor="she" className="line-through decoration-black">she</label>} developed
                                <input required id="sudden" className="peer/sudden appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} checked={props.formikState.values.HistoryOfPresentIllness.Detail.Motion === 'sudden'} name="HistoryOfPresentIllness.Detail.Motion" value="sudden" disabled={props.disabled}/>
                                <label htmlFor="sudden" className="cursor-pointer peer-checked/sudden:text-[#000000] peer-checked/sudden:font-[500] peer-checked/sudden:no-underline line-through decoration-black"> sudden</label>/
                                <input required id="gradual" className="peer/gradual appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} checked={props.formikState.values.HistoryOfPresentIllness.Detail.Motion === 'gradual'} name="HistoryOfPresentIllness.Detail.Motion" value="gradual" disabled={props.disabled}/>
                                <label htmlFor="gradual" className="cursor-pointer peer-checked/gradual:text-[#000000] peer-checked/gradual:font-[500] peer-checked/gradual:no-underline line-through decoration-black">gradual</label> onset of the above chief complaint since
                                {/* <select
                                    className='outline-none mx-2 text-[14px] border-[1px] border-[#E3E3E3]'
                                    name="HistoryOfPresentIllness.Detail.Motion"
                                    id="HistoryOfPresentIllness.Detail.Motion"
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.Motion}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="sudden">sudden</option>
                                    <option value="gradual">gradual</option>
                                </select> onset of the above chief complaint since */}
                                <input required
                                    id="HistoryOfPresentIllness.Detail.Duration1"
                                    name="HistoryOfPresentIllness.Detail.Duration1"
                                    className='w-[75px] border-b-[2px] text-center outline-none'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.Duration1}
                                    disabled={props.disabled}
                                />
                                <input required id="days1" className="peer/days1 appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.DMY2" value="days" checked={props.formikState.values.HistoryOfPresentIllness.Detail.DMY2 === 'days'} disabled={props.disabled}/>
                                <label htmlFor="days1" className="italic cursor-pointer peer-checked/days1:text-[#000000] peer-checked/days1:font-[500] peer-checked/days1:no-underline line-through decoration-black"> days</label>/
                                <input required id="months1" className="peer/months1 appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.DMY2" value="months" checked={props.formikState.values.HistoryOfPresentIllness.Detail.DMY2 === 'months'} disabled={props.disabled}/>
                                <label htmlFor="months1" className="italic cursor-pointer peer-checked/months1:text-[#000000] peer-checked/months1:font-[500] peer-checked/months1:no-underline line-through decoration-black">months</label>/
                                <input required id="years1" className="peer/years1 appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.DMY2" value="years" checked={props.formikState.values.HistoryOfPresentIllness.Detail.DMY2 === 'years'} disabled={props.disabled}/>
                                <label htmlFor="years1" className="italic cursor-pointer peer-checked/years1:text-[#000000] peer-checked/years1:font-[500] peer-checked/years1:no-underline line-through decoration-black">years</label> at
                                {/* <select
                                    className='outline-none mr-2 text-[14px] border-[1px] border-[#E3E3E3]'
                                    name="HistoryOfPresentIllness.Detail.DMY2"
                                    id="HistoryOfPresentIllness.Detail.DMY2"
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.DMY2}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="days">days</option>
                                    <option value="months">months</option>
                                    <option value="years">years</option>
                                </select>at */}
                                <input required
                                    id="HistoryOfPresentIllness.Detail.Part"
                                    name="HistoryOfPresentIllness.Detail.Part"
                                    className='w-[75px] border-b-[2px] text-center outline-none'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.Part}
                                />
                                this part of the body which is progressively
                                <input required id="increasing" className="peer/increasing appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.IncorDec" value="increse" checked={props.formikState.values.HistoryOfPresentIllness.Detail.IncorDec === 'increse'} disabled={props.disabled}/>
                                <label htmlFor="increasing" className="italic cursor-pointer peer-checked/increasing:text-[#000000] peer-checked/increasing:font-[500] peer-checked/increasing:no-underline line-through decoration-black"> increasing</label>/
                                <input required id="decreases" className="peer/decreases appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.IncorDec" value="decrease" checked={props.formikState.values.HistoryOfPresentIllness.Detail.IncorDec === 'decrease'} disabled={props.disabled}/>
                                <label htmlFor="decreases" className="italic cursor-pointer peer-checked/decreases:text-[#000000] peer-checked/decreases:font-[500] peer-checked/decreases:no-underline line-through decoration-black">decreases</label>/
                                <input required id="continuous" className="peer/continuous appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.IncorDec" value="continuous" checked={props.formikState.values.HistoryOfPresentIllness.Detail.IncorDec === 'continuous'} disabled={props.disabled}/>
                                <label htmlFor="continuous" className="italic cursor-pointer peer-checked/continuous:text-[#000000] peer-checked/continuous:font-[500] peer-checked/continuous:no-underline line-through decoration-black">continuous</label>/
                                <input required id="intermittent" className="peer/intermittent appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.IncorDec" value="intermittent" checked={props.formikState.values.HistoryOfPresentIllness.Detail.IncorDec === 'intermittent'} disabled={props.disabled}/>
                                <label htmlFor="intermittent" className="italic cursor-pointer peer-checked/intermittent:text-[#000000] peer-checked/intermittent:font-[500] peer-checked/intermittent:no-underline line-through decoration-black">intermittent</label> in nature, relived
                                {/* <select
                                    className='outline-none mx-2 mt-2 text-[14px] border-[1px] border-[#E3E3E3]'
                                    name="HistoryOfPresentIllness.Detail.IncorDec"
                                    id="HistoryOfPresentIllness.Detail.IncorDec"
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.IncorDec}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="increse">increasing</option>
                                    <option value="decrease">decreases</option>
                                    <option value="continuous">continuous</option>
                                    <option value="intermittent">intermittent</option>
                                </select>in nature, relived */}
                                <input required id="with" className="peer/with appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.WithorWithout" value="with" checked={props.formikState.values.HistoryOfPresentIllness.Detail.WithorWithout === 'with'} disabled={props.disabled}/>
                                <label htmlFor="with" className="italic cursor-pointer peer-checked/with:text-[#000000] peer-checked/with:font-[500] peer-checked/with:no-underline line-through decoration-black"> with</label>/
                                <input required id="without" className="peer/without appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.WithorWithout" value="without" checked={props.formikState.values.HistoryOfPresentIllness.Detail.WithorWithout === 'without'} disabled={props.disabled}/>
                                <label htmlFor="without" className="italic cursor-pointer peer-checked/without:text-[#000000] peer-checked/without:font-[500] peer-checked/without:no-underline line-through decoration-black">without</label> medications. And associated with
                                {/* <select
                                    className='outline-none mx-2 mt-2 text-[14px] border-[1px] border-[#E3E3E3]'
                                    name="HistoryOfPresentIllness.Detail.WithorWithout"
                                    id="HistoryOfPresentIllness.Detail.WithorWithout"
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.WithorWithout}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="with">with</option>
                                    <option value="without">without</option>
                                </select>medications. And associated with */}
                                <input required
                                    id="HistoryOfPresentIllness.Detail.Symptoms"
                                    name="HistoryOfPresentIllness.Detail.Symptoms"
                                    className='w-[75px] border-b-[2px] text-center outline-none'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.Symptoms}
                                    disabled={props.disabled}
                                /> symptoms. For above complaint
                                <input required id="he2" className="he2 appearance-none w-[0px]" type="text" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.Pronoun1" value="he" />
                                {/* <label htmlFor="he1" className="peer-checked/he1:text-[#000000] peer-checked/he1:font-[500]"> he</label>/ */}
                                {props.patient?.gender === 'MALE' ? <><label htmlFor="he2" className=" font-bold text-sm text-black font-roboto"> he</label><span>/</span></> : <label htmlFor="he2" className="line-through decoration-black"> he /</label>}
                                <input required id="she2" className="she appearance-none w-[0px]" type="text" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.Pronoun1" value="she" />
                                {props.patient?.gender === 'FEMALE' ? <label htmlFor="she2" className="font-bold text-sm text-black font-roboto">she</label> : <label htmlFor="she2" className="line-through decoration-black">she</label>} took
                                <input required
                                    id="HistoryOfPresentIllness.Detail.Treatment"
                                    name="HistoryOfPresentIllness.Detail.Treatment"
                                    className='w-[50px] border-b-[2px] text-center outline-none overflow-hidden'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.Treatment}
                                    disabled={props.disabled}
                                    style={{ width: Math.min(Math.max(props.formikState.values.HistoryOfPresentIllness.Detail.Treatment.length, 10), 100) + 'ch' }}
                                />
                                treatment before
                                <input required id="he1" className="he1 appearance-none w-[0px]" type="text" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.Pronoun2" value="he" />
                                {/* <label htmlFor="he1" className="peer-checked/he1:text-[#000000] peer-checked/he1:font-[500]"> he</label>/ */}
                                {props.patient?.gender === 'MALE' ? <><label htmlFor="he1" className=" font-bold text-sm text-black font-roboto"> he</label><span>/</span></> : <label htmlFor="he1" className="line-through decoration-black"> he /</label>}
                                <input required id="she1" className="she1 appearance-none w-[0px]" type="text" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.Pronoun2" value="she" />
                                {props.patient?.gender === 'FEMALE' ? <label htmlFor="she1" className="font-bold text-sm text-black font-roboto">she</label> : <label htmlFor="she1" className="line-through decoration-black">she</label>} took
                                 came to our hospital.
                                <input required id="with1" className="peer/with1 appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.WithorWithout1" value="with" checked={props.formikState.values.HistoryOfPresentIllness.Detail.WithorWithout1 === 'with'} disabled={props.disabled}/>
                                <label htmlFor="with1" className="italic cursor-pointer peer-checked/with1:text-[#000000] peer-checked/with1:font-[500] peer-checked/with1:no-underline line-through decoration-black"> With</label>/
                                <input required id="without1" className="peer/without1 appearance-none w-[0px]" type="radio" onChange={props.formikState.handleChange} name="HistoryOfPresentIllness.Detail.WithorWithout1" value="without" checked={props.formikState.values.HistoryOfPresentIllness.Detail.WithorWithout1 === 'without'} disabled={props.disabled}/>
                                <label htmlFor="without1" className="italic cursor-pointer peer-checked/without1:text-[#000000] peer-checked/without1:font-[500] peer-checked/without1:no-underline line-through decoration-black">without</label> injuries.
                                {/* <select
                                    className='outline-none mx-2 mt-2 text-[14px] border-[1px] border-[#E3E3E3]'
                                    name="HistoryOfPresentIllness.Detail.WithorWithout1"
                                    id="HistoryOfPresentIllness.Detail.WithorWithout1"
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.HistoryOfPresentIllness.Detail.WithorWithout1}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="with">with</option>
                                    <option value="without">without</option>
                                </select>injuries. */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex items-center'>
                    <div className='w-[180px] font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Not associated with<br />Negative History&apos;s :</div>
                    <div className='w-[calc(100%-180px)] items-center flex'>
                        <Check formikState={props.formikState} label='Fever' val='Yes' id='Fever' name='HistoryOfPresentIllness.NegativeHistory.Fever' checked={ props.isView ? props.formikState.values.HistoryOfPresentIllness.NegativeHistory.Fever === 'Yes' : null } disabled={props.disabled}/>
                        <Check formikState={props.formikState} label='Headache' val='Yes' id='Headache' name='HistoryOfPresentIllness.NegativeHistory.Headache' checked={ props.isView ? props.formikState.values.HistoryOfPresentIllness.NegativeHistory.Headache === 'Yes' : null} disabled={props.disabled}/>
                        <Check formikState={props.formikState} label='Body-Aches' val='Yes' id='BodyAches' name='HistoryOfPresentIllness.NegativeHistory.BodyAches' checked={props.isView ? props.formikState.values.HistoryOfPresentIllness.NegativeHistory.BodyAches === 'Yes' : null} disabled={props.disabled}/>
                        <Check formikState={props.formikState} label='Chills' val='Yes' id='Chills' name='HistoryOfPresentIllness.NegativeHistory.Chills' checked={props.isView ? props.formikState.values.HistoryOfPresentIllness.NegativeHistory.Chills === 'Yes' : null} disabled={props.disabled}/>
                        <Check formikState={props.formikState} label='Breathlessness' val='Yes' id='Breathlessness' name='HistoryOfPresentIllness.NegativeHistory.Breathlessness' checked={props.isView ? props.formikState.values.HistoryOfPresentIllness.NegativeHistory.Breathlessness === 'Yes' : null} disabled={props.disabled}/>
                        <label className='ml-[30px] font-"roboto" self-center mr-[15px] font-[500] text-[14px] italic leading-[15px] text-[#757575]'>If Other</label>
                        <input
                            className='h-[47px] w-[45%] text-[#8F8F8F] text-[13px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='HistoryOfPresentIllness.NegativeHistory.Other'
                            name='HistoryOfPresentIllness.NegativeHistory.Other'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.HistoryOfPresentIllness.NegativeHistory.Other}
                            disabled={props.disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hisofill;
