import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import ShortNotesForm from '../../forms/ShortNotes';
import Arrow1 from '../../assets/images/arrow1.svg';
import { ageCalculation } from '../../utils/common';

const ShortNotes = () => {
    const location = useLocation();
    const admissionDetails = location.state;
    const patientDetails = admissionDetails ? admissionDetails.patient : '';

    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const time = new Date();
    const currentTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return (
        <div>
            <div className="bg-gray h-auto w-full">
                <div className="flex flex-row justify-between p-4">
                    <div className="flex items-center">
                        <Link to="/home">
                            <span className="text-base font-normal px-4 py-2 text-col font-roboto">
                                    Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base px-2 py-2 font-roboto">Short Notes</span>
                    </div>
                    <div className='flex items-center'>
                        <span className="text-lg px-0.5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientDetails ? patientDetails?.firstName?.[0].toUpperCase() + ' ' + patientDetails?.surname?.toUpperCase() + ' ' + '|' : ''}</span>
                        <span className="text-lg px-0.5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientDetails ? ageCalculation(patientDetails?.birthDate) + ' ' + '|' : ''}</span>
                        <span className="text-lg px-0.5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientDetails ? patientDetails?.gender?.toUpperCase() + ' ' + '|' : ''}</span>
                        <span className="text-lg px-0.5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientDetails ? patientDetails?.uhid + ' ' + '|' : ''}</span>
                        <span className="text-lg px-0.5 py-0.5 font-bold leading-[15px] text-[#000000]">{admissionDetails ? admissionDetails?.inPatientId : ''}</span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] leading-[15px] text-[#000000]">
                            Time : {currentTime}
                        </span>
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] leading-[15px] text-[#000000]">
                            {currentDate}
                        </span>
                    </div>
                </div>
                <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                    <ShortNotesForm isDisabled={false} />
                </div>
            </div>
        </div>
    );
};

export default ShortNotes;
