import React from 'react';
import spinner from '../assets/images/spinner.gif';

const Loader = (props) => {
    return (

        <div>
            {props.showSpinner
                ? <div className='h-screen absolute bg-transparent z-[100] w-[100%]'>
                    <div className='flex justify-center items-center h-full'>
                        <img className='h-16 w-16' src={spinner} alt="" />
                    </div>
                </div>
                : <></>}
        </div>
    );
};

export default Loader;
