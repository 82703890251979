/* eslint-disable no-undef */
/* eslint-disable no-console */
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import ImageCapture from 'react-image-data-capture';

const CaptureImg = ({ btnClassName, CaptureImgInnerText, ...props }) => {
    const config = useMemo(() => ({ video: true }), []);
    const [imgSrc, setImgSrc] = useState('');
    const [imgFile, setImgFile] = useState();
    const [viewImg, setViewImg] = useState(false);
    const [showImgCapture, setShowImgCapture] = useState(false);
    const [openImgCaptureModel, setopenImgCaptureModel] = useState(false);
    const onCapture = (imageData) => {
        setImgSrc(imageData.webP);
        setViewImg(true);
        setImgFile(imageData.file);
        setShowImgCapture(false);
    };

    const RetakeImg = () => {
        setViewImg(false);
        setShowImgCapture(true);
    };
    const SubmitImg = () => {
        const formData = new FormData();
        props.ImgSrc(imgSrc);
        setImgSrc('');
        setViewImg(false);
        formData.append('Imagefile', imgFile);
        props.ImgFile(formData);
        setopenImgCaptureModel(false);
    };

    const CloseCamera = () => {
        setImgSrc(null);
        setShowImgCapture(false);
        setopenImgCaptureModel(false);
        formData.delete('Imagefile');
        props.ImgFile(formData);
    };
    return (
        <>
            <Dialog open={openImgCaptureModel} maxWidth="xl">
                <DialogTitle>
                </DialogTitle>
                <DialogContent>
                    {showImgCapture && (
                        <><ImageCapture
                            onCapture={onCapture}
                            onError={(err) => console.log(err)}
                            width={600}
                            userMediaConfig={config} />
                        <div className='text-center'>
                            <button type="button" className='w-[196px] h-[38px] border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white active' onClick={CloseCamera}>Close</button>
                        </div>
                        </>
                    )}
                    {viewImg && (
                        <><img src={imgSrc} className='w-[100%]' />
                            <div className='text-center my-5'>
                                <button type="button" className='w-[196px] mx-2 h-[38px] border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white active' onClick={() => RetakeImg()}>Retake</button>
                                <button type="button" className='px-6 py-2 w-[196px] mx-2 text-white bg-[#1663AA] text-base font-medium' onClick={() => SubmitImg()}>Submit</button>
                            </div>
                        </>
                    )
                    }
                </DialogContent>
            </Dialog>
            <div className='text-center'>
                <button type='button' className={`px-6 py-2 ${btnClassName}`} onClick={() => { setopenImgCaptureModel(true); setShowImgCapture(true); }} >{CaptureImgInnerText}</button>
            </div>

        </>
    );
};
export default CaptureImg;
