import React from 'react';
import NurseContainer from '../../components/NurseContain/NurseContainer';
import Data from '../../utils/json/Functionfields.json';

function ArterialBloodGas ({ isDisabled, ...props }) {
    return (
        <div>
            <NurseContainer data={Data.PH} formikState={props.formikState} val={props.formikState.values.PH} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.PCo2} formikState={props.formikState} val={props.formikState.values.PCo2} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.PO2} formikState={props.formikState} val={props.formikState.values.PO2} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.Hco3} formikState={props.formikState} val={props.formikState.values.Hco3} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.Lactate} formikState={props.formikState} val={props.formikState.values.Lactate} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.Saturation} formikState={props.formikState} val={props.formikState.values.Saturation} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
        </div>
    );
}

export default ArterialBloodGas;
