import React from 'react';
import { NavLink } from 'react-router-dom';

function ProgressNav () {
    return (
        <div className='pl-5 pt-2 flex bg-bgray border-b-2 border-b-slate-300'>
            <NavLink to="/progress-notes" className='w-[338px] h-[38px] flex items-center justify-center rounded-t-lg border-2 background font-"roboto" font-[500] text-[14px] leading-[15px] text-[#00869a] active:border-[#00A2B7] active:bg-iat active:text-white'>Progress Notes</NavLink>
            <NavLink to="/progress/primary" className='w-[196px] h-[38px] flex items-center justify-center rounded-t-lg border-2 background font-"roboto" font-[500] text-[14px] leading-[15px] text-[#00869a] active:border-[#00A2B7] active:bg-iat active:text-white'>Primary Care</NavLink>
            <NavLink to="/progress/secondary" className='w-[196px] h-[38px] flex items-center justify-center rounded-t-lg border-2 background font-"roboto" font-[500] text-[14px] leading-[15px] text-[#00869a] active:border-[#00A2B7] active:bg-iat active:text-white'>Secondary Care</NavLink>
        </div>
    );
}

export default ProgressNav;
