import React from 'react';
import { Route } from 'react-router-dom';

import Home from '../containers/Home/Home';
import Patient from '../containers/Doctor/Patient';
import PatientRegister from '../containers/Doctor/PatientRegister';
import PatientUpdate from '../containers/Doctor/PatientUpdate';
import ViewPatient from '../containers/Doctor/ViewPatient';
import ViewAdmission from '../containers/Doctor/ViewAdmission';
import AdmissionUpdate from '../containers/Doctor/AdmissionUpdate';
import PatientAdmission from '../containers/Doctor/PatientAdmission';
import PatientAdmissionList from '../containers/Doctor/PatientAdmissionList';
// import ProgressNotes from '../containers/Doctor/ProgressNotes';
import CheckPatient from '../containers/Doctor/CheckPatient';
import PatientHistory from '../containers/Doctor/PatientHistory';
import PatientVisit from '../containers/Doctor/PatientVisit';
import Doctor from '../containers/Doctor/Doctor';
import PrivateRoute from '../hooks/PrivateRoute';
import { ROLE } from '../utils/roles';
import InitialAssessment from '../containers/InitialAssessment/InitialAssessment';
import InitialAssessmentView from '../containers/InitialAssessment/InitialAssessmentView';
import InitialAssessmentEdit from '../containers/InitialAssessment/InitialAssessmentEdit';
import InitialAssessmentAdd from '../containers/InitialAssessment/InitialAssessmentAdd';
import InitialProgress from '../containers/InitialAssessment/InitialProgress';
import Floor from '../containers/Hospital/Floor';
import Ward from '../containers/Hospital/Ward';
import Room from '../containers/Hospital/Room';
import Bed from '../containers/Hospital/Bed';
import ProgressPrimaryCare from '../containers/ProgressNotes/ProgressPrimaryCare';
import ProgressSecondaryCare from '../containers/ProgressNotes/ProgressSecondaryCare';
import CriticalReport from '../containers/Doctor/CriticalReport';
import AdendumNotes from '../containers/Doctor/AdendumNotes';
import ShortNotes from '../containers/Doctor/ShortNotes';
import EmergencyNotes from '../containers/Doctor/EmergencyNotes';
import OpdSlip from '../containers/Doctor/OpdSlip';
import TransferNotes from '../containers/Doctor/TransferNotes';
import ProgressNotesEdit from '../containers/ProgressNotes/ProgressNotesEdit';
import ProgressNotesAdd from '../containers/ProgressNotes/ProgressNotesAdd';
import ProgressNotesView from '../containers/ProgressNotes/ProgressNotesView';
import PrimaryCareDoctorView from '../containers/InitialAssessment/PrimaryCareDoctorView';
import StationDoctorView from '../containers/InitialAssessment/StationDoctorView';
import PrimaryCareDoctorEdit from '../containers/InitialAssessment/PrimaryCareDoctorEdit';
import StationDoctorEdit from '../containers/InitialAssessment/StationDoctorEdit';
import DischargeSummaryAdd from '../containers/DischargeSummary/DischargeSummaryAdd';
import Appointments from '../containers/Appointments/Appointments';

const doctorRoutes = (
    <Route element={<PrivateRoute roles={[ROLE.doctor.name]} />} >
        <Route path="doctor" element={<Doctor />} />
        <Route path="initial" element={<InitialAssessment />} />
        <Route path="initial/view/:id" element={<InitialAssessmentView />} />
        <Route path="initial/edit/:id" element={<InitialAssessmentEdit />} />
        <Route path="initial/add/:id" element={<InitialAssessmentAdd />} />
        <Route path="primary/view/:id" element={<PrimaryCareDoctorView />} />
        <Route path="secondary/view/:id" element={<StationDoctorView />} />
        <Route path="primary/edit/:id" element={<PrimaryCareDoctorEdit />} />
        <Route path="secondary/edit/:id" element={<StationDoctorEdit />} />
        <Route path="home" element={<Home />} />
        <Route path="patientvisit" element={<PatientVisit />} />
        <Route path="patient" element={<Patient />} />
        <Route path="patient/register" element={<PatientRegister />} />
        <Route path="patient/edit/:id" element={<PatientUpdate />} />
        <Route path="patient/check-patient" element={<CheckPatient />} />
        <Route path="patient/view/:id" element={<ViewPatient />} />
        <Route path="patient/admission-list" element={<PatientAdmissionList />} />
        <Route path="patient/admission/:id" element={<PatientAdmission />} />
        <Route path="patient/admission/view/:id" element={<ViewAdmission />} />
        <Route path="patient/admission/edit/:id" element={<AdmissionUpdate />} />
        <Route path="progress-notes/:id" element={<ProgressNotesAdd />} />
        <Route path="patient/progress-notes/edit/:id" element={<ProgressNotesEdit />} />
        <Route path="patient/progress-notes/view/:id" element={<ProgressNotesView />} />
        <Route path="progress/primary" element={<ProgressPrimaryCare />} />
        <Route path="progress/secondary" element={<ProgressSecondaryCare />} />
        <Route path="critical-report" element={<CriticalReport />} />
        <Route path="adendum-notes" element={<AdendumNotes />} />
        <Route path="short-notes" element={<ShortNotes />} />
        <Route path="emergency-notes" element={<EmergencyNotes />} />
        <Route path="opd-slip" element={<OpdSlip />} />
        <Route path="transfer-notes" element={<TransferNotes />} />
        <Route path="patient/history" element={<PatientHistory />} />
        <Route path="patient/assessment/:id" element={<InitialProgress />} />
        <Route path="floor-management/floor" element={<Floor />} />
        <Route path="floor-management/ward" element={<Ward />} />
        <Route path="floor-management/room" element={<Room />} />
        <Route path="floor-management/bed" element={<Bed />} />
        <Route path="discharge-summary/add/:id" element={<DischargeSummaryAdd />} />
        <Route path="appointment/:id" element={<Appointments />} />
    </Route>
);

export default doctorRoutes;
