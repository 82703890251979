/* eslint-disable no-console */
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import Arrow1 from '../../assets/images/arrow1.svg';
import PatientParticulars from './PatientParticulars';
import RegistrationDetails from './RegistrationDetails';
import ReactToPrint from 'react-to-print';
// import SummaryDetail from './Summary';
import PastHist from '../InitialAssessment/Fields/PastHist';
import DischargeSummaryJson from '../../utils/json/DischargeSummary.json';
import { ageCalculation } from '../../utils/common';
import History from './History';
import Gphysical from '../InitialAssessment/Fields/Gphysical';
import Viralpm from '../InitialAssessment/Fields/Viralpm';
import Other from '../InitialAssessment/Fields/Other';
import Pertinent from '../InitialAssessment/Fields/Pertinent';
import Referral from '../InitialAssessment/Fields/Referral';
// import Referral from './Referral';
import HandoverReports from './HandoverReports';
import Followup from './Followup';
import Medications from './Medications';
import Diagnosis from './Diagnosis';
import SignatureCanvas from 'react-signature-canvas';

function DischargeSummary (props) {
    // console.log('proiodsokdkdk', props?.primary);
    // console.log('propsassessment', props?.assessment);
    // console.log('encounter', props?.data?.[0]);
    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
    const canvasStyle = {
        border: '1px solid black',
        height: '65px',
        width: '200px'
    };

    const signatureRefs = [useRef(null), useRef(null), useRef(null)];
    const relationRef = useRef();
    const componentRef = useRef();
    const inputData = Object.assign({}, DischargeSummaryJson);
    console.log('inputData', inputData);
    const patient = props?.data?.[0];
    const initialAssessment = props.assessment;
    // console.log('dncnhc', patient?.participant?.[0].givenName?.[0] + ' ' + patient?.participant?.[0].family);
    inputData.PatientParticulars.Name = patient?.patient?.firstName;
    inputData.PatientParticulars.Age = ageCalculation(patient?.patient?.birthDate);
    inputData.PatientParticulars.Sex = patient?.patient?.gender;
    inputData.PatientParticulars.BloodGroup = patient?.patient?.bloodGroup;
    inputData.PatientParticulars.MaritalStatus = patient?.patient?.maritalStatus;
    inputData.PatientParticulars.GuardianName = patient?.patient?.guardianName;
    inputData.PatientParticulars.Nationality = patient?.patient?.nationality;
    inputData.PatientParticulars.MobileNumber = patient?.patient?.telecom;
    inputData.PatientParticulars.AddressLine1 = patient?.patient?.resaddress1;
    inputData.PatientParticulars.AddressLine2 = patient?.patient?.resaddress2;
    inputData.PatientParticulars.City = patient?.patient?.city;
    inputData.PatientParticulars.State = patient?.patient?.state;
    inputData.PatientParticulars.Country = patient?.patient?.country;
    inputData.PatientParticulars.Pincode = patient?.patient?.postalCode;
    inputData.RegistrationDetails.UHID = patient?.patient?.uhid;
    inputData.RegistrationDetails.IPNo = patient?.inPatientId;
    inputData.RegistrationDetails.Department = patient?.department;
    inputData.RegistrationDetails.Doctor = patient?.participant?.[0].id;
    inputData.RegistrationDetails.Floor = patient?.location?.[1].id;
    inputData.RegistrationDetails.Ward = patient?.location?.[2].id;
    inputData.RegistrationDetails.Room = patient?.location?.[3].id;
    inputData.RegistrationDetails.Bed = patient?.location?.[4].id;
    inputData.RegistrationDetails.Date = patient?.admissionDate;
    inputData.RegistrationDetails.Time = patient?.admissionTime;
    inputData.RegistrationDetails.Category = patient?.category;
    inputData.RegistrationDetails.MLC = patient?.Mlc === true ? 'yes' : 'no';
    inputData.RegistrationDetails.MLCText = patient?.MlcText;
    inputData.GeneralPhysicalExamination.GeneralCondition.GeneralConditionInput = initialAssessment?.GeneralPhysicalExamination?.GeneralCondition?.GeneralConditionInput;
    inputData.GeneralPhysicalExamination.Temperature.TemperatureInput = initialAssessment?.GeneralPhysicalExamination?.Temperature?.TemperatureInput;
    inputData.GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse = initialAssessment?.GeneralPhysicalExamination?.LevelOfConciseness?.EyeOpeningResponse;
    inputData.GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse = initialAssessment?.GeneralPhysicalExamination?.LevelOfConciseness?.VerbalResponse;
    inputData.GeneralPhysicalExamination.LevelOfConciseness.MotorResponse = initialAssessment?.GeneralPhysicalExamination?.LevelOfConciseness?.MotorResponse;
    inputData.GeneralPhysicalExamination.LevelOfConciseness.Result = initialAssessment?.GeneralPhysicalExamination?.LevelOfConciseness?.Result;
    inputData.GeneralPhysicalExamination.Coherence.CoherenceInput = initialAssessment?.GeneralPhysicalExamination?.Coherence?.CoherenceInput;
    inputData.GeneralPhysicalExamination.Orientation.OrientationInput = initialAssessment?.GeneralPhysicalExamination?.Orientation?.OrientationInput;
    inputData.GeneralPhysicalExamination.Disorientation.DisorientationInput = initialAssessment?.GeneralPhysicalExamination?.Disorientation?.DisorientationInput;
    inputData.GeneralPhysicalExamination.Thyroid.ThyroidNA = initialAssessment?.GeneralPhysicalExamination?.Thyroid?.ThyroidNA;
    inputData.GeneralPhysicalExamination.Thyroid.ThyroidInput = initialAssessment?.GeneralPhysicalExamination?.Thyroid?.ThyroidInput;
    inputData.GeneralPhysicalExamination.Breast.BreastNA = initialAssessment?.GeneralPhysicalExamination?.Breast?.BreastNA;
    inputData.GeneralPhysicalExamination.Breast.BreastInput = initialAssessment?.GeneralPhysicalExamination?.Breast?.BreastInput;
    inputData.GeneralPhysicalExamination.Attitude.Attitude = initialAssessment?.GeneralPhysicalExamination?.Attitude?.Attitude;
    inputData.GeneralPhysicalExamination.Attitude.AttitudeInput = initialAssessment?.GeneralPhysicalExamination?.Attitude?.AttitudeInput;
    inputData.VitalParameters.Airway.AirwayPatentorNot = initialAssessment?.VitalParameters?.Airway?.AirwayPatentorNot;
    inputData.VitalParameters.Breathing.rate.checked = initialAssessment?.VitalParameters?.Breathing?.rate?.checked;
    inputData.VitalParameters.Breathing.rate.RateInput = initialAssessment?.VitalParameters?.Breathing?.rate?.RateInput;
    inputData.VitalParameters.Breathing.type.TypeDropdown = initialAssessment?.VitalParameters?.Breathing?.type?.TypeDropdown;
    inputData.VitalParameters.Breathing.type.TypeInput = initialAssessment?.VitalParameters?.Breathing?.type?.TypeInput;
    inputData.VitalParameters.Breathing.spo2.spo2DropDown = initialAssessment?.VitalParameters?.Breathing?.spo2?.spo2DropDown;
    inputData.VitalParameters.Breathing.spo2.spo2Input = initialAssessment?.VitalParameters?.Breathing?.spo2?.spo2Input;
    inputData.VitalParameters.Breathing.spo2.Other = initialAssessment?.VitalParameters?.Breathing?.spo2?.Other;
    inputData.VitalParameters.Circulation.HRPulse.checked = initialAssessment?.VitalParameters?.Circulation?.HRPulse?.checked;
    inputData.VitalParameters.Circulation.HRPulse.HRPulseInput = initialAssessment?.VitalParameters?.Circulation?.HRPulse?.HRPulseInput;
    inputData.VitalParameters.Circulation.PulseRhythm = initialAssessment?.VitalParameters?.Circulation?.PulseRhythm;
    inputData.VitalParameters.Circulation.SBp.SBpInput = initialAssessment?.VitalParameters?.Circulation?.SBp?.SBpInput;
    inputData.VitalParameters.Circulation.DBp.DBpInput = initialAssessment?.VitalParameters?.Circulation?.DBp?.DBpInput;
    inputData.VitalParameters.Circulation.DBp.Position = initialAssessment?.VitalParameters?.Circulation?.DBp?.Position;
    inputData.VitalParameters.Circulation.DBp.checked = initialAssessment?.VitalParameters?.Circulation?.DBp?.checked;
    inputData.VitalParameters.Circulation.Temperature.Temperature = initialAssessment?.VitalParameters?.Circulation?.Temperature?.Temperature;
    inputData.VitalParameters.Circulation.Temperature.TemperatureInput = initialAssessment?.VitalParameters?.Circulation?.Temperature?.TemperatureInput;
    inputData.VitalParameters.Circulation.Temperature.tempCelsius = initialAssessment?.VitalParameters?.Circulation?.Temperature?.tempCelsius;
    inputData.VitalParameters.Circulation.Temperature.tempFahrenheit = initialAssessment?.VitalParameters?.Circulation?.Temperature?.tempFahrenheit;
    inputData.VitalParameters.Circulation.Cardiac = initialAssessment?.VitalParameters?.Circulation?.Cardiac;
    inputData.VitalParameters.Secondary.Pallor = initialAssessment?.VitalParameters?.Secondary?.Pallor;
    inputData.VitalParameters.Secondary.Cyanosis = initialAssessment?.VitalParameters?.Secondary?.Cyanosis;
    inputData.VitalParameters.Secondary.Icterus = initialAssessment?.VitalParameters?.Secondary?.Icterus;
    inputData.VitalParameters.Secondary.Koilonychia = initialAssessment?.VitalParameters?.Secondary?.Koilonychia;
    inputData.VitalParameters.Secondary.Iymphadenopathy = initialAssessment?.VitalParameters?.Secondary?.Iymphadenopathy;
    inputData.VitalParameters.Secondary.Edema = initialAssessment?.VitalParameters?.Secondary?.Edema;
    inputData.VitalParameters.Secondary.secondaryInput = initialAssessment?.VitalParameters?.Secondary?.secondaryInput;
    inputData.VitalParameters.Secondary.Chest = initialAssessment?.VitalParameters?.Secondary?.Chest;
    inputData.VitalParameters.Heart.HeartSounds.HeartSounds = initialAssessment?.VitalParameters?.Heart?.HeartSounds?.HeartSounds;
    inputData.VitalParameters.Heart.HeartSounds.HeartSoundsInput = initialAssessment?.VitalParameters?.Heart?.HeartSounds?.HeartSoundsInput;
    inputData.VitalParameters.Heart.AddedSound.AddedSound = initialAssessment?.VitalParameters?.Heart?.AddedSound?.AddedSound;
    inputData.VitalParameters.Heart.AddedSound.AddedSoundInput = initialAssessment?.VitalParameters?.Heart?.AddedSound?.AddedSoundInput;
    inputData.VitalParameters.Heart.Abdomen = initialAssessment?.VitalParameters?.Heart?.Abdomen;
    inputData.VitalParameters.Heart.Bowelsound = initialAssessment?.VitalParameters?.Heart?.Bowelsound;
    inputData.VitalParameters.Pupil.RtEye = initialAssessment?.VitalParameters?.Pupil?.RtEye;
    inputData.VitalParameters.Pupil.LtEye = initialAssessment?.VitalParameters?.Pupil?.LtEye;
    inputData.VitalParameters.Pupil.pupilInput = initialAssessment?.VitalParameters?.Pupil?.pupilInput;
    inputData.VitalParameters.Pupil1.Rt = initialAssessment?.VitalParameters?.Pupil1?.Rt;
    inputData.VitalParameters.Pupil1.Lt = initialAssessment?.VitalParameters?.Pupil1?.Lt;
    inputData.VitalParameters.Pupil1.RtTone = initialAssessment?.VitalParameters?.Pupil1?.RtTone;
    inputData.VitalParameters.Pupil1.LtTone = initialAssessment?.VitalParameters?.Pupil1?.LtTone;
    inputData.VitalParameters.Pupil1.RtReflexes = initialAssessment?.VitalParameters?.Pupil1?.RtReflexes;
    inputData.VitalParameters.Pupil1.LtReflexes = initialAssessment?.VitalParameters?.Pupil1?.LtReflexes;
    inputData.VitalParameters.Pupil1.Rreflex = initialAssessment?.VitalParameters?.Pupil1?.Rreflex;
    inputData.VitalParameters.Pupil1.Lreflex = initialAssessment?.VitalParameters?.Pupil1?.Lreflex;
    inputData.Other.Osystem = initialAssessment?.Other?.Osystem;
    inputData.Other.GITsystem.GITsystem = initialAssessment?.Other?.GITsystem?.GITsystem?.[0];
    inputData.Other.GITsystem.GITsystemInput = initialAssessment?.Other?.GITsystem?.GITsystemInput?.[0];
    inputData.Other.CVSsystem.CVSsystem = initialAssessment?.Other?.CVSsystem?.CVSsystem?.[0];
    inputData.Other.Rsystem.Rsystem = initialAssessment?.Other?.Rsystem?.Rsystem?.[0];
    inputData.Other.Rsystem.RsystemInput = initialAssessment?.Other?.Rsystem?.RsystemInput?.[0];
    inputData.Other.CNSsystem.CNSsystem = initialAssessment?.Other?.CNSsystem?.CNSsystem?.[0];
    inputData.Other.CNSsystem.CNSsystemInput = initialAssessment?.Other?.CNSsystem?.CNSsystemInput?.[0];
    inputData.Other.GUTsystem.GUTsystem = initialAssessment?.Other?.GUTsystem?.GUTsystem?.[0];
    inputData.Other.GUTsystem.GUTsystemInput = initialAssessment?.Other?.GUTsystem?.GUTsystemInput?.[0];
    inputData.Other.Other.Other = initialAssessment?.Other?.Other?.Other?.[0];
    inputData.Other.Other.OtherInput = initialAssessment?.Other?.Other?.OtherInput?.[0];
    inputData.Pertinent = initialAssessment?.Pertinent;
    inputData.History.OperativeProcedures = patient?.procedure;
    inputData.History.ChiefComplaint = initialAssessment?.ChiefComplaint?.map((data) => data.Complaint);
    inputData.History.PresentIllness = initialAssessment?.HistoryOfPresentIllness?.Detail?.Content?.[0];
    inputData.PastMedicalSurgicalHistories.PastHistoryYesNo = initialAssessment?.PastMedicalSurgicalHistories?.PastHistoryYesNo?.[0];
    inputData.PastMedicalSurgicalHistories.PastMedicalTestInput = initialAssessment?.PastMedicalSurgicalHistories?.PastMedicalTestInput?.[0];
    inputData.PastMedicalSurgicalHistories.HTN = initialAssessment?.PastMedicalSurgicalHistories?.HTN?.[0];
    inputData.PastMedicalSurgicalHistories.DM2 = initialAssessment?.PastMedicalSurgicalHistories?.DM2?.[0];
    inputData.PastMedicalSurgicalHistories.CAD = initialAssessment?.PastMedicalSurgicalHistories?.CAD?.[0];
    inputData.PastMedicalSurgicalHistories.ASTHMA = initialAssessment?.PastMedicalSurgicalHistories?.ASTHMA?.[0];
    inputData.PastMedicalSurgicalHistories.TB = initialAssessment?.PastMedicalSurgicalHistories?.TB?.[0];
    inputData.PastMedicalSurgicalHistories.OBESITY = initialAssessment?.PastMedicalSurgicalHistories?.OBESITY?.[0];
    inputData.PastMedicalSurgicalHistories.DYSLIPIDEMIA = initialAssessment?.PastMedicalSurgicalHistories?.DYSLIPIDEMIA?.[0];
    inputData.PastMedicalSurgicalHistories.SCD = initialAssessment?.PastMedicalSurgicalHistories?.SCD?.[0];
    inputData.PastMedicalSurgicalHistories.CVA = initialAssessment?.PastMedicalSurgicalHistories?.CVA?.[0];
    inputData.PastMedicalSurgicalHistories.Others = initialAssessment?.PastMedicalSurgicalHistories?.Others?.[0];
    // inputData.Medication = props.primary?.Medication;
    const time = new Date();
    const currentTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const formikState = useFormik({
        initialValues: inputData,
        onSubmit: values => {
            console.log('dis', values);
            alert(JSON.stringify(values));
        }
    });
    console.log('formikState', formikState);
    return (
        <form className="w-full flex flex-col gap-y-4 mb-10" onSubmit={formikState.handleSubmit}>
            <div className="bg-background w-full">
                <div className="flex flex-row h-[57px] px-[22px] justify-between gap-x-10 border-b-[1px] border-[#C5C5C5]">
                    <div className="flex items-center">
                        <Link to={'/home'}>
                            <span className="text-base font-normal py-2 pr-[4px] text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-col font-normal text-base font-roboto px-2 py-2">Discharge Summary</span>
                    </div>
                </div>
                <div className='font-roboto font-[500] text-[30px] leading-[15px] p-[22px]'>
                    <div className='flex justify-between'>
                        Discharge Summary
                        <div className="flex items-center">
                            <span className="text-[13px] px-5 py-0.5 font-'roboto' font-[300] leading-[15px] text-[#000000]">
                            Time : {currentTime}
                            </span>
                            <span className="text-[13px] pl-5 py-0.5 font-'roboto' font-[300] leading-[15px] text-[#000000]">
                            Date : {currentDate}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='px-[22px]'>
                    <PatientParticulars formikState={formikState}/>
                    <RegistrationDetails formikState={formikState} location={patient?.location}/>
                    {/* <SummaryDetail formikState={formikState}/> */}
                    <Diagnosis progress={props.progress}/>
                    <PastHist formikState={formikState}/>
                    <History formikState={formikState}/>
                    <Gphysical formikState={formikState} disabled={props.isDisabled} />
                    <Viralpm formikState={formikState} disabled={props.isDisabled}/>
                    <Other formikState={formikState} disabled={props.isDisabled}/>
                    <Pertinent formikState={formikState} disabled={props.isDisabled}/>
                    <Medications primary={props?.primary}/>
                    <Followup formikState={formikState}/>
                    <Referral formikState={formikState} isDischarge={true}/>
                    <div className = "flex flex-col gap-y-6 pt-12 px-6 w-full bg-white">
                        <span className='font-roboto font-normal text-base text-[#2b2b2b]'>Forward protocol to finalize :</span>
                        <div className='flex flex-row justify-between w-full px-10 gap-x-20 pb-4'>
                            <div className='flex flex-col gap-y-2'>
                                <span>Sr Resident Doctor</span>
                                <div style={canvasStyle}>
                                    <SignatureCanvas style={canvasStyle} ref={signatureRefs[0]} onEnd={() => formikState.setFieldValue('Referral.signature1', signatureRefs[0].current.toDataURL())}>D/C Prepared Signature</SignatureCanvas>
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <span>Asst.Professor</span>
                                <div style={canvasStyle}>
                                    <SignatureCanvas ref={signatureRefs[1]} onEnd={() => formikState.setFieldValue('Referral.signature2', signatureRefs[1].current.toDataURL())}>D/C Verified Signature</SignatureCanvas>
                                </div>
                            </div>
                            <div className='flex flex-col gap-y-2'>
                                <span>Unit-I/C, Professor</span>
                                <div style={canvasStyle}>
                                    <SignatureCanvas ref={signatureRefs[2]} onEnd={() => formikState.setFieldValue('Referral.signature3', signatureRefs[2].current.toDataURL())}>D/C Verified Signature</SignatureCanvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <HandoverReports formikState={formikState}/>
                    <div className='pt-12 px-6 w-full bg-white'>
                        <div className='flex flex-col gap-y-4'>
                            <span className='text-base font-medium'>I here by Acknowledge that I have received the above mentioned Reports / X-Rays</span>
                            <div className='flex flex-row gap-x-3'>
                                <label className='text-base font-normal text-[#656565]'>Name & Relationship with patient : </label>
                                <input
                                    className='h-[36px] pl-[10px] text-[13px] self-center border-[1px] w-[362px] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                                    id='Handover.Relation'
                                    name='Handover.Relation'
                                    type='text'
                                    placeholder='Write a name / Relationship'
                                    onChange={formikState.handleChange}
                                    value={formikState.values.Handover.Relation}
                                />
                            </div>
                            <span className='text-base font-normal text-[#656565]'>Signature for the Attender/ Patient</span>
                            <div style={canvasStyle} className="w-[30%]">
                                <SignatureCanvas ref={relationRef} onEnd={() => formikState.setFieldValue('Handover.RelationSign', relationRef.current.toDataURL())}></SignatureCanvas>
                            </div>
                        </div>
                    </div>
                    <div className='hidden'>
                        <div ref={componentRef}>
                            <ComponentToPrint patient={patient} initialAssessment={initialAssessment} inputData={formikState.values}/>
                        </div>
                    </div>
                    <div className='h-[50px] bg-[#FFFFFF] text-right px-[30px] pb-[140px] justify-end flex flex-row'>
                        <ReactToPrint
                            trigger={() => <button className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF] mt-2'>Print</button>}
                            content={() => componentRef.current}
                        />
                        <button className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF] mt-2' type='submit'>Save</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

function ComponentToPrint (props) {
    return (
        <div className='m-[20px]'>
            <div className='flex justify-center pb-[15px]'>
                <img src="/static/media/vagus-logo.061d68bea73223e0040a.png" alt="logo" className='w-[200px]'></img>
            </div>
            <div className='pb-[10px] font-"roboto" font-[500] text-[25px] leading-[15px] text-center text-[#272727]'>Discharge Summary</div>
            <div className='pb-[12px] flex justify-center text-[12px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    1. Registration Details
                </div>
                <Summary head='UHID' data={props.patient?.patient?.uhid}/>
                <Summary head='IP NO' data={props.patient?.inPatientId}/>
                <Summary head='Treating Doctors/ consultants' data={props.patient?.participant?.[0]?.givenName}/>
                <Summary head='Treating/ Admission Department' data={props.patient?.department}/>
                <Summary head='Ward name/number' data={props.patient?.location?.[2]?.display}/>
                <Summary head='Room number' data={props.patient?.location?.[3]?.display}/>
                <Summary head='Bed number' data={props.patient?.location?.[4]?.display}/>
                <Summary head='Date of admission & time' data={props.patient?.admissionDate + '' + props.patient?.admissionTime}/>
                <Summary head='Type of discharge' data={props.patient?.category}/>
            </div>

            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    2. Patient particulars
                </div>
                <Summary head='Patient name' data={props.patient?.patient?.nameText}/>
                <Summary head='Age' data={ageCalculation(props.patient?.patient?.birthDate)}/>
                <Summary head='Sex' data={props.patient?.patient?.gender}/>
                <Summary head='Marriage status' data={props.patient?.patient?.maritalStatus}/>
                <Summary head='Father/husband /guardian name' data={props.patient?.patient?.guardianName}/>
                <Summary head='Guardian type' data={props.patient?.patient?.guardianType}/>
                <Summary head='Education' data={props.patient?.patient?.education}/>
                <Summary head='Occupation' data={props.patient?.patient?.occupation}/>
                <Summary head='Mobile number' data={props.patient?.patient?.telecom}/>
                <Summary head='Aadhar number' data={props.patient?.patient?.aadharNumber}/>
            </div>

            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    3.Address (Demographic Details)
                </div>
                <Summary head='Address' data={props.patient?.patient?.resaddress1}/>
                <Summary head='City' data={props.patient?.patient?.city}/>
                <Summary head='State' data={props.patient?.patient?.state}/>
                <Summary head='Country' data={props.patient?.patient?.country}/>
                <Summary head='Pincode' data={props.patient?.patient?.postalCode}/>
            </div>

            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    4.Diagnosis
                </div>
                {/* <Summary head='Admission diagnosis' data='Durga'/> */}
                <Summary head='Past medical illness' data={props.initialAssessment?.PastMedicalSurgicalHistories?.PastHistoryYesNo}/>
                <Summary head='Operative procedures & dates performed' data={props.patient?.procedure}/>
                <Summary head='Credited/ Prepared by' data={props.inputData?.Creditedby}/>
                <Summary head='Chief complaint/ admitting reason' data={props.initialAssessment?.ChiefComplaint?.[0]?.Complaint}/>
                <Summary head='History of present illness' data={props.initialAssessment?.HistoryOfPresentIllness?.Detail?.Content?.[0]}/>
                <Summary head='Pertinent clinical findings' data={props.initialAssessment?.Pertinent}/>
                {/* <Summary head='Discharge medications' data='DOR'/> */}
                <Summary head='Discharge instructions to patient and/or family' data={props.inputData?.Follow?.Instruction}/>
                <Summary head='Follow Up' data={props.inputData?.Follow?.Test}/>
                <Summary head='Pending investigations' data={props.initialAssessment?.Investigations?.Investigations?.[0]}/>
                <Summary head='Prognosis' data={props.initialAssessment?.Prognosis?.Prognosis}/>
                <Summary head='Diet for Disease' data={props.primary?.Diet?.data}/>
            </div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    5.General physical examination
                </div>
                <Summary head='General condition' data={props.initialAssessment?.GeneralPhysicalExamination?.GeneralCondition?.GeneralConditionInput}/>
                <Summary head='Temperature' data={props.initialAssessment?.GeneralPhysicalExamination?.Temperature?.TemperatureInput}/>
                <Summary head='Level of conciseness' data={props.initialAssessment?.GeneralPhysicalExamination?.LevelOfConciseness?.Result}/>
                <Summary head='Coherence' data={props.initialAssessment?.GeneralPhysicalExamination?.Coherence?.CoherenceInput}/>
                <Summary head='Orientation' data={props.initialAssessment?.GeneralPhysicalExamination?.Orientation?.OrientationInput}/>
                <Summary head='Disorientation' data={props.initialAssessment?.GeneralPhysicalExamination?.Disorientation?.DisorientationInput}/>
                <Summary head='Thyroid examination' data={props.initialAssessment?.GeneralPhysicalExamination?.Thyroid?.ThyroidNA}/>
                <Summary head='Breast examination' data={props.initialAssessment?.GeneralPhysicalExamination?.Breast?.BreastNA}/>
                <Summary head='Attitude of the patient' data={props.initialAssessment?.GeneralPhysicalExamination?.Attitude?.Attitude}/>
            </div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    5.Referral
                </div>
                <Summary head='Referred from' data={props.inputData?.Referral?.ReferredFrom}/>
                <Summary head='Referred to' data={props.inputData?.Referral?.ReferredTo}/>
                <Summary head='Referred to Department' data={props.inputData?.Referral?.Department}/>
                <Summary head='Referral Reason' data={props.inputData?.Referral?.Data}/>
                <Summary head='Special Instruction' data={props.inputData?.Referral?.Instruction}/>
                <ReferralDoctor head='Signature' data1={props.inputData?.Referral?.signature1} data2={props.inputData?.Referral?.signature2} data3={props.inputData?.Referral?.signature3}/>
            </div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    5.Handed over reports to Attendant/ Patient
                </div>
                <Summary head="Name& Relationship with patient" data = {props.inputData?.Handover?.Relation}/>
                <Relation head="Signature of Attender/ Guardian" data = {props.inputData?.Handover?.RelationSign}/>
            </div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    5.General physical examination
                </div>
                <Summary head='General condition' data={props.initialAssessment?.GeneralPhysicalExamination?.GeneralCondition?.GeneralConditionInput}/>
                <Summary head='Temperature' data={props.initialAssessment?.GeneralPhysicalExamination?.Temperature?.TemperatureInput}/>
                <Summary head='Level of conciseness' data={props.initialAssessment?.GeneralPhysicalExamination?.LevelOfConciseness?.Result}/>
                <Summary head='Coherence' data={props.initialAssessment?.GeneralPhysicalExamination?.Coherence?.CoherenceInput}/>
                <Summary head='Orientation' data={props.initialAssessment?.GeneralPhysicalExamination?.Orientation?.OrientationInput}/>
                <Summary head='Disorientation' data={props.initialAssessment?.GeneralPhysicalExamination?.Disorientation?.DisorientationInput}/>
                <Summary head='Thyroid examination' data={props.initialAssessment?.GeneralPhysicalExamination?.Thyroid?.ThyroidNA}/>
                <Summary head='Breast examination' data={props.initialAssessment?.GeneralPhysicalExamination?.Breast?.BreastNA}/>
                <Summary head='Attitude of the patient' data={props.initialAssessment?.GeneralPhysicalExamination?.Attitude?.Attitude}/>
            </div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    5.Referral
                </div>
                <Summary head='Referred from' data=''/>
                <Summary head='Referred to' data=''/>
                <Summary head='Referred to Department' data=''/>
                <Summary head='Referral Reason' data=''/>
                <Summary head='Special Instruction' data=''/>
                <ReferralDoctor head='Signature'/>
            </div>
            <div>
                <div className='h-[35px] bg-[#2CA9BA] flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[13px] leading: [15px] text-[#FFFFFF]'>
                    5.Handed over reports to Attendant/ Patient
                </div>
                <Summary head="Name& Relationship with patient" dada = ""/>
                <Relation head="Signature of Attender/ Guardian"/>
            </div>
        </div>
    );
}

function Summary (props) {
    return (
        <div className='w-[100%] flex'>
            <div className='w-[25%] pl-[15px] h-[27.5px] border-l-[1px] border-[0.5px] border-[#D7D7D7] items-center flex bg-[#F7F7F7] font-"roboto" font-[400] text-[10px] leading-[15px] text-[#000000]'>
                {props.head}
            </div>
            <div className='w-[75%] pl-[15px] h-[27.5px] border-r-[1px] border-[0.5px] border-[#D7D7D7] items-center flex bg-[#FFFFFF] font-"roboto" font-[400] text-[10px] leading-[15px] text-[#757575]'>
                {props.data}
            </div>
        </div>
    );
}

function ReferralDoctor (props) {
    return (
        <div className='w-[100%] flex'>
            <div className='w-[25%] pl-[15px] h-[70px] border-l-[1px] border-[0.5px] border-[#D7D7D7] items-center flex bg-[#F7F7F7] font-"roboto" font-[400] text-[10px] leading-[15px] text-[#000000]'>
                {props.head}
            </div>
            <div className='w-[75%] px-[15px] h-[70px] border-r-[1px] border-[0.5px] border-[#D7D7D7] justify-between flex flex-row gap-x-4 bg-[#FFFFFF] font-"roboto" font-[400] text-[10px] leading-[15px] text-[#757575]'>
                <div className='flex flex-col gap-y-2'>
                    <span>Sr Resident Doctor</span>
                    <div className=' px-[10px] h-[30px] border-r-[1px] pt-3 border-[0.5px] border-[#D7D7D7] flex flex-row bg-[#FFFFFF] font-"roboto" font-[400] text-[10px] text-[#757575]'>D/C Prepared Signature</div>
                </div>
                <div className='flex flex-col gap-y-2'>
                    <span>Asst.Professor</span>
                    <div className=' px-[10px] h-[30px] border-r-[1px] pt-3 border-[0.5px] border-[#D7D7D7] flex flex-row bg-[#FFFFFF] font-"roboto" font-[400] text-[10px] text-[#757575]'>D/C Verified Signature</div>
                </div>
                <div className='flex flex-col gap-y-2'>
                    <span>Unit-I/C, Professor</span>
                    <div className=' px-[10px] h-[30px] border-r-[1px] pt-3 border-[0.5px] border-[#D7D7D7] flex flex-row bg-[#FFFFFF] font-"roboto" font-[400] text-[10px] text-[#757575]'>D/C Finalised Signature</div>
                </div>
            </div>
        </div>
    );
}

function Relation (props) {
    return (
        <div className='w-[100%] flex'>
            <div className='w-[25%] pl-[15px] h-[70px] border-l-[1px] border-[0.5px] border-[#D7D7D7] items-center flex bg-[#F7F7F7] font-"roboto" font-[400] text-[10px] leading-[15px] text-[#000000]'>
                {props.head}
            </div>
            <div className='w-[75%] px-[15px] h-[70px] border-r-[1px] border-[0.5px] border-[#D7D7D7] flex flex-row'>
                <div className='flex flex-col gap-y-2'>
                    <span>Signature for the Attender/ Patient</span>
                    <div className='w-24 pl-[15px] h-[30px] border-r-[1px] pt-3 border-[0.5px] border-[#D7D7D7] flex flex-row bg-[#FFFFFF] font-"roboto" font-[400] text-[10px] text-[#757575]'></div>
                </div>
            </div>
        </div>
    );
}

export default DischargeSummary;
