import { toFhir as toPatientFhir, fromFhir as fromPatientFhir, fromFhirList as fromPatientFhirList } from './resources/Patient/index.js';

import { toEncounterFhir, fromCompleteEncounterFhirList, fromEncounterIdFhir } from './resources/Encounter/index.js';
import { fromCompleteEncounterFhirListPharmacy } from './resources/EncounterPharmacy/index.js';

import { fromFhir as fromQuestionnaireResponseFhir, toFhir as toQuestionnaireResponseFhir } from './resources/QuestionnaireResponse/index.js';
import { fromFhir as fromQuestionnaireFhir } from './resources/Questionnaire/index.js';

import { toFhir as toObservationFhir } from './resources/Observation/toObservation.js';
import { fromFhir as fromObservationFhir } from './resources/Observation/index.js';
import { toGeneralObservationFhir, toVitalParamsFhir, toBPObservationFhir, toObservationComponentFhir, fromObservationComponentFhir } from './resources/Observation/ObservationVariants.js';

import { toFhir as toDiagnosticReportFhir, toFhirBundle as toDiagnosticBundleFhir, fromFhirList as fromDiagnosticReportFhirList, fromFhir as fromDiagnosticReportFhir } from './resources/DiagnosticReport/index.js';

import { toLocationFhir } from './resources/Location/index.js';

import { fromFhir as fromConditionFhir, toFhir as toConditionFhir } from './resources/Condition/index.js';

import { fromFhir as fromClinicalImpressionFhir, fromSecondaryCare, toFhir as toClinicalImpressionFhir, toPrimaryCareFhir, fromPrimaryCareFhir, toSecondaryCareFhir } from './resources/ClinicalImpression/index.js';
import { fromProgressNotes, toProgressNotes } from './resources/ProgressNotes/index.js';

import { fromPractitionerRoleFhirList } from './resources/PractitionerRole/index.js';
import { fromFhirList as fromPractitionerFhirList } from './resources/Practitioner/index.js';

import { toFhir as toMedicationFhir, fromFhir as fromMedicationFhir } from './resources/MedicationRequest/index.js';
import { toFhir as toMedicationBundleFhir } from './resources/MedicationRequestBundle/index.js';
import { toFhir as toMedicationDispenseFhir } from './resources/MedicationDispense/index.js';

import { fromMediaFhirList, toFhir as toMediaFhir } from './resources/Media/index.js';

import { toFhir as toRiskAssessment, fromFhir as fromRiskAssessment } from './resources/RiskAssessment/index.js';

import { toFhir as toMedicationAdministrationFhir, fromFhir as fromMedicationAdministrationFhir } from './resources/MedicationAdministration/index.js';

import { toFhir as toServiceRequestFhir, fromFhir as fromServiceRequestFhir } from './resources/ServiceRequest/index.js';

export function convertToFhir (resourceType, data) {
    try {
        switch (resourceType) {
        case 'Patient':
            return toPatientFhir(data)
                .then(res => Promise.resolve(res))
                .catch(e => Promise.reject(e));
        case 'Encounter':
            return toEncounterFhir(data)
                .then(res => Promise.resolve(res))
                .catch(e => Promise.reject(e));
        case 'Location':
            return toLocationFhir(data)
                .then(res => Promise.resolve(res))
                .catch(e => Promise.reject(e));
        case 'ClinicalImpression':
            return toClinicalImpressionFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'QuestionnaireResponse':
            return toQuestionnaireResponseFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'GeneralObservation':
            return toGeneralObservationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'VitalParametersObservation':
            return toVitalParamsFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Condition':
            return toConditionFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'MedicationrequestBundle':
            return toMedicationBundleFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'MedicationRequest':
            return toMedicationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'MedicationDispense':
            return toMedicationDispenseFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'DiagnosticReport':
            return toDiagnosticReportFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'DiagnosticReportBundle':
            return toDiagnosticBundleFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Media':
            return toMediaFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Observation':
            return toObservationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Observation-Component':
            return toObservationComponentFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'PrimaryCare':
            return toPrimaryCareFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'RiskAssessment':
            return toRiskAssessment(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'SecondaryCare':
            return toSecondaryCareFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Observation-BP':
            return toBPObservationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'MedicationAdministration':
            return toMedicationAdministrationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Progress-Notes':
            return toProgressNotes(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'ServiceRequest':
            return toServiceRequestFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        default: throw new Error('Invalid resource');
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}

export function convertFromFhir (resourceType, data) {
    try {
        switch (resourceType) {
        case 'Patient':
            return fromPatientFhir(data)
                .then(res => Promise.resolve(res))
                .catch(e => Promise.reject(e));
        case 'Encounter':
            return fromEncounterIdFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'QuestionnaireResponse':
            return fromQuestionnaireResponseFhir(data)
                .then(re => Promise.resolve(re))
                .catch(e => Promise.reject(e));
        case 'Observation':
            return fromObservationFhir(data).then(re => Promise.resolve(re)).catch(e => Promise.reject(e));
        case 'Condition':
            return fromConditionFhir(data).then(re => Promise.resolve(re)).catch(e => Promise.reject(e));
        case 'Questionnaire':
            return fromQuestionnaireFhir(data).then(re => Promise.resolve(re)).catch(e => Promise.reject(e));
        case 'ClinicalImpression':
            return fromClinicalImpressionFhir(data).then(re => Promise.resolve(re).catch(e => Promise.reject(e)));
        case 'SecondaryCare':
            return fromSecondaryCare(data).then(re => Promise.resolve(re)).catch(e => Promise.reject(e));
        case 'MedicationRequest':
            return fromMedicationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'DiagnosticReport':
            return fromDiagnosticReportFhir(data).then(re => Promise.resolve(re).catch(e => Promise.reject(e)));
        case 'Media':
            return toMediaFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'RiskAssessment':
            return fromRiskAssessment(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Observation-Component':
            return fromObservationComponentFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'PrimaryCare':
            return fromPrimaryCareFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'Progress-Notes':
            return fromProgressNotes(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'ServiceRequest':
            return fromServiceRequestFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        case 'MedicationAdministration':
            return fromMedicationAdministrationFhir(data).then(res => Promise.resolve(res)).catch(e => Promise.reject(e));
        default: throw new Error('Invalid resource');
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return e;
    }
}

export function convertFromFhirList (resourceType, data) {
    try {
        switch (resourceType) {
        case 'Patient':
            return fromPatientFhirList(data)
                .then((res) => Promise.resolve(res))
                .catch(e => Promise.reject(e));
        case 'Encounter':
            return fromCompleteEncounterFhirList(data)
                .then(res => Promise.resolve(res))
                .catch((e) => Promise.reject(e));
        case 'PractitionerRole':
            return fromPractitionerRoleFhirList(data)
                .then(res => Promise.resolve(res))
                .catch((e) => Promise.reject(e));
        case 'EncounterPharmacy':
            return fromCompleteEncounterFhirListPharmacy(data)
                .then(res => Promise.resolve(res))
                .catch((e) => Promise.reject(e));
        case 'Practitioner':
            return fromPractitionerFhirList(data)
                .then(res => Promise.resolve(res))
                .catch((e) => Promise.reject(e));
        case 'DiagnosticReport':
            return fromDiagnosticReportFhirList(data)
                .then(res => Promise.resolve(res))
                .catch((e) => Promise.reject(e));
        case 'Media':
            return fromMediaFhirList(data)
                .then(res => Promise.resolve(res))
                .catch((e) => Promise.reject(e));
        default: throw new Error('Invalid resource');
        }
    } catch (e) {
        return e;
    }
}
