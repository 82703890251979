/* eslint-disable no-console */
import React, { useState } from 'react';
// import Select from 'react-select';
import ReportStatus from '../../utils/json/DiagnosticReportStatus.json';

const InvestigationPopup = ({ prefillData, ...props }) => {
    // const [value, setValue] = useState('');
    const [popUpvalues, setPopupValues] = useState({
        investigation: '',
        doctor: '',
        date: '',
        reportStatus: ''

    });
    const HandleInputValues = (name, value) => {
        setPopupValues({ ...popUpvalues, [name]: value });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        if (popUpvalues.diagnosis !== '' || popUpvalues.doctor !== '' || popUpvalues.date !== '') {
            props.sendValue(popUpvalues);
        } else {
            props.handleModalClose(false);
        }
    };
    return (
        <div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Investigations :</label>
                        </div>
                        <input
                            id="investigation"
                            name="investigation"
                            type="text"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverFrom}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.investigation}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">By Doctor :</label>
                        </div>
                        <input
                            id="doctor"
                            name="doctor"
                            type="text"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverTo}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.doctor}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Date :</label>
                        </div>
                        <input
                            id="date"
                            name="date"
                            type="datetime-local"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverTo}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.date}
                        />
                    </div>
                </div>
                {/* <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Time :</label>
                        </div>
                        <input
                            id="time"
                            name="time"
                            type="time"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverTo}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.time}
                        />
                    </div>
                </div> */}
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Report Status :</label>
                        </div>
                        {/* <input
                            id="reportStatus"
                            name="reportStatus"
                            type="text"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverTo}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.reportStatus}
                        /> */}
                        {/* <Select
                            name="reportStatus"
                            options={ReportStatus}
                            defaultValue={prefillData.reportStatus}
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.time)
                            }
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                        /> */}
                        <select defaultValue={prefillData.reportStatus}
                            name="reportStatus"
                            id="reportStatus"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col">
                            {ReportStatus.map((Name) => (
                                <option key={Name.value} value={Name.value}>
                                    {Name.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    {prefillData.state === 1
                        ? <></>
                        : <button type='submit' disabled={props.isDisabled} className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[0.5rem] my-8 text-sm border rounded-md text-white' onClick={handlerSubmit} >Submit</button>
                    }
                    {/* <button type='submit' className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[0.5rem] my-8 text-sm border rounded-md text-white'>Submit</button> */}
                    <button type='button' className='bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col' onClick={() => props.handleModalClose(false)}>Cancel</button>

                </div>
            </div>
        </div>
    );
};

export default InvestigationPopup;
