import { React, createContext, useEffect, useState } from 'react';
import { getUserCredFromLocal } from '../utils/helper';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});

    useEffect(() => {
        const checkUserAuth = () => {
            getUserCredFromLocal()
                .then(creds => setAuth(creds))
                // eslint-disable-next-line no-console
                .catch(console.error);
        };
        if (!auth.length) checkUserAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
