import React from 'react';
// import * as Yup from 'yup';
import { TextField } from '../components/TextField';

const VitalSignView = (props) => {
    // console.log('view', props);

    // const props.formik = useFormik({
    //     initialValues: {
    //         weight:"",
    //         Height:"",
    //         bmi:"",
    //         Systolic:"",
    //         Diastolic:"",
    //         Temperature:"",
    //         RespiratoryRate:"",
    //         HeartRate:"",
    //         UrineOutput:"",
    //         BloodSugarF:"",
    //         BloodSugarR:"",
    //         SPO2:"",
    //         AVPU:"",
    //         Trauma:"",
    //         Mobility:"",
    //         OxygenSupplementation:"",
    //         Intake:"",
    //         Output:"",
    //         VitalTakenTime:"",
    //         Comments:""
    //     },
    // })

    // console.log(props.formik.values)

    return (
        <form className="w-full flex flex-col gap-y-4" >
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Weight (kg)"
                            id="weight"
                            name="Weight"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Weight}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // // isMandatory = {true}
                            // // style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Height (cm)"
                            id="Height"
                            name="Height"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Height}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // // isMandatory = {true}
                            // // style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="BMI (kg/m2)"
                            id="Bmi"
                            name="Bmi"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Bmi}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // // isMandatory = {true}
                            // // style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Systolic B.P."
                            id="Systolic"
                            name="Systolic"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Systolic}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // // isMandatory = {true}
                            // // style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Diastolic B.P."
                            id="Diastolic"
                            name="Diastolic"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Diastolic}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Temperature(oC)"
                            id="Temperature"
                            name="Temperature"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Temperature}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%] px-6">
                        <TextField
                            label="RespiratoryRate"
                            id="RespiratoryRate"
                            name="RespiratoryRate"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.RespiratoryRate}
                            // onBlur={props.formik.handleBlur}
                            className="font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Heart Rate"
                            id="HeartRate"
                            name="HeartRate"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.HeartRate}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Urine Output"
                            id="UrineOutput "
                            name="UrineOutput"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.UrineOutput}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Blood Sugar(F)"
                            id="BloodSugarF"
                            name="BloodSugarF"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.BloodSugarF}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Blood Sugar(R)"
                            id="BloodSugarR"
                            name="BloodSugarR"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.BloodSugarR}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="SPO2"
                            id="SPO2"
                            name="SPO2"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.SPO2}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="AVPU"
                            id="AVPU"
                            name="AVPU"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.AVPU}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Trauma"
                            id="Trauma"
                            name="Trauma"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Trauma}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Mobility"
                            id="Mobility"
                            name="Mobility"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Mobility}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Oxygen
                                supplementation"
                            id="OxygenSupplementation"
                            name="OxygenSupplementation"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.OxygenSupplementation}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Intake"
                            id="Intake"
                            name="Intake"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Intake}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Output"
                            id="Output"
                            name="Output"
                            type="text"
                            // onChange={props.formik.handleChange}
                            value={props.data.Output}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                    <div className="flex flex-col w-[30%]  px-6">
                        <TextField
                            label="Vital Taken Time"
                            id="VitalTakenTime"
                            name="VitalTakenTime"
                            type="datetime-local"
                            // onChange={props.formik.handleChange}
                            value={props.data.VitalTakenTime}
                            // onBlur={props.formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={true}
                            // isMandatory = {true}
                            // style = {props.style}
                        />

                    </div>
                </div>
            </div>
            <div className="px-6">
                <TextField
                    label="Comments"
                    id="Comments"
                    name="Comments"
                    type="text"
                    // onChange={props.formik.handleChange}
                    value={props.data.Comments}
                    // onBlur={props.formik.handleBlur}
                    className="text-col font-roboto bg-gray box-border border-gray focus:outline-none w-full text-sm h-10"
                    disabled={true}
                    // isMandatory = {true}
                    // style = {props.style}
                />
            </div>

        </form>
    );
};
export default VitalSignView;
