import React from 'react';

const CancelModal = ({ isShowing, hide, alertMsg, back }) => isShowing
    ? <div><div className="modal-overlay" /><div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
            <div className="modal-header">
                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="p-4 text-center">
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{alertMsg}</h3>
                <button data-modal-toggle="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={back}>
                    Yes, I&apos;m sure
                </button>
                <button data-modal-toggle="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-green-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={hide}>No, cancel</button>
            </div>
        </div>
    </div></div>
    : null;

export default CancelModal;
