/* eslint-disable no-console */
import React from 'react';
import Nav from './Nav';
import { Link, Outlet } from 'react-router-dom';
import Arrow1 from '../../assets/images/arrow1.svg';

function NurseFunctions (props) {
    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const todate = tomorrow.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const time = new Date();
    const currentTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    return (
        <div className='relative'>
            <Nav />
            <div className='bg-bgray px-5 pb-5'>
                <div className='flex justify-between py-2'>
                    <div className="flex items-center py-2">
                        <Link to="/home">
                            <span className="text-lg font-semibold px-2 py-0.5 font-'roboto' font-[400] text-[16px] leading-[15px] text-[#757575]">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base  font-roboto px-2 py-2">{props.text}</span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] text-[13px] leading-[15px] text-[#000000]">
                            Time : {currentTime}
                        </span>
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] text-[13px] leading-[15px] text-[#000000]">
                            From Date : {currentDate}
                        </span>
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] text-[13px] leading-[15px] text-[#000000]">
                            To Date : {todate}
                        </span>
                    </div>
                </div>
                <div><Outlet /></div>
            </div>
        </div>
    );
}

export default NurseFunctions;
