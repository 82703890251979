export const toObservationComponentFhir = (inputData) => {
    // array of input data [] with transformed params in format - {name: '', patient: '', encounter: '', input: {data: '', name: '' }}
    // used in secondary care
    try {
        const result = {
            resourceType: 'Observation',
            status: 'final',
            identifier: [
                {
                    system: 'PrimaryCare',
                    value: inputData[0].name
                }
            ],
            subject: {
                reference: 'Patient/' + inputData[0].patient
            },
            encounter: {
                reference: 'Encounter/' + inputData[0].encounter
            },
            issued: new Date().toISOString()
        };
        // if (inputData[0].input.quantity) {
        //     result.valueQuantity = {
        //         value: inputData[0].input.data || 0
        //     };
        // } else {
        //     result.valueString = inputData[0].input.data || '';
        // }
        result.component = [];
        for (const element of inputData) {
            result.component.push({
                code: { text: element.input.name },
                valueString: element.input.data
            });
        }

        return Promise.resolve(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const fromObservationComponentFhir = (inputData) => {
    const identifier = inputData.resource.identifier[0].value;
    const result = {
        [identifier]: {}
    };
    for (const component of inputData.resource.component) {
        const name = component.code.text || 'None';
        const value = component.valueString || 'None';
        result[identifier][name] = value;
    }
    if (inputData.resource.valueString) {
        result[identifier].data = inputData.resource.valueString;
    }
    return Promise.resolve(result);
};

export const toVitalParamsFhir = (obj) => {
    try {
        const vitalParametersInput = obj.VitalParameters;
        const vitalParameters =
    {
        resourceType: 'Observation',
        status: 'final',
        extension: [
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams',
                extension: [
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Circulation',
                        extension: [
                            {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Circulation/HeartRate',
                                valueCodeableConcept: {

                                    coding: [{
                                        code: vitalParametersInput.Circulation.HRPulse.checked || '',
                                        display: 'Circulation.HRPulse.checked'
                                    }, {
                                        code: vitalParametersInput.Circulation.HRPulse.HRPulseInput || '',
                                        display: 'Circulation.HRPulse.HRPulseInput'
                                    }
                                    ]
                                }
                            },
                            {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Circulation/PulseRhythm',
                                valueCodeableConcept: {
                                    coding: [{
                                        code: vitalParametersInput.Circulation.PulseRhythm || '',
                                        display: 'Circulation.PulseRhythm'
                                    }]
                                }
                            },
                            {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Circulation/BloodPressure',
                                valueCodeableConcept: {
                                    coding: [
                                        {
                                            code: vitalParametersInput.Circulation.SBp.SBpInput || '',
                                            display: 'Circulation.SBp.SBpInput'
                                        }, {
                                            code: vitalParametersInput.Circulation.DBp.DBpInput || '',
                                            display: 'Circulation.DBp.DBpInput'
                                        }, {
                                            code: vitalParametersInput.Circulation.DBp.Position || '',
                                            display: 'Circulation.DBp.Position'
                                        }, {
                                            code: vitalParametersInput.Circulation.DBp.checked || '',
                                            display: 'Circulation.DBp.Checked'
                                        }
                                    ]
                                }
                            },
                            {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Circulation/Temperature',
                                valueCodeableConcept: {
                                    coding: [
                                        {
                                            code: vitalParametersInput.Circulation.Temperature.Temperature || '',
                                            display: 'Circulation.Temperature.Temperature'
                                        }, {
                                            code: vitalParametersInput.Circulation.Temperature.TemperatureInput || '',
                                            display: 'Circulation.Temperature.TemperatureInput'
                                        },
                                        {
                                            code: vitalParametersInput.Circulation.Temperature.tempCelcius || '',
                                            display: 'Circulation.Temperature.tempCelsius'
                                        }, {
                                            code: vitalParametersInput.Circulation.Temperature.tempFahrenheit || '',
                                            display: 'Circulation.Temperature.tempFahrenheit'
                                        }
                                    ]
                                }
                            }, {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Circulation/CardiacReading',
                                valueCodeableConcept: {
                                    coding: [
                                        {
                                            code: vitalParametersInput.Circulation.Cardiac || '',
                                            display: 'Circulation.Cardiac'
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Airway',
                        valueCodeableConcept: {
                            coding: [
                                {
                                    code: vitalParametersInput.Airway.AirwayPatentorNot || '',
                                    display: 'Airway.AirwayPatentorNot'
                                }, {
                                    code: vitalParametersInput.Airway.AirwayInput || '',
                                    display: 'Airway.AirwayInput'
                                }
                            ]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Breathing',
                        extension: [
                            {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Breathing/RespirtaionRate',
                                valueCodeableConcept: {
                                    coding: [
                                        {
                                            code: vitalParametersInput.Breathing.rate.checked || '',
                                            display: 'Breathing.rate.checked'
                                        }, {
                                            code: vitalParametersInput.Breathing.rate.RateInput || '',
                                            display: 'Breathing.rate.RateInput'
                                        }
                                    ]
                                }
                            }, {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Breathing/RespirtaionRate/type',
                                valueCodeableConcept: {
                                    coding: [
                                        {
                                            code: vitalParametersInput.Breathing.type.TypeDropdown || '',
                                            display: 'Breathing.type.TypeDropdown'
                                        }, {
                                            code: vitalParametersInput.Breathing.type.TypeInput || '',
                                            display: 'Breathing.type.TypeInput'
                                        }
                                    ]
                                }
                            }, {
                                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Breathing/RespirtaionRate/spo2',
                                valueCodeableConcept: {
                                    coding: [
                                        {
                                            code: vitalParametersInput.Breathing.spo2.spo2Input || '',
                                            display: 'Breathing.spo2.spo2Input'
                                        }, {
                                            code: vitalParametersInput.Breathing.spo2.spo2DropDown || '',
                                            display: 'Breathing.spo2.spo2DropDown'
                                        },
                                        {
                                            code: vitalParametersInput.Breathing.spo2.Other || '',
                                            display: 'Breathing.spo2.Other'
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Pallor',
                        valueCodeableConcept: {
                            coding: [
                                {
                                    code: vitalParametersInput.Secondary.Pallor || '',
                                    display: 'Secondary.Pallor'
                                }
                            ]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Cyanosis',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.Cyanosis || '',
                                display: 'Secondary.Cyanosis'
                            }
                            ]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Icterus',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.Icterus || '',
                                display: 'Secondary.Iceterus'
                            }]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Koilonychia',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.Koilonychia || '',
                                display: 'Secondary.Koilonychia'
                            }]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Iymphadenopathy',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.Iymphadenopathy || '',
                                display: 'Secondary.Iymphadenopathy'
                            }]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Edema',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.Edema || '',
                                display: 'Secondary.Edema'
                            }]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Chest',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.Chest || '',
                                display: 'Secondary.Chest'
                            }]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/secondaryInput',
                        valueCodeableConcept: {
                            coding: [{
                                code: vitalParametersInput.Secondary.secondaryInput || '',
                                display: 'Secondary.Chest'
                            }]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Heart',
                        valueCodeableConcept: {
                            coding: [
                                {
                                    code: vitalParametersInput.Heart.HeartSounds.HeartSounds || '',
                                    display: 'Heart.HeartSounds.HeartSounds'
                                }, {
                                    code: vitalParametersInput.Heart.HeartSounds.HeartSoundsInput || '',
                                    display: 'Heart.HeartSounds.HeartSoundsInput'
                                }, {
                                    code: vitalParametersInput.Heart.AddedSound.AddedSound || '',
                                    display: 'Heart.AddedSound.AddedSound'
                                }, {
                                    code: vitalParametersInput.Heart.AddedSound.AddedSoundInput || '',
                                    display: 'Heart.AddedSound.AddedSoundInput'
                                }, {
                                    code: vitalParametersInput.Heart.Abdomen || '',
                                    display: 'Heart.Abdomen'
                                }, {
                                    code: vitalParametersInput.Heart.Bowelsound,
                                    display: 'Heart.BowelSound'
                                }
                            ]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/Pupil',
                        valueCodeableConcept: {
                            coding: [
                                {
                                    code: vitalParametersInput.Pupil.RtEye || '',
                                    display: 'Pupil.RtEye'
                                }, {
                                    code: vitalParametersInput.Pupil.LtEye || '',
                                    display: 'Pupil.LtEye'
                                },
                                {
                                    code: vitalParametersInput.Pupil.pupilInput || '',
                                    display: 'Pupil.pupilInput'
                                }
                            ]
                        }
                    },
                    {
                        url: 'http://3.109.121.35:8080/fhir/StructureDefinition/AddedParams/GCNS',
                        valueCodeableConcept: {
                            coding: [
                                {
                                    code: vitalParametersInput.Pupil1.Rt || '',
                                    display: 'Pupil1.Rt'
                                },
                                {
                                    code: vitalParametersInput.Pupil1.Lt || '',
                                    display: 'Pupil1.Lt'
                                },
                                {
                                    code: vitalParametersInput.Pupil1.RtTone || '',
                                    display: 'Pupil1.RtTone'
                                },
                                {
                                    code: vitalParametersInput.Pupil1.LtTone || '',
                                    display: 'Pupil1.LtTone'
                                }, {
                                    code: vitalParametersInput.Pupil1.RtReflexes || '',
                                    display: 'Pupil1.RtReflexes'
                                }, {
                                    code: vitalParametersInput.Pupil1.LtReflexes || '',
                                    display: 'Pupil1.LtReflexes'
                                }, {
                                    code: vitalParametersInput.Pupil1.Rreflex || '',
                                    display: 'Pupil1.Rreflex'
                                }, {
                                    code: vitalParametersInput.Pupil1.Lreflex || '',
                                    display: 'Pupil1.Lreflex'
                                }
                            ]
                        }
                    }
                ]
            }
        ],
        subject: {
            reference: `Patient/${obj.patientId}`
        },
        encounter: {
            reference: `Encounter/${obj.encounterId}`
        }
    };
        return Promise.resolve(vitalParameters);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toGeneralObservationFhir = (obj) => {
    const generalPhysicalExamination = obj.GeneralPhysicalExamination;
    const generalObservation =
    {
        resourceType: 'Observation',
        status: 'final',
        extension: [
            {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/GeneralCondition',
                valueString: generalPhysicalExamination.GeneralCondition.GeneralConditionInput || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/Temperature',
                valueString: generalPhysicalExamination.Temperature.TemperatureInput || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/LevelOfConciseness',
                extension: [
                    {
                        url: 'https://3.109.111.166:8080/fhir/StructureDefinition/EyeOpeningResponse',
                        valueString: generalPhysicalExamination.LevelOfConciseness.EyeOpeningResponse || ''
                    }, {
                        url: 'https://3.109.111.166:8080/fhir/StructureDefinition/VerbalResponse',
                        valueString: generalPhysicalExamination.LevelOfConciseness.VerbalResponse || ''
                    }, {
                        url: 'https://3.109.111.166:8080/fhir/StructureDefinition/MotorResponse',
                        valueString: generalPhysicalExamination.LevelOfConciseness.MotorResponse || ''
                    }
                ]
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/Coherence',
                valueString: generalPhysicalExamination.Coherence.CoherenceInput || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/Attitude',
                valueString: generalPhysicalExamination.Attitude.Attitude || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/AttitudeInput',
                valueString: generalPhysicalExamination.Attitude.AttitudeInput || ''
            },
            {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/Orientation',
                valueString: generalPhysicalExamination.Orientation.OrientationInput || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/Disorientation',
                valueString: generalPhysicalExamination.Disorientation.DisorientationInput || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/ThyroidNA',
                valueString: generalPhysicalExamination.Thyroid.ThyroidNA || ''
            },
            {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/ThyroidInput',
                valueString: generalPhysicalExamination.Thyroid.ThyroidInput || ''
            }, {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/BreastInput',
                valueString: generalPhysicalExamination.Breast.BreastInput || ''
            },
            {
                url: 'https://3.109.111.166:8080/fhir/StructureDefinition/BreastNA',
                valueString: generalPhysicalExamination.Breast.BreastNA || ''
            }
        ],
        subject: {
            reference: `Patient/${obj.patientId}`
        },
        encounter: {
            reference: `Encounter/${obj.encounterId}`
        }
    };
    return Promise.resolve(generalObservation);
};

export const toBPObservationFhir = (inputData) => {
    // array of input data [] with transformed params in format - {name: '', patient: '', encounter: '', input: {data: '', name: '' }}
    // used in secondary care
    try {
        const result = {
            resourceType: 'Observation',
            status: 'final',
            identifier: [
                {
                    system: 'PrimaryCare',
                    value: inputData.name
                }
            ],
            subject: {
                reference: 'Patient/' + inputData.patient
            },
            encounter: {
                reference: 'Encounter/' + inputData.encounter
            },
            issued: new Date().toISOString()
        };
        result.component = [];
        for (const element of inputData.input) {
            result.component.push({
                code: { text: element.name },
                valueString: element.data
            });
        }

        return Promise.resolve(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
