import React from 'react';
function TwoField (props) {
    return (
        <div className='px-[1%] w-[100%] flex py-2.5'>
            <div className='w-[50%] flex justify-between pr-[2%]'>
                <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                    {props.field1} :
                </div>
                <div className='w-[76%]'>
                    <input
                        className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                        type='text'
                        placeholder={props.place1}
                        value={ props.val1 }
                        name={props.name1}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                    />
                </div>
            </div>
            <div className='w-[50%] flex justify-between pl-[2%]'>
                <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                    {props.field2} :
                </div>
                <div className='w-[76%]'>
                    <input
                        className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                        type='text'
                        placeholder={props.place2}
                        value={ props.val2 }
                        name={props.name2}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                    />
                </div>
            </div>
        </div>
    );
}

export default TwoField;
