import axios from '../utils/axios';

export const getMedicationAdministration = (params) => {
    let url = '/prod/fhir/MedicationAdministration';
    if (params) {
        url += `?${params}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};
