import React from 'react';
import score from '../../utils/json/PhlebitisScore.json';

function PhlebitisScore (props) {
    const change = (e) => {
        props.formikState.setFieldValue('phlebitisScore.phlebitisScore', e.target.value);
    };
    return (
        <div>
            <div className='h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            Phlebitis Score
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Phlebitis Score <span className='text-[crimson]'>*</span></div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="phlebitisScore"
                                id="phlebitisScore"
                                onChange={e => change(e)}
                                disabled={props.isDisabled}
                                value={props.formikState.values.phlebitisScore.phlebitisScore}
                            >
                                <option value='Select' key=''>Select</option>
                                {
                                    score.map((data, i) => (
                                        <option value={data.value} key={i}>{data.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <label className='font-"roboto" ml-[160px] font-[500] text-[14px] leading-[15px] text-[#757575]'><span className='italic'>Result :</span> {(props.formikState.values.phlebitisScore.phlebitisScore)}</label>
            </div>
        </div>
    );
}

export default PhlebitisScore;
