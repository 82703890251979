/* eslint-disable no-console */
import React from 'react';
import MultiInputForm from '../../components/NurseContain/MulInputForm';

const IntakeOutput = ({ isDisabled, ...props }) => {
    const InputFields = [
        {
            id: 'intraFluids',
            label: 'Intravenous Fluids',
            type: 'text'
        },
        {
            id: 'ivAntibiotics',
            label: 'IV Antibiotics',
            type: 'number',
            unit: 'ml'
        },
        {
            id: 'oralFluid',
            label: 'Oral Fluids/Liquid Diet in mL.',
            type: 'number',
            unit: 'ml'
        },
        {
            id: 'urineOuput',
            label: 'Urine Output',
            type: 'number',
            unit: 'ml'
        },
        {
            id: 'gastricAspiration',
            label: 'Gastric Aspiration (if present)',
            type: 'number',
            unit: 'ml'
        },
        {
            id: 'stoolPassed',
            label: 'Stool Passed/Not',
            type: 'text'
        },
        {
            id: 'balance',
            label: 'Balance',
            type: 'text'
        },
        {
            id: 'remark',
            label: 'Remarks',
            type: 'text'
        }
    ];
    return (
        <>
            < MultiInputForm sectionTitle={'Intake Output'} dateCount={1} formik={props.formikState} fields={InputFields} formikArrayTitle={'IntakeOutput'} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
        </>
    );
};

export default IntakeOutput;
