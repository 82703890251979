import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import '../../assets/css/Style.css';
import Modal from '../../components/Modal';
import BedForm from '../../forms/Bed';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';
import View from '../../assets/images/view.svg';
import { patientCustomStyles } from '../../style';
import { convertToFhir } from '../../fhir-evolver';
import { addLocation, editLocation } from '../../api/location';

const Bed = () => {
    const [selectedObject, setSelectedObject] = useState({ id: '', name: '' });
    const [showAddModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleCloseAddModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        setShowEditModal(showAddModal);
        setSelectedObject({
            id: '',
            name: ''
        });
    }, [showAddModal]);

    const editHandler = (row) => {
        setSelectedObject(row);
        setShowEditModal(true);
    };

    const viewHandler = (row) => {
        setSelectedObject(row);
        setShowEditModal(true);
    };
    const handleCloseModal = () => {
        if (showAddModal) {
            handleCloseAddModal();
        } else {
            setShowEditModal(false);
        }
    };

    const columns = [
        {
            name: 'Floor Name',
            selector: (row) => row.floorName,
            sortable: true
        },
        {
            name: 'Ward Name',
            selector: (row) => row.wardName,
            sortable: true
        },
        {
            name: 'Room Number',
            selector: (row) => row.roomNumber,
            sortable: true
        },
        {
            name: 'Bed Number',
            selector: (row) => row.bedNumber,
            sortable: true
        },
        {
            name: 'Actions',
            selector: (row) => (
                <div>

                    <button className="px-1">
                        <img className="h-3 w-4" onClick={() => viewHandler(row)} src={View} alt="view"/>
                    </button>
                    <button className="px-1">
                        <img className="h-3 w-4" onClick={() => editHandler(row)} src={Edit} alt="edit"/>
                    </button>

                    <button >
                        <img className="h-3 w-4" onClick={() => deleteHandler(row.id)} src={Delete} alt="delete" />
                    </button>
                </div>
            )
        }
    ];

    const rowData = [
        {
            id: 1,
            floorName: 'Floor 1',
            wardName: 'ICU',
            roomNumber: '101',
            bedNumber: 1

        },
        {
            id: 2,
            floorName: 'Floor 2',
            wardName: 'ICU',
            roomNumber: '201',
            bedNumber: 1
        }
    ];

    const [values, setValues] = useState(rowData);

    const onAdd = (obj) => {
        // data.id = values.length + 1;
        // setValues([...values, data]);
        // setShowModal(false);
        convertToFhir('Location', obj).then(res => {
            // eslint-disable-next-line no-console
            console.log('resward', res);
            addLocation(res).then(res => {
                // eslint-disable-next-line no-console
                console.log('ward', res);
                setValues(res.data.entry);
                setShowModal(false);
                // obj.id = values.length + 1;
                // setValues([...values, obj]);
            });
        });
    };

    // const onEdit = (data) => {
    //     data.id = selectedObject.id;
    //     setValues(values.map(item => (item.id === data.id ? data : item)));
    //     setShowEditModal(false);
    // };

    const onEdit = (obj) => {
        convertToFhir('Location', obj).then(res => {
            // eslint-disable-next-line no-console
            console.log('ev', res);
            res.id = selectedObject.resource.id;
            editLocation(selectedObject.resource.id, res)
                .then((response) => {
                    // eslint-disable-next-line no-console
                    console.log('api', response);
                    setShowEditModal(false);
                })
                .catch(e => {
                    return e;
                });
        });
    };

    const deleteHandler = id => {
        setValues(values.filter(value => value.id !== id));
    };

    return (
        <div>
            <div className="bg-background h-full w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center px-4 py-2 gap-x-2">
                        <Link to={'/home'}>
                            <span className="text-base font-normal text-col">
                  Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow"/>
                        <span className="text-black font-normal text-base  font-roboto">Bed</span>
                    </div>
                    <div className="flex flex-col p-2">
                        <button className="w-72 p-2 h-11 mt-4 bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base text-extrabold text-white" onClick={() => setShowModal(true)}>+ Add Bed</button>
                        {showEditModal && <Modal
                            handleModalClose={handleCloseModal}
                            heading="Bed"
                            body={<BedForm
                                handleModalClose={handleCloseModal}
                                data={selectedObject}
                                onAdd={onAdd}
                                onEdit={onEdit}
                                isEdit={!showAddModal}
                            />}
                        />}
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <span className="text-3xl font-bold px-4 py-2 text-black">
              Bed
                    </span>
                    <div className="flex flex-col p-2">
                        <div className="pt-2 relative mx-auto text-col">
                            <input
                                className="shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none"
                                type="search"
                                name="search"
                                placeholder="Search"
                            />
                            <button type="submit" className="absolute right-0 top-0 mt-6 mr-4">
                                <img src={Search} alt="search"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                    <Table rows={values} columns={columns} customStyles={patientCustomStyles}/>
                </div>
            </div>
        </div>
    );
};

export default Bed;
