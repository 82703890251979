import React, { useState } from 'react';

const Followup = (props) => {
    const [val, setVal] = useState([]);
    const handleAdd = (i) => {
        const abc = [...val, {}];
        setVal(abc);
        props.formikState.values.Follow.splice(i + 1, 0, {
            Date: '',
            Test: '',
            Name: '',
            Unit: '',
            Instruction: '',
            Consultation: ''
        });
    };
    return (
        <div>
            <div className='h-[45px] bg-iat flex w-full items-center px-[1%] font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
        Follow Up
            </div>
            {props.formikState.values.Follow.map((data, i) => {
                return (
                    <div className='flex px-3 pt-[20px] pb-2 bg-white gap-x-24' key={i}>
                        <div className='text-col font-roboto text-sm'>Follow Up :</div>

                        <div className='w-full flex flex-wrap gap-y-2 gap-x-4' >
                            <input
                                className='h-[47px] text-[#8F8F8F] w-[24.1%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                name={`Follow[${i}].Date`}
                                type='datetime-local'
                                placeholder='Date & Time'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Follow[i].Date}
                                disabled={props.disabled}
                            />
                            <input
                                className='h-[47px] text-[#8F8F8F] w-[24.1%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                name={`Follow[${i}].Date`}
                                type='text'
                                placeholder='Follow up tests'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Follow[i].Test}
                                disabled={props.disabled}
                            />
                            <input
                                className='h-[47px] text-[#8F8F8F] w-[24.1%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                name={`Follow[${i}].Date`}
                                type='text'
                                placeholder='Doctor Name'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Follow[i].Name}
                                disabled={props.disabled}
                            />
                            <input
                                className='h-[47px] text-[#8F8F8F] w-[24.3%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                name={`Follow[${i}].Date`}
                                type='text'
                                placeholder='Follow up unit'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Follow[i].Unit}
                                disabled={props.disabled}
                            />
                            <textarea
                                className='h-[93px] text-[#8F8F8F] w-[100%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                name={`Follow[${i}].Date`}
                                type='text'
                                placeholder='Write F/U Instructions'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Follow[i].Instruction}
                                disabled={props.disabled}
                            />
                            <input
                                className='h-[47px] text-[#8F8F8F] w-[88.5%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                                name={`Follow[${i}].Date`}
                                type='text'
                                placeholder='Before/After consultation'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Follow[i].Consultation}
                                disabled={props.disabled}
                            />
                            <button className={'border border-iat w-[149px] h-10 bg-iat text-white'} type='button' onClick={() => handleAdd(i)}>+ Add</button>
                        </div>

                    </div>
                );
            })}
            <div className='flex px-3 bg-white gap-x-7 pb-2'>
                <div className='text-col font-roboto text-sm'>Credited/ Prepared by :</div>
                <div className='w-full flex'>
                    <input
                        className='h-[47px] text-[#8F8F8F] w-[100%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                        id='Creditedby'
                        name='Creditedby'
                        type='text'
                        placeholder='Doctor Name/ Who prepared'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Creditedby}
                        disabled={props.disabled}
                    />
                </div>
            </div>
            <div className='flex px-3 bg-white gap-x-[68px] pb-2.5'>
                <div className='text-col font-roboto text-sm'>Share a copy to :</div>
                <div className='w-full flex'>
                    <input
                        className='h-[47px] text-[#8F8F8F] w-[100%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                        id='Copyto'
                        name='Copyto'
                        type='text'
                        placeholder='I/C'
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.Copyto}
                        disabled={props.disabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default Followup;
