/* eslint-disable no-console */
import React from 'react';
import NurseContainer from '../../components/NurseContain/NurseContainer';
import Data from '../../utils/json/Functionfields.json';
import Temperature from '../../components/NurseContain/Temperature';
import Radio from '../../components/NurseContain/Radio';
import IVaccess from '../../components/NurseContain/IVaccess';
import BloodPressure from '../../components/NurseContain/BloodPressure';

function HemoDynamicParams ({ onChange, isDisabled, ...props }) {
    return (
        <div>
            <NurseContainer data={Data.HeartRate} unit={'/ min'} formikState={props.formikState} val={props.formikState.values.HeartRate} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <BloodPressure title={[Data.SystolicBP, Data.DiastolicBP]} unit={'mm of Hg'} formikState={props.formikState} val={props.formikState.values.BP} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <IVaccess data={Data.IVaccess} formikState={props.formikState} val={props.formikState.values.IVaccess} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <div>
                { props.isDoctor
                    ? <div className='h-[45px] items-center bg-iat flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                        {Data.RespiratoryRate.title}
                    </div>
                    : <div className='h-[45px] items-center bg-tgray flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                        {Data.RespiratoryRate.title}
                    </div>}
                <div className='flex bg-white px-[16px] py-[10px] w-full'>
                    <div className='flex items-center w-1/3'>
                        <div className='w-[246px] justify-end flex items-center'>
                            <input
                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                type='number'
                                id={'RespiratoryRate'}
                                name={'RespiratoryRate'}
                                onChange={props.formikState.handleChange}
                                defaultValue={props.formikState.values.RespiratoryRate}
                                disabled={isDisabled}
                            />
                            <div className='absolute text-right mr-[15px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Per min</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                { props.isDoctor
                    ? <div className='h-[45px] items-center bg-iat flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                        {Data.PeripheralPulse.title}
                    </div>
                    : <div className='h-[45px] items-center bg-tgray flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                        {Data.PeripheralPulse.title}
                    </div> }
                <div className='bg-white py-[15px]'>
                    <Radio formikState={props.formikState} title={'Common Carotid Artery :'} name='PeripheralPulse.CCA' value={props.formikState.values.PeripheralPulse.CCA} checked={props.formikState.values.PeripheralPulse.CCA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Radial Artery :'} name='PeripheralPulse.RA' value={props.formikState.values.PeripheralPulse.RA} checked={props.formikState.values.PeripheralPulse.RA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Brachial Artery :'} name='PeripheralPulse.BA' value={props.formikState.values.PeripheralPulse.BA} checked={props.formikState.values.PeripheralPulse.BA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Dorsalis Pedis Artery :'} name='PeripheralPulse.DPA' value={props.formikState.values.PeripheralPulse.DPA} checked={props.formikState.values.PeripheralPulse.DPA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Posterior Tibial Artery :'} name='PeripheralPulse.PTA' value={props.formikState.values.PeripheralPulse.PTA} checked={props.formikState.values.PeripheralPulse.PTA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Femoral Artery :'} name='PeripheralPulse.FA' value={props.formikState.values.PeripheralPulse.FA} checked={props.formikState.values.PeripheralPulse.FA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Popliteal Artery :'} name='PeripheralPulse.PA' value={props.formikState.values.PeripheralPulse.PA} checked={props.formikState.values.PeripheralPulse.PA === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Radio Femoral Delay :'} name='PeripheralPulse.RFD' value={props.formikState.values.PeripheralPulse.RFD} checked={props.formikState.values.PeripheralPulse.RFD === 'Present'} isDisabled={isDisabled}/>
                    <Radio formikState={props.formikState} title={'Radio Radial Delay :'} name='PeripheralPulse.RRD' value={props.formikState.values.PeripheralPulse.RRD} checked={props.formikState.values.PeripheralPulse.RRD === 'Present'} isDisabled={isDisabled}/>
                    <div className='bg-white px-3 py-4 flex items-center'>
                        <div className='w-[240px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Remarks if any:</div>
                        <input
                            className='h-[38px] text-input border-[1px] px-2 border-[#E3E3E3] bg-gray w-full outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                            type='text'
                            id={'PeripheralPulse.Remarks'}
                            name={'PeripheralPulse.Remarks'}
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.PeripheralPulse.Remarks}
                            placeholder='Write Here'
                            disabled = {isDisabled}
                        />
                    </div>
                </div>
            </div>
            <NurseContainer data={Data.Spo2} unit={'%'} formikState={props.formikState} val={props.formikState.values.Spo2} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.RBS} formikState={props.formikState} val={props.formikState.values.RBS} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <NurseContainer data={Data.Insulin} formikState={props.formikState} val={props.formikState.values.Insulin} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <Temperature data={Data.Temperature} formikState={props.formikState} val={props.formikState.values.Temperature} isDoctor={props.isDoctor} isDisabled={isDisabled}/>
            <div>
                {props.isDoctor
                    ? <div className='h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    Remarks if any:
                    </div>
                    : <div className='h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    Remarks if any:
                    </div>}
                <div className='bg-white px-3 py-4 flex items-center'>
                    <input
                        className='h-[38px] text-input border-[1px] border-[#E3E3E3] bg-gray w-full outline-none font-"roboto" font-[500] px-2 text-[14px] leading-[15px] text-[#757575]'
                        type='text'
                        id={'HemoDynamicRemarks[0].data'}
                        name={'HemoDynamicRemarks[0].data'}
                        onChange={props.formikState.handleChange}
                        value={props.formikState.values.HemoDynamicRemarks[0].data}
                        placeholder='Write Here'
                        disabled = {isDisabled}
                    />
                </div>
            </div>
        </div>
    );
}

export default HemoDynamicParams;
