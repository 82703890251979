import React from 'react';
import educ from '../.././../utils/json/EducationHistory.json';

function Edusoc (props) {
    const change = (r) => {
        if ((r) <= 29 && (r) >= 26) {
            return ('Upper Class' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 16 && (r) <= 25) {
            return ('Upper Middle' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 11 && (r) <= 15) {
            return ('Lower Middle' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 5 && (r) <= 10) {
            return ('Upper Lower' + ' ' + '(' + `${r}` + ')');
        } else if ((r) < 5) {
            return ('Lower' + ' ' + '(' + `${r}` + ')');
        }
    };
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
            Educational & Social History<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Educational &<br/>Social History :<span className='text-[crimson]'>*</span></div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="EducationalSocialHistory.Education"
                                id="EducationalSocialHistory.Education"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.EducationalSocialHistory.Education}
                            >
                                {
                                    educ.education.map((data, i) => (
                                        <option value={educ.education[i].value} key={i}>{educ.education[i].label}</option>
                                    ))
                                }
                            </select>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="EducationalSocialHistory.Occupation"
                                id="EducationalSocialHistory.Occupation"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.EducationalSocialHistory.Occupation}
                            >
                                {
                                    educ.occupation.map((data, i) => (
                                        <option value={educ.occupation[i].value} key={i}>{educ.occupation[i].label}</option>
                                    ))
                                }
                            </select>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="EducationalSocialHistory.TotalPerCapital"
                                id="EducationalSocialHistory.TotalPerCapital"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.EducationalSocialHistory.TotalPerCapital}
                            >
                                {
                                    educ.capita.map((data, i) => (
                                        <option value={educ.capita[i].value} key={i}>{educ.capita[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <label className='font-"roboto" ml-[160px] font-[500] text-[14px] leading-[15px] text-[#757575]'><span className='italic'>Result</span> : {change(Number(props.formikState.values.EducationalSocialHistory.Education) + Number(props.formikState.values.EducationalSocialHistory.Occupation) + Number(props.formikState.values.EducationalSocialHistory.TotalPerCapital))}</label>
            </div>
        </div>
    );
}

export default Edusoc;
