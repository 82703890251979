import React from 'react';
import Cons from '../.././../utils/json/Consents.json';

function Consents (props) {
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>
            Consents Forms<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Consents Forms :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-full h-[47px] text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                name="Consents"
                                id="Consents"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Consents}
                                disabled={props.disabled}
                            >
                                <option value="">Select Here</option>
                                {
                                    Cons.map((data, i) => (
                                        <option value={Cons[i].value} key={i}>{Cons[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Consents;
