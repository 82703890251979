import axios from '../utils/axios';

export const getLocation = (id) => {
    return axios.get(`/prod/fhir/Location?partof=Location/${id}`).then((response) => {
        return response;
    });
};

export const addLocation = (body) => {
    return axios.post('/prod/fhir/Location', JSON.stringify(body)).then((response) => {
        return response;
    });
};

export const editLocation = (id, body) => {
    return axios.put(`/prod/fhir/Location/${id}`, body).then((response) => {
        return response;
    });
};
