import React from 'react';
import Title from '../Title';
import status from '../.././../utils/json/PsychologicalStatus.json';

function Psycstatus (props) {
    return (
        <div>
            <Title title={'Psychological Status'} isNurse = {props.isNurse}/>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Psychological Status :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PsychologicalStatus.status"
                                id="PsychologicalStatus.status"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PsychologicalStatus.status}
                                disabled={props.disabled}
                            >
                                {
                                    status.map((data, i) => (
                                        <option value={status[i].value} key={i}>{status[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Psycstatus;
