import React from 'react';
import DataTable from 'react-data-table-component';
import { tableHeadingPatientStyle } from '../style';

const Table = ({ tableHeading, ...props }) => {
    return (
        <>
            {tableHeading ? <p className='py-3 px-4 my-3' style={tableHeadingPatientStyle.style}>{tableHeading}</p> : <></>}
            <DataTable
                columns={props.columns}
                data={props.rows}
                customStyles={props.customStyles}
                pagination
                {...props} />
        </>
    );
};

export default Table;
