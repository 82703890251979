import { locationMap } from '../../utils/common.js';
// import { config } from '../../../utils/config.js';

export const toLocationFhir = (obj) => {
    return Promise.resolve({
        resourceType: 'Location',
        meta: {
            profile: [
                'http://3.109.121.35:8080/fhir/StructureDefinition/Location'
            ]
        },
        managingOrganization: {
            type: 'Organization',
            reference: `Organization/${3}`
        },
        name: obj.name,
        physicalType: {
            coding: [
                {
                    code: obj.code,
                    display: obj.name
                }
            ]
        },
        partOf: {
            reference: `Location/${obj.id}`,
            type: obj.type
        }

    });
};

export const fromFhir = (data) => {
    try {
        const location = {};
        const resource = data.resource;
        location.name = resource.name;
        location.id = resource.id;
        location.organization = resource.managingOrganization.reference.split('/')[1];
        const coding = resource.physicalType.coding[0];
        location.type = locationMap[coding.code];
        location.display = coding.display;
        return Promise.resolve(location);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
