import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

import { login } from '../../api/auth';
// import Logo from '../../assets/images/logo.svg';
import clustrex from '../../assets/images/cluss.jpg';
import LoginForm from '../../forms/LoginForm';
import Loader from '../../components/Loader';
import { setUserCredsToLocal } from '../../utils/helper';
import { ROLE } from '../../utils/roles';

const Login = () => {
    const [hasError, setError] = useState(false);
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname === '/' ? auth?.user?.role ? ROLE?.[auth?.user?.role]?.rootPath : '/' : location.state?.from?.pathname || '/';
    const [spinner, setSpinner] = useState(false);

    const submitHandler = (data) => {
        setSpinner(true);
        login(data)
            .then((res) => {
                setAuth(res);
                return setUserCredsToLocal(res)
                    .then(() => {
                        setSpinner(false);
                        navigate(ROLE[res.user.role].rootPath, { replace: true });
                    });
            })
            .catch(e => {
                setError(true);
                setSpinner(false);
            });
    };
    return (
        <>
            {auth?.user
                ? <Navigate exact to={from} />
                : <div>
                    <Loader showSpinner={spinner} />
                    <div className="bg-backgroundImage w-full h-screen flex justify-center items-center  bg-cover bg-no-repeat bg-center" >
                        <div className="box-border box-shadow bg-white pt-2 px-10 pb-10">
                            <div className="flex justify-center">
                                <img src={clustrex} alt="logo" className='px-20'/>
                            </div>
                            <LoginForm submitHandler={submitHandler} hasError={hasError} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Login;
