import axios from '../utils/axios';

export const addClinicalImpression = (body) => {
    return axios.post('/prod/fhir/ClinicalImpression', JSON.stringify(body)).then((response) => {
        return 'Questionniare Response created successfully';
    });
};

export const getClinicalImpression = (params) => {
    let url = '/prod/fhir/ClinicalImpression';
    if (params) {
        url += `${params}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};

export const editClinicalImpression = (id, body) => {
    return axios.put(`/prod/fhir/ClinicalImpression/${id}`, body).then((response) => {
        return response;
    });
};

export const deleteClinicalImpression = (id) => {
    return axios.delete(`/prod/fhir/ClinicalImpression/${id}`).then((response) => {
        return response;
    });
};

export const getClinicalImpressionById = (id) => {
    return axios.get(`/prod/fhir/ClinicalImpression?_include=*&_id=${id}`).then((response) => {
        return response;
    });
};

export const getClinicalImpressionByEncounter = (id) => {
    return axios.get(`/prod/fhir/ClinicalImpression?encounter=${id}`).then((response) => {
        return response;
    });
};
