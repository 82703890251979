import React from 'react';

const InstructionByDoctor = (props) => {
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Instruction Ordered by Doctor</span>
            </div>
            <div className='flex flex-col flex-wrap w-full px-6'>
                <table className="table-auto border border-slate-300 w-full">
                    <thead>
                        <tr>
                            <th className='border border-slate-300 text-left p-2'>Date</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>Time</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>Order by Doctors</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </>
    );
};

export default InstructionByDoctor;
