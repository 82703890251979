/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { getDataWithFilter, getDataWithOutFilter } from '../../../api/initialAssessment';
import DropdownWithSearch from '../../../components/Dropdown-withsearch';

function WPDiagnosis (props) {
    const [dropDownValue, setdropDownValue] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        (async () => {
            const result = await getDataWithOutFilter(currentPage);
            setdropDownValue(result.results);
        })();
    }, []);

    const getDataByFilterHandle = async (value) => {
        if (
            value !== null &&
            value !== '' &&
            value !== undefined &&
            value.split('').length > 2
        ) {
            const data = await getDataWithFilter('text', value, currentPage);
            if (data.results !== []) {
                currentPage > 1
                    ? setdropDownValue([...dropDownValue, ...data.results])
                    : setdropDownValue(data.results);
            }
        } else {
            const data = await getDataWithOutFilter(currentPage);
            if (data.results !== []) {
                currentPage > 1
                    ? setdropDownValue([...dropDownValue, ...data.results])
                    : setdropDownValue(data.results);
            }
        }
    };

    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>
                Working/Provisional Diagnosis
                <span className="text-[crimson] text-[20px]">
                    <pre>*</pre>
                </span>
            </div>
            <div className="bg-white px-3 py-[20px]">
                <div className="flex items-center">
                    <div className='w-[160px] self-start pt-[10px] font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>
                        Working/Provisional
                        <br />
                        Diagnosis :
                    </div>
                    <div className="w-[calc(100%-160px)]">
                        <DropdownWithSearch
                            searchInput={(value) => {
                                setCurrentPage(1);
                                getDataByFilterHandle(value);
                            }}
                            InputclassName='pl-[10px] text-[#8F8F8F] w-[100%] text-[15px] self-center text-input bg-gray outline-none'
                            id='WorkingProvisionalDiagnosis'
                            name='WorkingProvisionalDiagnosis'
                            values={dropDownValue}
                            defaultValue={props.formikState.values.WorkingProvisionalDiagnosis}
                            placeholder={'Write a reason'}
                            changeCurrentPage={(value) => {
                                setCurrentPage(Number(currentPage) + 1);
                                getDataByFilterHandle(value);
                            }}
                            onChange={(e) => { console.log(e); props.formikState.values.WorkingProvisionalDiagnosis = e; }}
                            disabled={props.disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WPDiagnosis;
