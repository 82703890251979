export const ageCalculation = (dob) => {
    const today = new Date();
    const bday = new Date(dob);
    const diff = today - bday;
    const daysOld = Math.floor(diff / (1000 * 60 * 60 * 24));
    const yearsOld = Number((daysOld / 365).toFixed(0));
    return yearsOld;
};

export const stringReplace = (data) => {
    const newStr = data?.substr(0, 1);
    const convert = newStr?.toUpperCase();
    return convert;
};

export const handleUncontrolledChange = (stateVarArr, stateVarFunc, event) => {
    // e.target.name of element should be in format fieldName.index.key ex: Temp.1.data
    const params = event.target.name.split('.');
    // eslint-disable-next-line no-console
    console.log(params);
    if (params.includes('SystolicBP') || params.includes('DiastolicBP')) {
        // params[1] = index in array ; params[0] = SystBP || DiastBP; params[2] = data | date | time
        stateVarArr[params[1]][params[0]][0][params[2]] = event.target.value;
        stateVarFunc([...stateVarArr]);
    } else {
        stateVarArr[params[1]][params[2]] = event.target.value;
        stateVarFunc([...stateVarArr]);
    }
};

export const zeroAdder = (integer) => {
    try {
        if (integer.toString().length === 1) {
            return ('0' + integer.toString());
        } else {
            return integer;
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const valueCheck = (defaultInput, inputParam) => {
    if (inputParam) {
        return inputParam;
    } else {
        return defaultInput;
    }
};
