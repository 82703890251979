/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState, React, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/Style.css';
import { editMedicationRequest } from '../../api/medicationRequest';
import { addMedicationDispense } from '../../api/medicationDispense';
import { convertToFhir } from '../../fhir-evolver';
import { stringReplace } from '../../utils/common';
import Loader from '../../components/Loader';

const PharmacyMedicationList = (props) => {
    // const prevdata = JSON.parse(JSON.stringify(props.data));
    const [medicationData, setMedicationData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setSpinner(true);
        setMedicationData(props.data.medicationRequest);
        setSpinner(false);
    }, [props]);

    const handleChange = (name, e, index) => {
        medicationData[index][name] = e.target.value;
    };

    const quantityPending = (name, e, index) => {
        if (e.target.value > medicationData[index].QuantityPending) {
            // medicationData[index][name] = e.target.value;
            // medicationData[index].QuantityPending = medicationData[index].QuantityPending - e.target.value;
            // document.getElementById('quantityPending' + index).value = medicationData[index].QuantityPending;
            document.getElementById('quantityGiven' + index).value = medicationData[index][name];
            document.getElementById('error-msg' + index).innerHTML = 'greater than Quantity';
        } else {
            medicationData[index][name] = e.target.value;
            // medicationData[index].QuantityPending = medicationData[index].QuantityPending - e.target.value;
            if (e.target.value < medicationData[index].QuantityPending) document.getElementById('error-msg' + index).innerHTML = 'lesser than Quantity';
            else document.getElementById('error-msg' + index).innerHTML = '';
        }
    };

    const onSubmit = () => {
        const filteredData = medicationData.filter((item) => item.quantityGiven && item.quantityGiven > 0);
        console.log('filter', filteredData);
        if (filteredData.length > 0) {
            for (const data of filteredData) {
                data.QuantityPending = data.QuantityPending - data.quantityGiven;
                convertToFhir('MedicationRequest', data).then(res => {
                    res.id = data.id;
                    editMedicationRequest(data.id, res)
                        .then((response) => {
                        })
                        .catch(e => {
                            return e;
                        });
                });
                data.medicationRequest = data.id;
                data.status = 'completed';
                convertToFhir('MedicationDispense', data).then(res => {
                    addMedicationDispense(res)
                        .then((response) => {
                        // eslint-disable-next-line no-console
                            console.log('dispense', response);
                            navigate('/pharmacy');
                        })
                        .catch(e => {
                            return e;
                        });
                });
            };
        } else {
            navigate('/pharmacy');
        }
    };
    return (
        <div>
            <Loader showSpinner={spinner}></Loader>
            <div className="h-screen">
                <div className="bg-background h-full w-full">
                    <div className='bg-white p-6'>
                        <div className='bg-[#EAC05D] to [#000000] p-3 flex flex-row items-center justify-between'>
                            <span className='text-white text-base font-roboto '>Prescribed Medicine</span>
                            <div className='flex flex-row gap-x-6'>
                                <span className='text-sm text-white'> {props.data.patient?.firstName?.[0]?.toUpperCase() + ' ' + props.data?.patient?.surname?.toUpperCase()} | {props.data.patient?.gender ? stringReplace(props.data?.patient?.gender) : ''} | {props.data?.patient?.uhid} | {props.data?.inPatientId}</span>
                                <span className='text-sm text-white'>{props.data.location?.[1]?.name?.toUpperCase()} | {props.data?.location?.[2]?.name?.toUpperCase()} | {props.data?.location?.[3]?.name?.toUpperCase()} | {props.data?.location?.[4]?.name?.toUpperCase()}</span>
                            </div>
                        </div>
                        <table className="table-auto border border-slate-300 mt-2 w-full">
                            <thead>
                                <tr>
                                    <th className='border border-slate-300 text-left p-2'>#</th>
                                    <th className='border border-slate-300 text-left p-2'>Doctor</th>
                                    <th className='border border-slate-300 text-left p-2'>Form</th>
                                    <th className='border border-slate-300 text-left p-2'>Drug Name</th>
                                    <th className='border border-slate-300 text-left p-2'>Dosage</th>
                                    <th className='border border-slate-300 text-left p-2'>Regimen</th>
                                    <th className='border border-slate-300 text-left p-2'>Days</th>
                                    <th className='border border-slate-300 text-left p-2'>Quantity</th>
                                    <th className='border border-slate-300 text-left p-2'>Quantity Given</th>
                                    {props.type === 'edit' ? <th className='border border-slate-300 text-left p-2'>Dispense </th> : null}
                                    <th className='border border-slate-300 text-left p-2'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {medicationData?.map((value, index) => (
                                    <tr key={index} className='border border-slate-300'>
                                        <td className='text-sm p-2 border border-slate-300 text-col counterCell'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{props.data?.participant?.[0]?.givenName}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{value?.Route}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{value?.Med}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{value?.dosageRange}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{value?.Reg}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{value?.Days}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>{value?.Quantity}</td>
                                        <td className='text-sm p-2 border border-slate-300 text-col' >
                                            <input type="text" id={`quantityPending${index}`} name="quantityPending" defaultValue={value?.Quantity - value?.QuantityPending}
                                                disabled /><br/></td>
                                        {props.type === 'edit'
                                            ? <td className='text-sm p-2 border border-slate-300 text-col'>
                                                <input className='border' type="text" id={`quantityGiven${index}`} name="quantityGiven" onKeyUp={(event) =>
                                                    quantityPending('quantityGiven', event, index)
                                                } disabled={props.disable} /><br/><span className='text-[#FF0000] text-xs' id={`error-msg${index}`}></span></td>
                                            : null}
                                        <td className='text-sm p-2 border border-slate-300 text-col'>
                                            <select onChange={(event) =>
                                                handleChange('status', event, index)
                                            } className={value.status === 'active' ? '!text-[#FF0000]' : value.status === 'on-hold' ? '!text-[#F17401]' : '!text-[#429C0A]'} defaultValue={value.status} disabled={props.disable}>
                                                <option className='!text-[#FF0000]' value='active'>Yet to be Delivered</option>
                                                <option className='text-[#F17401]' value='on-hold'>Partial Delivered</option>
                                                <option className='text-[#429C0A]' value='completed'>Delivered</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='w-full text-center'>
                            {props.type === 'view'
                                ? <Link to='/pharmacy'>
                                    <button type='button' className='w-[120px] h-[38px] bg-[#EAC05D] to [#000000] mt-2 mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' >Back</button>
                                </Link>
                                : <button type='button' className='w-[120px] h-[38px] bg-[#EAC05D] to [#000000] mt-2 mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' onClick={onSubmit}>Submit</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PharmacyMedicationList;
