import React, { useState } from 'react';
import { patientAdd } from '../../api/patient';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'react-simple-snackbar';
import { convertToFhir, convertFromFhir } from '../../fhir-evolver/index';
import Loader from '../../components/Loader';
import PatientRegistration from '../../forms/Patient';
import Arrow1 from '../../assets/images/arrow1.svg';

const PatientRegister = (props) => {
    const [spinner, setSpinner] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const isAdmission = location.state ? location.state.isAdmission : false;
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const [open] = useSnackbar(options);

    const onAdd = (obj) => {
        convertToFhir('Patient', obj).then(res => {
            patientAdd(res).then(res => {
                setSpinner(true);
                open('Patient created successfully');
                isAdmission ? getPatient(res) : navigate('/patient');
            })
                .catch(e => {
                    return e;
                });
        });
    };

    const getPatient = (data) => {
        setSpinner(true);
        convertFromFhir('Patient', data.data).then(res => {
            setSpinner(false);
            navigate('/patient/admission', { state: res });
        });
    };

    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className="bg-gray h-auto w-full">
                <div className="flex flex-row justify-between">
                    <div className="flex items-center">
                        <Link to="/home">
                            <span className="text-base font-normal pl-5 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base px-2 py-2 font-roboto">Patient Registration Form</span>
                    </div>
                </div>
                <div className="flex justify-center">
                    <span className="text-3xl font-bold px-2 pb-4 text-black">
                        Patient Registration Form
                    </span>
                </div>
                <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                    <PatientRegistration handleSubmit={onAdd} isDisabled={false} isAdmission={isAdmission} buttonName="Cancel" />
                </div>
            </div>
        </div>
    );
};

export default PatientRegister;
