import React from 'react';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const HandwashChart = ({ formikState, onChange, onBlur, ...props }) => {
    const currentDate = new Date();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes();
    const handleNewField = () => {
        formikState.setFieldValue('handwash', [
            // ...formikState.values.handwash
            ...formikState.values.handwash,
            {
                beforetouch: '',
                beforepatient: '',
                floodelevation: '',
                aftertouch: '',
                averageeffect: ''
            }
        ]);
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Hand Wash/Hand Rub Chart</span>
            </div>
            <div className='flex flex-row flex-wrap w-[60%] justify-between pl-6 pr-2'>
                <span className='text-sm text-col'>Time: {currentTime}</span>
                <button className={props.isDoctor ? 'border border-iat w-32 h-10 bg-iat text-white' : 'border border-[#991622] w-32 h-10 bg-[#991622] text-white'} type='button' onClick={handleNewField}>Add</button>
            </div>
            {formikState.values.handwash.map((handwash, index) => (
                <div key={index}>
                    <div className='flex flex-col flex-wrap w-full px-6'>
                        <table className="table-auto border border-slate-300 w-[60%]">
                            <thead>
                                <tr>
                                    <th className='border border-slate-300 text-left text-sm p-2'>Chart</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2 border border-slate-300 text-col'>Before Touching Patient</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="beforetouch"
                                        name={`handwash[${index}].beforetouch`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].beforetouch === 'yes'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`handwash[${index}].beforetouch`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].beforetouch === 'no'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`handwash[${index}].beforetouch`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].beforetouch === 'refused'}
                                        disabled={props.isDisabled}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Before Patient</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="beforepatient"
                                        name={`handwash[${index}].beforepatient`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].beforepatient === 'yes'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`handwash[${index}].beforepatient`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].beforepatient === 'no'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`handwash[${index}].beforepatient`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].beforepatient === 'refused'}
                                        disabled={props.isDisabled}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Advisory Base Flood Elevation</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="floodelevation"
                                        name={`handwash[${index}].floodelevation`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].floodelevation === 'yes'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`handwash[${index}].floodelevation`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].floodelevation === 'no'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`handwash[${index}].floodelevation`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].floodelevation === 'refused'}
                                        disabled={props.isDisabled}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>After Touching Patient</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="aftertouch"
                                        name={`handwash[${index}].aftertouch`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].aftertouch === 'yes'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`handwash[${index}].aftertouch`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].aftertouch === 'no'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`handwash[${index}].aftertouch`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].aftertouch === 'refused'}
                                        disabled={props.isDisabled}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Average Treatment Effect</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="averageeffect"
                                        name={`handwash[${index}].averageeffect`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].averageeffect === 'yes'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        n name={`handwash[${index}].averageeffect`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].averageeffect === 'no'}
                                        disabled={props.isDisabled}
                                    /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`handwash[${index}].averageeffect`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        checked={formikState.values.handwash[index].averageeffect === 'refused'}
                                        disabled={props.isDisabled}
                                    /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-col w-full  px-6 pt-4">
                        <div className="grid grid-cols-c3 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Remarks if any :</label>
                            </div>
                            <input
                                // id="remarks"
                                // name="remarks"
                                id={`handwash[${index}].remarks`}
                                name={`handwash[${index}].remarks`}
                                type="text"
                                onChange={onChange}
                                value={formikState.values.handwash[index].remarks}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default HandwashChart;
