import React from 'react';
import Title from '../Title';

function GynHist (props) {
    const num = ['5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
    const day = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
    return (
        <div>
            <Title title={'Gynaecological History'} isNurse = {props.isNurse}/>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Gynaecological History :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex flex-col w-[49%]'>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GynaecologicalHistory.GynaecologicalHistory.AgeofMenarche"
                                id="GynaecologicalHistory.GynaecologicalHistory.AgeofMenarche"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GynaecologicalHistory.GynaecologicalHistory.AgeofMenarche}>
                                <option value="">Age of Menarche</option>
                                {
                                    num.map((data, i) => (
                                        <option value={num[i]} key={i}>{num[i]}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GynaecologicalHistory.GynaecologicalHistory.Regularity"
                                id="GynaecologicalHistory.GynaecologicalHistory.Regularity"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.GynaecologicalHistory.GynaecologicalHistory.Regularity}>
                                <option value="">Regularity of Cycle</option>
                                <option value="Regular">Regular</option>
                                <option value="Irregular">Irregular</option>
                                <option value="Others">Others</option>
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GynaecologicalHistory.GynaecologicalHistory.Flow"
                                id="GynaecologicalHistory.GynaecologicalHistory.Flow"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GynaecologicalHistory.GynaecologicalHistory.Flow}>
                                <option value="">Flow</option>
                                <option value="heavy">Heavy</option>
                                <option value="light">Light</option>
                                <option value="scanty">Scanty</option>
                                <option value="clots">Clots</option>
                            </select>
                        </div>
                        <div className='flex flex-col w-[49%]'>
                            <input
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GynaecologicalHistory.GynaecologicalHistory.LMP"
                                id="GynaecologicalHistory.GynaecologicalHistory.LMP"
                                type='text'
                                placeholder='LMP'
                                onFocus={(e) => (e.target.type = 'date')}
                                onBlur={(e) => (e.target.type = 'text')}
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GynaecologicalHistory.GynaecologicalHistory.LMP}
                            />
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GynaecologicalHistory.GynaecologicalHistory.Duration"
                                id="GynaecologicalHistory.GynaecologicalHistory.Duration"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GynaecologicalHistory.GynaecologicalHistory.Duration}>
                                <option value="">Duration of Cycle</option>
                                {
                                    day.map((data, i) => (
                                        <option value={day[i]} key={i}>{day[i]}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center'>
                    <div className='w-[140px] font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Pain During<br/>Menstruation :</div>
                    <div className='w-[150px] justify-around flex'>
                        <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruation'
                                type='radio'
                                id='No'
                                onChange={props.formikState.handleChange}
                                checked={props.formikState.values.GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruation === 'No'}
                                value='No'
                                disabled={props.disabled}
                            />No
                        </label>
                        <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruation'
                                id='Yes'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                checked={props.formikState.values.GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruation === 'Yes'}
                                value='Yes'
                                disabled={props.disabled}
                            />Yes
                        </label>
                    </div>
                </div>
                {props.formikState.values.GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruation === 'Yes' &&
            <textarea
                className='h-[100px] mt-[10px] ml-[160px] text-[15px] self-center border-[1px] w-[calc(100%-160px)] border-[#E3E3E3] text-input bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F]  font-[400] leading-[15px] placeholder:font-[400] pl-[10px] pt-[10px] placeholder:text-[13px] placeholder:leading-[15px]'
                id='GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruationInput'
                name='GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruationInput'
                type='text'
                placeholder='Write here'
                onChange={props.formikState.handleChange}
                value={props.formikState.values.GynaecologicalHistory.PainDuringMenstruation.PainDuringMenstruationInput}
                disabled={props.disabled}
            />
                }
            </div>
        </div>
    );
}

export default GynHist;
