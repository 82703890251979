import React, { useState, useEffect } from 'react';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import ModeOfVentilation from '../../utils/json/ModeOfVentilation.json';

function VentilationType ({ isDisabled, ...props }) {
    const currentDate = new Date();
    const dateFormat = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes();
    const sampleData = {
        data: 0,
        time: currentTime,
        date: dateFormat
    };
    const [value, setVal] = useState(props.val);
    useEffect(() => {
        props.formikState.setFieldValue(props.data.id, value);
    }, [value]);
    const handleAdd = () => {
        setVal([...value, sampleData]);
    };
    const handleDelete = () => {
        if (value.length > 1) {
            value.pop();
            setVal([...value]);
        }
    };
    return (
        <div>
            {props.isDoctor
                ? <div className='h-[45px] items-center bg-iat flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    {props.data.title}
                </div>
                : <div className='h-[45px] items-center bg-tgray flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    {props.data.title}
                </div>}
            <div className='flex bg-white'>
                <div className='w-full my-[5px]'>
                    {value.map((data, i) => {
                        return (
                            <div className='bg-white px-[16px] py-[10px] flex justify-between' key={i}>
                                <div className="flex flex-row w-1/3 justify-around items-center">
                                    <div className='w-[350px] justify-end flex items-center'>
                                        <select
                                            className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                            type='number'
                                            id={`${props.data.id}.${0}.data`}
                                            name={`${props.data.id}.${0}.data`}
                                            onChange={props.formikState.handleChange}
                                            defaultValue={data.data}
                                            disabled={isDisabled}
                                        >
                                            {Object.keys(ModeOfVentilation).map(element =>
                                                <option
                                                    key={element}
                                                    value={ModeOfVentilation[element].value}
                                                >
                                                    {ModeOfVentilation[element].label}
                                                </option>)
                                            }
                                        </select>
                                        <div className='absolute text-right mr-[10px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>{props.min}</div>
                                    </div>
                                </div>
                                <div className="flex flex-row w-1/3 justify-around items-center">
                                    <label className='flex flex-col items-center w-[calc(100%-173px)] justify-around font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Time :</label>
                                    <div className='relative flex'>
                                        <input
                                            className='h-[38px] w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3] text-center outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                            id={`${props.data.id}.${i}.time`}
                                            name={`${props.data.id}.${i}.time`}
                                            type='time'
                                            onChange={props.formikState.handleChange}
                                            defaultValue={data.time}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-1/3 items-center">
                                    <label className='flex flex-col items-center justify-around w-[calc(90%-173px)] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Date :</label>
                                    <input
                                        className='h-[38px] w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3] text-center outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                        id={`${props.data.id}.${i}.date`}
                                        name={`${props.data.id}.${i}.date`}
                                        type='date'
                                        onChange={props.formikState.handleChange}
                                        defaultValue= {data.date}
                                        disabled={isDisabled}
                                    />
                                </div>
                                <button type='button' className='items-center flex justify-center text-fc border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1 mr-[0.75rem]' disabled={isDisabled} onClick={() => handleAdd(i + 1)}>
                                    <FaPlus/>
                                </button>
                                <button type='button' className='items-center text-fc justify-center flex border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1' disabled={isDisabled} onClick={() => handleDelete(i + 1)}>
                                    <FaMinus/>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default VentilationType;
