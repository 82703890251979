/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import DischargeSummary from './DischargeSummary';
import { getEncounter } from '../../api/encounter';
import { convertFromFhirList, convertFromFhir } from '../../fhir-evolver';
import { getClinicalImpressionByEncounter } from '../../api/clinicalImpression';
import { toInitialAssessmentForm } from '../../fhir-evolver/resources/ClinicalImpression';

const DischargeSummaryAdd = () => {
    const [spinner, setSpinner] = useState(false);
    const [encounter, setEncounter] = useState('');
    const [primary, setPrimary] = useState('');
    const [progress, setProgress] = useState('');
    console.log('progress', progress);
    const [viewAssessment, setViewAssessment] = useState('');
    // console.log('viewww', viewAssessment);
    const params = useParams();
    // console.log('params.idd', params.id);
    // const location = useLocation();
    // console.log('loccc', location.state);
    const navigate = useNavigate();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const [snackbarOpen] = useSnackbar(options);

    useEffect(() => {
        getEncounter(`_id=${params.id}&_include=Encounter:*&_pretty=true`).then((response) => {
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                setEncounter(res);
            });
        });
        getClinicalImpressionByEncounter(`${params.id}&identifier=InitialAssessment&_include=*`).then((response) => {
            console.log('getclinicalimpression', response);
            convertFromFhir('ClinicalImpression', response.data.entry).then((res) => {
                // console.log('clinicalfhir', res);
                // setViewAssessment(res);
                toInitialAssessmentForm(res).then((res) => {
                    // console.log('toform', res);
                    setViewAssessment(res);
                }).catch(e => {
                    return e;
                });
            });
        });
        getClinicalImpressionByEncounter(`${params.id}&identifier=PrimaryCare&_include=*`).then((response) => {
            convertFromFhir('PrimaryCare', response).then((output) => {
                // console.log('primarycare evolver', output);
                setPrimary(output);
            });
        });
        getClinicalImpressionByEncounter(`${params.id}&identifier=ProgressNotes&_include=*`).then((response) => {
            convertFromFhir('Progress-Notes', response).then((output) => {
                console.log('progressnotes evolver', output);
                setProgress(output);
            });
        });
    }, []);

    const onAdd = (obj) => {
        console.log(obj);
        setSpinner(true);
        snackbarOpen('DischargeSummary Created Successfully');
        navigate('/');
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            <DischargeSummary handleSubmit={onAdd} isDisabled={false} isAdd={true} type='add' data={encounter} assessment={viewAssessment} primary={primary} progress={progress}/>
        </>
    );
};

export default DischargeSummaryAdd;
