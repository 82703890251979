/* eslint-disable no-console */
/* eslint-disable quote-props */
import { convertFromFhir, convertToFhir } from '../../index.js';
import initialAssessmentSample from '../../../utils/json/InitialAssessmentSample.json';
import secondaryCareFormik from '../../../utils/json/SecondaryCareFormik.json';
import { generatePrimaryCare } from '../../../utils/PrimaryCareFormik.js';
import { getMedicationAdministration } from '../../../api/medicationAdministration.js';
// generic
export const fromFhir = (data) => {
    try {
        if (!data) {
            return Promise.resolve([]);
        }
        const observation = [];
        const condition = [];
        const questionnaireResponse = [];
        const clinicalImpression = [];
        const resultingAssessment = [];
        const patient = [];
        const encounter = [];
        const promises = [];
        const questionnaire = [];
        const diagnosticReport = [];
        const riskAssessment = [];
        const arrayTypeMap = {
            'ClinicalImpression': clinicalImpression,
            'QuestionnaireResponse': questionnaireResponse,
            'Condition': condition,
            'Observation': observation,
            'Patient': patient,
            'Encounter': encounter,
            'Questionnaire': questionnaire,
            'DiagnosticReport': diagnosticReport,
            'RiskAssessment': riskAssessment
        };
        for (const entry of data) {
            arrayTypeMap[entry.resource.resourceType].push(entry);
        }
        for (const assessment of clinicalImpression) {
            const result = {};
            const resource = assessment.resource;
            result.date = resource.date;
            result.status = resource.status;
            result.id = resource.id;
            const identifier = assessment.resource.identifier?.find(impression => (impression.system) === 'identifier').value;
            result.identifier = identifier;
            data.questionnaireType = 'ClinicalImpression';
            // patient
            if (patient.length > 0) {
                const patientPromise = convertFromFhir('Patient', patient?.[0].resource).then((output) => {
                    result.patient = output;
                // eslint-disable-next-line no-console
                }).catch(e => console.log(e));
                promises.push(patientPromise);
            } else {
                if (resource.patient) {
                    result.patient = resource.subject;
                }
            }
            // encounter
            if (encounter.length > 0) {
                const encounterPromise = convertFromFhir('Encounter', encounter?.[0].resource).then((output) => {
                    // console.log('output.encounter', output);
                    result.encounter = output;
                // eslint-disable-next-line no-console
                }).catch(e => console.log(e));
                promises.push(encounterPromise);
            } else {
                if (resource.encounter) {
                    // console.log('resource.encounter', resource.encounter);
                    result.encounter = resource.encounter;
                }
            }
            // condition
            if (condition.length > 0) {
                result.condition = [];
                for (const problem of resource.problem) {
                    const conditionId = problem.reference?.split('/')[1];
                    const conditionResource = condition.find((element) => element.resource.id === conditionId);
                    const conditionPromise = convertFromFhir('Condition', conditionResource.resource).then((output) => {
                        result.condition.push(output);
                        // eslint-disable-next-line no-console
                    }).catch(e => console.log(e));
                    promises.push(conditionPromise);
                }
            } else {
                if (resource.problem) {
                    result.condition = resource.problem;
                }
            }
            // Investigations
            result.investigations = [];
            if (resource.investigation) {
                for (const investigation of resource.investigation) {
                    if (investigation.item) {
                        for (const item of investigation.item) {
                            if (item.reference) {
                                const res = item.type || item.reference.split('/')[0];
                                const resId = item.reference?.split('/')[1];
                                const investigationResource = arrayTypeMap[res].find((element) => element.resource.id === resId);
                                if (investigationResource) {
                                    const investigationPromise = convertFromFhir(res, investigationResource).then((data) => {
                                        data.identifierName = res;
                                        result.investigations.push(data);
                                    }
                                    // eslint-disable-next-line no-console
                                    ).catch((e) => { console.log(e); });
                                    promises.push(investigationPromise);
                                } else {
                                    if (resource.investigation) {
                                        result.investigations.push(item);
                                    }
                                }
                            } else {
                                for (const codeObject of item.code.coding) {
                                    result.investigations.push(codeObject);
                                }
                            }
                        }
                    }
                }
            }
            // Findings
            result.findings = [];
            if (resource.finding) {
                for (const finding of resource.finding) {
                    if (finding.itemReference) {
                        const res = finding.itemReference.type || finding.itemReference.reference.split('/')[0];
                        const resId = finding.itemReference.reference?.split('/')[1];
                        const findingResource = arrayTypeMap[res].find((element) => element.resource.id === resId);
                        if (findingResource) {
                            const findingPromise = convertFromFhir(res, findingResource).then((data) => { result.findings.push(data); }
                                // eslint-disable-next-line no-console
                            ).catch((e) => { console.log(e); });
                            promises.push(findingPromise);
                        } else {
                            if (resource.finding) {
                                result.findings.push(finding);
                            }
                        }
                    } else {
                        for (const codeObject of finding.itemCodeableConcept.coding) {
                            result.findings.push(codeObject);
                        }
                    }
                }
            }
            result.prognosis = [];
            if (resource.prognosisCodeableConcept?.[0].coding) {
                for (const prognosis of resource.prognosisCodeableConcept[0].coding) {
                    result.prognosis.push(prognosis);
                }
            }
            resultingAssessment.push(result);
        }
        // eslint-disable-next-line no-console
        return Promise.all(promises).then(() => Promise.resolve(resultingAssessment)).catch((e) => console.log(e));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toFhir = async (data) => {
    // eslint-disable-next-line no-console
    // console.log('tofhirindex', data[0].ChiefComplaint.length);
    // console.log('conditionlength', data[1].map((condition) => condition.ConLength.conditionLength));
    // bundle
    try {
        const resourceIdMap = data[1];
        data = data.type === 'add' ? data : data[0];
        let apiType = data.apiType;
        switch (data.type) {
        case 'add':
            apiType = 'POST';
            break;
        case 'edit':
            apiType = 'PUT';
            break;
        }
        const promises = [];
        const BundleRequest = {};
        BundleRequest.resourceType = 'Bundle';
        BundleRequest.type = 'transaction';
        BundleRequest.entry = [];
        const conditionUUID = 'urn:uuid:condition';
        const generalObservationUUID = 'urn:uuid:generalObservation';
        const vitalParametersUUID = 'urn:uuid:vitalParameters';
        const questionnaireResponseUUID = 'urn:uuid:questionnaireResponse';
        data.Bundle = true;
        data.questionnaireType = 'ClinicalImpression';
        const entry = BundleRequest.entry;
        // questionnaire Response
        const questionnaireResponsePromise = convertToFhir('QuestionnaireResponse', data).then((output) => {
            if (data.type === 'edit') {
                output.id = resourceIdMap.ques.resourceType === output.resourceType ? resourceIdMap.ques.id : '';
            }
            const transform = {
                request: {
                    method: apiType,
                    url: 'QuestionnaireResponse'
                },
                fullUrl: questionnaireResponseUUID,
                resource: output
            };
            if (data.type === 'edit') {
                transform.request.url = `QuestionnaireResponse/${output.id}`;
            }
            entry.push(transform);
        });
        // general Physical Examination
        const generalObervationPromise = convertToFhir('GeneralObservation', data).then(output => {
            if (data.type === 'edit') {
                output.id = resourceIdMap.observation.find((data) => data.resourceType === 'GeneralCondition').id;
            }
            const transform = {
                request: {
                    method: apiType,
                    url: 'Observation'
                },
                fullUrl: generalObservationUUID,
                resource: output
            };
            if (data.type === 'edit') {
                transform.request.url = `Observation/${output.id}`;
            }
            entry.push(transform);
        });
        // Vital Parameters
        const vitalParameterPromise = convertToFhir('VitalParametersObservation', data).then(output => {
            if (data.type === 'edit') {
                output.id = resourceIdMap.observation.find((data) => data.resourceType === 'VitalParameters').id;
            }

            const transform = {
                request: {
                    method: apiType,
                    url: 'Observation'
                },
                fullUrl: vitalParametersUUID,
                resource: output
            };
            if (data.type === 'edit') {
                transform.request.url = `Observation/${output.id}`;
            }
            entry.push(transform);
        });
        promises.push(questionnaireResponsePromise, generalObervationPromise, vitalParameterPromise);
        // Clinical Impression
        const clinicalImpression = {};
        // clinicalImpression.type = data.type;
        clinicalImpression.status = data.status;
        clinicalImpression.identifier = [
            {
                system: 'identifier',
                value: 'InitialAssessment'
            }
        ];
        clinicalImpression.resourceType = 'ClinicalImpression';
        if (data.type === 'edit') {
            clinicalImpression.id = resourceIdMap.clinical.id;
        }
        clinicalImpression.subject = {
            type: 'Patient',
            reference: `Patient/${data.patientId}`
        };
        clinicalImpression.encounter = {
            type: 'Encounter',
            reference: `Encounter/${data.encounterId}`
        };
        // ClinicalImpression.investigation
        clinicalImpression.investigation = {
            item: [
                {
                    type: 'Observation',
                    reference: 'urn:uuid:generalObservation'
                }, {
                    type: 'Observation',
                    reference: 'urn:uuid:vitalParameters'
                }, {
                    type: 'QuestionnaireResponse',
                    reference: 'urn:uuid:questionnaireResponse'
                }, {
                    text: 'investigations',
                    code: {
                        coding: []
                    }
                }
            ]
        };
        let diagnosticfilter = '';
        if (data.type === 'edit') {
            diagnosticfilter = resourceIdMap.diagnostic;
        }
        for (const [index, item] of data.Investigations.Investigations.entries()) {
            if (item.value) {
                const obj = {
                    patientId: data.patientId,
                    encounterId: data.encounterId,
                    practitioner: data.practitioner,
                    investigation: item.value
                };
                let DiagnosticApi = apiType;
                if (data.type === 'edit') {
                    if (index < diagnosticfilter.length) {
                        obj.id = diagnosticfilter[index].id;
                    } else {
                        DiagnosticApi = 'POST';
                    }
                }
                const diagnosticReportUUID = `urn:uuid:diagnosticReport:${item.value}`;
                const diagnosticReport = {
                    request: {
                        method: DiagnosticApi,
                        url: `DiagnosticReport/${obj.id}`
                    },
                    fullUrl: diagnosticReportUUID,
                    resource: await convertToFhir('DiagnosticReport', obj)
                };
                if (data.type === 'edit') {
                    diagnosticReport.request.url = `DiagnosticReport/${obj.id}`;
                }
                clinicalImpression.investigation.item.push(
                    {
                        type: 'DiagnosticReport',
                        reference: diagnosticReportUUID
                    }
                );
                entry.push(diagnosticReport);
            }
        }

        // ChiefComplaint
        clinicalImpression.problem = [];
        let count = 0;
        // const inputCondition = data?.[0].ChiefComplaint?.length;
        // const getCondition = data?.[1].ConLength?.conditionLength;
        let filterConditionData;
        if (data.type === 'edit') { filterConditionData = resourceIdMap.condition; }
        for (const [index, complaint] of data.ChiefComplaint.entries()) {
            complaint.patientId = data.patientId;
            complaint.encounterId = data.encounterId;
            let ConditionApi = apiType;
            if (data.type === 'edit') {
                if (index < filterConditionData.length) {
                    complaint.id = filterConditionData[index].id;
                } else {
                    ConditionApi = 'POST';
                }
            }
            const conditionPromise = convertToFhir('Condition', complaint).then(output => {
                const transform = {
                    request: {
                        method: ConditionApi,
                        url: 'Condition'
                    },
                    fullUrl: conditionUUID + count,
                    resource: output
                };
                if (data.type === 'edit') {
                    transform.request.url = `Condition/${complaint.id}`;
                }
                entry.push(transform);
                clinicalImpression.problem.push(
                    {
                        type: 'Condition',
                        reference: conditionUUID + count
                    }
                );
                count++;
            });
            promises.push(conditionPromise);
        }
        // prognosis
        clinicalImpression.prognosisCodeableConcept = {
            coding: [
                {
                    code: data.Prognosis.PrognosisInput,
                    display: data.Prognosis.Prognosis
                }
            ]
        };
        // pertinent clinical findings
        clinicalImpression.finding = [{
            itemCodeableConcept: {
                coding: [{
                    code: data.Pertinent,
                    display: 'Pertinent Clinical Findings'
                }]
            }
        }];
        // Provisional Diagnosis
        for (const provisionalDiagnosis of data.WorkingProvisionalDiagnosis) {
            clinicalImpression.finding[0].itemCodeableConcept.coding.push(
                {
                    code: provisionalDiagnosis.code || '',
                    system: 'Provisional Diagnosis',
                    display: provisionalDiagnosis.name || ''
                }
            );
        }
        // date
        clinicalImpression.date = new Date().toISOString();
        const clinicalImpressionPromise = Promise.all(promises).then(() => Promise.resolve(clinicalImpression).then((output) => {
            const transform = {
                request: {
                    method: apiType,
                    url: 'ClinicalImpression'
                },
                resource: output
            };
            if (data.type === 'edit') {
                transform.request.url = `ClinicalImpression/${output.id}`;
            }
            entry.push(transform);
        }));
        return Promise.resolve(clinicalImpressionPromise).then(() => Promise.resolve(BundleRequest));
        // console.log('promises', promises);
        // return  Promise.resolve(BundleRequest));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toInitialAssessmentForm = (data) => {
    try {
        data = data[0];
        // console.log('inputData', data);
        // console.log('initialAssessmentSample', initialAssessmentSample[1]);
        const result = Object.assign({}, initialAssessmentSample[1]);
        result.patientId = data.patient.id;
        result.encounterId = data.encounter.id;
        // Chief Complaint
        result.ChiefComplaint = [];
        for (const complaint of data.condition) {
            result.ChiefComplaint.push(
                {
                    Complaint: complaint.complaints,
                    Duration: complaint.Duration,
                    DurationUnit: complaint.DurationUnit
                }
            );
        }
        // History of Present Illness
        const questionnaireResponse = data.investigations.find((el) => el.identifierName === 'QuestionnaireResponse');
        const findAllSubResponses = (linkId) => {
            const element = questionnaireResponse.filter((el) => linkId === el.linkId?.substring(0, linkId.length));
            return element;
        };
        const findResponse = (question, arrayPart) => {
            const element = arrayPart.find((el) => question === el.question);
            return element.answer;
        };
        if (questionnaireResponse) {
            const historyOfPresentIllness = findAllSubResponses('1.1');
            for (const part of historyOfPresentIllness) {
                result.HistoryOfPresentIllness.Detail[part.question] = findResponse(part.question, historyOfPresentIllness);
            }
            const negativeHistory = findAllSubResponses('1.2');
            for (const part of negativeHistory) {
                result.HistoryOfPresentIllness.NegativeHistory[part.question] = findResponse(part.question, negativeHistory);
            }
            // PastMedicalHistories
            const pastMedicalHistories = findAllSubResponses('2');
            for (const part of pastMedicalHistories) {
                result.PastMedicalSurgicalHistories[part.question] = findResponse(part.question, pastMedicalHistories);
            }
            // Personal History
            const personalHistory = findAllSubResponses('3');
            for (const part of personalHistory) {
                result.PersonalHo[part.question] = findResponse(part.question, personalHistory);
            }
            // Allergic Ho
            const allergicHo = findAllSubResponses('4');
            for (const part of allergicHo) {
                result.AllergicHo[part.question] = findResponse(part.question, allergicHo);
            }
            // Vaccinated
            const vaccinated = findAllSubResponses('5');
            for (const part of vaccinated) {
                result.Vaccinated[part.question] = findResponse(part.question, vaccinated);
            }
            // GynaecologicalHistory
            const GynaecologicalHistory = findAllSubResponses('6.1');
            for (const part of GynaecologicalHistory) {
                result.GynaecologicalHistory.GynaecologicalHistory[part.question] = findResponse(part.question, GynaecologicalHistory);
            }
            const painDuringMenstruation = findAllSubResponses('6.2');
            for (const part of painDuringMenstruation) {
                result.GynaecologicalHistory.PainDuringMenstruation[part.question] = findResponse(part.question, painDuringMenstruation);
            }
            // Educational Social History
            const educationalSocialHistory = findAllSubResponses('7');
            for (const part of educationalSocialHistory) {
                result.EducationalSocialHistory[part.question] = findResponse(part.question, educationalSocialHistory);
            }
            // Psycological Status
            const psycologicalStatus = findAllSubResponses('8');
            for (const part of psycologicalStatus) {
                result.PsychologicalStatus[part.question] = findResponse(part.question, psycologicalStatus);
            }
            // Functional Status
            const functionalStatus = findAllSubResponses('9');
            for (const part of functionalStatus) {
                result.FunctionalStatus[part.question] = findResponse(part.question, functionalStatus);
            }
            // Nutritional Status
            const nutritionalStatus = findAllSubResponses('10.1');
            for (const part of nutritionalStatus) {
                result.NutritionalStatus.NutritionalStatus[part.question] = findResponse(part.question, nutritionalStatus);
            }
            const weightLossInThreeMonths = findAllSubResponses('10.2');
            for (const part of weightLossInThreeMonths) {
                result.NutritionalStatus.WtLossinThreeMonths[part.question] = findResponse(part.question, weightLossInThreeMonths);
            }
            const historyOfReducedFoodIntake = findAllSubResponses('10.3');
            for (const part of historyOfReducedFoodIntake) {
                result.NutritionalStatus.HistoryofReducedFoodIntake[part.question] = findResponse(part.question, historyOfReducedFoodIntake);
            }
            const parameter = findAllSubResponses('10.4');
            for (const part of parameter) {
                result.NutritionalStatus.Parameter[part.question] = findResponse(part.question, parameter);
            }
            // Other
            const oSystem = findAllSubResponses('11.1');
            result.Other.Osystem = findResponse('Osystem', oSystem);
            const gitsystem = findAllSubResponses('11.2');
            for (const part of gitsystem) {
                result.Other.GITsystem[part.question] = findResponse(part.question, gitsystem);
            }
            const cvsSystem = findAllSubResponses('11.3');
            for (const part of cvsSystem) {
                result.Other.CVSsystem[part.question] = findResponse(part.question, cvsSystem);
            }
            const rSystem = findAllSubResponses('11.5');
            for (const part of rSystem) {
                result.Other.Rsystem[part.question] = findResponse(part.question, rSystem);
            }
            const cnsSystem = findAllSubResponses('11.6');
            for (const part of cnsSystem) {
                result.Other.CNSsystem[part.question] = findResponse(part.question, cnsSystem);
            }
            const gutSystem = findAllSubResponses('11.7');
            for (const part of gutSystem) {
                result.Other.GUTsystem[part.question] = findResponse(part.question, gutSystem);
            }
            const other = findAllSubResponses('11.8');
            for (const part of other) {
                result.Other.Other[part.question] = findResponse(part.question, other);
            }
            // Consents
            const consents = findAllSubResponses('12');
            for (const part of consents) {
                result.Consents = findResponse(part.question, consents);
            }
            // Family Ho
            const familyHo = findAllSubResponses('13');
            for (const part of familyHo) {
                result.FamilyHo[part.question] = findResponse(part.question, familyHo);
            }
            // Drug History
            const drugHistory = findAllSubResponses('14');
            for (const part of drugHistory) {
                result.DrugHistory[part.question] = findResponse(part.question, drugHistory);
            }
            // CNS
            const CNS = findAllSubResponses('15');
            for (const part of CNS) {
                result.CNS[part.question] = findResponse(part.question, CNS);
            }
        }
        // Pertinent Clinical Findings and Working Provisional Diagnosis
        result.Pertinent = data.findings.find((el) => el.display === 'Pertinent Clinical Findings')?.code || [];
        const provisionalDiagnosisArray = data.findings.filter((el) => el.system === 'Provisional Diagnosis') || [];
        result.WorkingProvisionalDiagnosis = [];
        if (provisionalDiagnosisArray.length > 0) {
            for (const provisionalDiagnosis of provisionalDiagnosisArray) {
                result.WorkingProvisionalDiagnosis.push(
                    {
                        name: provisionalDiagnosis.display || '',
                        code: provisionalDiagnosis.code || ''
                    }
                );
            }
        }
        result.Prognosis.PrognosisInput = data.prognosis[0].code || [];
        result.Prognosis.Prognosis = data.prognosis[0].display || [];
        // Vital Parameters
        const vitalParameters = data.investigations.find((el) => (el.identifierName === 'Observation' && el.AddedParams));
        if (vitalParameters) {
        // Airway
            result.VitalParameters.Airway.AirwayInput = vitalParameters.AddedParams.Airway.coding[1]?.code || '';
            result.VitalParameters.Airway.AirwayPatentorNot = vitalParameters.AddedParams.Airway.coding[0]?.code || '';
            // Breathing
            result.VitalParameters.Breathing.rate.checked = vitalParameters.AddedParams.Breathing[0]?.valueCodeableConcept.coding[0]?.code || '';
            result.VitalParameters.Breathing.rate.RateInput = vitalParameters.AddedParams.Breathing[0]?.valueCodeableConcept.coding[1]?.code || '';
            result.VitalParameters.Breathing.spo2.spo2Input = vitalParameters.AddedParams.Breathing[2]?.valueCodeableConcept.coding[0]?.code || '';
            result.VitalParameters.Breathing.spo2.spo2DropDown = vitalParameters.AddedParams.Breathing[2]?.valueCodeableConcept.coding[1].code || '';
            result.VitalParameters.Breathing.spo2.Other = vitalParameters.AddedParams.Breathing[2]?.valueCodeableConcept.coding[2]?.code || '';
            result.VitalParameters.Breathing.type.TypeDropdown = vitalParameters.AddedParams.Breathing[1]?.valueCodeableConcept.coding[0]?.code || '';
            result.VitalParameters.Breathing.type.TypeInput = vitalParameters.AddedParams.Breathing[1]?.valueCodeableConcept.coding[1]?.code || '';
            // Circulation
            result.VitalParameters.Circulation.SBp.SBpInput = vitalParameters.AddedParams.Circulation[2].valueCodeableConcept.coding[0]?.code || '';
            result.VitalParameters.Circulation.DBp.DBpInput = vitalParameters.AddedParams.Circulation[2].valueCodeableConcept.coding[1]?.code || '';
            result.VitalParameters.Circulation.DBp.Position = vitalParameters.AddedParams.Circulation[2].valueCodeableConcept.coding[2]?.code || '';
            result.VitalParameters.Circulation.DBp.checked = vitalParameters.AddedParams.Circulation[2].valueCodeableConcept.coding[3]?.code || '';
            result.VitalParameters.Circulation.Cardiac = vitalParameters.AddedParams.Circulation[4].valueCodeableConcept?.coding[0]?.code || '';
            result.VitalParameters.Circulation.Temperature.Temperature = vitalParameters.AddedParams.Circulation[3].valueCodeableConcept?.coding[0]?.code || '';
            result.VitalParameters.Circulation.Temperature.TemperatureInput = vitalParameters.AddedParams.Circulation[3].valueCodeableConcept?.coding[1]?.code || '';
            result.VitalParameters.Circulation.Temperature.tempCelsius = vitalParameters.AddedParams.Circulation[3].valueCodeableConcept?.coding[2]?.code || '';
            result.VitalParameters.Circulation.Temperature.tempFahrenheit = vitalParameters.AddedParams.Circulation[3].valueCodeableConcept?.coding[3]?.code || '';
            result.VitalParameters.Circulation.HRPulse.checked = vitalParameters.AddedParams.Circulation[0].valueCodeableConcept?.coding[0]?.code || '';
            result.VitalParameters.Circulation.HRPulse.HRPulseInput = vitalParameters.AddedParams.Circulation[0].valueCodeableConcept?.coding[1]?.code || '';
            result.VitalParameters.Circulation.PulseRhythm = vitalParameters.AddedParams.Circulation[1].valueCodeableConcept?.coding[0]?.code || '';
            // Heart
            result.VitalParameters.Heart.Abdomen = vitalParameters.AddedParams.Heart.coding[4]?.code || '';
            result.VitalParameters.Heart.HeartSounds.HeartSounds = vitalParameters.AddedParams.Heart.coding[0]?.code || '';
            result.VitalParameters.Heart.HeartSounds.HeartSoundsInput = vitalParameters.AddedParams.Heart.coding[1]?.code || '';
            result.VitalParameters.Heart.AddedSound.AddedSound = vitalParameters.AddedParams.Heart.coding[2]?.code || '';
            result.VitalParameters.Heart.AddedSound.AddedSoundInput = vitalParameters.AddedParams.Heart.coding[3]?.code || '';
            result.VitalParameters.Heart.Bowelsound = vitalParameters.AddedParams.Heart.coding[5]?.code || '';
            // Pupil
            result.VitalParameters.Pupil.LtEye = vitalParameters.AddedParams.Pupil.coding[1]?.code || '';
            result.VitalParameters.Pupil.RtEye = vitalParameters.AddedParams.Pupil.coding[0]?.code || '';
            result.VitalParameters.Pupil.pupilInput = vitalParameters.AddedParams.Pupil.coding[2]?.code || '';
            // Pupil1
            result.VitalParameters.Pupil1.Rt = vitalParameters.AddedParams.GCNS.coding[0]?.code || '';
            result.VitalParameters.Pupil1.Lt = vitalParameters.AddedParams.GCNS.coding[1]?.code || '';
            result.VitalParameters.Pupil1.RtTone = vitalParameters.AddedParams.GCNS.coding[2]?.code || '';
            result.VitalParameters.Pupil1.LtTone = vitalParameters.AddedParams.GCNS.coding[3]?.code || '';
            result.VitalParameters.Pupil1.RtReflexes = vitalParameters.AddedParams.GCNS.coding[4]?.code || '';
            result.VitalParameters.Pupil1.LtReflexes = vitalParameters.AddedParams.GCNS.coding[5]?.code || '';
            result.VitalParameters.Pupil1.Rreflex = vitalParameters.AddedParams.GCNS.coding[6]?.code || '';
            result.VitalParameters.Pupil1.Lreflex = vitalParameters.AddedParams.GCNS.coding[7]?.code || '';
            // Secondary
            result.VitalParameters.Secondary.Pallor = vitalParameters.AddedParams.Pallor?.coding[0]?.code || '';
            result.VitalParameters.Secondary.Icterus = vitalParameters.AddedParams.Icterus?.coding[0]?.code || '';
            result.VitalParameters.Secondary.Iymphadenopathy = vitalParameters.AddedParams.Iymphadenopathy?.coding[0].code || '';
            result.VitalParameters.Secondary.Koilonychia = vitalParameters.AddedParams.Koilonychia?.coding[0]?.code || '';
            result.VitalParameters.Secondary.Edema = vitalParameters.AddedParams.Edema?.coding[0]?.code || '';
            result.VitalParameters.Secondary.Cyanosis = vitalParameters.AddedParams.Cyanosis?.coding[0]?.code || '';
            result.VitalParameters.Secondary.Chest = vitalParameters.AddedParams.Chest?.coding[0]?.code || '';
            result.VitalParameters.Secondary.secondaryInput = vitalParameters.AddedParams.secondaryInput?.coding[0]?.code || '';
        }
        // General Physical Examination
        const generalPhysicalExam = data.investigations.find((el) => (el.identifierName === 'Observation' && el.LevelOfConciseness));
        if (generalPhysicalExam) {
            result.GeneralPhysicalExamination.Attitude.Attitude = generalPhysicalExam.Attitude || '';
            result.GeneralPhysicalExamination.Attitude.AttitudeInput = generalPhysicalExam.AttitudeInput || '';
            result.GeneralPhysicalExamination.Breast.BreastNA = generalPhysicalExam.BreastNA || '';
            result.GeneralPhysicalExamination.Breast.BreastInput = generalPhysicalExam.BreastInput || '';
            result.GeneralPhysicalExamination.Coherence.CoherenceInput = generalPhysicalExam.Coherence || '';
            result.GeneralPhysicalExamination.Disorientation.DisorientationInput = generalPhysicalExam.Disorientation || '';
            result.GeneralPhysicalExamination.GeneralCondition.GeneralConditionInput = generalPhysicalExam.generalcondition || '';
            result.GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse = generalPhysicalExam.LevelOfConciseness.EyeOpeningResponse || '';
            result.GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse = generalPhysicalExam.LevelOfConciseness.VerbalResponse || '';
            result.GeneralPhysicalExamination.LevelOfConciseness.MotorResponse = generalPhysicalExam.LevelOfConciseness.MotorResponse || '';
            result.GeneralPhysicalExamination.LevelOfConciseness.Result = generalPhysicalExam.LevelOfConciseness.Result || '';
            result.GeneralPhysicalExamination.Orientation.OrientationInput = generalPhysicalExam.Orientation || '';
            result.GeneralPhysicalExamination.Thyroid.ThyroidInput = generalPhysicalExam.ThyroidInput || '';
            result.GeneralPhysicalExamination.Thyroid.ThyroidNA = generalPhysicalExam.ThyroidNA || '';
            result.GeneralPhysicalExamination.Temperature.TemperatureInput = generalPhysicalExam.temperature || '';
        }
        // Investigations
        const investigations = data.investigations.filter((el) => el.identifierName === 'DiagnosticReport');
        result.Investigations.Investigations = [];
        if (investigations.length > 0) {
            for (const diagnosticInstance of investigations) {
                result.Investigations.Investigations.push({ label: diagnosticInstance.investigation, display: diagnosticInstance.investigation });
            }
        }
        return Promise.resolve(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toPrimaryCareFhir = (data) => {
    // bundle
    try {
        const resourceIdMap = data[1];
        data = data.type === 'add' ? data : data[0];
        console.log('primaryCare', data);
        let apiType = data.apiType;
        switch (data.type) {
        case 'add':
            apiType = 'POST';
            break;
        case 'edit':
            apiType = 'PUT';
            break;
        }
        const promises = [];
        const BundleRequest = {};
        BundleRequest.entry = [];
        BundleRequest.resourceType = 'Bundle';
        BundleRequest.type = 'transaction';
        data.Bundle = true;
        data.questionnaireType = 'PrimaryCare';
        const clinicalImpression = {};
        clinicalImpression.note = [];
        clinicalImpression.date = new Date().toISOString();
        // const notes = ['GivenBy', 'TakenBy', 'CompletedTasks', 'PendingTasks', 'SpecialInstruction'];
        const observations = ['HeartRate', 'BP', 'IVaccess', 'Spo2', 'RBS', 'Insulin', 'Temperature', 'Tidal', 'Fio2', 'PEEP', 'Airway', 'RateOfRespiration', 'PH', 'PCo2', 'PO2', 'Hco3', 'Lactate', 'Saturation', 'IntakeOutput'];
        let index = 0;
        // console.log('resourceIdMap', resourceIdMap);
        const refactorObservationPromise = (inputData, resourceType) => {
            const observationPromise = convertToFhir(resourceType, inputData).then((output) => {
                const transform = {
                    resource: output,
                    fullUrl: 'urn:uuid:Observation' + index.toString(),
                    request: {
                        method: apiType,
                        url: 'Observation'
                    }
                };
                if (data.type === 'edit') {
                    if (index < resourceIdMap.observation.length) {
                        output.id = resourceIdMap.observation[index].id;
                        transform.request.url = `Observation/${output.id}`;
                    } else {
                        transform.request.url = 'Observation';
                        transform.request.method = 'POST';
                    }
                }
                BundleRequest.entry.push(transform);
                clinicalImpression.investigation.item.push(
                    {
                        type: 'Observation',
                        reference: 'urn:uuid:Observation' + index.toString()
                    }
                );
                index += 1;
            // eslint-disable-next-line no-console
            }).catch((e) => console.log(e));
            promises.push(observationPromise);
        };
        // ClinicalImpression.investigation
        clinicalImpression.investigation = {
            item: [
                {
                    type: 'QuestionnaireResponse',
                    reference: 'urn:uuid:questionnaireResponse'
                }
            ]
        };
        for (const element in data) {
            const dataelement = data[element];
            if (observations.includes(element)) {
                for (const subelement of dataelement) {
                    const inputData = {
                        input: subelement,
                        patient: data.Patient,
                        encounter: data.Encounter,
                        name: element
                    };
                    if (element === 'BP') {
                        const systolic = subelement.SystolicBP[0];
                        const diastolic = subelement.DiastolicBP[0];
                        const systolicData = Object.assign({}, inputData);
                        systolicData.input = [];
                        systolicData.name = 'SystolicBP';
                        systolicData.input.push({
                            data: systolic.data,
                            name: 'data'
                        }, {
                            data: systolic.date,
                            name: 'date'
                        }, {
                            data: systolic.time,
                            name: 'time'
                        });
                        const diastolicData = Object.assign({}, inputData);
                        diastolicData.name = 'DiastolicBP';
                        diastolicData.input = [];
                        diastolicData.input.push({
                            data: diastolic.data,
                            name: 'data'
                        }, {
                            data: diastolic.date,
                            name: 'date'
                        }, {
                            data: diastolic.time,
                            name: 'time'
                        });
                        refactorObservationPromise(systolicData, 'Observation-BP');
                        refactorObservationPromise(diastolicData, 'Observation-BP');

                        continue;
                    };
                    // default execution
                    // refactorObservationPromise(inputData, 'Observation');
                    const componentArray = [];
                    for (const el in subelement) {
                        componentArray.push({
                            input: {
                                data: subelement[el],
                                name: el
                            },
                            patient: data.Patient,
                            encounter: data.Encounter,
                            name: element
                        });
                    }
                    refactorObservationPromise(componentArray, 'Observation-Component');
                    continue;
                }
            }
        };

        // questionnaire Response
        // Default value Absent isnt fixed, therefore fixing it here
        const questionnaireResponsePromise = convertToFhir('QuestionnaireResponse', data).then((output) => {
            if (data.type === 'edit') {
                output.id = resourceIdMap.ques.resourceType === output.resourceType ? resourceIdMap.ques.id : '';
            }
            const transform = {
                resource: output,
                fullUrl: 'urn:uuid:questionnaireResponse',
                request: {
                    method: apiType,
                    url: 'QuestionnaireResponse'
                }
            };
            if (data.type === 'edit') {
                transform.request.url = `QuestionnaireResponse/${output.id}`;
            }
            BundleRequest.entry.push(transform);
        });
        promises.push(questionnaireResponsePromise);
        // Clinical Impression
        clinicalImpression.resourceType = 'ClinicalImpression';
        clinicalImpression.identifier = [
            {
                system: 'identifier',
                value: 'PrimaryCare'
            }
        ];
        if (data.type === 'edit') {
            clinicalImpression.id = resourceIdMap.clinical.id;
        }
        clinicalImpression.subject = {
            type: 'Patient',
            reference: `Patient/${data.Patient}`
        };
        clinicalImpression.encounter = {
            type: 'Encounter',
            reference: `Encounter/${data.Encounter}`
        };
        promises.push(Promise.resolve(clinicalImpression).then(() => {
            let tempurl = 'ClinicalImpression';
            if (data.type === 'edit') {
                tempurl = `ClinicalImpression/${clinicalImpression.id}`;
            }
            BundleRequest.entry.push({
                request: { method: apiType, url: tempurl },
                resource: clinicalImpression
            });
        }
        ));
        for (const medication of data.Medication) {
            medication.patient = data.Patient;
            medication.encounter = data.Encounter;
            promises.push(convertToFhir('MedicationAdministration', medication).then((resolved) => {
                const transform = {
                    request: {
                        method: 'PUT',
                        url: `MedicationAdministration/${resolved.id}`
                    },
                    resource: resolved
                };
                BundleRequest.entry.push(transform);
            }));
        }
        return Promise.all(promises).then(() => Promise.resolve(BundleRequest));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const fromPrimaryCareFhir = (data) => {
    try {
        // eslint-disable-next-line no-console
        // console.log(data);
        const entry = data.data.entry;
        const promises = [];
        const result = Object.assign({}, generatePrimaryCare());
        const clinicalImpression = entry.find((el) => el.resource.resourceType === 'ClinicalImpression');
        result.id = clinicalImpression.resource.id;
        result.date = clinicalImpression.resource.date;
        const observations = entry.filter((el) => el.resource.resourceType === 'Observation');
        const questionnaireResponse = entry.find((el) => el.resource.resourceType === 'QuestionnaireResponse');
        const patientId = entry.find((el) => el.resource.resourceType === 'Patient').resource.id;
        const encounterId = entry.find((el) => el.resource.resourceType === 'Encounter').resource.id;
        // Questionnaire Response
        questionnaireResponse.questionnaireType = 'PrimaryCare';
        const questionnaireResponsePromise = convertFromFhir('QuestionnaireResponse', questionnaireResponse).then((output) => {
            const findAllSubResponses = (linkId) => {
                const element = output.filter((el) => linkId === el.linkId?.substring(0, linkId.length));
                return element;
            };
            const findResponse = (question, arrayPart) => {
                const element = arrayPart.find((el) => question === el.question);
                return element.answer;
            };
            const keys = ['PeripheralPulse', 'ADW', 'AMI', 'Diet', 'IVA', 'HEP', 'Order', 'Sent', 'Bed', 'Psl', 'Air', 'Pressure', 'Rounds', 'Reports', 'dutyDoctor', 'remarks', 'Nebulisation', 'ionotropeSupport', 'sedation', 'headElevated', 'eyeCareGiven', 'eyeLubricant', 'centralCvp', 'etCuff', 'rtPosition', 'urinaryCathChecked', 'urinaryInsertion', 'weaningTrail', 'mouthCare', 'backCare', 'bedSore', 'urinaryCathFixed', 'btTransfusion', 'GivenBy', 'TakenBy', 'CompletedTasks', 'PendingTasks', 'SpecialInstruction'];
            for (const [index, key] of keys.entries()) {
                const subArray = findAllSubResponses(`${index + 1}.`);
                // eslint-disable-next-line no-console
                // console.log(key, subArray);
                for (const part of subArray) {
                    result[key][part.question] = findResponse(part.question, subArray)[0];
                }
            }
        });
        promises.push(questionnaireResponsePromise);
        // Observation
        const observationsWithComponent = observations.filter((el) => !!el.resource.component);
        // to handle for bp and this would be great
        const observationpromises = [];
        const systolicArray = [];
        const diastolicArray = [];
        for (const observation of observationsWithComponent) {
            const observationPromise = convertFromFhir('Observation-Component', observation).then((output) => {
                // Object.keys(output) will return all keys in an array, since we only have one key, we are using 0th index
                // Same for values.
                const formikKey = Object.keys(output)[0];
                const formikValue = Object.values(output)[0];
                // console.log('key', formikKey);
                const BPMap = {
                    'SystolicBP': systolicArray,
                    'DiastolicBP': diastolicArray
                };
                if (formikKey === 'SystolicBP' || formikKey === 'DiastolicBP') {
                    BPMap[formikKey].push(formikValue);
                }
                if (formikKey in result) {
                    if ((result[formikKey]).constructor === ([]).constructor) {
                        result[formikKey].push(formikValue);
                        return;
                    }
                    result[formikKey] = Object.values(output);
                }
            });
            observationpromises.push(observationPromise);
            promises.push(observationPromise);
        }
        const observationKeys = ['HeartRate', 'BP', 'IVaccess', 'Spo2', 'RBS', 'Insulin', 'Temperature', 'Tidal', 'Fio2', 'PEEP', 'Airway', 'RateOfRespiration', 'PH', 'PCo2', 'PO2', 'Hco3', 'Lactate', 'Saturation', 'IntakeOutput'];
        promises.push(Promise.all(observationpromises).then(() => {
            for (const key in result) {
                if (result[key].constructor === ([]).constructor && observationKeys.includes(key) && result[key].length !== 1) {
                    result[key].shift();
                }
            }
            for (const [index, bp] of systolicArray.entries()) {
                if (!result.BP[index]) {
                    result.BP.push({
                        SystolicBP: [],
                        DiastolicBP: []
                    });
                }
                result.BP[index].SystolicBP[0] = bp;
                result.BP[index].DiastolicBP[0] = diastolicArray[index];
            }
        }));
        const medicationAdministrationPromise = getMedicationAdministration(`context=${encounterId}&subject=${patientId}`).then((output) => {
            if (output.data.entry) {
                const medicationArray = [];
                for (const medication of output.data.entry) {
                    medicationArray.push(convertFromFhir('MedicationAdministration', medication.resource).then((resolved) => result.Medication.push(resolved)));
                }
                return medicationArray;
            }
        });
        promises.push(medicationAdministrationPromise);
        return Promise.all(promises).then(() => Promise.resolve(result));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toSecondaryCareFhir = (data) => {
    try {
        const resourceIdMap = data[1];
        data = data.type === 'add' ? data : data[0];
        let apiType = data.apiType;
        switch (data.type) {
        case 'add':
            apiType = 'POST';
            break;
        case 'edit':
            apiType = 'PUT';
            break;
        }
        const promises = [];
        const BundleRequest = {};
        BundleRequest.entry = [];
        BundleRequest.type = 'transaction';
        BundleRequest.resourceType = 'Bundle';
        data.Bundle = true;
        data.questionnaireType = 'SecondaryCare';
        const clinicalImpression = {};
        clinicalImpression.date = new Date().toISOString();
        clinicalImpression.identifier = [
            {
                system: 'identifier',
                value: 'SecondaryCare'
            }
        ];
        const observations = ['peripheralLine', 'restraintAssessment', 'painAssessment', 'handwash', 'detailedPainAssessment', 'ventilatedPatient'];
        let index = 0;
        const refactorObservationPromise = (inputData) => {
            const observationPromise = convertToFhir('Observation-Component', inputData).then((output) => {
                const transformOutput = {
                    request: {
                        method: apiType,
                        url: 'Observation'
                    },
                    fullUrl: 'urn:uuid:Observation' + index.toString(),
                    resource: output
                };
                if (data.type === 'edit') {
                    if (index < resourceIdMap.observation.length) {
                        output.id = resourceIdMap.observation[index].id;
                        transformOutput.request.url = `Observation/${output.id}`;
                    } else {
                        transformOutput.request.url = 'Observation';
                        transformOutput.request.method = 'POST';
                    }
                }
                BundleRequest.entry.push(transformOutput);
                index += 1;
            // eslint-disable-next-line no-console
            }).catch((e) => console.log(e));
            promises.push(observationPromise);
        };
        // Observations
        let inputDataArray = [];
        for (const observation of observations) {
            const inputData = data[observation];
            if (inputData.constructor === ([]).constructor) {
                inputData.forEach(element => {
                    for (const key in element) {
                        if ((element[key]).constructor === ({}).constructor) {
                            element[key] = JSON.stringify(element[key]);
                        }
                        const transformData = {
                            input: {
                                data: element[key],
                                name: key
                            },
                            patient: data.Patient,
                            encounter: data.Encounter,
                            name: observation
                        };
                        inputDataArray.push(transformData);
                    }
                    refactorObservationPromise(inputDataArray);
                    inputDataArray = [];
                });
            }
            if (inputData.constructor === ({}).constructor) {
                for (const key in inputData) {
                    const transformData = {
                        input: {
                            data: inputData[key],
                            name: key
                        },
                        patient: data.Patient,
                        encounter: data.Encounter,
                        name: observation
                    };
                    inputDataArray.push(transformData);
                }
                refactorObservationPromise(inputDataArray);
                inputDataArray = [];
            }
        }
        // Clinical Impression
        clinicalImpression.resourceType = 'ClinicalImpression';
        clinicalImpression.subject = {
            type: 'Patient',
            reference: `Patient/${data.Patient}`
        };
        clinicalImpression.encounter = {
            type: 'Encounter',
            reference: `Encounter/${data.Encounter}`
        };
        if (data.type === 'edit') {
            clinicalImpression.id = resourceIdMap.clinical.id;
        }
        // ClinicalImpression.investigation
        clinicalImpression.investigation = {
            item: [
                {
                    type: 'QuestionnaireResponse',
                    reference: 'urn:uuid:questionnaireResponse'
                }
            ]
        };
        clinicalImpression.prognosisReference = [];
        let PromisesIndex = 0;
        while (PromisesIndex < (promises.length)) {
            // getting number of observations through the number of promises created.
            // doing this in a roundabout so as not to interrupt asynchronous operation.
            clinicalImpression.investigation.item.push(
                {
                    type: 'Observation',
                    reference: 'urn:uuid:Observation' + PromisesIndex
                }
            );
            PromisesIndex++;
        }
        // Questionnaire Response
        const questionnaireResponsePromise = convertToFhir('QuestionnaireResponse', data).then((output) => {
            if (data.type === 'edit') {
                output.id = resourceIdMap.ques.resourceType === output.resourceType ? resourceIdMap.ques.id : '';
            }
            const transformOutput = {
                request: {
                    method: apiType,
                    url: 'QuestionnaireResponse'
                },
                fullUrl: 'urn:uuid:questionnaireResponse',
                resource: output
            };
            if (data.type === 'edit') {
                transformOutput.request.url = `QuestionnaireResponse/${output.id}`;
            }
            index += 1;
            BundleRequest.entry.push(transformOutput);
        });
        promises.push(questionnaireResponsePromise);
        // Risk Assessment
        const riskAssessments = ['aldreteScore', 'phlebitisScore', 'mews', 'bredenRiskAssessment', 'scoreCalculation', 'dvtRisk', 'pressureUlcerAssessment'];
        for (const [index, risk] of riskAssessments.entries()) {
            const transformedData = {
                criteria: data[risk],
                Patient: data.Patient,
                Encounter: data.Encounter,
                assessment: '',
                identifier: risk
            };
            console.log(transformedData);
            const riskAssessmentPromise = convertToFhir('RiskAssessment', transformedData).then((output) => {
                // if (data.type === 'edit') {
                //     output.id = resourceIdMap.risk.resourceType === output.resourceType ? resourceIdMap.risk?.id : '';
                // }
                const transformOutput = {
                    request: {
                        method: apiType,
                        url: 'RiskAssessment'
                    },
                    fullUrl: 'urn:uuid:RiskAssessment' + index,
                    resource: output
                };
                if (data.type === 'edit') {
                    if (index < resourceIdMap.risk.length) {
                        output.id = resourceIdMap.risk[index].id;
                        transformOutput.request.url = `RiskAssessment/${output.id}`;
                    } else {
                        transformOutput.request.url = 'RiskAssessment';
                        transformOutput.request.method = 'POST';
                    }
                }
                clinicalImpression.investigation.item.push(
                    {
                        type: 'RiskAssessment',
                        reference: 'urn:uuid:RiskAssessment' + index
                    }
                );
                BundleRequest.entry.push(transformOutput);
            });
            promises.push(riskAssessmentPromise);
        }
        return Promise.all(promises).then(() => Promise.resolve(clinicalImpression).then(() => {
            let tempurl = 'ClinicalImpression';
            if (data.type === 'edit') {
                tempurl = `ClinicalImpression/${clinicalImpression.id}`;
            }
            BundleRequest.entry.push({
                request: { method: apiType, url: tempurl },
                fullUrl: 'ClinicalImpression',
                resource: clinicalImpression
            });
        }
        ).then(() => Promise.resolve(BundleRequest)));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const fromSecondaryCare = (data) => {
    // eslint-disable-next-line no-console
    // console.log('inputData', data);
    data = data.data;
    const promises = [];
    const questionnaireResponse = data.entry.find((el) => el.resource.resourceType === 'QuestionnaireResponse');
    const riskAssessment = data.entry.filter((el) => el.resource.resourceType === 'RiskAssessment');
    const observations = data.entry.filter((el) => el.resource.resourceType === 'Observation');
    const result = Object.assign({}, secondaryCareFormik);
    const clinicalImpression = data.entry.find((el) => el.resource.resourceType === 'ClinicalImpression');
    result.id = clinicalImpression.resource.id;
    result.date = clinicalImpression.resource.date;
    questionnaireResponse.questionnaireType = 'SecondaryCare';
    convertFromFhir('QuestionnaireResponse', questionnaireResponse).then((output) => {
        // Blood Transfusion
        const findAllSubResponses = (linkId) => {
            const element = output.filter((el) => linkId === el.linkId?.substring(0, linkId.length));
            return element;
        };
        const findResponse = (question, arrayPart) => {
            const element = arrayPart.find((el) => question === el.question);
            return element.answer;
        };
        // Blood Transfusion
        const bloodTransfusion = findAllSubResponses('1');
        for (const part of bloodTransfusion) {
            result.bloodTransfusion[part.question] = findResponse(part.question, bloodTransfusion)[0];
        }
        // Accessories
        const accessories = findAllSubResponses('2');
        for (const part of accessories) {
            if (part.linkId.includes('2.1.')) {
                result.accessories.etSize[part.question] = findResponse(part.question, accessories)[0];
                continue;
            }
            if (part.linkId.includes('2.2.')) {
                result.accessories.cvpLine[part.question] = findResponse(part.question, accessories)[0];
                continue;
            }
            if (part.linkId.includes('2.3.')) {
                result.accessories.arteralLine[part.question] = findResponse(part.question, accessories)[0];
                continue;
            }
            if (part.linkId.includes('2.4.')) {
                result.accessories.cxr[part.question] = findResponse(part.question, accessories)[0];
                continue;
            }
            if (part.linkId.includes('2.5.')) {
                result.accessories.ecg[part.question] = findResponse(part.question, accessories)[0];
                continue;
            }
            if (part.linkId.includes('2.6.')) {
                result.accessories.misc[part.question] = findResponse(part.question, accessories);
                continue;
            }
        }
        // Investigation Check List
        const investigationCheckList = findAllSubResponses('3');
        const lab = findAllSubResponses('3.3');
        const ecg = findAllSubResponses('3.4');
        const xray = findAllSubResponses('3.5');
        const echo = findAllSubResponses('3.6');
        const esg = findAllSubResponses('3.7');
        for (const part of investigationCheckList) {
            // result.investigationCheckList[part.question] = findResponse(part.question, investigationCheckList)[0];
            if (part.linkId === '3.1') {
                result.investigationCheckList.investigationDate = findResponse(part.question, investigationCheckList)[0] || '';
                continue;
            }
            if (part.linkId === '3.2') {
                result.investigationCheckList.investigationShift = findResponse(part.question, investigationCheckList)[0] || '';
                continue;
            }
            if (part.linkId.includes('3.3')) {
                result.investigationCheckList.lab[part.question] = findResponse(part.question, lab)[0] || '';
                continue;
            }
            if (part.linkId.includes('3.4')) {
                result.investigationCheckList.ecg[part.question] = findResponse(part.question, ecg)[0] || '';
                continue;
            }
            if (part.linkId.includes('3.5')) {
                result.investigationCheckList.xray[part.question] = findResponse(part.question, xray)[0] || '';
                continue;
            }
            if (part.linkId.includes('3.6')) {
                result.investigationCheckList.echo[part.question] = findResponse(part.question, echo)[0] || '';
                continue;
            }
            if (part.linkId.includes('3.7')) {
                result.investigationCheckList.esg[part.question] = findResponse(part.question, esg)[0] || '';
                continue;
            }
        }
        // Bio Medical Equipment
        const bioMedicalEquipment = findAllSubResponses('4');
        for (const part of bioMedicalEquipment) {
            result.bioMedicalEquipment[part.question] = findResponse(part.question, bioMedicalEquipment)[0];
        }
        // Old Report
        const oldReport = findAllSubResponses('5');
        for (const part of oldReport) {
            result.oldReport[part.question] = findResponse(part.question, oldReport)[0];
        }
    });
    // Risk Assessment
    for (const risk of riskAssessment) {
        const riskAssessmentPromise = convertFromFhir('RiskAssessment', risk).then((output) => {
            result[Object.keys(output)[0]] = Object.values(output)[0];
        }
        );
        promises.push(riskAssessmentPromise);
    }
    const observationpromises = [];
    // to remove last if condition after fixing UI.
    result.handwash = [];
    result.restraintAssessment = [];
    for (const observation of observations) {
        const observationPromise = convertFromFhir('Observation-Component', observation).then((output) => {
            // Object.keys(output) will return all keys in an array, since we only have one key, we are using 0th index
            // Same for values.
            const formikKey = Object.keys(output)[0];
            const formikValue = Object.values(output)[0];
            // console.log('formik Key', formikKey, 'Formik Value', formikValue);
            if (formikKey in result) {
                if ((result[formikKey]).constructor === ([]).constructor) {
                    result[formikKey].push(formikValue);
                    // problem --- appends with existing data when there shouldnt be any data. How is data from previous execution retained?
                    // console.log(formikKey, result[formikKey]);
                    return;
                }
                result[formikKey] = Object.values(output);
            }
        });
        observationpromises.push(observationPromise);
        promises.push(observationPromise);
    }
    const observationKeys = ['peripheralLine', 'restraintAssessment', 'painAssessment', 'handwash', 'detailedPainAssessment', 'ventilatedPatient'];
    promises.push(Promise.all(observationpromises).then(() => {
        for (const key in result) {
            // to remove last if condition after fixing UI.
            if (result[key].constructor === ([]).constructor && observationKeys.includes(key) && result[key].length !== 1 && !(['restraintAssessment', 'handwash'].includes(key))) {
                result[key].shift();
            }
        }
    }));
    // revise for keys: handwash and restrain assessment & pressureUlcer assessment
    return Promise.all(promises).then(() => {
        result.painAssessment = result.painAssessment[0];
        result.ventilatedPatient = result.ventilatedPatient[0];
        result.peripheralLine = result.peripheralLine[0];
        result.detailedPainAssessment = result.detailedPainAssessment[0];
        for (const restraintAssessment of result.restraintAssessment) {
            restraintAssessment.skinintact = restraintAssessment.skinintact || {
                skinintact: '',
                pulsepresent: '',
                foodoffered: '',
                positionchange: '',
                hygiene: '',
                privacy: '',
                restraintreleased: '',
                nurseId: '',
                restraintremark: ''
            };
        }
        // console.log('2 data', result);
        return Promise.resolve(result);
    });
};
