import React from 'react';
import status from '../.././../utils/json/GeneralCon.json';
import att from '../.././../utils/json/Attitude.json';

function Gphysical (props) {
    const change = (r) => {
        if ((r) >= 13 && (r) <= 15) {
            return ('Minor Brian Injury' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 9 && (r) <= 12) {
            return ('Moderate Brian Injury' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 3 && (r) <= 8) {
            return ('Severe Brian Injury' + ' ' + '(' + `${r}` + ')');
        }
    };
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 mt-4 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
            General Physical Examination<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>General Condition :<span className='text-[crimson]'>*</span></div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GeneralPhysicalExamination.GeneralCondition.GeneralConditionInput"
                                id="GeneralPhysicalExamination.GeneralCondition.GeneralConditionInput"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GeneralPhysicalExamination.GeneralCondition.GeneralConditionInput}>
                                {
                                    status.map((data, i) => (
                                        <option value={status[i].value} key={i}>{status[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white px-3'>
                <div className='flex items-center'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Temperature :<span className='text-[crimson]'>*</span></div>
                    <div className='flex'>
                        <label className='self-center font-"roboto" flex w-[100px] font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input required
                                className='self-center mr-[2.5px]'
                                name='GeneralPhysicalExamination.Temperature.TemperatureInput'
                                type='radio'
                                id='Afebrile'
                                onChange={props.formikState.handleChange}
                                value='Afebrile'
                                disabled={props.disabled}
                                checked={props.formikState.values.GeneralPhysicalExamination.Temperature.TemperatureInput === 'Afebrile'}
                            />Afebrile
                        </label>
                        <label className='self-center font-"roboto" flex w-[120px] font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input required
                                className='self-center mr-[2.5px]'
                                name='GeneralPhysicalExamination.Temperature.TemperatureInput'
                                id='febrile'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                value='Febrile'
                                disabled={props.disabled}
                                checked={props.formikState.values.GeneralPhysicalExamination.Temperature.TemperatureInput === 'Febrile'}
                            />Febrile
                        </label>
                    </div>
                </div>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>LevelOfConciseness Of Conciseness :<span className='text-[crimson]'>*</span></div>
                    <div className='w-[calc(100%-160px)] justify-between flex flex-col'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse"
                                id="GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse}
                            >
                                <option value="">Eye Opening Response</option>
                                <option value="4">Eye Opening Spontaneously (4)</option>
                                <option value="3">Eye Opening to Verbal Command, Speech, or Shout (3)</option>
                                <option value="2">Eye Opening to Pain (not applied to face) (2)</option>
                                <option value="1">No Eye Opening (1)</option>
                            </select>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse"
                                id="GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse}
                            >
                                <option value="">Verbal Response</option>
                                <option value="5">Oriented (5)</option>
                                <option value="4">Confused Conversation, but able to answer questions (4)</option>
                                <option value="3">Inappropriate Responses, Words Discernible (3)</option>
                                <option value="2">Incomprehensible Sounds or Speech (2)</option>
                                <option value="1">No Verbal Response (1)</option>
                            </select>
                            <select required
                                className='w-[32.5%] text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="GeneralPhysicalExamination.LevelOfConciseness.MotorResponse"
                                id="GeneralPhysicalExamination.LevelOfConciseness.MotorResponse"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.GeneralPhysicalExamination.LevelOfConciseness.MotorResponse}
                            >
                                <option value="">Motor Response</option>
                                <option value="6">Obeys Commands for Movement (6)</option>
                                <option value="5">Purposeful Movement to Painful Stimulus (5)</option>
                                <option value="4">Withdraws from Pain (4)</option>
                                <option value="3">Abnormal (Spastic) Flexion, Decorticate Posture (3)</option>
                                <option value="2">Extensor (Rigid) Response, Decerebrate Posture (2)</option>
                                <option value="1">No Motor Response (1)</option>
                            </select>
                        </div>
                        <label className='font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'><span className='italic'>Result</span> : {change(Number(props.formikState.values.GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse) + Number(props.formikState.values.GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse) + Number(props.formikState.values.GeneralPhysicalExamination.LevelOfConciseness.MotorResponse))}</label>
                    </div>
                </div>
            </div>
            <div className='bg-white px-3 py-[20px] flex w-full'>
                <div className='w-[450px]'>
                    <div className='bg-white py-[7.5px]'>
                        <div className='flex items-center'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Coherence :<span className='text-[crimson]'>*</span></div>
                            <div className='justify-around flex'>
                                <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='GeneralPhysicalExamination.Coherence.CoherenceInput'
                                        type='radio'
                                        id='Coherent'
                                        onChange={props.formikState.handleChange}
                                        value='Coherent'
                                        disabled={props.disabled}
                                        checked={props.formikState.values.GeneralPhysicalExamination.Coherence.CoherenceInput === 'Coherent'}
                                    />Coherent
                                </label>
                                <label className='self-center w-[120px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='GeneralPhysicalExamination.Coherence.CoherenceInput'
                                        id='Non Coherent'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Non Coherent'
                                        disabled={props.disabled}
                                        checked={props.formikState.values.GeneralPhysicalExamination.Coherence.CoherenceInput === 'Non Coherent'}
                                    />Non Coherent
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white py-[7.5px]'>
                        <div className='flex items-center'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Orientation :<span className='text-[crimson]'>*</span></div>
                            <div className='justify-around flex'>
                                <label className='self-center w-[100px] font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='GeneralPhysicalExamination.Orientation.OrientationInput'
                                        type='radio'
                                        id='Present'
                                        onChange={props.formikState.handleChange}
                                        value='Present'
                                        disabled={props.disabled}
                                        checked={props.formikState.values.GeneralPhysicalExamination.Orientation.OrientationInput === 'Present'}
                                    />Present
                                </label>
                                <label className='self-center w-[120px] font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='GeneralPhysicalExamination.Orientation.OrientationInput'
                                        id='Absent'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Absent'
                                        disabled={props.disabled}
                                        checked={props.formikState.values.GeneralPhysicalExamination.Orientation.OrientationInput === 'Absent'}
                                    />Absent
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white py-[7.5px]'>
                        <div className='flex items-center'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Disorientation :<span className='text-[crimson]'>*</span></div>
                            <div className='justify-around flex'>
                                <label className='self-center w-[100px] font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='GeneralPhysicalExamination.Disorientation.DisorientationInput'
                                        type='radio'
                                        id='No'
                                        onChange={props.formikState.handleChange}
                                        value='No'
                                        disabled={props.disabled}
                                        checked={props.formikState.values.GeneralPhysicalExamination.Disorientation.DisorientationInput === 'No'}
                                    />No
                                </label>
                                <label className='self-center w-[120px] font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='GeneralPhysicalExamination.Disorientation.DisorientationInput'
                                        id='Yes'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Yes'
                                        disabled={props.disabled}
                                        checked={props.formikState.values.GeneralPhysicalExamination.Disorientation.DisorientationInput === 'Yes'}
                                    />Yes
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[calc(100%-350px)]'>
                    <div className='w-full'>
                        <div className='bg-white w-full'>
                            <div className='flex w-full items-center'>
                                <div className='font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Attitude of the patient :</div>
                                <div className='w-full justify-around flex flex-col'>
                                    <select
                                        className='w-full mb-[12px] h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                        name="GeneralPhysicalExamination.Attitude.Attitude"
                                        id="GeneralPhysicalExamination.Attitude.Attitude"
                                        onChange={props.formikState.handleChange}
                                        disabled={props.disabled}
                                        value={props.formikState.values.GeneralPhysicalExamination.Attitude.Attitude}
                                    >
                                        <option value="">Select Here</option>
                                        {
                                            att.map((data, i) => (
                                                <option value={att[i].value} key={i}>{att[i].label}</option>
                                            ))
                                        }
                                    </select>
                                    <input
                                        className='h-[47px] pl-[5px] text-[15px] self-center border-[1px] text-[#8F8F8F] w-[100%] border-[#E3E3E3] text-input bg-gray outline-none'
                                        type='text'
                                        placeholder='Write here'
                                        name="GeneralPhysicalExamination.Attitude.AttitudeInput"
                                        id="GeneralPhysicalExamination.Attitude.AttitudeInput"
                                        onChange={props.formikState.handleChange}
                                        disabled={props.disabled}
                                        value={props.formikState.values.GeneralPhysicalExamination.Attitude.AttitudeInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-white px-3 py-[7.5px]'>
                <div className='flex items-center w-full h-[47px]'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Thyroid Examination :</div>
                    <div className='flex w-[calc(100%-160px)]'>
                        <div className='flex'>
                            <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input
                                    className='self-center mr-[2.5px]'
                                    name='GeneralPhysicalExamination.Thyroid.ThyroidNA'
                                    type='radio'
                                    id='Normal'
                                    onChange={props.formikState.handleChange}
                                    value='Normal'
                                    disabled={props.disabled}
                                    checked={props.formikState.values.GeneralPhysicalExamination.Thyroid.ThyroidNA === 'Normal'}
                                />Normal
                            </label>
                            <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input
                                    className='self-center mr-[2.5px]'
                                    name='GeneralPhysicalExamination.Thyroid.ThyroidNA'
                                    id='Abnormal'
                                    type='radio'
                                    onChange={props.formikState.handleChange}
                                    value='Abnormal'
                                    disabled={props.disabled}
                                    checked={props.formikState.values.GeneralPhysicalExamination.Thyroid.ThyroidNA === 'Abnormal'}
                                />Abnormal
                            </label>
                        </div>
                        {props.formikState.values.GeneralPhysicalExamination.Thyroid.ThyroidNA === 'Abnormal' &&
                        <input
                            className='h-[47px] w-[calc(100%-150px)] ml-[55px] text-[#8F8F8F] text-[15px] pl-[5px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                            id='GeneralPhysicalExamination.Thyroid.ThyroidInput'
                            name='GeneralPhysicalExamination.Thyroid.ThyroidInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.GeneralPhysicalExamination.Thyroid.ThyroidInput}
                            disabled={props.disabled}
                        />
                        }
                    </div>
                </div>
            </div>
            <div className='bg-white px-3 py-[7.5px]'>
                <div className='flex items-center w-full h-[47px]'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Breast Examination :</div>
                    <div className='flex w-[calc(100%-160px)]'>
                        <div className='flex'>
                            <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input
                                    className='self-center mr-[2.5px]'
                                    name='GeneralPhysicalExamination.Breast.BreastNA'
                                    type='radio'
                                    id='Normal'
                                    onChange={props.formikState.handleChange}
                                    value='Normal'
                                    checked={props.formikState.values.GeneralPhysicalExamination.Breast.BreastNA === 'Normal'}
                                    disabled={props.disabled}
                                />Normal
                            </label>
                            <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input
                                    className='self-center mr-[2.5px]'
                                    name='GeneralPhysicalExamination.Breast.BreastNA'
                                    id='Abnormal'
                                    type='radio'
                                    onChange={props.formikState.handleChange}
                                    value='Abnormal'
                                    checked={props.formikState.values.GeneralPhysicalExamination.Breast.BreastNA === 'Abnormal'}
                                    disabled={props.disabled}
                                />Abnormal
                            </label>
                        </div>
                        {props.formikState.values.GeneralPhysicalExamination.Breast.BreastNA === 'Abnormal' &&
                        <input
                            className='h-[47px] w-[calc(100%-150px)] ml-[55px] text-[#8F8F8F] text-[15px] pl-[5px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                            id='GeneralPhysicalExamination.Breast.BreastInput'
                            name='GeneralPhysicalExamination.Breast.BreastInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.GeneralPhysicalExamination.Breast.BreastInput}
                            disabled={props.disabled}
                        />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gphysical;
