export const fromFhir = (data) => {
    try {
        const practitioner = {};
        practitioner.id = data.id;
        const name = data.name[0];
        practitioner.givenName = name.given;
        practitioner.family = name.family;
        practitioner.birthDate = data.birthDate;
        practitioner.gender = data.gender;
        if (data.address && data.address.length > 0) {
            practitioner.addressline1 = data.address[0].line[0];
            practitioner.addressline2 = data.address[0].line[1];
            practitioner.city = data.address[0].city;
            practitioner.state = data.address[0].state;
            practitioner.postalCode = data.address[0].postalCode;
            practitioner.country = data.address[0].country;
        }
        return Promise.resolve(practitioner);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const fromFhirList = async (data) => {
    const practitioner = [];
    for (const entry of data) {
        practitioner.push(fromFhir(entry.resource));
    }
    return Promise.all(practitioner);
};
