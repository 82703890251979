import React from 'react';

const CheifComplaints = (props) => {
    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Cheif Complaints</div>
            </div>
            <div className="flex flex-col w-full pl-6 pr-3.5 pt-2">
                {/* <div className="grid grid-cols-c3 gap-[1%] items-center"> */}
                <textarea
                    id="CheifComplaints"
                    name="CheifComplaints"
                    type="text"
                    onChange={props.formikState.handleChange}
                    value={props.formikState.values.CheifComplaints}
                    className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-32 w-full px-1 text-sm text-col"
                    placeholder='Write Today Complaints here'
                    disabled={props.isDisabled}
                />
                {/* </div> */}
            </div>
        </div>

    );
};

export default CheifComplaints;
