/* eslint-disable no-console */
import React from 'react';
import Check from '../Check';

function PastHist (props) {
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
            Past Medical/ Surgical Histories<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Past Medical/ Surgical Histories :<span className='text-[crimson]'>*</span></div>
                    <div className='w-[150px] justify-around flex'>
                        <label className='self-center flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input required
                                className='self-center mr-[2.5px]'
                                name='PastMedicalSurgicalHistories.PastHistoryYesNo'
                                type='radio'
                                id='No'
                                onChange={props.formikState.handleChange}
                                value='No'
                                checked={props.formikState.values.PastMedicalSurgicalHistories.PastHistoryYesNo === 'No'}
                                disabled={props.disabled}
                            />No
                        </label>
                        <label className='self-center flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input required
                                className='self-center mr-[2.5px]'
                                name='PastMedicalSurgicalHistories.PastHistoryYesNo'
                                id='Yes'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                value='Yes'
                                checked={props.formikState.values.PastMedicalSurgicalHistories.PastHistoryYesNo === 'Yes'}
                                disabled={props.disabled}
                            />Yes
                        </label>
                    </div>
                    {props.formikState.values.PastMedicalSurgicalHistories.PastHistoryYesNo === 'Yes' &&
                <>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.HTN' val='Yes' label='HTN' name='PastMedicalSurgicalHistories.HTN' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.HTN === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.DM2' val='Yes' label='DM2' name='PastMedicalSurgicalHistories.DM2' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.DM2 === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.CAD' val='Yes' label='CAD' name='PastMedicalSurgicalHistories.CAD' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.CAD === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.ASTHMA' val='Yes' label='ASTHMA' name='PastMedicalSurgicalHistories.ASTHMA' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.ASTHMA === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.TB' val='Yes' label='TB' name='PastMedicalSurgicalHistories.TB' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.TB === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.OBESITY' val='Yes' label='OBESITY' name='PastMedicalSurgicalHistories.OBESITY' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.OBESITY === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.DYSLIPIDEMIA' val='Yes' label='DYSLIPIDEMIA' name='PastMedicalSurgicalHistories.DYSLIPIDEMIA' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.DYSLIPIDEMIA === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.SCD' val='Yes' label='SCD' name='PastMedicalSurgicalHistories.SCD' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.SCD === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.CVA' val='Yes' label='CVA(stroke)' name='PastMedicalSurgicalHistories.CVA' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.CVA === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='PastMedicalSurgicalHistories.Others' val='Yes' label='Others' name='PastMedicalSurgicalHistories.Others' checked={props.isView ? props.formikState.values.PastMedicalSurgicalHistories.Others === 'Yes' : null} disabled={props.disabled}/>
                </>
                    }
                </div>
                {props.formikState.values.PastMedicalSurgicalHistories.PastHistoryYesNo === 'Yes' &&
            <input required
                className='h-[47px] mt-[10px] pl-[10px] ml-[180px] text-[13px] self-center border-[1px] w-[calc(100%-180px)] border-[#E3E3E3] text-input required bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                id='PastMedicalSurgicalHistories.PastMedicalTestInput'
                name='PastMedicalSurgicalHistories.PastMedicalTestInput'
                type='text'
                placeholder='Write here'
                onChange={props.formikState.handleChange}
                value={props.formikState.values.PastMedicalSurgicalHistories.PastMedicalTestInput}
            />
                }
            </div>
        </div>
    );
}

export default PastHist;
