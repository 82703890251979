import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../containers/Header/Header';
import SideNavbar from '../../containers/SideNavbar/SideNavbar';
import HamburgerState from '../../components/HamburgerState';
import useAuth from '../../hooks/useAuth';
import Footer from '../../containers/Footer/Footer';

const Main = () => {
    const { isNavOpen, openClose } = HamburgerState();
    const { auth } = useAuth();

    return (
        <><div className="w-full justify-between flex flex-col">
            <Header action={openClose} />
            <div className="flex flex-row mt-[3.2rem] mb-[5rem]">
                {isNavOpen === true && auth.user.role === 'doctor' ? <div className="fixed"> <SideNavbar action={openClose} /></div> : ''}
                <div className={isNavOpen === true && auth.user.role === 'doctor' ? 'ml-[12.5rem] h-full w-full overflow-hidden' : 'w-full'}>  <Outlet /></div>
            </div>
        </div><Footer /></>
    );
};

export default Main;
