/* eslint-disable no-console */
import React, { useState } from 'react';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import DeleteModal from '../../../components/DeleteModal';
import useModal from '../../../components/useModal';

function ChiefComplaint (props) {
    const [val, setVal] = useState([]);
    const { isShowing, toggle } = useModal();
    const [deleterow, setDeleterow] = useState('');

    const handleAdd = (i) => {
        const abc = [...val, {}];
        setVal(abc);
        props.val.splice(i + 1, 0, {
            Complaint: '',
            Duration: '',
            DurationUnit: ''
        });
    };

    const handleDelete = (i) => {
        const deletVal = [...val];
        deletVal.splice(i, 1);
        setVal(deletVal);
        if (props.val.length > 1) {
            props.val.splice(i, 1);
        }
    };

    const deleteHandler = event => {
        setDeleterow(event);
        toggle();
    };
    return (
        <div>
            <div className={ props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
                Chief Complaint<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white py-4 flex justify-between items-center'>
                <div className='w-full items-center'>
                    {props.val.map((data, i) => {
                        return (
                            <div className='bg-white px-[16px] py-[10px] flex justify-between' key={i}>
                                <div className='min-w-fit font-"roboto" self-center font-[400] text-[14px] leading-[15px] text-[#757575]'>Chief Complaint:<span className='text-[crimson]'>*</span></div>
                                <textarea
                                    className='h-[47px] pl-[5px] pt-[5px] mx-[20px] text-input border-[1px] border-[#E3E3E3] bg-gray w-full outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                    type='text'
                                    id={`ChiefComplaint[${i}].Complaint`}
                                    name={`ChiefComplaint[${i}].Complaint`}
                                    onChange={props.formikState.handleChange}
                                    value={props.val[i].Complaint}
                                    required
                                    disabled={props.disabled}
                                />
                                <input
                                    className='h-[47px] mx-[20px] w-[80px] text-center text-input border-[1px] border-[#E3E3E3] bg-gray outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                    type='number'
                                    placeholder='Duration'
                                    pattern='[0-9]{1,}'
                                    id={`ChiefComplaint[${i}].Duration`}
                                    name={`ChiefComplaint[${i}].Duration`}
                                    onChange={props.formikState.handleChange}
                                    value={props.val[i].Duration}
                                    required
                                    disabled={props.disabled}
                                />
                                <select
                                    className='outline-none mx-[20px] h-[47px] text-[14px]  border-[1px] border-[#E3E3E3] bg-gray'
                                    name={`ChiefComplaint[${i}].DurationUnit`}
                                    id={`ChiefComplaint[${i}].DurationUnit`}
                                    onChange={props.formikState.handleChange}
                                    value={props.val[i]?.DurationUnit || ''}
                                    required
                                    disabled={props.disabled}
                                >
                                    <option value="">select</option>
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                    <option value="days">Days</option>
                                    <option value="weeks">Weeks</option>
                                    <option value="months">Months</option>
                                    <option value="years">Years</option>
                                </select>
                                <button type='button' className='items-center flex justify-center text-fc border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1 mr-[0.75rem]' onClick={() => handleAdd(i)}>
                                    <FaPlus />
                                </button>
                                <button type='button' className='items-center text-fc justify-center flex border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1' onClick={() => deleteHandler(i)}>
                                    <FaMinus />
                                    <DeleteModal
                                        isShowing={isShowing}
                                        hide={toggle}
                                        deleteData={handleDelete}
                                        data={deleterow}
                                        msg='Are you sure you want to delate this row?'
                                    />
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default ChiefComplaint;
