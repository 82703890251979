import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const LoginForm = (props) => {
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string('Enter your username')
                .required('Username is required'),
            password: Yup.string('Enter your password')
                .min(8, 'Invalid password')
                .required('Password is required')
        }),
        onSubmit: props.submitHandler
    });

    return (
        <form onSubmit={formik.handleSubmit} >
            <div className="flex flex-col ">
                <div >
                    <input
                        id="username"
                        name="username"
                        type="input"
                        placeholder="Username"
                        autoComplete="new-password"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        onBlur={formik.handleBlur}
                        className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-12 w-full text-sm px-1 mt-4"
                    />
                    {formik.touched.username && formik.errors.username
                        ? (
                            <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.username}</div>
                        )
                        : null}
                </div>
                <div>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password"
                        className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-12 w-full mt-4 text-sm px-1"
                    />
                    {formik.touched.password && formik.errors.password
                        ? (
                            <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.password}</div>
                        )
                        : null}
                </div>
                { (props.hasError)
                    ? <p className="text-error font-roboto mt-4 text-sm text-right">Invalid Username or Password</p>
                    : ''
                }
                <div className="flex items-center justify-center">
                    <button type="submit" className=
                        "bg-blue w-full h-12 py-2 px-4 mt-4 text-sm text-white border  border-blue"
                    >Login</button>
                </div>
                <div className="flex mt-4 items-center justify-center">
                    <a
                        href="#"
                        className={'justify-left text-[#0079BC] text-sm hover:underline px-1'}
                    >
                        Forgot Password?
                    </a>
                </div>
            </div>
        </form>

    );
};

export default LoginForm;
