/* eslint-disable no-console */
import React, { useRef } from 'react';
import { FiUpload } from 'react-icons/fi';
const MultipleFileUpload = ({ label, btnClassName, ...props }) => {
    const fileInput = useRef(null);
    const HandleFileUpload = (e) => {
        const formdata = new FormData();
        const filesArray = e.target.files;
        for (let i = 0; i < filesArray.length; i++) {
            formdata.append(filesArray[i].name, filesArray[i]);
        }
        props.fileList(formdata);
    };
    return (
        <>
            <label>{label}</label>
            <input type="file" ref={fileInput} multiple {...props} onChange={HandleFileUpload} className='hidden' />
            <button type='button' className={`px-6 py-2 ${btnClassName}`} onClick={() => fileInput.current.click()}><FiUpload /></button>
        </>
    );
};

export default MultipleFileUpload;
