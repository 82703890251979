/* eslint-disable no-console */
import React from 'react';
// import Select from 'react-select';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const BloodTransfusion = ({ formikState, onChange, onBlur, ...props }) => {
    return (
        <>
            { props.isDoctor
                ? <div className='bg-iat p-3 flex flex-row items-center justify-between'>
                    <span className='text-white text-base font-roboto '>Blood transfusion</span>
                </div>
                : <div className='bg-tgray p-3 flex flex-row items-center justify-between'>
                    <span className='text-white text-base font-roboto '>Blood transfusion</span>
                </div>}<div className="flex flex-col flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-full  px-6">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                            <RadioButton
                                label="Yes"
                                name='bloodTransfusion.bloodTransfusion'
                                id="yes"
                                onChange={onChange}
                                value='yes'
                                onBlur={onBlur}
                                disabled={props.isDisabled}
                                checked={formikState.values.bloodTransfusion.bloodTransfusion === 'yes'} />
                            <RadioButton
                                label="No"
                                name='bloodTransfusion.bloodTransfusion'
                                id="no"
                                onChange={onChange}
                                value='no'
                                onBlur={onBlur}
                                disabled={props.isDisabled}
                                checked={formikState.values.bloodTransfusion.bloodTransfusion === 'no'} />
                        </div>
                        {formikState.values.bloodTransfusion.bloodTransfusion === 'yes' &&
                        <input
                            name='bloodTransfusion.bloodTransfusionText'
                            id='bloodTransfusion.bloodTransfusionText'
                            type="text"
                            disabled={props.isDisabled}
                            onChange={onChange}
                            value={formikState.values.bloodTransfusion.bloodTransfusionText}
                            className=" bg-gray box-border border-graycol text-sm text-col focus:outline-none h-10 w-full px-1"
                        /> }
                    </div>
                </div>
                <div className="flex flex-col w-full  px-6">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Remarks if any :</label>
                        </div>
                        <input
                            id="bloodTransfusion.remarks"
                            name="bloodTransfusion.remarks"
                            type="text"
                            disabled={props.isDisabled}
                            onChange={onChange}
                            value={formikState.values.bloodTransfusion.remarks}
                            className=" bg-gray box-border border-graycol text-sm text-col focus:outline-none h-10 w-full px-1" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BloodTransfusion;
