/* eslint-disable no-console */
import React from 'react';

import Checkbox from '../../components/Check';

const AldreteScore = ({ formikState, onBlur, ...props }) => {
    // const [count, setCount] = useState(0);
    const handleChange = (e) => {
        const { checked, name, value } = e.target;
        formikState.setFieldValue(name, value);
        // if (checked) {
        //     const sum = count + 1;
        //     setCount(sum);
        //     formikState.setFieldValue(name, value);
        // } else {
        //     const sum = count - 1;
        //     setCount(sum);
        //     formikState.setFieldValue(name, value);
        // };
        if (checked) {
            formikState.setFieldValue('aldreteScore.count', formikState.values.aldreteScore.count + 1);
        } else {
            formikState.setFieldValue('aldreteScore.count', formikState.values.aldreteScore.count - 1);
        };
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Aldrete Score</span>
            </div>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">

                    <div className="flex flex-col w-full  px-6">
                        <div className="grid grid-cols-c3 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Aldrete Score :</label>
                            </div>
                            <table className="table-auto border border-slate w-[60%]">
                                <tbody>
                                    <tr className='border border-slate-300'>
                                        <td className='text-sm p-2'>Consciousness</td>
                                        <td className='text-sm p-2'>+1</td>
                                        <td><Checkbox
                                            id="aldreteScore.consciousness"
                                            name="aldreteScore.consciousness"
                                            // onChange={formikState.handleChange}
                                            onChange={handleChange}
                                            checked={formikState.values.aldreteScore.consciousness === 'consciousness' ? true : null}
                                            value='consciousness'
                                            onBlur={onBlur}
                                            disabled={props.isDisabled}
                                        /></td>
                                    </tr>
                                    <tr className='border border-slate-300'>
                                        <td className='text-sm p-2 '>Activity</td>
                                        <td className='text-sm p-2'>+1</td>
                                        <td><Checkbox
                                            id="aldreteScore.activity"
                                            name="aldreteScore.activity"
                                            // onChange={formikState.handleChange}
                                            onChange={handleChange}
                                            checked={formikState.values.aldreteScore.activity === 'activity' ? true : null}
                                            value='activity'
                                            onBlur={onBlur}
                                            disabled={props.isDisabled}
                                        /></td>
                                    </tr>
                                    <tr className='border border-slate-300'>
                                        <td className='text-sm p-2'>Circulation</td>
                                        <td className='text-sm p-2'>+1</td>
                                        <td><Checkbox
                                            id="aldreteScore.circulation"
                                            name="aldreteScore.circulation"
                                            // onChange={formikState.handleChange}
                                            onChange={handleChange}
                                            checked={formikState.values.aldreteScore.circulation === 'circulation' ? true : null}
                                            value='circulation'
                                            onBlur={onBlur}
                                            disabled={props.isDisabled}
                                        /></td>
                                    </tr>
                                    <tr className='border border-slate-300'>
                                        <td className='text-sm p-2'>Breathing</td>
                                        <td className='text-sm p-2'>+1</td>
                                        <td><Checkbox
                                            id="aldreteScore.breathing"
                                            name="aldreteScore.breathing"
                                            // onChange={formikState.handleChange}
                                            onChange={handleChange}
                                            checked={formikState.values.aldreteScore.breathing === 'breathing' ? true : null}
                                            value='breathing'
                                            onBlur={onBlur}
                                            disabled={props.isDisabled}
                                        /></td>
                                    </tr>
                                    <tr className='border border-slate-300'>
                                        <td className='text-sm p-2'>Colour</td>
                                        <td className='text-sm p-2'>+1</td>
                                        <td><Checkbox
                                            id="aldreteScore.colour"
                                            name="aldreteScore.colour"
                                            // onChange={formikState.handleChange}
                                            onChange={handleChange}
                                            checked={formikState.values.aldreteScore.colour === 'colour' ? true : null}
                                            value='colour'
                                            onBlur={onBlur}
                                            disabled={props.isDisabled}
                                        /></td>
                                    </tr>
                                    <tr className='border border-slate-300'>
                                        <td className='text-sm p-2'></td>
                                        <td className='text-sm p-2 text-col'>Total Score: {formikState.values.aldreteScore.count}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AldreteScore;
