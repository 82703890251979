import { fromFhirList } from '../Practitioner/index.js';

export const fromPractitionerRoleFhirList = async (data) => {
    const response = [];
    let practitionerList = data.filter((entry) => entry.resource.resourceType === 'Practitioner');
    practitionerList = await fromFhirList(practitionerList);
    const practitionerRole = data.filter((entry) => entry.resource.resourceType === 'PractitionerRole');
    for (let entry of practitionerRole) {
        entry = entry.resource;
        const practitionerId = entry.practitioner.reference.split('/')[1];
        const practitioner = practitionerList.find((prac) => prac.id === practitionerId);
        practitioner.role = entry.code[0].coding[0].code;
        response.push(practitioner);
    }
    return Promise.resolve(response);
};
