import React, { useState } from 'react';
import ProgressNav from './ProgressNav';
import PrimaryCare from '../NurseFunctions/PrimaryCare';

const ProgressPrimaryCare = () => {
    const [primary] = useState([]);
    return (
        <div className='h-screen'>
            <ProgressNav />
            {primary ? <div className='flex items-center'><span className="text-2xl pt-4 font-bold font-montserrat mx-auto pb-2 text-black ">No Data Available</span></div> : <PrimaryCare isDoctor={true}/>}
        </div>
    );
};

export default ProgressPrimaryCare;
