/* eslint-disable no-console */
export const toFhir = (obj) => {
    // eslint-disable-next-line no-console
    console.log('medicationadministrationobj', obj);
    const medicationAdmin = {
        resourceType: 'MedicationAdministration',
        status: obj.Status,
        subject: {
            reference: `Patient/${obj.patient}`
        },
        context: {
            reference: `Encounter/${obj.encounter}`
        },
        medicationCodeableConcept: {
            text: obj.Medication
        },
        effectivePeriod:
        {
            start: obj.StartFormat,
            end: obj.EndFormat
        },
        note:
        {
            authorString: 'Food.Days',
            text: obj.FoodRestriction + '.' + obj.Days
        },
        dosage:
        {
            route: {
                coding: [
                    {
                        system: 'http://snomed.info',
                        code: 26643006,
                        display: obj.Route
                    }
                ]
            },
            method: {
                coding: [
                    {
                        system: 'http://snomed.info/id/',
                        code: 420360002,
                        display: obj.Regimen
                    }
                ]
            },
            dose: {
                value: obj.Dosage,
                unit: obj.Unit
            },
            text: obj.subRow?.[0].status
        }
    };
    // if (obj.subRow) {
    //     for (const value of obj.subRow) {
    //         medicationAdmin.dosage.text = value.status;
    //     }
    //     // eslint-disable-next-line no-console
    //     console.log('med', medicationAdmin.dosage.text);
    // }
    if (obj.id) {
        medicationAdmin.id = obj.id;
    }
    return Promise.resolve(medicationAdmin);
};

export const fromFhir = (data) => {
    // eslint-disable-next-line no-console
    console.log('medicationadministrationData', data?.dosage);
    // eslint-disable-next-line no-console
    const obj = {};
    obj.id = data?.id;
    obj.Status = data?.status;
    obj.Patient = data?.subject?.reference.split('/')[1];
    const note = data.note?.[0]?.text.split('.');
    obj.Encounter = data?.context?.reference?.split('/')[1];
    obj.Route = data?.dosage?.route?.coding[0]?.display;
    obj.Regimen = data?.dosage?.method?.coding[0]?.display;
    obj.dosageRange = data?.dosage?.dose?.value + ' ' + data?.dosage?.dose?.unit;
    obj.Dosage = data?.dosage?.dose?.value;
    obj.Unit = data?.dosage?.dose?.unit;
    obj.Days = note[1];
    obj.Medication = data?.medicationCodeableConcept?.text;
    obj.StartFormat = data?.effectivePeriod?.start;
    obj.EndFormat = data?.effectivePeriod?.end;
    obj.FoodRestriction = note[0];
    obj.StartDate = obj.StartFormat;
    obj.End = obj.EndFormat;
    // obj.ShiftStatus = [];
    // obj.ShiftStatus.push(data?.dosage?.text);
    obj.ShiftStatus = data?.dosage?.text;
    return Promise.resolve(obj);
};
