import React from 'react';
import InitialProgress from '../../containers/InitialAssessment/InitialProgress';

const NurseAssessment = () => {
    return (
        <InitialProgress isNurse = {true} />
    );
};

export default NurseAssessment;
