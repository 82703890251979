import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { RequireAuth } from '../components/RequireAuth';
import Main from '../modules/protected/Main';
import Login from '../modules/public/Login';
import DoctorRoutes from '../routes/DoctorRoutes';
import NurseRoutes from '../routes/NurseRoutes';
import PharmacyRoutes from '../routes/PharmacyRoutes';
import LabRoutes from '../routes/LabRoutes';
import AdminRoutes from '../routes/AdminRoutes';

const Index = () => {
    return (
        <Router>
            <Routes>
                {/* public routes */}
                <Route path="/login" element={<Login />} />

                {/* private routes */}
                <Route element={<RequireAuth />} >
                    <Route path="/" element={<Main />} >
                        {DoctorRoutes}
                        {NurseRoutes}
                        {PharmacyRoutes}
                        {LabRoutes}
                        {AdminRoutes}
                    </Route>
                </Route>
            </Routes>
        </Router>
    );
};

export default Index;
