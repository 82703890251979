/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import '../../assets/css/Style.css';
import { patientCustomStyles } from '../../style';
import { getEncounter, deleteEncounter } from '../../api/encounter';
import { convertFromFhirList } from '../../fhir-evolver';
import AddPatient from '../../assets/images/addpatient.svg';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';
import View from '../../assets/images/view.svg';
import PatientAd from '../../assets/images/patientadmission.svg';
import DeleteModal from '../../components/DeleteModal';
import useModal from '../../components/useModal';
import Loader from '../../components/Loader';
import { useSnackbar } from 'react-simple-snackbar';
import { SnackbarStyles } from '../../components/SnackbarStyles';
import { FaArrowRight } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const PatientAdmissionList = () => {
    const [spinner, setSpinner] = useState(false);
    const [openSnackbar] = useSnackbar(SnackbarStyles);
    const [deleterow, setDeleterow] = useState('');
    const [encounterlist, setEncounterList] = useState('');
    const [encounterlist1, setEncounterList1] = useState('');
    const [uhid, setUhid] = useState('');
    const [ipid, setIpid] = useState('');

    useEffect(() => {
        setSpinner(true);
        getEncounter('_include=Encounter:*&_pretty=true').then((response) => {
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                console.log('admission', res);
                const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setEncounterList(data);
                setEncounterList1(data);
                setSpinner(false);
            }).catch(e => {
                return e;
            });
        }).catch((e) => {
            return e;
        });
    }, []);

    const { isShowing, toggle } = useModal();

    const deleteData = (id) => {
        const datalist = [...encounterlist];
        const selected = encounterlist.find((results) =>
            results.id === id
        );
        datalist.splice(encounterlist.indexOf(selected), 1);
        deleteEncounter(id)
            .then((response) => {
                setTimeout(() => {
                    setSpinner(true);
                    getEncounter('_include=Encounter:*&_pretty=true').then((response) => {
                        convertFromFhirList('Encounter', response.data.entry).then((res) => {
                            setEncounterList(res);
                            setSpinner(false);
                            openSnackbar('Encounter Deleted Successfully');
                            window.location.reload();
                        });
                    }).catch((e) => {
                        return e;
                    });
                }, 1000);
            }).catch((e) => {
                return e;
            });
    };

    const getResult = () => {
        if (uhid.length === 8) {
            uhidFilter(uhid);
        } else if (ipid.length <= 12) {
            ipidFilter(ipid);
        }
    };

    const uhidFilter = (e) => {
        getEncounter(`_include=*&_count=100&subject:Patient.identifier=${e}`).then((response) => {
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setEncounterList1(data);
                setSpinner(false);
            });
        }).catch((e) => {
            return e;
        });
    };

    const ipidFilter = (e) => {
        getEncounter(`_include=*&_count=100&identifier=${e}`).then((response) => {
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setEncounterList1(data);
                setSpinner(false);
            });
        }).catch((e) => {
            return e;
        });
    };

    const deleteHandler = event => {
        setDeleterow(event);
        toggle();
    };

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.patient.uhid + ' / ' + row.inPatientId,
            sortable: true
        },
        {
            name: 'Patient',
            selector: (row) => row.patient.firstName[0].toUpperCase() + ' ' + row.patient.surname.toUpperCase(),
            sortable: true
        },
        {
            name: 'Doctor',
            selector: (row) => row.participant[0]?.givenName?.[0].toUpperCase() + ' ' + row.participant[0]?.family?.toUpperCase(),
            sortable: true
        },
        {
            name: 'Category',
            selector: (row) => row.category,
            sortable: true
        },
        {
            name: 'Date of Admission',
            selector: (row) => new Date(row.admissionDate).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'

            }) + ' ' + row.admissionTime.substring(0, 5),
            sortable: true
        },
        {
            name: 'Actions',
            selector: (row, index) => (
                <div>
                    <Link to={`/patient/admission/view/${row.id}`} state={row}>
                        <button className='px-1' id={`view${index}`}>
                            <img className='h-3 w-4' src={View} alt='view' />
                            <Tooltip place="bottom" type="success" anchorId={`view${index}`} effect="solid" content="View"/>
                        </button>
                    </Link>
                    <Link to={`/patient/admission/edit/${row.id}`} state={row}>
                        <button className='px-1' id={`edit${index}`}>
                            <img className='h-3 w-4' src={Edit} alt='edit' />
                            <Tooltip place="bottom" type="success" anchorId={`edit${index}`} effect="solid" content="Edit"/>
                        </button>
                    </Link>
                    <button onClick={() => deleteHandler(row.id)} id={`delete${index}`}>
                        <img className='h-3 w-4' src={Delete} alt='delete' />
                        <Tooltip place="bottom" type="success" anchorId={`delete${index}`} effect="solid" content="Delete"/>
                        <DeleteModal
                            isShowing={isShowing}
                            hide={toggle}
                            deleteData={deleteData}
                            data={deleterow}
                            msg = 'Are you sure you want to delete the encounter?'
                        />
                    </button>
                    <Link to={`/patient/assessment/${row.id}`} state={row}>
                        <button className="px-1" id={`initial${index}`}>
                            <FaArrowRight />
                            <Tooltip place="bottom" type="success" anchorId={`initial${index}`} effect="solid" content="Initial Assessment"/>
                        </button>
                    </Link>
                </div>
            )
        }
    ];
    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className='bg-background h-full w-full'>
                <div className='flex flex-row justify-between flex-wrap px-4'>
                    <div className='flex items-center'>
                        <Link to={'/home'}>
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base px-2 py-2">Admissions</span>
                    </div>
                    <div className='flex flex-row'>
                        <div className='flex flex-col p-2'>
                            <Link to={'/patient/register'} state={{ isAdmission: true }}>
                                <button>
                                    <img src={AddPatient} className='w-64 pt-4' alt='patient' />
                                </button>
                            </Link>
                        </div>

                        <div className='flex flex-col p-2'>
                            <Link to={'/patient/check-patient'}>
                                <button>
                                    <img src={PatientAd} className='w-64 pt-4' alt='add patient' />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between px-4">
                    <span className="text-3xl font-bold font-mont px-3 py-5 text-black">
                        Admissions
                    </span>
                    <div className='flex items-center flex-wrap'>
                        {/* <div className='pl-2 pt-2'>
                            <select className='w-[180px] h-[44px] text-left outline-none bg-white px-2 text-[#8F8F8F]'>
                                <option>Status</option>
                                <option>Admitted</option>
                                <option>Discharged</option>
                            </select>
                        </div> */}
                        <div className='pl-2 pt-2'>
                            <input className='h-[44px] text-left outline-none px-2'
                                placeholder='IPID'
                                maxLength='12'
                                onChange={(e) => {
                                    setIpid(e.target.value);
                                    setEncounterList1(encounterlist);
                                }}
                            />
                        </div>
                        <div className='pl-2 pt-2'>
                            <input className='h-[44px] text-left outline-none px-2'
                                placeholder='UHID'
                                maxLength='8'
                                onChange={(e) => {
                                    setUhid(e.target.value);
                                    setEncounterList1(encounterlist);
                                }}
                            />
                        </div>
                        <div className='flex pt-2'>
                            <button type='button' className='px-3 py-3 ml-2 bg-bgrad' onClick={getResult}>
                                <img src={Search} alt='search' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between'>
                    <Table rows={encounterlist1} columns={columns} customStyles={patientCustomStyles} />
                </div>
            </div>
        </div>
    );
};

export default PatientAdmissionList;
