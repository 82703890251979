import React from 'react';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const PressureUlcerAssessment = ({ formikState, onBlur, onChange, ...props }) => {
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Pressure Ulcer Assessment</span>
            </div>
            <div className='flex flex-col flex-wrap w-full px-6'>
                <table className="table-auto border border-slate-300 w-full">
                    <thead>
                        <tr>
                            <th className='border border-slate-300 text-left p-2'>Shift</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>1&deg;</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>2&deg;</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>3&deg;</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>4&deg;</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='border border-slate-300'>
                            <td className='text-sm p-2 border border-slate-300 text-col'>Morning</td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="1"
                                name="pressureUlcerAssessment.morning.morning"
                                onChange={onChange}
                                value='1'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.morning.morning === '1'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="2"
                                name="pressureUlcerAssessment.morning.morning"
                                onChange={onChange}
                                value='2'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.morning.morning === '2'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="3"
                                name="pressureUlcerAssessment.morning.morning"
                                onChange={onChange}
                                value='3'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.morning.morning === '3'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="4"
                                name="pressureUlcerAssessment.morning.morning"
                                onChange={onChange}
                                value='4'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.morning.morning === '4'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'>  <input
                                id="pressureUlcerAssessment.morning.morningremark"
                                name="pressureUlcerAssessment.morning.morningremark"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.pressureUlcerAssessment.morning.morningremark}
                                className="focus:outline-none border-none px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            /></td>
                        </tr>
                        <tr className='border border-slate-300'>
                            <td className='text-sm border border-slate-300 p-2 text-col'>Afternoon</td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="1"
                                name="pressureUlcerAssessment.afternoon.afternoon"
                                onChange={onChange}
                                value='1'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.afternoon.afternoon === '1'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="2"
                                name="pressureUlcerAssessment.afternoon.afternoon"
                                onChange={onChange}
                                value='2'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.afternoon.afternoon === '2'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="3"
                                name="pressureUlcerAssessment.afternoon.afternoon"
                                onChange={onChange}
                                value='3'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.afternoon.afternoon === '3'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="4"
                                name="pressureUlcerAssessment.afternoon.afternoon"
                                onChange={onChange}
                                value='4'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.afternoon.afternoon === '4'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'>  <input
                                id="pressureUlcerAssessment.afternoon.afternoonremark"
                                name="pressureUlcerAssessment.afternoon.afternoonremark"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.pressureUlcerAssessment.afternoon.afternoonremark}
                                className="focus:outline-none border-none px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            /></td>
                        </tr>
                        <tr className='border border-slate-300'>
                            <td className='text-sm border border-slate-300 p-2 text-col'>Night</td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="1"
                                name="pressureUlcerAssessment.night.night"
                                onChange={onChange}
                                value='1'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.night.night === '1'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="2"
                                name="pressureUlcerAssessment.night.night"
                                onChange={onChange}
                                value='2'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.night.night === '2'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="3"
                                name="pressureUlcerAssessment.night.night"
                                onChange={onChange}
                                value='3'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.night.night === '3'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'> <RadioButton
                                id="4"
                                name="pressureUlcerAssessment.night.night"
                                onChange={onChange}
                                value='4'
                                onBlur={onBlur}
                                checked={formikState.values.pressureUlcerAssessment.night.night === '4'}
                                disabled={props.isDisabled}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'>  <input
                                id="pressureUlcerAssessment.night.nightremark"
                                name="pressureUlcerAssessment.night.nightremark"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.pressureUlcerAssessment.night.nightremark}
                                className="focus:outline-none border-none px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default PressureUlcerAssessment;
