/* eslint-disable no-console */
import React from 'react';
import Title from '../Title';
import rtype from '../.././../utils/json/RespiratoryType.json';
import cardiac from '../.././../utils/json/Cardiac.json';
import chest from '../.././../utils/json/Chest.json';
import { RiFahrenheitLine } from '@react-icons/all-files/ri/RiFahrenheitLine';
import { RiCelsiusLine } from '@react-icons/all-files/ri/RiCelsiusLine';

function Viralpm (props) {
    const dtype = [
        {
            label: 'Room air',
            value: 'Room air'
        },
        {
            label: 'Nasal O2 inhalation',
            value: 'Nasal O2 inhalation'
        },
        {
            label: 'NIV',
            value: 'NIV'
        },
        {
            label: 'Ventilation',
            value: 'Ventilation'
        },
        {
            label: 'Others',
            value: 'Others'
        }
    ];

    const fahrenheitToCelsius = (e) => {
        const fahrenheit = e.target.value;
        const celcius = (fahrenheit - 32) / 1.8;
        props.formikState.handleChange(e);
        props.formikState.values.VitalParameters.Circulation.Temperature.tempCelsius = celcius.toFixed(1);
    };

    const CelsiusTofahrenheit = (e) => {
        const celsius = e.target.value;
        const fahrenheit = (celsius * 1.8) + 32;
        props.formikState.handleChange(e);
        props.formikState.values.VitalParameters.Circulation.Temperature.tempFahrenheit = fahrenheit.toFixed(1);
    };
    return (
        <div>
            <Title title={'Vital Parameters'} isNurse = {props.isNurse}/>
            <div className='bg-white px-3 py-[20px]'>
                <div className='font-"roboto" font-[600] mb-[10px] text-[14px] leading-[15px] text-[#000000]'>Primary Assessment</div>
                <div>
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Airway<span className='text-[crimson]'>*</span></label>
                    <div className='flex items-center h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Airway Patency </div>
                        <div className='flex w-full'>
                            <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center mr-[2.5px]'
                                    name='VitalParameters.Airway.AirwayPatentorNot'
                                    type='radio'
                                    id='Patent'
                                    onChange={props.formikState.handleChange}
                                    value='Patent'
                                    checked={props.formikState.values.VitalParameters.Airway.AirwayPatentorNot === 'Patent'}
                                    disabled={props.disabled}
                                />Patent
                            </label>
                            <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center mr-[2.5px]'
                                    name='VitalParameters.Airway.AirwayPatentorNot'
                                    id='Not Patent'
                                    type='radio'
                                    onChange={props.formikState.handleChange}
                                    value='Not Patent'
                                    checked={props.formikState.values.VitalParameters.Airway.AirwayPatentorNot === 'Not Patent'}
                                    disabled={props.disabled}
                                />Not Patent
                            </label>
                            {props.formikState.values.VitalParameters.Airway.AirwayPatentorNot === 'Not Patent' &&
                                <input required
                                    className='h-[47px] ml-[45px] text-[15px] w-full text-[#8F8F8F] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Airway.AirwayInput'
                                    name='VitalParameters.Airway.AirwayInput'
                                    type='text'
                                    placeholder='Write here'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.VitalParameters.Airway.AirwayInput}
                                    disabled={props.disabled}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Breathing<span className='text-[crimson]'>*</span></label>
                    <div className='flex items-center h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Respiratory Rate </div>
                        <div className='flex'>
                            <label className='self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center peer'
                                    name='VitalParameters.Breathing.rate.checked'
                                    type='radio'
                                    id='rate'
                                    onChange={props.formikState.handleChange}
                                    value='Patient Input'
                                    checked={props.formikState.values.VitalParameters.Breathing.rate.checked === 'Patient Input'}
                                    disabled={props.disabled}
                                />
                                <input required
                                    className='h-[47px] w-[112px] mx-[5px] text-[#8F8F8F] text-[15px] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Breathing.rate.RateInput'
                                    name='VitalParameters.Breathing.rate.RateInput'
                                    type='text'
                                    pattern='[0-9]{1,}'
                                    placeholder='Write No'
                                    onChange={props.formikState.handleChange}
                                    disabled={props.formikState.values.VitalParameters.Breathing.rate.checked === 'Not Palpable (NP)'}
                                    value={props.formikState.values.VitalParameters.Breathing.rate.RateInput}
                                /> /min
                            </label>
                            <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center mr-[2.5px]'
                                    name='VitalParameters.Breathing.rate.checked'
                                    id='Not Palpable (NP)'
                                    type='radio'
                                    onChange={props.formikState.handleChange}
                                    value='Not Palpable (NP)'
                                    checked={props.formikState.values.VitalParameters.Breathing.rate.checked === 'Not Palpable (NP)'}
                                    disabled={props.disabled}
                                />Not Palpable (NP)
                            </label>
                        </div>
                    </div>
                    <div className='w-full mt-[20px]'>
                        <div className='w-full'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Respiration Type </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-row gap-x-4'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Breathing.type.TypeDropdown"
                                            id="VitalParameters.Breathing.type.TypeDropdown"
                                            onChange={props.formikState.handleChange}
                                            disabled={props.disabled}
                                            value={props.formikState.values.VitalParameters.Breathing.type.TypeDropdown}
                                        >
                                            <option value="">Select Here</option>
                                            {
                                                rtype.map((data, i) => (
                                                    <option value={rtype[i].value} key={i}>{rtype[i].label}</option>
                                                ))
                                            }
                                        </select>
                                        <input
                                            className='h-[47px] pl-[5px] text-[#8F8F8F] text-[15px] self-center border-[1px] w-[100%] border-[#E3E3E3] text-input required bg-gray outline-none'
                                            type='text'
                                            placeholder='Write here'
                                            name="VitalParameters.Breathing.type.TypeInput"
                                            id="VitalParameters.Breathing.type.TypeInput"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Breathing.type.TypeInput}
                                            disabled={props.disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center mt-[20px] h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Spo2 % On Room Air </div>
                        <div className='flex items-center'>
                            <label className='self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='h-[47px] w-[112px] text-[15px] text-[#8F8F8F] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Breathing.spo2.spo2Input'
                                    name='VitalParameters.Breathing.spo2.spo2Input'
                                    type='text'
                                    pattern='[0-9]{1,}'
                                    placeholder='Write No'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.VitalParameters.Breathing.spo2.spo2Input}
                                    disabled={props.disabled}
                                /> %[0 -100%]
                            </label>
                            <select required
                                className='ml-[30px] h-[47px] w-[200px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                name="VitalParameters.Breathing.spo2.spo2DropDown"
                                id="VitalParameters.Breathing.spo2.spo2DropDown"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.VitalParameters.Breathing.spo2.spo2DropDown}
                                disabled={props.disabled}
                            >
                                <option value="">Select Here</option>
                                {
                                    dtype.map((data, i) => (
                                        <option value={dtype[i].value} key={i}>{dtype[i].label}</option>
                                    ))
                                }
                            </select>
                            {props.formikState.values.VitalParameters.Breathing.spo2.spo2DropDown === 'Others' &&
                                <input required
                                    className='h-[47px] ml-[45px] text-[15px] text-[#8F8F8F] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Breathing.spo2.Other'
                                    name='VitalParameters.Breathing.spo2.Other'
                                    type='text'
                                    placeholder='Write here'
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.VitalParameters.Breathing.spo2.Other}
                                    disabled={props.disabled}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div>
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Circulation<span className='text-[crimson]'>*</span></label>
                    <div className='flex items-center h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>HR/Pulse Rate </div>
                        <div className='flex'>
                            <label className='self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center peer'
                                    name='VitalParameters.Circulation.HRPulse.checked'
                                    type='radio'
                                    id='rate1'
                                    onChange={props.formikState.handleChange}
                                    value='Patient Input'
                                    checked={props.formikState.values.VitalParameters.Circulation.HRPulse.checked === 'Patient Input'}
                                    disabled={props.disabled}
                                />
                                <input required
                                    className='h-[47px] w-[112px] mx-[5px] text-[#8F8F8F] text-[15px] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Circulation.HRPulse.HRPulseInput'
                                    name='VitalParameters.Circulation.HRPulse.HRPulseInput'
                                    type='text'
                                    pattern='[0-9]{1,}'
                                    placeholder='Write No'
                                    onChange={props.formikState.handleChange}
                                    disabled={props.formikState.values.VitalParameters.Circulation.HRPulse.checked === 'absent'}
                                    value={props.formikState.values.VitalParameters.Circulation.HRPulse.HRPulseInput}
                                /> /BPM
                            </label>
                            <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center mr-[2.5px]'
                                    name='VitalParameters.Circulation.HRPulse.checked'
                                    id='absent'
                                    type='radio'
                                    onChange={props.formikState.handleChange}
                                    value='absent'
                                    checked={props.formikState.values.VitalParameters.Circulation.HRPulse.checked === 'absent'}
                                    disabled={props.disabled}
                                />Absent
                            </label>
                        </div>
                    </div>
                    <div className='w-full my-[20px]'>
                        <div className='w-full'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Pulse Rhythm </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Circulation.PulseRhythm"
                                            id="VitalParameters.Circulation.PulseRhythm"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Circulation.PulseRhythm}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Regular">Regular</option>
                                            <option value="Irregular">Irregular</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='flex items-center h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] mr-4'>Systolic Blood Pressure </div>
                        <div className='flex items-center'>
                            <label className='self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='h-[47px] w-[112px] mx-[5px] text-[#8F8F8F] text-[15px] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Circulation.SBp.SBpInput'
                                    name='VitalParameters.Circulation.SBp.SBpInput'
                                    type='text'
                                    pattern='[0-9]{1,}'
                                    placeholder='Write No'
                                    onChange={props.formikState.handleChange}
                                    disabled={props.formikState.values.VitalParameters.Circulation.SBp.SBpInput === 'Not Recordable(NR)'}
                                    value={props.formikState.values.VitalParameters.Circulation.SBp.SBpInput}
                                /> mmHg
                            </label>
                        </div>
                    </div> */}
                    <div className='flex items-center pt-4 h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] mr-4'>SBP / DBP</div>
                        {/* <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] mr-4'>Diastolic Blood Pressure</div> */}
                        <div className='flex items-center'>
                            <label className='self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                {/* <input required
                                    className='self-center peer'
                                    name='VitalParameters.Circulation.DBp.checked'
                                    type='radio'
                                    id='DBp'
                                    onChange={props.formikState.handleChange}
                                    value='Patient Input'
                                    checked={props.formikState.values.VitalParameters.Circulation.DBp.checked === 'Patient Input'}
                                    disabled={props.disabled}
                                /> */}
                                <input required
                                    className='h-[47px] w-[112px] mx-[5px] text-[#8F8F8F] text-[15px] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Circulation.SBp.SBpInput'
                                    name='VitalParameters.Circulation.SBp.SBpInput'
                                    type='text'
                                    pattern='[0-9]{1,}'
                                    placeholder='SBP'
                                    onChange={props.formikState.handleChange}
                                    disabled={props.formikState.values.VitalParameters.Circulation.SBp.SBpInput === 'Not Recordable(NR)'}
                                    value={props.formikState.values.VitalParameters.Circulation.SBp.SBpInput}
                                />
                                <input required
                                    className='h-[47px] w-[112px] mx-[5px] text-[#8F8F8F] text-[15px] placeholdertext-center text-center self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                    id='VitalParameters.Circulation.DBp.DBpInput'
                                    name='VitalParameters.Circulation.DBp.DBpInput'
                                    type='text'
                                    pattern='[0-9]{1,}'
                                    placeholder='DBP'
                                    onChange={props.formikState.handleChange}
                                    disabled={props.formikState.values.VitalParameters.Circulation.DBp.DBpInput === 'Not Recordable(NR)'}
                                    value={props.formikState.values.VitalParameters.Circulation.DBp.DBpInput}
                                /> mmHg
                            </label>
                            <select required
                                className='ml-[30px] h-[47px] w-[200px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                name="VitalParameters.Circulation.DBp.Position"
                                id="VitalParameters.Circulation.DBp.Position"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.VitalParameters.Circulation.DBp.Position}
                                disabled={props.disabled}
                            >
                                <option value="">Select Here</option>
                                <option value="Supine position">Supine position</option>
                                <option value="Sitting position">Sitting position</option>
                            </select>
                            <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input required
                                    className='self-center mr-[2.5px]'
                                    name='VitalParameters.Circulation.DBp.checked'
                                    id='Not Recordable(NR)'
                                    type='radio'
                                    onChange={props.formikState.handleChange}
                                    value='Not Recordable(NR)'
                                    checked={props.formikState.values.VitalParameters.Circulation.DBp.checked === 'Not Recordable(NR)'}
                                    disabled={props.disabled}
                                />Not Recordable(NR)
                            </label>
                        </div>
                    </div>
                    <div className='w-full mt-[20px]'>
                        <div className='flex mb-[12px] items-center w-full h-[47px]'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Temperature </div>
                            <div className='flex w-[calc(100%-160px)] gap-x-7'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Circulation.Temperature.Temperature'
                                            type='radio'
                                            id='Afebrile'
                                            onChange={props.formikState.handleChange}
                                            value='Afebrile'
                                            checked={props.formikState.values.VitalParameters.Circulation.Temperature.Temperature === 'Afebrile'}
                                            disabled={props.disabled}
                                        />Afebrile
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Circulation.Temperature.Temperature'
                                            id='febrile'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='febrile'
                                            checked={props.formikState.values.VitalParameters.Circulation.Temperature.Temperature === 'febrile'}
                                            disabled={props.disabled}
                                        />Febrile
                                    </label>
                                </div>
                                {props.formikState.values.VitalParameters.Circulation.Temperature.Temperature === 'febrile' &&
                                    <><div className='w-[350px] flex justify-around items-center'>
                                        <div className='w-[45%] justify-end flex items-center'>
                                            <input
                                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                type='number'
                                                id='VitalParameters.Circulation.Temperature.tempFahrenheit'
                                                name='VitalParameters.Circulation.Temperature.tempFahrenheit'
                                                onChange={(e) => fahrenheitToCelsius(e)}
                                                disabled={props.disabled}
                                                value={props.formikState.values.VitalParameters.Circulation.Temperature.tempFahrenheit} />
                                            <div className='mr-[5px] absolute font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] px-2'><RiFahrenheitLine /></div>
                                        </div>
                                        <div className='w-[45%] justify-end flex items-center'>
                                            <input
                                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                type='number'
                                                id='VitalParameters.Circulation.Temperature.tempCelsius'
                                                name='VitalParameters.Circulation.Temperature.tempCelsius'
                                                onChange={(e) => CelsiusTofahrenheit(e)}
                                                disabled={props.disabled}
                                                value={props.formikState.values.VitalParameters.Circulation.Temperature.tempCelsius} />
                                            <div className='mr-[5px] absolute font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] px-2'><RiCelsiusLine /></div>
                                        </div>
                                    </div><input required
                                        className='h-[47px] w-[calc(100%-150px)] text-[#8F8F8F] text-[15px] pl-[5px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                        id='VitalParameters.Circulation.Temperature.TemperatureInput'
                                        name='VitalParameters.Circulation.Temperature.TemperatureInput'
                                        type='text'
                                        placeholder='Write here'
                                        disabled={props.disabled}
                                        onChange={props.formikState.handleChange}
                                        value={props.formikState.values.VitalParameters.Circulation.Temperature.TemperatureInput} /></>
                                }
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='flex h-[47px] items-center w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Cardiac Monitor Reading </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Circulation.Cardiac"
                                            id="VitalParameters.Circulation.Cardiac"
                                            onChange={props.formikState.handleChange}
                                            disabled={props.disabled}
                                            value={props.formikState.values.VitalParameters.Circulation.Cardiac}
                                        >
                                            <option value="">Select Here</option>
                                            {
                                                cardiac.map((data, i) => (
                                                    <option value={cardiac[i].value} key={i}>{cardiac[i].label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-[30px]">
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Secondary Assessment<span className='text-[crimson]'>*</span></label>
                    <div className='w-full mt-[20px]'>
                        <div className='flex mb-[12px] items-center w-full'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Pallor </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Pallor'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Secondary.Pallor === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Pallor'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Secondary.Pallor === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Cyanosis </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Cyanosis'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Secondary.Cyanosis === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Cyanosis'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Secondary.Cyanosis === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Icterus </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Icterus'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Secondary.Icterus === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Icterus'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Secondary.Icterus === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Koilonychia </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Koilonychia'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Secondary.Koilonychia === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Koilonychia'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Secondary.Koilonychia === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Iymphadenopathy </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Iymphadenopathy'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Secondary.Iymphadenopathy === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Iymphadenopathy'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Secondary.Iymphadenopathy === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Edema </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Edema'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Secondary.Edema === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Secondary.Edema'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Secondary.Edema === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[30px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-full pl-40 justify-around flex flex-col'>
                                        <input
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px] px-1'
                                            name="VitalParameters.Secondary.secondaryInput"
                                            id="VitalParameters.Secondary.secondaryInput"
                                            onChange={props.formikState.handleChange}
                                            placeholder='Write here'
                                            disabled={props.disabled}
                                            value={props.formikState.values.VitalParameters.Secondary.secondaryInput}>
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full pt-4'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Chest </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Secondary.Chest"
                                            id="VitalParameters.Secondary.Chest"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Secondary.Chest}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            {
                                                chest.map((data, i) => (
                                                    <option value={chest[i].value} key={i}>{chest[i].label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[30px]'>
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Heart<span className='text-[crimson]'>*</span></label>
                    <div className='w-full mt-[20px]'>
                        <div className='flex mb-[12px] items-center w-full h-[47px]'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Heart Sounds </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex flex-row gap-x-2 py-4'>
                                    <input required
                                        className='self-center'
                                        name='VitalParameters.Heart.HeartSounds.HeartSounds'
                                        type='radio'
                                        id='S1,S2 Present'
                                        onChange={props.formikState.handleChange}
                                        value='S1,S2 Present'
                                        checked={props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSounds === 'S1,S2 Present'}
                                        disabled={props.disabled}
                                    />
                                    <div className='flex flex-row gap-x-2'>
                                        {/* <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S1,S2 Present
                                        </label> */}
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S1,</span>
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S2</span>
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Present</span>
                                    </div>
                                </div>
                                {props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSounds === 'S1,S2 Present' &&
                                    <input required
                                        className='h-[47px] w-[calc(100%-150px)] ml-[50px] text-[#8F8F8F] text-[15px] pl-[5px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                        id='VitalParameters.Heart.HeartSounds.HeartSoundsInput'
                                        name='VitalParameters.Heart.HeartSounds.HeartSoundsInput'
                                        type='text'
                                        placeholder='Write here'
                                        onChange={props.formikState.handleChange}
                                        value={props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSoundsInput}
                                    />
                                }
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full h-[47px]'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'></div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex flex-row gap-x-2 py-4'>
                                    <input required
                                        className='self-center'
                                        name='VitalParameters.Heart.HeartSounds.HeartSounds'
                                        type='radio'
                                        id='S1,S2 Absent'
                                        onChange={props.formikState.handleChange}
                                        value='S1,S2 Absent'
                                        checked={props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSounds === 'S1,S2 Absent'}
                                        disabled={props.disabled}
                                    />
                                    <div className='flex flex-row gap-x-2'>
                                        {/* <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S1,S2 Absent
                                        </label> */}
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S1,</span>
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S2</span>
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Absent</span>
                                    </div>
                                </div>
                                {props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSounds === 'S1,S2 Absent' &&
                                    <input required
                                        className='h-[47px] w-[calc(100%-150px)] ml-[50px] text-[#8F8F8F] text-[15px] pl-[5px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                        id='VitalParameters.Heart.HeartSounds.HeartSoundsInput'
                                        name='VitalParameters.Heart.HeartSounds.HeartSoundsInput'
                                        type='text'
                                        placeholder='Write here'
                                        onChange={props.formikState.handleChange}
                                        value={props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSoundsInput}
                                    />
                                }
                            </div>
                        </div>

                        <div className='flex mb-[12px] items-center w-full h-[47px]'>
                            <div className='w-[160px]'>
                            </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex gap-x-2'>
                                    {/* <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'> */}
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='VitalParameters.Heart.HeartSounds.HeartSounds'
                                        type='radio'
                                        id='S1,S2 Audible'
                                        onChange={props.formikState.handleChange}
                                        value='S1,S2 Audible'
                                        checked={props.formikState.values.VitalParameters.Heart.HeartSounds.HeartSounds === 'S1,S2 Audible'}
                                        disabled={props.disabled}
                                    />
                                    {/* S1,S2 Audible */}
                                    {/* </label> */}
                                    <div className='flex flex-row gap-x-2'>
                                        {/* <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S1,S2 Present
                                        </label> */}
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S1,</span>
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>S2</span>
                                        <span className='font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Audible</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex mb-[12px] items-center w-full h-[47px]'>
                            <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Any Added Sound<br />/Murmurs </div>
                            <div className='flex w-[calc(100%-160px)]'>
                                <div className='flex'>
                                    <label className='self-center w-[100px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Heart.AddedSound.AddedSound'
                                            type='radio'
                                            id='No'
                                            onChange={props.formikState.handleChange}
                                            value='No'
                                            checked={props.formikState.values.VitalParameters.Heart.AddedSound.AddedSound === 'No'}
                                            disabled={props.disabled}
                                        />No
                                    </label>
                                    <label className='self-center w-[30px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='self-center mr-[2.5px]'
                                            name='VitalParameters.Heart.AddedSound.AddedSound'
                                            id='Yes'
                                            type='radio'
                                            onChange={props.formikState.handleChange}
                                            value='Yes'
                                            checked={props.formikState.values.VitalParameters.Heart.AddedSound.AddedSound === 'Yes'}
                                            disabled={props.disabled}
                                        />Yes
                                    </label>
                                </div>
                                {props.formikState.values.VitalParameters.Heart.AddedSound.AddedSound === 'Yes' &&
                                    <input required
                                        className='h-[47px] w-[calc(100%-150px)] text-[#8F8F8F] ml-[50px] text-[15px] pl-[5px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                        id='VitalParameters.Heart.AddedSound.AddedSoundInput'
                                        name='VitalParameters.Heart.AddedSound.AddedSoundInput'
                                        type='text'
                                        placeholder='Write here'
                                        onChange={props.formikState.handleChange}
                                        value={props.formikState.values.VitalParameters.Heart.AddedSound.AddedSoundInput}
                                        disabled={props.disabled}
                                    />
                                }
                            </div>
                        </div>
                        <div className='w-full mt-[10px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Abdomen </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Heart.Abdomen"
                                            id="VitalParameters.Heart.Abdomen"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Heart.Abdomen}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Tenderness">Tenderness</option>
                                            <option value="Soft">Soft</option>
                                            <option value="BS+/-">BS+/-</option>
                                            <option value="Distended">Distended</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Bowel Sound </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Heart.Bowelsound"
                                            id="VitalParameters.Heart.Bowelsound"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Heart.Bowelsound}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Present">Present</option>
                                            <option value="Reduced">Reduced</option>
                                            <option value="Absent">Absent</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[30px]'>
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Pupil<span className='text-[crimson]'>*</span></label>
                    <div className='w-full mt-[20px]'>
                        <div className='w-full'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Right Eye </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil.RtEye"
                                            id="VitalParameters.Pupil.RtEye"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil.RtEye}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Dilated & Reacting to light">Dilated & Reacting to light</option>
                                            <option value="Constricted pinpoint">Constricted pinpoint</option>
                                            <option value="Asymmetrical">Asymmetrical</option>
                                            <option value="NRL(non-reacting to light)">NRL(non-reacting to light)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Left Eye </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil.LtEye"
                                            id="VitalParameters.Pupil.LtEye"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil.LtEye}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Dilated & Reacting to light">Dilated & Reacting to light</option>
                                            <option value="Constricted pinpoint">Constricted pinpoint</option>
                                            <option value="Asymmetrical">Asymmetrical</option>
                                            <option value="NRL(non-reacting to light)">NRL(non-reacting to light)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[30px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-full pl-40 justify-around flex flex-col'>
                                        <input
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px] px-1'
                                            name="VitalParameters.Pupil.pupilInput"
                                            id="VitalParameters.Pupil.pupilInput"
                                            onChange={props.formikState.handleChange}
                                            placeholder='Write here'
                                            disabled={props.disabled}
                                            value={props.formikState.values.VitalParameters.Pupil.pupilInput}>
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-[30px]'>
                    <label className='font-"roboto" font-[600] text-[14px] leading-[15px] text-[#000000]'>Pupil</label>
                    <div className='w-full mt-[20px]'>
                        <div className='w-full mb-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Right(mm) </div>
                                    <div className='w-[calc(100%-160px)] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='h-[47px] text-[15px] w-[100px] text-[#8F8F8F] mr-[5px] text-center border-[1px] border-[#E3E3E3] required bg-gray outline-none'
                                            id='VitalParameters.Pupil1.Rt'
                                            name='VitalParameters.Pupil1.Rt'
                                            type='text'
                                            placeholder='Write No'
                                            disabled={props.disabled}
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.Rt}
                                        />
                                        mm
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mb-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Left(mm) </div>
                                    <div className='w-[calc(100%-160px)] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                        <input required
                                            className='h-[47px] text-[15px] text-[#8F8F8F] w-[100px] mr-[5px] text-center border-[1px] border-[#E3E3E3] required bg-gray outline-none'
                                            id='VitalParameters.Pupil1.Lt'
                                            name='VitalParameters.Pupil1.Lt'
                                            type='text'
                                            placeholder='Write No'
                                            disabled={props.disabled}
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.Lt}
                                        />
                                        mm
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Right Side Tone </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil1.RtTone"
                                            id="VitalParameters.Pupil1.RtTone"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.RtTone}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Normal">Normal</option>
                                            <option value="Spasticity">Spasticity</option>
                                            <option value="Flaccidity">Flaccidity</option>
                                            <option value="Plasticity (UMN/LMN)">Plasticity (UMN/LMN)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Left Side Tone </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil1.LtTone"
                                            id="VitalParameters.Pupil1.LtTone"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.LtTone}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Normal">Normal</option>
                                            <option value="Spasticity">Spasticity</option>
                                            <option value="Flaccidity">Flaccidity</option>
                                            <option value="Plasticity (UMN/LMN)">Plasticity (UMN/LMN)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Right Reflexes </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil1.RtReflexes"
                                            id="VitalParameters.Pupil1.RtReflexes"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.RtReflexes}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Normal">Normal</option>
                                            <option value="UMN">UMN</option>
                                            <option value="LMN">LMN</option>
                                            <option value="Absent">Absent</option>
                                            <option value="Exaggerated">Exaggerated</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Left Reflexes </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil1.LtReflexes"
                                            id="VitalParameters.Pupil1.LtReflexes"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.LtReflexes}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Normal">Normal</option>
                                            <option value="UMN">UMN</option>
                                            <option value="LMN">LMN</option>
                                            <option value="Absent">Absent</option>
                                            <option value="Exaggerated">Exaggerated</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Right Plantar reflex </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil1.Rreflex"
                                            id="VitalParameters.Pupil1.Rreflex"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.Rreflex}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Flexion">Flexion</option>
                                            <option value="Extension">Extension</option>
                                            <option value="Mute">Mute</option>
                                            <option value="Withdrawal">Withdrawal</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full mt-[15px]'>
                            <div className='bg-white w-full'>
                                <div className='flex w-full items-center'>
                                    <div className='w-[160px] font-"roboto" self-start py-[10px] pr-[15px] min-w-fit font-[400] text-[14px] leading-[15px] text-[#757575]'>Left Plantar reflex </div>
                                    <div className='w-[calc(100%-160px)] justify-around flex flex-col'>
                                        <select required
                                            className='w-full h-[47px] text-[#8F8F8F] border-[1px] outline-none border-[#E3E3E3] text-input required bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                            name="VitalParameters.Pupil1.Lreflex"
                                            id="VitalParameters.Pupil1.Lreflex"
                                            onChange={props.formikState.handleChange}
                                            value={props.formikState.values.VitalParameters.Pupil1.Lreflex}
                                            disabled={props.disabled}
                                        >
                                            <option value="">Select Here</option>
                                            <option value="Flexion">Flexion</option>
                                            <option value="Extension">Extension</option>
                                            <option value="Mute">Mute</option>
                                            <option value="Withdrawal">Withdrawal</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Viralpm;
