import React from 'react';
import Select from 'react-select';
import BradenRisk from '../../utils/json/BradenRisk.json';

const BredenRiskAssessment = ({ formikState, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    const change = (r) => {
        if ((r) >= 15) {
            return ('Mild Risk' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 12 && (r) <= 14) {
            return ('Moderate' + ' ' + '(' + `${r}` + ')');
        } else if ((r) < 12) {
            return ('High Risk' + ' ' + '(' + `${r}` + ')');
        }
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Breden Risk Assessment Scale for bed scores</span>
            </div>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Sensory/Mental :</label>
                            </div>
                            <Select
                                name="bredenRiskAssessment.sensory"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bredenRiskAssessment.sensory', value.value);
                                }}
                                value={
                                    BradenRisk['Sensory/Mental']
                                        ? BradenRisk['Sensory/Mental'].find((option) => option.value === formikState.values.bredenRiskAssessment.sensory)
                                        : ''}
                                options={BradenRisk['Sensory/Mental']}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Moisture :</label>
                            </div>
                            <Select
                                name="bredenRiskAssessment.moisture"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bredenRiskAssessment.moisture', value.value);
                                }}
                                value={
                                    BradenRisk.Moisture
                                        ? BradenRisk.Moisture.find((option) => option.value === formikState.values.bredenRiskAssessment.moisture)
                                        : ''}
                                options={BradenRisk.Moisture}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Activity :</label>
                            </div>
                            <Select
                                name="bredenRiskAssessment.activity"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bredenRiskAssessment.activity', value.value);
                                }}
                                value={
                                    BradenRisk.Activity
                                        ? BradenRisk.Activity.find((option) => option.value === formikState.values.bredenRiskAssessment.activity)
                                        : ''}
                                options={BradenRisk.Activity}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Mobility :</label>
                            </div>
                            <Select
                                name="bredenRiskAssessment.mobility"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bredenRiskAssessment.mobility', value.value);
                                }}
                                value={
                                    BradenRisk.Mobility
                                        ? BradenRisk.Mobility.find((option) => option.value === formikState.values.bredenRiskAssessment.mobility)
                                        : ''}
                                options={BradenRisk.Mobility}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Nutrition :</label>
                            </div>
                            <Select
                                name="bredenRiskAssessment.nutrition"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bredenRiskAssessment.nutrition', value.value);
                                }}
                                value={
                                    BradenRisk.Nutrition
                                        ? BradenRisk.Nutrition.find((option) => option.value === formikState.values.bredenRiskAssessment.nutrition)
                                        : ''}
                                options={BradenRisk.Nutrition}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Friction :</label>
                            </div>
                            <Select
                                name="bredenRiskAssessment.friction"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bredenRiskAssessment.friction', value.value);
                                }}
                                value={
                                    BradenRisk['Friction/Shear']
                                        ? BradenRisk['Friction/Shear'].find((option) => option.value === formikState.values.bredenRiskAssessment.friction)
                                        : ''}
                                options={BradenRisk['Friction/Shear']}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <label className='font-"roboto" px-6 font-[500] text-[14px] leading-[15px] text-[#757575]'><span className='italic'>Result</span> : {change(Number(formikState.values.bredenRiskAssessment.sensory) + Number(formikState.values.bredenRiskAssessment.moisture) + Number(formikState.values.bredenRiskAssessment.activity) + Number(formikState.values.bredenRiskAssessment.mobility) + Number(formikState.values.bredenRiskAssessment.nutrition) + Number(formikState.values.bredenRiskAssessment.friction))}</label>
                    <div className="flex flex-col w-full  px-6">
                        {/* <div className="grid grid-cols-c3 gap-[1%] items-center"> */}
                        {/* <div></div> */}
                        <div className="flex flex-row flex-wrap">
                            <span className="text-black font-roboto text-sm">Note* : </span>
                            <span className="text-col font-roboto text-sm">15 - 16 = Mild risk | 12 - 14 = Moderate | &lt; 12 = High risk</span>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BredenRiskAssessment;
