import React, { useEffect, useState } from 'react';
import PharmacyMedicationList from './PharmacyMedicationList.js';
import Button from '../../components/Button';
import Arrow1 from '../../assets/images/arrow1.svg';
import { useLocation, Link, useParams } from 'react-router-dom';
import { getEncounter } from '../../api/encounter.js';
import { convertFromFhirList } from '../../fhir-evolver/index.js';
import Loader from '../../components/Loader';

const PharmacyMedicationEdit = () => {
    const location = useLocation();
    const [encounter, setEncounter] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const params = useParams();
    useEffect(() => {
        if (!location.state && params.id) {
            setSpinner(true);
            getEncounter(`_id=${params.id}&_include=*`).then((response) => {
                convertFromFhirList('EncounterPharmacy', response.data.entry).then((res) => {
                    setEncounter(res[0]);
                    setSpinner(false);
                });
            });
        }
    }, []);
    const dateFormat = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'

    });
    return (
        <div>
            <Loader showSpinner={spinner}></Loader>
            <div className="h-screen">
                <div className="bg-background h-full w-full">
                    <div className="flex flex-row justify-between py-2">
                        <div className="flex items-center">
                            <Link to={'/pharmacy'}>
                                <span className="text-base font-normal py-2 text-col">
                                    Home
                                </span>
                            </Link>
                            <img src={Arrow1} alt="arrow" />
                            <span className="text-black font-normal text-base  font-roboto px-2 py-2">Prescribed Medicine</span>
                        </div>

                        <div className="flex items-center gap-x-10">
                            <span className="font-normal text-base  font-roboto px-2 py-2 text-sm text-col">Date: {dateFormat}</span>
                            <div className='text-right'>
                                <Button label={'View History'} className="m-2 px-[2em] py-[.68em] mx-4 bg-[#EAC05D] text-white font-semibold" />
                            </div>
                        </div>
                    </div>
                    <PharmacyMedicationList data={location.state ? location.state : encounter} type={'edit'} disable={false}/>
                </div>
            </div>
        </div>
    );
};

export default PharmacyMedicationEdit;
