/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
// import { useSnackbar } from 'react-simple-snackbar';
// // import axios from 'axios';
// import { SnackbarStyles } from '../../components/SnackbarStyles';
import Arrow1 from '../../assets/images/arrow1.svg';
import Hisofill from './Fields/Hisofill';
import ChiefComplaint from './Fields/ChiefComplaint';
import PastHist from './Fields/PastHist';
import FamilyHo from './Fields/FamilyHo';
import PersonalHo from './Fields/PersonalHo';
import AllergicHo from './Fields/AllergicHo';
import Vaccinated from './Fields/Vaccinated';
import DrugHist from './Fields/DrugHist';
import GynHist from './Fields/GynHist';
import Edusoc from './Fields/Edusoc';
import Psycstatus from './Fields/Psycstatus';
import Funcstatus from './Fields/Funcstatus';
import Nutstatus from './Fields/Nutstatus';
import Gphysical from './Fields/Gphysical';
import Viralpm from './Fields/Viralpm';
import CNS from './Fields/CNS';
import Other from './Fields/Other';
import WPDiagnosis from './Fields/WPDiagnosis';
import Investigations from './Fields/Investigations';
import Prognosis from './Fields/Prognosis';
import Pertinent from './Fields/Pertinent';
import Consents from './Fields/Consents';
// import { convertToFhir } from '../../fhir-evolver';
// import { addInitialAssessment } from '../../api/initialAssessment';
import { ageCalculation } from '../../utils/common';
// import sample from '../../utils/json/InitialAssessmentSample.json';
import ScrollWindowUpDown from '../../components/scrollWindowUpDown';
function InitialAssessment (props) {
    // console.log('props', props.encounter);
    const navigate = useNavigate();
    // const [openSnackbar] = useSnackbar(SnackbarStyles);
    const location = useLocation();
    const [patientData, setPatientData] = useState({});
    // const patientData = {};
    useEffect(() => {
        if (props.isView) {
            setPatientData({ name: props.patient?.firstName?.[0].toUpperCase() + ' ' + props.patient?.surname.toUpperCase(), age: ageCalculation(props.patient?.birthDate), gender: props.patient?.gender.toUpperCase(), uhid: props.patient?.uhid, inPatient: props.encounter?.inPatientId });
        } else if (props.isEdit) {
            setPatientData({ name: props.patient?.firstName?.[0].toUpperCase() + ' ' + props.patient?.surname.toUpperCase(), age: ageCalculation(props.patient?.birthDate), gender: props.patient?.gender.toUpperCase(), uhid: props.patient?.uhid, inPatient: props.encounter?.inPatientId });
        } else if (props.isAdd && !location.state) {
            console.log('addddelse', props);
            setPatientData({ name: props.encounter?.patient?.firstName?.[0].toUpperCase() + ' ' + props.encounter?.patient?.surname.toUpperCase(), age: ageCalculation(props.encounter?.patient?.birthDate), gender: props.encounter?.patient?.gender.toUpperCase(), uhid: props.encounter?.patient?.uhid?.[0], inPatient: props.encounter?.inPatientId });
        } else {
            setPatientData({ name: location.state?.patient?.firstName?.[0].toUpperCase() + ' ' + location.state?.patient?.surname.toUpperCase(), age: ageCalculation(location.state?.patient?.birthDate), gender: location.state?.patient?.gender.toUpperCase(), uhid: location.state?.patient?.uhid, inPatient: location.state?.inPatientId });
        }
    }, [props]);
    const change = (r) => {
        if ((r) <= 29 && (r) >= 26) {
            return ('Upper Class');
        } else if ((r) >= 16 && (r) <= 25) {
            return ('Upper Middle');
        } else if ((r) >= 11 && (r) <= 15) {
            return ('Lower Middle');
        } else if ((r) >= 5 && (r) <= 10) {
            return ('Upper Lower');
        } else if ((r) < 5) {
            return ('Lower');
        }
    };
    const submitHandler = (data, { setSubmitting }) => {
        formikState.values.type = props.type;
        const val = props.type === 'add' ? data : [data, props.data];
        props.handleSubmit(val);
        setSubmitting(false);
    };
    const celValue = (props.viewData?.VitalParameters?.Circulation?.Temperature?.tempFahrenheit - 32) / 1.8;
    const celsius = celValue.toFixed(1);
    const formikState = useFormik({
        initialValues: {
            patientId: props.patient?.id || location.state?.patient?.id,
            encounterId: props.viewData?.encounterId || location.state?.id,
            practitioner: props.viewData?.participant?.[0].id || location.state?.participant?.[0].id,
            ChiefComplaint: props.viewData?.ChiefComplaint || [
                {
                    Complaint: '',
                    Duration: '',
                    DurationUnit: ''
                }
            ],
            HistoryOfPresentIllness: {
                Detail: {
                    Duration: props.viewData?.HistoryOfPresentIllness?.Detail.Duration?.[0] || '',
                    DMY: props.viewData?.HistoryOfPresentIllness?.Detail.DMY?.[0] || '',
                    Pronoun: props.viewData?.HistoryOfPresentIllness?.Detail.Pronoun?.[0] || '',
                    Motion: props.viewData?.HistoryOfPresentIllness?.Detail.Motion?.[0] || '',
                    Duration1: props.viewData?.HistoryOfPresentIllness?.Detail.Duration1?.[0] || '',
                    DMY2: props.viewData?.HistoryOfPresentIllness?.Detail.DMY2?.[0] || '',
                    Part: props.viewData?.HistoryOfPresentIllness?.Detail.Part?.[0] || '',
                    IncorDec: props.viewData?.HistoryOfPresentIllness?.Detail.IncorDec?.[0] || '',
                    WithorWithout: props.viewData?.HistoryOfPresentIllness?.Detail.WithorWithout?.[0] || '',
                    Symptoms: props.viewData?.HistoryOfPresentIllness?.Detail.Symptoms?.[0] || '',
                    Treatment: props.viewData?.HistoryOfPresentIllness?.Detail.Treatment?.[0] || '',
                    Pronoun1: props.viewData?.HistoryOfPresentIllness?.Detail.Pronoun1?.[0] || '',
                    WithorWithout1: props.viewData?.HistoryOfPresentIllness?.Detail.WithorWithout1?.[0] || '',
                    Content: props.viewData?.HistoryOfPresentIllness?.Detail.Content?.[0] || ''
                },
                NegativeHistory: {
                    Fever: props.viewData?.HistoryOfPresentIllness?.NegativeHistory.Fever?.[0] || '',
                    Headache: props.viewData?.HistoryOfPresentIllness?.NegativeHistory.Headache?.[0] || '',
                    BodyAches: props.viewData?.HistoryOfPresentIllness?.NegativeHistory.BodyAches?.[0] || '',
                    Chills: props.viewData?.HistoryOfPresentIllness?.NegativeHistory.Chills?.[0] || '',
                    Breathlessness: props.viewData?.HistoryOfPresentIllness?.NegativeHistory.Breathlessness?.[0] || '',
                    // negativeHistory: props.viewData?.HistoryOfPresentIllness?.NegativeHistory?.negativeHistory || '',
                    Other: props.viewData?.HistoryOfPresentIllness?.NegativeHistory.Other?.[0] || ''
                }
            },
            PastMedicalSurgicalHistories: {
                PastHistoryYesNo: props.viewData?.PastMedicalSurgicalHistories?.PastHistoryYesNo?.[0] || '',
                PastMedicalTestInput: props.viewData?.PastMedicalSurgicalHistories?.PastMedicalTestInput?.[0] || '',
                HTN: props.viewData?.PastMedicalSurgicalHistories?.HTN?.[0] || '',
                DM2: props.viewData?.PastMedicalSurgicalHistories?.DM2?.[0] || '',
                CAD: props.viewData?.PastMedicalSurgicalHistories?.CAD?.[0] || '',
                ASTHMA: props.viewData?.PastMedicalSurgicalHistories?.ASTHMA?.[0] || '',
                TB: props.viewData?.PastMedicalSurgicalHistories?.TB?.[0] || '',
                OBESITY: props.viewData?.PastMedicalSurgicalHistories?.OBESITY?.[0] || '',
                DYSLIPIDEMIA: props.viewData?.PastMedicalSurgicalHistories?.DYSLIPIDEMIA?.[0] || '',
                SCD: props.viewData?.PastMedicalSurgicalHistories?.SCD?.[0] || '',
                CVA: props.viewData?.PastMedicalSurgicalHistories?.CVA?.[0] || '',
                Others: props.viewData?.PastMedicalSurgicalHistories?.Others?.[0] || ''
            },
            FamilyHo: {
                HYN: props.viewData?.FamilyHo?.HYN?.[0] || '',
                FamilyHoInput: props.viewData?.FamilyHo?.FamilyHoInput?.[0] || '',
                HTN: props.viewData?.FamilyHo?.HTN?.[0] || '',
                DM2: props.viewData?.FamilyHo?.DM2?.[0] || '',
                CAD: props.viewData?.FamilyHo?.CAD?.[0] || '',
                ASTHMA: props.viewData?.FamilyHo?.ASTHMA?.[0] || '',
                TB: props.viewData?.FamilyHo?.TB?.[0] || '',
                OBESITY: props.viewData?.FamilyHo?.OBESITY?.[0] || '',
                DYSLIPIDEMIA: props.viewData?.FamilyHo?.DYSLIPIDEMIA?.[0] || '',
                SCD: props.viewData?.FamilyHo?.SCD?.[0] || '',
                CVA: props.viewData?.FamilyHo?.CVA?.[0] || '',
                Others: props.viewData?.FamilyHo?.Others?.[0] || '',
                NotSignificant: props.viewData?.FamilyHo?.NotSignificant?.[0] || ''
            },
            PersonalHo: {
                addict: props.viewData?.PersonalHo?.addict?.[0] || '',
                addictDays: props.viewData?.PersonalHo?.addictDays?.[0] || '',
                built: props.viewData?.PersonalHo?.built?.[0] || '',
                complexion: props.viewData?.PersonalHo?.complexion?.[0] || '',
                sleep: props.viewData?.PersonalHo?.sleep?.[0] || '',
                bladder: props.viewData?.PersonalHo?.bladder?.[0] || '',
                diet: props.viewData?.PersonalHo?.diet?.[0] || '',
                dietInput: props.viewData?.PersonalHo?.dietInput?.[0] || '',
                height: props.viewData?.PersonalHo?.height?.[0] || '',
                nourishment: props.viewData?.PersonalHo?.nourishment?.[0] || '',
                bowel: props.viewData?.PersonalHo?.bowel?.[0] || '',
                apt: props.viewData?.PersonalHo?.apt?.[0] || ''
            },
            AllergicHo: {
                AllergicHoYesNo: props.viewData?.AllergicHo?.AllergicHoYesNo?.[0] || '',
                AllergicHoInput: props.viewData?.AllergicHo?.AllergicHoInput?.[0] || ''
            },
            Vaccinated: {
                VaccinatedYesNo: props.viewData?.Vaccinated?.VaccinatedYesNo?.[0] || '',
                VaccinatedInput: props.viewData?.Vaccinated?.VaccinatedYesNo?.[0] || ''
            },
            DrugHistory: {
                DrugHistoryYesNo: props.viewData?.DrugHistory?.DrugHistoryYesNo?.[0] || '',
                DrugHistoryInput: props.viewData?.DrugHistory?.DrugHistoryInput?.[0] || ''
            },
            GynaecologicalHistory: {
                GynaecologicalHistory: {
                    AgeofMenarche: props.viewData?.GynaecologicalHistory?.GynaecologicalHistory?.AgeofMenarche?.[0] || '',
                    Regularity: props.viewData?.GynaecologicalHistory?.GynaecologicalHistory?.Regularity?.[0] || '',
                    Flow: props.viewData?.GynaecologicalHistory?.GynaecologicalHistory?.Flow?.[0] || '',
                    LMP: props.viewData?.GynaecologicalHistory?.GynaecologicalHistory?.LMP?.[0] || '',
                    Duration: props.viewData?.GynaecologicalHistory?.GynaecologicalHistory?.Duration?.[0] || ''
                },
                PainDuringMenstruation: {
                    PainDuringMenstruation: props.viewData?.GynaecologicalHistory?.PainDuringMenstruation?.PainDuringMenstruation?.[0] || '',
                    PainDuringMenstruationInput: props.viewData?.GynaecologicalHistory?.PainDuringMenstruation?.PainDuringMenstruationInput?.[0] || ''
                }
            },
            EducationalSocialHistory: {
                Education: props.viewData?.EducationalSocialHistory?.Education?.[0] || '',
                Occupation: props.viewData?.EducationalSocialHistory?.Occupation?.[0] || '',
                TotalPerCapital: props.viewData?.EducationalSocialHistory?.TotalPerCapital?.[0] || '',
                Result: props.viewData?.EducationalSocialHistory?.Result?.[0] || ''
            },
            PsychologicalStatus: {
                status: props.viewData?.PsychologicalStatus?.status?.[0] || ''
            },
            FunctionalStatus: {
                status: props.viewData?.FunctionalStatus?.status?.[0] || ''
            },
            NutritionalStatus: {
                NutritionalStatus: {
                    NutritionalStatusInput: props.viewData?.NutritionalStatus?.NutritionalStatus?.NutritionalStatusInput?.[0] || ''
                },
                WtLossinThreeMonths: {
                    WtLossinThreeMonths: props.viewData?.NutritionalStatus?.WtLossinThreeMonths?.WtLossinThreeMonths?.[0] || '',
                    WtLossinThreeMonthsInput: props.viewData?.NutritionalStatus?.WtLossinThreeMonths?.WtLossinThreeMonthsInput?.[0] || ''
                },
                HistoryofReducedFoodIntake: {
                    HistoryofReducedFoodIntake: props.viewData?.NutritionalStatus?.HistoryofReducedFoodIntake?.HistoryofReducedFoodIntake?.[0] || '',
                    HistoryofReducedFoodIntakeInput: props.viewData?.NutritionalStatus?.HistoryofReducedFoodIntake?.HistoryofReducedFoodIntakeInput?.[0] || ''
                },
                Parameter: {
                    HeightMeter: props.viewData?.NutritionalStatus?.Parameter?.HeightMeter?.[0] || 0,
                    HeightCentimeter: props.viewData?.NutritionalStatus?.Parameter?.HeightCentimeter?.[0] || 0,
                    HeightInch: props.viewData?.NutritionalStatus?.Parameter?.HeightInch?.[0] || 0,
                    Weight: props.viewData?.NutritionalStatus?.Parameter?.Weight?.[0] || 0,
                    BMI: props.viewData?.NutritionalStatus?.Parameter?.BMI?.[0] || 0
                }
            },
            GeneralPhysicalExamination: {
                GeneralCondition: {
                    GeneralConditionInput: props.viewData?.GeneralPhysicalExamination?.GeneralCondition?.GeneralConditionInput || ''
                },
                Temperature: {
                    TemperatureInput: props.viewData?.GeneralPhysicalExamination?.Temperature?.TemperatureInput || ''
                },
                LevelOfConciseness: {
                    EyeOpeningResponse: props.viewData?.GeneralPhysicalExamination?.LevelOfConciseness?.EyeOpeningResponse || '',
                    VerbalResponse: props.viewData?.GeneralPhysicalExamination?.LevelOfConciseness?.VerbalResponse || '',
                    MotorResponse: props.viewData?.GeneralPhysicalExamination?.LevelOfConciseness?.MotorResponse || '',
                    Result: props.viewData?.GeneralPhysicalExamination?.LevelOfConciseness?.Result || ''
                },
                Coherence: {
                    CoherenceInput: props.viewData?.GeneralPhysicalExamination?.Coherence?.CoherenceInput || ''
                },
                Orientation: {
                    OrientationInput: props.viewData?.GeneralPhysicalExamination?.Orientation?.OrientationInput || ''
                },
                Disorientation: {
                    DisorientationInput: props.viewData?.GeneralPhysicalExamination?.Disorientation?.DisorientationInput || ''
                },
                Thyroid: {
                    ThyroidNA: props.viewData?.GeneralPhysicalExamination?.Thyroid?.ThyroidNA || '',
                    ThyroidInput: props.viewData?.GeneralPhysicalExamination?.Thyroid?.ThyroidInput || ''
                },
                Breast: {
                    BreastNA: props.viewData?.GeneralPhysicalExamination?.Breast?.BreastNA || '',
                    BreastInput: props.viewData?.GeneralPhysicalExamination?.Breast?.BreastInput || ''
                },
                Attitude: {
                    Attitude: props.viewData?.GeneralPhysicalExamination?.Attitude?.Attitude || '',
                    AttitudeInput: props.viewData?.GeneralPhysicalExamination?.Attitude?.AttitudeInput || ''
                }
            },
            VitalParameters: {
                Airway: {
                    AirwayPatentorNot: props.viewData?.VitalParameters?.Airway?.AirwayPatentorNot || '',
                    AirwayInput: props.viewData?.VitalParameters?.Airway?.AirwayInput || ''
                },
                Breathing: {
                    rate: {
                        checked: props.viewData?.VitalParameters?.Breathing?.rate?.checked || '',
                        RateInput: props.viewData?.VitalParameters?.Breathing?.rate?.RateInput || ''
                    },
                    type: {
                        TypeDropdown: props.viewData?.VitalParameters?.Breathing?.type?.TypeDropdown || '',
                        TypeInput: props.viewData?.VitalParameters?.Breathing?.type?.TypeInput || ''
                    },
                    spo2: {
                        spo2Input: props.viewData?.VitalParameters?.Breathing?.spo2?.spo2Input || '',
                        spo2DropDown: props.viewData?.VitalParameters?.Breathing?.spo2?.spo2DropDown || '',
                        Other: props.viewData?.VitalParameters?.Breathing?.spo2?.Other || ''
                    }
                },
                Circulation: {
                    HRPulse: {
                        checked: props.viewData?.VitalParameters?.Circulation?.HRPulse?.checked || '',
                        HRPulseInput: props.viewData?.VitalParameters?.Circulation?.HRPulse?.HRPulseInput || ''
                    },
                    PulseRhythm: props.viewData?.VitalParameters?.Circulation?.PulseRhythm || '',
                    SBp: {
                        SBpInput: props.viewData?.VitalParameters?.Circulation?.SBp?.SBpInput || ''
                        // Position: '',
                        // checked: ''
                    },
                    DBp: {
                        DBpInput: props.viewData?.VitalParameters?.Circulation?.DBp?.DBpInput || '',
                        Position: props.viewData?.VitalParameters?.Circulation?.DBp?.Position || '',
                        checked: props.viewData?.VitalParameters?.Circulation?.DBp?.checked || ''
                    },
                    Temperature: {
                        Temperature: props.viewData?.VitalParameters?.Circulation?.Temperature?.Temperature || '',
                        TemperatureInput: props.viewData?.VitalParameters?.Circulation?.Temperature?.TemperatureInput || '',
                        // tempCelsius: props.viewData?.VitalParameters?.Circulation?.Temperature?.tempCelsius || 0,
                        tempCelsius: celsius || 0,
                        tempFahrenheit: props.viewData?.VitalParameters?.Circulation?.Temperature?.tempFahrenheit || 0
                    },
                    Cardiac: props.viewData?.VitalParameters?.Circulation?.Cardiac || ''
                },
                Secondary: {
                    Pallor: props.viewData?.VitalParameters?.Secondary?.Pallor || '',
                    Cyanosis: props.viewData?.VitalParameters?.Secondary?.Cyanosis || '',
                    Icterus: props.viewData?.VitalParameters?.Secondary?.Icterus || '',
                    Koilonychia: props.viewData?.VitalParameters?.Secondary?.Koilonychia || '',
                    Iymphadenopathy: props.viewData?.VitalParameters?.Secondary?.Iymphadenopathy || '',
                    Edema: props.viewData?.VitalParameters?.Secondary?.Edema || '',
                    Chest: props.viewData?.VitalParameters?.Secondary?.Chest || '',
                    secondaryInput: props.viewData?.VitalParameters?.Secondary?.secondaryInput || ''
                },
                Heart: {
                    HeartSounds: {
                        HeartSounds: props.viewData?.VitalParameters?.Heart?.HeartSounds?.HeartSounds || '',
                        HeartSoundsInput: props.viewData?.VitalParameters?.Heart?.HeartSounds?.HeartSoundsInput || ''
                    },
                    AddedSound: {
                        AddedSound: props.viewData?.VitalParameters?.Heart?.AddedSound?.AddedSound || '',
                        AddedSoundInput: props.viewData?.VitalParameters?.Heart?.AddedSound?.AddedSoundInput || ''
                    },
                    Abdomen: props.viewData?.VitalParameters?.Heart?.Abdomen || '',
                    Bowelsound: props.viewData?.VitalParameters?.Heart?.Bowelsound || ''
                },
                Pupil: {
                    RtEye: props.viewData?.VitalParameters?.Pupil?.RtEye || '',
                    LtEye: props.viewData?.VitalParameters?.Pupil?.LtEye || '',
                    pupilInput: props.viewData?.VitalParameters?.Pupil?.pupilInput || ''
                },
                Pupil1: {
                    Rt: props.viewData?.VitalParameters?.Pupil1?.Rt || '',
                    Lt: props.viewData?.VitalParameters?.Pupil1?.Lt || '',
                    RtTone: props.viewData?.VitalParameters?.Pupil1?.RtTone || '',
                    LtTone: props.viewData?.VitalParameters?.Pupil1?.LtTone || '',
                    RtReflexes: props.viewData?.VitalParameters?.Pupil1?.RtReflexes || '',
                    LtReflexes: props.viewData?.VitalParameters?.Pupil1?.LtReflexes || '',
                    Rreflex: props.viewData?.VitalParameters?.Pupil1?.Rreflex || '',
                    Lreflex: props.viewData?.VitalParameters?.Pupil1?.Lreflex || ''
                }
            },
            Other: {
                Osystem: props.viewData?.Other?.Osystem?.[0] || '',
                GITsystem: {
                    GITsystem: props.viewData?.Other?.GITsystem?.GITsystem?.[0] || '',
                    GITsystemInput: props.viewData?.Other?.GITsystem?.GITsystemInput?.[0] || ''
                },
                CVSsystem: {
                    CVSsystem: props.viewData?.Other?.CVSsystem?.CVSsystem?.[0] || '',
                    CVSsystemInput: props.viewData?.Other?.CVSsystem?.CVSsystemInput?.[0] || ''
                },
                Rsystem: {
                    Rsystem: props.viewData?.Other?.Rsystem?.Rsystem?.[0] || '',
                    RsystemInput: props.viewData?.Other?.Rsystem?.RsystemInput?.[0] || ''
                },
                CNSsystem: {
                    CNSsystem: props.viewData?.Other?.CNSsystem?.CNSsystem?.[0] || '',
                    CNSsystemInput: props.viewData?.Other?.CNSsystem?.CNSsystemInput?.[0] || ''
                },
                GUTsystem: {
                    GUTsystem: props.viewData?.Other?.GUTsystem?.GUTsystem?.[0] || '',
                    GUTsystemInput: props.viewData?.Other?.GUTsystem?.GUTsystemInput?.[0] || ''
                },
                Other: {
                    Other: props.viewData?.Other?.Other?.Other?.[0] || '',
                    OtherInput: props.viewData?.Other?.Other?.OtherInput?.[0] || ''
                }
            },
            WorkingProvisionalDiagnosis: props.viewData?.WorkingProvisionalDiagnosis || '',
            Investigations: {
                Investigations: props.viewData?.Investigations?.Investigations || '',
                InvestigationsInput: props.viewData?.Investigations?.InvestigationsInput || ''
            },
            Prognosis: {
                Prognosis: props.viewData?.Prognosis?.Prognosis || 'Outcome of current diagnosis',
                PrognosisInput: props.viewData?.Prognosis?.PrognosisInput || ''
            },
            Pertinent: props.viewData?.Pertinent || '',
            Consents: props.viewData?.Consents || '',
            CNS: {
                RtTone: props.viewData?.RtTone || '',
                LtTone: props.viewData?.LtTone || '',
                RtPower: props.viewData?.RtPower || '',
                LtPower: props.viewData?.LtPower || '',
                RtReflexes: props.viewData?.RtReflexes || '',
                LtReflexes: props.viewData?.LtReflexes || '',
                RtPlantar: props.viewData?.RtPlantar || '',
                LtPlantar: props.viewData?.LtPlantar || '',
                RtGait: props.viewData?.RtGait || '',
                LtGait: props.viewData?.LtGait || '',
                RtMovementDisorder: props.viewData?.RtMovementDisorder || '',
                LtMovementDisorder: props.viewData?.LtMovementDisorder || ''
            }
        },
        enableReinitialize: false,
        // onSubmit: values => {
        //     convertToFhir('ClinicalImpression', values).then((output) => {
        //         console.log('output', output);
        //         addInitialAssessment(output)
        //             .then(res => {
        //                 console.log('res', res);
        //                 openSnackbar('Initial Assessment Created Successfully');
        //                 navigate('/patient/admission-list');
        //             });
        //     });
        // }
        onSubmit: submitHandler
        // onSubmit: values => {
        //     console.log('valll', values);
        //     props.handleSubmit(values);
        // }
    });

    const change1 = (r) => {
        if ((r) >= 13 && (r) <= 15) {
            return ('Minor Brian Injury');
        } else if ((r) >= 9 && (r) <= 12) {
            return ('Moderate Brian Injury');
        } else if ((r) >= 3 && (r) <= 8) {
            return ('Severe Brian Injury');
        }
    };

    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    const time = new Date();
    const currentTime = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    return (
        <div className='bg-[#F5F5F5]'>
            {/* {console.log('patient data', patientData)} */}
            <ScrollWindowUpDown isNurse = {props.isNurse}/>
            <div className='p-[16px]'>
                <div className='flex justify-between py-2'>
                    <div className="flex items-center py-2">
                        <Link to="/home">
                            <span className="text-lg font-semibold px-2 py-0.5 font-'roboto' leading-[15px] text-[#757575]">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-lg font-semibold px-2 py-0.5 font-'roboto' leading-[15px] text-[#757575]">
                            Initial Assessment
                        </span>
                    </div>
                    <div className='flex items-center'>
                        <span className="text-lg px-5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientData.name}</span> |
                        <span className="text-lg px-5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientData.age}</span> |
                        <span className="text-lg px-5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientData.gender}</span> |
                        <span className="text-lg px-5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientData.uhid}</span> |
                        <span className="text-lg px-5 py-0.5 font-bold leading-[15px] text-[#000000]">{patientData.inPatient}</span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] leading-[15px] text-[#000000]">
                            Time : {currentTime}
                        </span>
                        <span className="text-lg px-5 py-0.5 font-'roboto' font-[300] leading-[15px] text-[#000000]">
                            {currentDate}
                        </span>
                    </div>
                </div>
                <form onSubmit={formikState.handleSubmit} className='text-left mb-16' autoComplete='off'>
                    <ChiefComplaint formikState={formikState} val={formikState.values.ChiefComplaint} disabled={props.isDisabled} isView={props.isView} isNurse = {props.isNurse}/>
                    <Hisofill formikState={formikState} disabled={props.isDisabled} patient={patientData} isView={props.isView} isNurse = {props.isNurse} />
                    <PastHist formikState={formikState} disabled={props.isDisabled} isView={props.isView} isNurse = {props.isNurse}/>
                    <FamilyHo formikState={formikState} disabled={props.isDisabled} isView={props.isView} isNurse = {props.isNurse}/>
                    <PersonalHo formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <AllergicHo formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Vaccinated formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <DrugHist formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    {
                        String(patientData.gender).toLowerCase() === 'female' &&
                        <GynHist formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    }
                    <Edusoc formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Psycstatus formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Funcstatus formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Nutstatus formikState={formikState} isView={props.isView || false} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Gphysical formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Viralpm formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <CNS formikState={formikState} isNurse = {props.isNurse} disabled={props.isDisabled}/>
                    <Other formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <WPDiagnosis formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Investigations formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Prognosis formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Pertinent formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    <Consents formikState={formikState} disabled={props.isDisabled} isNurse = {props.isNurse}/>
                    {props.isView
                        ? <div className='flex items-center justify-center'>
                            <div className='flex flex-row gap-4'>
                                <button type='button' className="bg-gray border-graycol py-[0.5rem] px-[4rem] my-8 text-sm  border rounded-md text-col h-12"
                                    onClick={() => navigate('/patient/admission-list')}>Cancel
                                </button>
                            </div>
                        </div>
                        : <div className='r-[10px] w-full pl-[20px] pt-[20px] pb-[20px] bg-white text-end'>
                            <button type='submit'
                                onClick={() => {
                                    formikState.values.status = 'in-progress';
                                    formikState.values.NutritionalStatus.Parameter.BMI =
                                    (Number(formikState.values.NutritionalStatus.Parameter.Weight) /
                                        (Number(formikState.values.NutritionalStatus.Parameter.Height) *
                                            Number(formikState.values.NutritionalStatus.Parameter.Height)));
                                    formikState.values.EducationalSocialHistory.Result =
                                    change(Number(formikState.values.EducationalSocialHistory.Education) +
                                        Number(formikState.values.EducationalSocialHistory.Occupation) +
                                        Number(formikState.values.EducationalSocialHistory.TotalPerCapital));
                                    formikState.values.GeneralPhysicalExamination.LevelOfConciseness.Result =
                                    change1(Number(formikState.values.GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse) +
                                        Number(formikState.values.GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse) +
                                        Number(formikState.values.GeneralPhysicalExamination.LevelOfConciseness.MotorResponse));
                                    formikState.values.HistoryOfPresentIllness.Detail.Content = (`My patient Miss.Xyz, of 42 years old female apparently well ${formikState.values.HistoryOfPresentIllness.Detail.Duration} ${formikState.values.HistoryOfPresentIllness.Detail.DMY} back, then ${formikState.values.HistoryOfPresentIllness.Detail.Pronoun} developed ${formikState.values.HistoryOfPresentIllness.Detail.Motion} onset of the above chief complaint since ${formikState.values.HistoryOfPresentIllness.Detail.Duration1} ${formikState.values.HistoryOfPresentIllness.Detail.DMY2} at ${formikState.values.HistoryOfPresentIllness.Detail.Part} this part of the body which is progressively ${formikState.values.HistoryOfPresentIllness.Detail.IncDec} in nature, relived ${formikState.values.HistoryOfPresentIllness.Detail.WithWithout} medications. And associated with ${formikState.values.HistoryOfPresentIllness.Detail.Symptoms} symptoms. For above complaint ${formikState.values.HistoryOfPresentIllness.Detail.Pronoun1} took ${formikState.values.HistoryOfPresentIllness.Detail.Treatment} treatment before she came to our hospital, ${formikState.values.HistoryOfPresentIllness.Detail.WithWithout1} injuries.`);
                                }}
                                className={props.isNurse ? 'w-[120px] h-[38px] bg-gradient mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>Sign Later</button>
                            <button type='submit'
                                onClick={() => {
                                    formikState.values.status = 'completed';
                                    formikState.values.NutritionalStatus.Parameter.BMI =
                                        (Number(formikState.values.NutritionalStatus.Parameter.Weight) /
                                            (Number(formikState.values.NutritionalStatus.Parameter.Height) *
                                                Number(formikState.values.NutritionalStatus.Parameter.Height)));
                                    formikState.values.EducationalSocialHistory.Result =
                                        change(Number(formikState.values.EducationalSocialHistory.Education) +
                                            Number(formikState.values.EducationalSocialHistory.Occupation) +
                                            Number(formikState.values.EducationalSocialHistory.TotalPerCapital));
                                    formikState.values.GeneralPhysicalExamination.LevelOfConciseness.Result =
                                        change1(Number(formikState.values.GeneralPhysicalExamination.LevelOfConciseness.EyeOpeningResponse) +
                                            Number(formikState.values.GeneralPhysicalExamination.LevelOfConciseness.VerbalResponse) +
                                            Number(formikState.values.GeneralPhysicalExamination.LevelOfConciseness.MotorResponse));
                                    formikState.values.HistoryOfPresentIllness.Detail.Content = (`My patient Miss.Xyz, of 42 years old female apparently well ${formikState.values.HistoryOfPresentIllness.Detail.Duration} ${formikState.values.HistoryOfPresentIllness.Detail.DMY} back, then ${formikState.values.HistoryOfPresentIllness.Detail.Pronoun} developed ${formikState.values.HistoryOfPresentIllness.Detail.Motion} onset of the above chief complaint since ${formikState.values.HistoryOfPresentIllness.Detail.Duration1} ${formikState.values.HistoryOfPresentIllness.Detail.DMY2} at ${formikState.values.HistoryOfPresentIllness.Detail.Part} this part of the body which is progressively ${formikState.values.HistoryOfPresentIllness.Detail.IncDec} in nature, relived ${formikState.values.HistoryOfPresentIllness.Detail.WithWithout} medications. And associated with ${formikState.values.HistoryOfPresentIllness.Detail.Symptoms} symptoms. For above complaint ${formikState.values.HistoryOfPresentIllness.Detail.Pronoun1} took ${formikState.values.HistoryOfPresentIllness.Detail.Treatment} treatment before she came to our hospital, ${formikState.values.HistoryOfPresentIllness.Detail.WithWithout1} injuries.`);
                                }} className={props.isNurse ? 'w-[120px] h-[38px] bg-gradient mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>Sign & Exit</button>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
}

export default InitialAssessment;
