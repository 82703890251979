import React from 'react';
import Select from 'react-select';
import FallRisk from '../../utils/json/FallRisk.json';

const RiskScoreCalculation = ({ formikState, onChange, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    const change = (r) => {
        if ((r) > 12) {
            return ('High Fall Risk' + ' ' + '(' + `${r}` + ')');
        } else if ((r) >= 6 && (r) <= 12) {
            return ('Moderate Fall Risk' + ' ' + '(' + `${r}` + ')');
        } else if ((r) < 6) {
            return ('Mild Fall Risk' + ' ' + '(' + `${r}` + ')');
        }
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Full Risk Score Calculation</span>
            </div>
            <div className="flex flex-col w-[99%] px-6">
                <div className="grid grid-cols-c5 gap-[1%] items-center">
                    <div></div>
                    <span className='text-sm font-medium'>SCORING ON ADMISSION</span>
                    <span className='text-sm font-medium'>SCORING AFTER PROCEDURE</span>
                </div>
            </div>
            <div className="flex flex-col w-[99%] px-6">
                <div className="grid grid-cols-c5 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Age :</label>
                    </div>
                    <Select
                        name="scoreCalculation.age1"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.age1', value.value);
                        }}
                        value={
                            FallRisk.Age
                                ? FallRisk.Age.find((option) => option.value === formikState.values.scoreCalculation.age1)
                                : ''}
                        options={FallRisk.Age}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                    <Select
                        name="scoreCalculation.age2"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.age2', value.value);
                        }}
                        value={
                            FallRisk.Age
                                ? FallRisk.Age.find((option) => option.value === formikState.values.scoreCalculation.age2)
                                : ''}
                        options={FallRisk.Age}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                </div>
            </div>
            <div className="flex flex-col w-[99%] px-6">
                <div className="grid grid-cols-c5 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Fall History :</label>
                    </div>
                    <Select
                        name="scoreCalculation.fallHistory1"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.fallHistory1', value.value);
                        }}
                        value={
                            FallRisk.fallHistory
                                ? FallRisk.fallHistory.find((option) => option.value === formikState.values.scoreCalculation.fallHistory1)
                                : ''}
                        options={FallRisk.fallHistory}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                    <Select
                        name="scoreCalculation.fallHistory2"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.fallHistory2', value.value);
                        }}
                        value={
                            FallRisk.fallHistory
                                ? FallRisk.fallHistory.find((option) => option.value === formikState.values.scoreCalculation.fallHistory2)
                                : ''}
                        options={FallRisk.fallHistory}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                </div>
            </div>
            <div className="flex flex-col w-[99%] px-6">
                <div className="grid grid-cols-c5 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Fall Risk Medications :</label>
                    </div>
                    <Select
                        name="scoreCalculation.medications.medications1"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.medications.medications1', value.value);
                        }}
                        value={
                            FallRisk.FallRiskMedications
                                ? FallRisk.FallRiskMedications.find((option) => option.value === formikState.values.scoreCalculation.medications.medications1)
                                : ''}
                        options={FallRisk.FallRiskMedications}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                    <Select
                        name="scoreCalculation.medications.medications2"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.medications.medications2', value.value);
                        }}
                        value={
                            FallRisk.FallRiskMedications
                                ? FallRisk.FallRiskMedications.find((option) => option.value === formikState.values.scoreCalculation.medications.medications2)
                                : ''}
                        options={FallRisk.FallRiskMedications}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                </div>
            </div>
            <div className="flex flex-col w-[99%] px-6">
                <div className="grid grid-cols-c5 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Lines :</label>
                    </div>
                    <Select
                        name="scoreCalculation.lines1"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.lines1', value.value);
                        }}
                        value={
                            FallRisk.Lines
                                ? FallRisk.Lines.find((option) => option.value === formikState.values.scoreCalculation.lines1)
                                : ''}
                        options={FallRisk.Lines}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                    <Select
                        name="scoreCalculation.lines2"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.lines2', value.value);
                        }}
                        value={
                            FallRisk.Lines
                                ? FallRisk.Lines.find((option) => option.value === formikState.values.scoreCalculation.lines2)
                                : ''}
                        options={FallRisk.Lines}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                </div>
            </div>
            <div className="flex flex-col w-[99%] px-6">
                <div className="grid grid-cols-c5 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Mobility :</label>
                    </div>
                    <Select
                        name="scoreCalculation.mobility1"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.mobility1', value.value);
                        }}
                        value={
                            FallRisk.Mobility
                                ? FallRisk.Mobility.find((option) => option.value === formikState.values.scoreCalculation.mobility1)
                                : ''}
                        options={FallRisk.Mobility}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                    <Select
                        name="scoreCalculation.mobility2"
                        isSearchable={true}
                        onChange={(value) => {
                            formikState.setFieldValue('scoreCalculation.mobility2', value.value);
                        }}
                        value={
                            FallRisk.Mobility
                                ? FallRisk.Mobility.find((option) => option.value === formikState.values.scoreCalculation.mobility2)
                                : ''}
                        options={FallRisk.Mobility}
                        className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                        styles={colourStyles}
                        isDisabled={props.isDisabled}
                    >
                    </Select>
                </div>
            </div>
            <div className="flex flex-col w-full  px-6">
                <div className="flex flex-row">
                    <label className="text-col font-roboto text-sm">Total fall risk score : <span className='ml-28 font-[500] italic'> Scoring on Admission - {change(Number(formikState.values.scoreCalculation.age1) + Number(formikState.values.scoreCalculation.fallHistory1) + Number(formikState.values.scoreCalculation.medications.medications1) + Number(formikState.values.scoreCalculation.lines1) + Number(formikState.values.scoreCalculation.mobility1))}</span> | <span className='italic font-[500]'>Scoring after procedure - {change(Number(formikState.values.scoreCalculation.age2) + Number(formikState.values.scoreCalculation.fallHistory2) + Number(formikState.values.scoreCalculation.medications.medications2) + Number(formikState.values.scoreCalculation.lines2) + Number(formikState.values.scoreCalculation.mobility2))}</span></label>
                </div>
            </div>
            <div className="flex flex-col w-full  px-6">
                <div className="grid grid-cols-c3 gap-[1%] items-center">
                    <div className="flex flex-row">
                        <label className="text-col font-roboto text-sm">Name of the staff with ID :</label>
                    </div>
                    <input
                        id="scoreCalculation.staffname"
                        name="scoreCalculation.staffname"
                        type="text"
                        onChange={onChange}
                        value={formikState.values.scoreCalculation.staffname}
                        className="border-none text-col text-sm h-10 w-full px-1 focus:outline-none"
                        placeholder='Name'
                        disabled={props.isDisabled}
                    />
                </div>
            </div>
        </>
    );
};

export default RiskScoreCalculation;
