import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { useNavigate } from "react-router-dom";

import { TextField } from '../components/TextField';
import Button from '../components/Button';

const UserForm = (props) => {
    const addFunc = (value) => {
        // console.log(value);
        props.onAdd(value);
    };

    const editFunc = (value) => {
        // console.log('data', value);
        props.onEdit(value);
    };

    // const navigate = useNavigate();
    // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const formik = useFormik({
        initialValues: {
            firstName: props.data?.firstName || '',
            lastName: props.data?.lastName || '',
            userName: props.data?.userName || '',
            description: props.data?.description || '',
            email: props.data?.email || '',
            password: props.data?.password || '',
            reenterpassword: props.data?.reenterpassword || '',
            cell: props.data?.cell || ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Required *'),
            lastName: Yup.string().required('Required *'),
            userName: Yup.string().required('Required *'),
            description: Yup.string().required('Required *'),
            email: Yup.string('Enter your email')
                .email('Enter a valid email')
                .required('Required'),
            password: Yup.string('Enter your password')
                .min(8, 'Invalid password')
                .required('Required*'),
            reenterpassword: Yup.string('Enter your password')
                .min(8, 'Invalid password')
                .required('Required').oneOf([Yup.ref('password')], 'Passwords do not match')
            // cell: Yup.string().matches(phoneRegExp, 'Invalid').min(10, "to short")
            // .max(10, "to long"),
        }),
        // onSubmit: values => {
        //     // alert(JSON.stringify(values));
        //     console.log(values)
        //    window.location.href = "/user"
        // //    navigate("/user")

        // },
        onSubmit: props.isEdit ? editFunc : addFunc
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="First Name"
                            id="firstName"
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}

                        />
                        {formik.touched.firstName && formik.errors.firstName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.firstName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Last Name"
                            id="lastName"
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.lastName && formik.errors.lastName
                            ? (
                                <div className="flex w-full text-sm text-error font-roboto justify-end">{formik.errors.lastName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="User Name"
                            id="userName"
                            name="userName"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.userName}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.userName && formik.errors.userName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.userName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="User Description"
                            id="description"
                            name="description"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.description && formik.errors.description
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.description}</div>
                            )
                            : null}
                    </div>

                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Email"
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.email && formik.errors.email
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.email}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Cell"
                            id="cell"
                            name="cell"
                            type="text"
                            value={formik.values.cell}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={false}
                            style={props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Password"
                            id="password"
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.password && formik.errors.password
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.password}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%] px-6">
                        <TextField
                            label="Re-Enter Password"
                            id="reenterpassword"
                            name="reenterpassword"
                            type="password"
                            value={formik.values.reenterpassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.reenterpassword && formik.errors.reenterpassword
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.reenterpassword}</div>
                            )
                            : null}
                    </div>

                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[1rem] my-8 text-sm border rounded-md text-white h-10 w-16"
                        disabled={props.isDisabled}
                    />
                    <Button
                        onClick={() => props.handleModalClose(false)}
                        label="Cancel"
                        className="bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col h-10 w-16"
                    />

                </div>
            </div>
        </form>

    );
};

export default UserForm;
