import React from 'react';
import TwoField from '../../components/TwoField';

function PatientParticulars (props) {
    return (
        <div>
            <div className='h-[45px] bg-iat flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                Patient particulars
                {/* <span className='text-[crimson] text-[20px]'><pre>*</pre></span> */}
            </div>
            <div className='bg-white py-2.5'>
                <TwoField field1='Patient name' field2='Age' name1='PatientParticulars.Name' name2='PatientParticulars.Age' val1={props.formikState.values.PatientParticulars.Name} val2={props.formikState.values.PatientParticulars.Age} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TwoField field1='Sex' field2='Blood group' name1='PatientParticulars.Sex' name2='PatientParticulars.BloodGroup' val1={props.formikState.values.PatientParticulars.Sex} val2={props.formikState.values.PatientParticulars.BloodGroup} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TwoField field1='Marriage status' field2='Father/ husband/ guardian name' name1='PatientParticulars.MaritalStatus' name2='PatientParticulars.GuardianName' val1={props.formikState.values.PatientParticulars.MaritalStatus} val2={props.formikState.values.PatientParticulars.GuardianName} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TwoField field1='Nationality' field2='Mobile number' name1='PatientParticulars.Nationality' name2='PatientParticulars.MobileNumber' val1={props.formikState.values.PatientParticulars.Nationality} val2={props.formikState.values.PatientParticulars.MobileNumber} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TwoField field1='Address Line1' field2='Address Line2' name1='PatientParticulars.AddressLine1' name2='PatientParticulars.AddressLine2' val1={props.formikState.values.PatientParticulars.AddressLine1} val2={props.formikState.values.PatientParticulars.AddressLine2} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TwoField field1='City' field2='State' name1='PatientParticulars.City' name2='PatientParticulars.State' val1={props.formikState.values.PatientParticulars.City} val2={props.formikState.values.PatientParticulars.State} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TwoField field1='Country' field2='Pincode' name1='PatientParticulars.Country' name2='PatientParticulars.Pincode' val1={props.formikState.values.PatientParticulars.Country} val2={props.formikState.values.PatientParticulars.Pincode} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
            </div>
        </div>
    );
}

export default PatientParticulars;
