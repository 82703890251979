/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// import React, { useEffect } from 'react';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
// import { useLocation, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Medications from '../containers/InitialAssessment/Fields/Medications';
import Handover from '../containers/ProgressNotes/Handover';
import CheifComplaints from '../containers/ProgressNotes/CheifComplaints';
import Diagnosis from '../containers/ProgressNotes/Diagnosis';
import Investigations from '../containers/ProgressNotes/Investigations';
import SpecialInstruction from '../containers/ProgressNotes/SpecialInstruction';
import Prognosis from '../containers/ProgressNotes/Prognosis';
import VitalSigns from '../containers/ProgressNotes/VitalSigns';
import Referral from '../containers/InitialAssessment/Fields/Referral';
// import { convertToFhir } from '../fhir-evolver';
// import { convertToFhir, convertFromFhir } from '../fhir-evolver';
// import { addBundle } from '../api/bundleRequest';
// import { useSnackbar } from 'react-simple-snackbar';
// import { SnackbarStyles } from '../components/SnackbarStyles';
// import { getClinicalImpressionById } from '../api/clinicalImpression';
import { generateProgressNotesFormik } from '../utils/ProgressNotesFormik';

const ProgressNotesForm = ({ encounter, assessment, primary, ...props }) => {
    const [from, setfrom] = useState('');
    const [to, setto] = useState('');
    const [si, setsi] = useState('');
    const [pogressNotesDetails, setProgressNotesDetails] = useState({
        admissionDetails: '',
        patientDetails: '',
        participantDetails: ''
    });
    // console.log('progressdetailss', pogressNotesDetails?.admissionDetails?.id, pogressNotesDetails?.patientDetails?.id, pogressNotesDetails?.participantDetails?.id);
    // const [openSnackbar] = useSnackbar(SnackbarStyles);
    const location = useLocation();
    // console.log('loc', location.state);
    useEffect(() => {
        // console.log('checking props', encounter, location.state);
        if (location.state) {
            // console.log('locationState', location.state);
            if (props.isAdd) {
                setProgressNotesDetails({
                    admissionDetails: location?.state,
                    patientDetails: location?.state?.patient,
                    participantDetails: location?.state?.participant?.[0]
                });
            } else {
                setProgressNotesDetails({
                    admissionDetails: location?.state.encounter,
                    patientDetails: location?.state?.patient,
                    participantDetails: location?.state?.participant
                });
            }
        } else {
            // console.log('encounter', encounter?.[0]);
            setProgressNotesDetails({
                admissionDetails: encounter?.[0],
                patientDetails: encounter?.[0]?.patient,
                participantDetails: encounter?.[0]?.participant?.[0]
            });
        }
    }, [encounter]);

    const submitHandler = (data, { setSubmitting }) => {
        console.log('inputprogressNotes', data);
        if (from !== '' || to !== '' || si !== '') {
            data.Handover.push({
                id: data.Handover.length,
                HandoverFrom: from,
                HandoverTo: to,
                // Shift: 'Morning (A)',
                // Date: '26/12/2022',
                // Time: '8.00 am',
                SpecialInstruction: si
            });
        }
        formikState.values.clinicalStatus = 'in-progress';
        if (props.resourceIdMap) {
            formikState.values.resourceIdMap = props.resourceIdMap;
        }
        formikState.values.Patient = pogressNotesDetails?.patientDetails?.id;
        formikState.values.Practitioner = pogressNotesDetails?.participantDetails?.id;
        formikState.values.Encounter = pogressNotesDetails?.admissionDetails?.id;
        const val = data;
        props.handleSubmit(val);
        setSubmitting(false);
    };
    let progressNotesInput = {};
    if (props.viewProgressNotes) {
        progressNotesInput = props.viewProgressNotes;
    } else {
        progressNotesInput = Object.assign({}, generateProgressNotesFormik());
    }
    const formikState = useFormik({
        initialValues: progressNotesInput,
        onSubmit: submitHandler
    });

    const updateForm = (data) => {
        formikState.values.Medication = data;
    };

    const updateDiagnosisForm = (data) => {
        formikState.values.Diagnosis = data;
    };

    const updateInvestigationForm = (data) => {
        formikState.values.Investigation = data;
    };

    const updateInstructionForm = (data) => {
        formikState.values.SpecialInstruction = data;
    };
    return (
        <div className='flex flex-col w-full bg-[#F5F5F5] gap-y-4 mb-16'>
            <form className="w-full flex flex-col gap-y-4" onSubmit={formikState.handleSubmit}>
                <Handover formikState={formikState} from={setfrom} to={setto} si={setsi} isDisabled={props.isDisabled}/>
                <CheifComplaints formikState={formikState} isDisabled={props.isDisabled}/>
                <Diagnosis updateData={(data) => updateDiagnosisForm(data)} formikState={formikState} isDisabled={props.isDisabled}/>
                <Investigations formikState={formikState} updateData={(data) => updateInvestigationForm(data)} isDisabled={props.isDisabled}/>
                <Medications formikState={formikState} updateData={(data) => updateForm(data)} isDisabled={props.isDisabled}/>
                <SpecialInstruction formikState={formikState} updateData={(data) => updateInstructionForm(data)} isDisabled={props.isDisabled}/>
                <Prognosis formikState={formikState} isDisabled={props.isDisabled}/>
                <Referral formikState={formikState} isDisabled={props.isDisabled}/>
                <VitalSigns assessment={assessment} primary={primary}/>
                {/* <Link to='/patient/admission-list'> */}
                <div className='flex justify-end'>
                    <button type='submit' className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>Sign Later</button>
                    <button type='submit' className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>Sign & Exit</button>
                </div>
                {/* </Link> */}
            </form>
        </div>
    );
};

export default ProgressNotesForm;
