/* eslint-disable no-console */
import React from 'react';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';
import Checkbox from '../../components/Check';

const DvtRisk = ({ formikState, onChange, onBlur, ...props }) => {
    // const [totalCount, setTotalCount] = useState(0);

    const inputHandleChange = (e) => {
        const { checked, name, value } = e.target;
        formikState.setFieldValue(name, value);
        // if (checked) {
        //     const sum = totalCount + 1;
        //     setTotalCount(sum);
        //     formikState.setFieldValue(name, value);
        // } else {
        //     const sum = totalCount - 1;
        //     setTotalCount(sum);
        //     formikState.setFieldValue(name, value);
        // };
        if (checked) {
            formikState.setFieldValue('dvtRisk.count', formikState.values.dvtRisk.count + 1);
        } else {
            formikState.setFieldValue('dvtRisk.count', formikState.values.dvtRisk.count - 1);
        };
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>DVT-Risk Assessment Chart</span>
            </div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className='flex flex-col w-full px-6'>
                    <div className='grid grid-row gap-[1%] items-center'>
                        <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                            <RadioButton
                                label="On"
                                name="dvtRisk.dvtRisk"
                                id="dvtRisk.on"
                                onChange={onChange}
                                value='on'
                                onBlur={onBlur}
                                checked={formikState.values.dvtRisk.dvtRisk === 'on'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="Off"
                                id="dvtRisk.off"
                                name="dvtRisk.dvtRisk"
                                onChange={onChange}
                                value='off'
                                onBlur={onBlur}
                                checked={formikState.values.dvtRisk.dvtRisk === 'off'}
                                disabled={props.isDisabled}
                            />
                        </div>
                        {formikState.values.dvtRisk.dvtRisk === 'on' &&
                <div className="flex flex-col w-full">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Modified well Criteria :</label>
                        </div>
                        <table className="table-auto border border-slate w-[60%]">
                            <tbody>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2'>Active Cancer</td>
                                    <td className='text-sm p-2'>+1</td>
                                    <td className='text-sm p-2'><Checkbox
                                        id="dvtRisk.activecancer"
                                        name="dvtRisk.activecancer"
                                        // onChange={formikState.handleChange}
                                        onChange= {inputHandleChange }
                                        value='cancer'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.dvtRisk.activecancer === 'cancer' ? true : null}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2 '>Paralysis, Paresis, or recent plaster immobilization of the lower extremities</td>
                                    <td className='text-sm p-2'>+1</td>
                                    <td className='text-sm p-2'><Checkbox
                                        id="dvtRisk.paralysis"
                                        name="dvtRisk.paralysis"
                                        // onChange={formikState.handleChange}
                                        onChange= {inputHandleChange }
                                        value='paralysis'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.dvtRisk.paralysis === 'paralysis' ? true : null}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2'>Recently bedridden for three days or major surgery within the last 12 weeks</td>
                                    <td className='text-sm p-2'>+1</td>
                                    <td className='text-sm p-2'><Checkbox
                                        id="dvtRisk.bedridden"
                                        name="dvtRisk.bedridden"
                                        // onChange={formikState.handleChange}
                                        onChange= {inputHandleChange }
                                        value='bedridden'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.dvtRisk.bedridden === 'bedridden' ? true : null}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2'>Localized tenderness along the deep venous system</td>
                                    <td className='text-sm p-2'>+1</td>
                                    <td className='text-sm p-2'><Checkbox
                                        id="dvtRisk.tenderness"
                                        name="dvtRisk.tenderness"
                                        // onChange={formikState.handleChange}
                                        onChange= {inputHandleChange }
                                        value='tenderness'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.dvtRisk.tenderness === 'tenderness' ? true : null}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2'>Entire leg swollen</td>
                                    <td className='text-sm p-2'>+1</td>
                                    <td className='text-sm p-2'><Checkbox
                                        id="dvtRisk.legswollen"
                                        name="dvtRisk.legswollen"
                                        // onChange={formikState.handleChange}
                                        onChange= {inputHandleChange }
                                        value='legswollen'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.dvtRisk.legswollen === 'legswollen' ? true : null}
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2'>Calf swelling &gt;3 cm larger than asymptomatic side</td>
                                    <td className='text-sm p-2'>+1</td>
                                    <td className='text-sm p-2'><Checkbox
                                        id="dvtRisk.calfswelling"
                                        name="dvtRisk.calfswelling"
                                        // onChange={formikState.handleChange}
                                        onChange= {inputHandleChange }
                                        value='calfswelling'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.dvtRisk.calfswelling === 'calfswelling' ? true : null }
                                    /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2'></td>
                                    <td className='text-sm p-2 text-col'>Total: {formikState.values.dvtRisk.count} </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DvtRisk;
