import React from 'react';
import Select from 'react-select';
import shift from '../../utils/json/ShiftTimes.json';
import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const OldReport = ({ formikState, onChange, onBlur, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Old Report Check List</span>
            </div>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Date :</label>
                            </div>
                            <input
                                id="oldReport.oldreportdate"
                                name="oldReport.oldreportdate"
                                type="date"
                                onChange={onChange}
                                value={formikState.values.oldReport.oldreportdate}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Shift :</label>
                            </div>
                            <Select
                                name="oldReport.oldreportshift"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('oldReport.oldreportshift', value.value);
                                }}
                                value={ shift.find((option) => option.value === formikState.values.oldReport.oldreportshift)}
                                options={shift}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className='flex flex-col w-[49%] px-6'>
                        <div className='grid grid-cols-c4 gap-[1%] items-center'>
                            <span className='flex flex-row text-col font-roboto text-sm'>Old Report :</span>
                            <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                                <RadioButton
                                    label="Yes"
                                    name="oldReport.oldreport"
                                    id="yes"
                                    onChange={onChange}
                                    value='yes'
                                    onBlur={onBlur}
                                    checked={formikState.values.oldReport.oldreport === 'yes'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="No"
                                    id="no"
                                    name="oldReport.oldreport"
                                    onChange={onChange}
                                    value='no'
                                    onBlur={onBlur}
                                    checked={formikState.values.oldReport.oldreport === 'no'}
                                    disabled={props.isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Received By :</label>
                            </div>
                            <input
                                id="oldReport.receivedby"
                                name="oldReport.receivedby"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.oldReport.receivedby}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                placeholder='Write Here'
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Handed Over to :</label>
                            </div>
                            <input
                                id="oldReport.handedover"
                                name="oldReport.handedover"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.oldReport.handedover}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                placeholder='Write Here'
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OldReport;
