import React from 'react';
import Select from 'react-select';
import shift from '../../utils/json/ShiftTimes.json';
import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const BiomedicalEquipment = ({ formikState, onChange, onBlur, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Bio-Medical Equipment check list</span>
            </div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Date & Time :</label>
                        </div>
                        <input
                            id="bioMedicalEquipment.bioMedicalDateTime"
                            name="bioMedicalEquipment.bioMedicalDateTime"
                            type="datetime-local"
                            onChange={onChange}
                            value={formikState.values.bioMedicalEquipment.bioMedicalDateTime}
                            className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Shift :</label>
                        </div>
                        <Select
                            name="bioMedicalEquipment.bioMedicalShift"
                            isSearchable={true}
                            onChange={(value) => {
                                formikState.setFieldValue('bioMedicalEquipment.bioMedicalShift', value.value);
                            }}
                            value={
                                shift.find((option) => option.value === formikState.values.bioMedicalEquipment.bioMedicalShift)}
                            className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                            styles={colourStyles}
                            options = {shift}
                            isDisabled={props.isDisabled}
                        >
                        </Select>
                    </div>
                </div>
                <div className="flex flex-col w-full  px-6">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div></div>
                        <div className="flex flex-row text-col font-roboto text-sm gap-x-10">
                            <RadioButton
                                label="Function"
                                name="bioMedicalEquipment.bioMedical"
                                id="bioMedicalEquipment.function"
                                onChange={onChange}
                                value='function'
                                onBlur={onBlur}
                                checked={formikState.values.bioMedicalEquipment.bioMedical === 'function'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="Not Function"
                                id="bioMedicalEquipment.not function"
                                name="bioMedicalEquipment.bioMedical"
                                onChange={onChange}
                                value='not function'
                                onBlur={onBlur}
                                checked={formikState.values.bioMedicalEquipment.bioMedical === 'not function'}
                                disabled={props.isDisabled}
                            />
                            {formikState.values.bioMedicalEquipment.bioMedical === 'not function' &&
                        <input
                            id="bioMedicalEquipment.bioMedicalText"
                            name="bioMedicalEquipment.bioMedicalText"
                            type="text"
                            onChange={onChange}
                            value={formikState.values.bioMedicalEquipment.bioMedicalText}
                            className=" bg-gray box-border border-graycol focus:outline-none text-sm text-col h-10 w-full px-1"
                            disabled={props.isDisabled}
                        />}
                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-full  px-6">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Remarks :</label>
                        </div>
                        <input
                            id="bioMedicalEquipment.bioMedicalRemarks"
                            name="bioMedicalEquipment.bioMedicalRemarks"
                            type="text"
                            onChange={onChange}
                            value={formikState.values.bioMedicalEquipment.bioMedicalRemarks}
                            className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BiomedicalEquipment;
