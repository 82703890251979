/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Table from '../../components/Table';
import '../../assets/css/Style.css';
import Arrow1 from '../../assets/images/arrow1.svg';
import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';
import View from '../../assets/images/view.svg';
import { patientCustomStyles, nurseCustomStyles } from '../../style';
import { ageCalculation, stringReplace } from '../../utils/common';
import { getClinicalImpressionByEncounter, deleteClinicalImpression } from '../../api/clinicalImpression';
import { convertFromFhir, convertFromFhirList } from '../../fhir-evolver';
import { Tooltip } from 'react-tooltip';
import { useSnackbar } from 'react-simple-snackbar';
import Loader from '../../components/Loader';
import 'react-tooltip/dist/react-tooltip.css';
import useModal from '../../components/useModal';
import DeleteModal from '../../components/DeleteModal';
// import Nav from '../../containers/NurseFunctions/Nav';
import { getEncounter } from '../../api/encounter';

function InitialProgress (props) {
    const [encounter, setEncounter] = useState('');
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };

    useEffect(() => {
        setSpinner(true);
        getEncounter('_include=Encounter:*&_pretty=true').then((response) => {
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                const encounterData = res.filter((el) => el.id === params.id);
                setEncounter(encounterData);
                setSpinner(false);
            });
        }).catch((e) => {
            return e;
        });
        getClinicalImpressionByEncounter(`${params.id}`).then((response) => {
            if (response.data.entry) {
                convertFromFhir('ClinicalImpression', response.data.entry).then((output) => {
                    // console.log('outputtttt', output);
                    for (const impression of output) {
                        if (!impression.patient) {
                            impression.patient = {
                                id: patientDetails.id
                            };
                        }
                        impression.encounter = {
                            id: encounterId
                        };
                        impression.participant = {
                            id: admissionDetails?.participant?.[0].id
                        };
                    }
                    // console.log('clinical list', output);
                    setInitial(output);
                    setSpinner(false);
                });
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }, []);

    const [deleterow, setDeleterow] = useState('');
    const [openSnackbar] = useSnackbar(options);
    const [spinner, setSpinner] = useState(false);
    const [initial, setInitial] = useState('');
    const location = useLocation();
    const admissionDetails = location.state;
    // console.log('admissionDetailslss', admissionDetails);
    const params = useParams();
    // console.log('initiallll', initial);
    let patientDetails = '';
    let encounterId = '';
    let inpatient = '';
    if (admissionDetails) {
        patientDetails = admissionDetails ? admissionDetails?.patient : '';
        encounterId = admissionDetails ? admissionDetails?.id : '';
        inpatient = admissionDetails ? admissionDetails?.inPatientId : '';
    } else {
        patientDetails = encounter ? encounter?.[0].patient : '';
        encounterId = encounter ? encounter?.[0].id : '';
        inpatient = encounter ? encounter?.[0].inPatientId : '';
    }
    // useEffect(() => {
    //     setSpinner(true);
    //     getClinicalImpressionByEncounter(`${encounterId}`).then((response) => {
    //         console.log('enid', encounterId);
    //         if (response.data.entry) {
    //             convertFromFhir('ClinicalImpression', response.data.entry).then((output) => {
    //                 // const Clinical = res.filter(data => data.status === 'completed');
    //                 // const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
    //                 for (const impression of output) {
    //                     if (!impression.patient) {
    //                         impression.patient = {
    //                             id: patientDetails.id
    //                         };
    //                     }
    //                     impression.encounter = {
    //                         id: encounterId
    //                     };
    //                 }
    //                 console.log('clinical list', output);
    //                 setInitial(output);
    //                 setSpinner(false);
    //                 // console.log('initial progress', initial);
    //             });
    //         }
    //     }).catch(e => {
    //         console.log(e);
    //         return e;
    //     });
    // }, []);
    const deleteData = (id) => {
        const list = [...initial];
        const selected = list.find((results) =>
            results.id === id
        );
        list.splice(list.indexOf(selected), 1);
        deleteClinicalImpression(id)
            .then((response) => {
                window.location.reload();
                setTimeout(() => {
                    setSpinner(true);
                    getClinicalImpressionByEncounter(`${encounterId}`).then((response) => {
                        if (response.data.entry !== undefined) {
                            convertFromFhir('ClinicalImpression', response.data.entry).then((res) => {
                                const Clinical = res.filter(data => data.status === 'completed');
                                setInitial(Clinical);
                                setSpinner(false);
                                isShowing(false);
                                openSnackbar('InitialAssessment Deleted Successfully');
                            }).catch(e => {
                                return e;
                            });
                        }
                    });
                }, 1000);
            });
    };

    const { isShowing, toggle } = useModal();

    const deleteHandler = event => {
        setDeleterow(event);
        toggle();
    };
    const columns = [
        {
            name: 'Date',
            selector: (row) => new Date(row.date).toLocaleDateString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            })
        },
        {
            name: 'Type',
            selector: (row) => row.identifier || 'none'
        },
        {
            name: 'Actions',
            selector: (row, index) => (
                <div>
                    {/* <Link to={row.type !== 'Initial Assessment' ? '/progress-notes' : '/initial'} */}
                    <Link to={props.isNurse && row.identifier === 'SecondaryCare' ? `/functions/station/view/${row.id}` : props.isNurse && row.identifier === 'PrimaryCare' ? `/functions/primary-care/view/${row.id}` : props.isNurse && row.identifier === 'ProgressNotes' ? `/nurse/progress/view/${row.id}` : props.isNurse ? `/nurse/assessment/view/${row.id}` : row.identifier === 'PrimaryCare' ? `/primary/view/${row.id}` : row.identifier === 'SecondaryCare' ? `/secondary/view/${row.id}` : row.identifier === 'ProgressNotes' ? `/patient/progress-notes/view/${row.id}` : `/initial/view/${row.id}`} state={row}>
                        <button className="px-1" id={`view${index}`}>
                            <img className="h-3 w-4"
                                src={View}
                                alt="view" />
                            <Tooltip place='bottom' type="success" anchorId={`view${index}`} effect="solid" content="View"/>
                        </button>
                    </Link>
                    {/* <Link to={row.type !== 'Initial Assessment' ? '/progress-notes' : '/initial'} state={row}> */}
                    <Link to={props.isNurse && row.identifier === 'SecondaryCare' ? `/functions/station/edit/${row.id}` : props.isNurse && row.identifier === 'PrimaryCare' ? `/functions/primary-care/edit/${row.id}` : props.isNurse && row.identifier === 'ProgressNotes' ? `/nurse/progress/edit/${row.id}` : props.isNurse ? `/nurse/assessment/edit/${row.id}` : row.identifier === 'PrimaryCare' ? `/primary/edit/${row.id}` : row.identifier === 'SecondaryCare' ? `/secondary/edit/${row.id}` : row.identifier === 'ProgressNotes' ? `/patient/progress-notes/edit/${row.id}` : `/initial/edit/${row.id}` } state={row}>
                        <button className="px-1" id={`edit${index}`}>
                            <Tooltip place='bottom' type="success" anchorId={`edit${index}`} effect="solid" content="Edit"/>
                            <img className="h-3 w-4"
                                src={Edit}
                                alt="edit" />
                        </button>
                    </Link>
                    {/* </Link> */}
                    {props.isNurse && row.identifier === 'InitialAssessment'
                        ? <button id={`delete${index}`} onClick={() => deleteHandler(row.id)} style={{ display: 'none' }}>
                            <img className="h-3 w-4" src={Delete} alt="delete" />
                        </button>
                        : props.isNurse && row.identifier === 'ProgressNotes'
                            ? <button id={`delete${index}`} onClick={() => deleteHandler(row.id)} style={{ display: 'none' }}>
                                <img className="h-3 w-4" src={Delete} alt="delete" />
                            </button>

                            : <button id={`delete${index}`} onClick={() => deleteHandler(row.id)}>
                                <img className="h-3 w-4" src={Delete} alt="delete" />
                            </button>}
                    <Tooltip place='bottom' type="success" anchorId={`delete${index}`} effect="solid" content="Delete"/>
                    <DeleteModal
                        isShowing={isShowing}
                        hide={toggle}
                        deleteData={deleteData}
                        data={deleterow}
                        msg='Are you sure want to delete the patient?'
                    />
                </div>
            )
        }
    ];
    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className="bg-background h-full w-full">
                { props.isNurse
                    ? <div className="flex flex-row justify-between px-4 gap-x-10">
                        <div className="flex items-center">
                            <Link to={'/home'}>
                                <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                            Home
                                </span>
                            </Link>
                            <img src={Arrow1} alt="arrow" />
                            <span className="text-black font-normal text-base  font-roboto px-2 py-2">Assessment</span>
                        </div>
                        <div className='flex flex-row flex-wrap'>
                            <div className="flex flex-col px-2 pb-1 pt-3 ">
                                <Link to={`/functions/primary-care/${params.id}`} state={ location.state }>
                                    <button className=" p-2   bg-gradient font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Primary Care</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/functions/station'} state={ location.state }>
                                    <button className=" p-2   bg-gradient font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Secondary Care</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    : <div className="flex flex-row justify-between px-4 gap-x-10">
                        <div className="flex items-center">
                            <Link to={'/home'}>
                                <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                                </span>
                            </Link>
                            <img src={Arrow1} alt="arrow" />
                            <Link to={'/patient'}>
                                <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">Patients</span>
                            </Link>
                            <img src={Arrow1} alt="arrow" />
                            <span className="text-black font-normal text-base  font-roboto px-2 py-2">Assessment</span>
                        </div>
                        <div className='flex flex-row flex-wrap'>
                            <div className="flex flex-col px-2 pb-1 pt-3 ">
                                <Link to={`/initial/add/${params.id}`} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Initial Assessment</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={`/progress-notes/${params.id}`} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Progress Notes</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={`/discharge-summary/add/${params.id}`} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Discharge Summary</button>
                                </Link>
                            </div>
                            {/* <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/critical-report'} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Critical Report Alert Notes</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/adendum-notes'} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Adendum Notes</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/short-notes'} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Short Notes</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/emergency-notes'} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Emergency Notes</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/opd-slip'} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add OPD-Slip</button>
                                </Link>
                            </div>
                            <div className="flex flex-col px-2 pb-1 pt-3">
                                <Link to={'/transfer-notes'} state={ admissionDetails }>
                                    <button className=" p-2   bg-gradient-to-t font-roboto from-[#006E82] to-[#00a2b7] border border-blue text-base font-medium text-white">+ Add Transfer Notes</button>
                                </Link>
                            </div> */}
                        </div>
                    </div> }
                <div className="flex flex-row justify-between px-4">
                    <span className="text-3xl font-bold px-3 py-5 text-black">
                        Assessment
                    </span>
                </div>
                <div className= { props.isNurse ? 'mx-2 bg-gradient from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between' : 'mx-2 bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between' }>
                    <span className='text-white text-base font-roboto '>{patientDetails?.firstName?.[0].toUpperCase() + ' ' + patientDetails?.surname?.toUpperCase()} | {stringReplace(patientDetails?.gender)} | {ageCalculation(patientDetails?.birthDate)}</span>
                    <span className='text-white text-base font-roboto '> {patientDetails?.uhid} | {inpatient}</span>
                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between !overflow-x-auto">
                    <Table rows={initial} columns={columns} customStyles= {props.isNurse ? nurseCustomStyles : patientCustomStyles }/>
                </div>
            </div>

        </div>
    );
}

export default InitialProgress;
