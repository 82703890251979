import React from 'react';
import Diagnosis from '../../forms/Diagnosis';
import Arrow1 from '../../assets/images/arrow1.svg';
import { useLocation, Link } from 'react-router-dom';

const PatientDiagnosisView = () => {
    const location = useLocation();
    return (
        <div className="bg-gray h-full w-full">
            <div className="flex flex-row justify-between ">
                <div className="flex items-center">
                    <Link to="/home">
                        <span className="text-base font-normal px-2 py-2 text-col font-roboto">
                    Home
                        </span>
                    </Link>
                    <img src={Arrow1} alt="arrow"/>
                    <span className="text-black font-normal text-base px-2 py-2 font-roboto">Patient Registration Form</span>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
            Patient Diagnosis Form
                </span>
            </div>
            <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                <Diagnosis viewData={location.state} isDisabled={true} style={{
                    backgroundColor: 'white'
                }}/>
            </div>
        </div>
    );
};

export default PatientDiagnosisView;
