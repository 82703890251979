import axios from '../utils/axios';

export const addQuestionnaire = (body) => {
    return axios.post('/prod/fhir/Questionnaire', JSON.stringify(body)).then((response) => {
        return 'Questionniare created successfully';
    });
};

export const getQuestionnaire = (params) => {
    let url = '/prod/fhir/Questionnaire';
    if (params) {
        url += `${params}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};

export const editQuestionnaire = (id, body) => {
    return axios.put(`/prod/fhir/Questionnaire/${id}`, body).then((response) => {
        return response;
    });
};

export const deleteQuestionnaire = (id) => {
    return axios.delete(`/prod/fhir/Questionnaire/${id}`).then((response) => {
        return response;
    });
};
