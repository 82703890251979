export const nurseCustomStyles = {
    headCells: {
        style: {
            background: 'linear-gradient(178.36deg, #BF1C2B 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            color: 'white',
            fontSize: '16px'
        }
    },
    cells: {
        style: {
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
        }
    }
};

export const patientCustomStyles = {
    headCells: {
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            color: 'white',
            fontSize: '16px'
        }
    },
    cells: {
        style: {
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
        }
    }
};

export const pharmacyCustomStyles = {
    headCells: {
        style: {
            background: 'linear-gradient(178.36deg, #EAC05D 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            color: 'white',
            fontSize: '16px'
        }
    },
    cells: {
        style: {
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
        }
    }
};

export const pharmacyPatientHistoryCustomStyles = {
    headCells: {
        style: {
            fontWeight: '700',
            fontSize: '13px',
            border: '.5px solid black',
            textAlign: 'center'
        }
    },
    cells: {
        style: {
            border: '.5px solid black',
            margin: '0px !important'
        }
    }
};
export const labCustomStyles = {
    headCells: {
        style: {
            background: 'linear-gradient(178.36deg, #1C7CD5 1.4%, rgba(0, 0, 0, 0.9) 245.84%)',
            color: 'white',
            fontSize: '16px'
        }
    },
    cells: {
        style: {
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
        }
    }
};

export const tableHeadingPatientStyle = {
    style: {
        background: 'linear-gradient(178.36deg, #EAC05D 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
        color: 'white'
    }
};
