export const ROLE = {
    doctor: {
        name: 'doctor',
        rootPath: '/home'
    },
    nurse: {
        name: 'nurse',
        rootPath: '/nurse/patient'
    },
    pharmacy: {
        name: 'pharmacy',
        rootPath: '/pharmacy'
    },
    lab: {
        name: 'lab',
        rootPath: '/lab/patient'
    },
    admin: {
        name: 'admin',
        rootPath: '/user'
    }
};
