export const toFhir = (data) => {
    // params = data.encounterId, data.patientId, data.status, data.dateTime,data.ReferredFrom, data.ReferredTo, data.Data, data.Instruction
    const result = {
        status: data.status || 'active',
        resourceType: 'ServiceRequest',
        identifier: [
            {
                system: 'referral',
                value: 'ProgressNotes'
            }
        ],
        subject: {
            reference: 'Patient/' + data.patientId,
            type: 'Patient'
        },
        encounter: {
            reference: 'Encounter/' + data.encounterId,
            type: 'Encounter'
        },
        code: {
            coding: [
                {
                    code: 3457005,
                    display: 'Patient Referral'
                }
            ]
        },
        occurrenceDataTime: data.dateTime || new Date().toISOString(),
        authoredOn: data.dateTime || new Date().toISOString(),
        note: [
            {
                authorString: 'ReferredFrom',
                text: data.ReferredFrom || 'none'
            }, {
                authorString: 'ReferredTo',
                text: data.ReferredTo || 'none'
            }, {
                authorString: 'Data',
                text: data.Data || 'none'
            }, {
                authorString: 'Instruction',
                text: data.Instruction || 'none'
            }, {
                authorString: 'Department',
                text: data.Department || 'none'
            }
        ]
    };
    return Promise.resolve(result);
};

export const fromFhir = (data) => {
    const obj = {};
    if (data.note) {
        obj.ReferredTo = data.note.find((el) => el.authorString === 'ReferredTo')?.text;
        obj.ReferredFrom = data.note.find((el) => el.authorString === 'ReferredFrom')?.text;
        obj.Data = data.note.find((el) => el.authorString === 'Data')?.text;
        obj.Department = data.note.find((el) => el.authorString === 'Department')?.text;
        obj.Instruction = data.note.find((el) => el.authorString === 'Instruction')?.text;
    }
    return Promise.resolve(obj);
};
