import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { useNavigate } from "react-router-dom";

import { TextField } from '../components/TextField';
import Button from '../components/Button';

const HospitalForm = (props) => {
    const addFunc = (value) => {
        // console.log(value)
        props.onAdd(value);
    };

    const editFunc = (value) => {
        // console.log("data",value)
        props.onEdit(value);
    };

    // const navigate = useNavigate();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const formik = useFormik({
        initialValues: {
            hospitalname: props.data?.hospitalname || '',
            logo: props.data?.logo || '',
            address1: props.data?.address1 || '',
            address2: props.data?.address2 || '',
            city: props.data?.city || '',
            pincode: props.data?.pincode || '',
            email: props.data?.email || '',
            phone: props.data?.phone || ''
        },
        validationSchema: Yup.object({
            hospitalname: Yup.string().required('Required *'),
            // logo:Yup.mixed().nullable()
            // .required('A file is required').test('fileType', "Unsupported File Format", value => SUPPORTED_FORMATS.includes(['image/*']) ),
            address1: Yup.string().required('Required *'),
            address2: Yup.string().required('Required *'),
            city: Yup.string().required('Required *'),
            pincode: Yup.string().required('Required *'),
            email: Yup.string().email('Enter a valid email').required('required'),
            phone: Yup.string().matches(phoneRegExp, 'Invalid').min(10, 'to short')
                .max(10, 'to long').required('Required *')
        }),
        onSubmit: props.isEdit ? editFunc : addFunc
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Hospital Name"
                            id="hospitalname"
                            name="hospitalname"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.hospitalname}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.hospitalname && formik.errors.hospitalname
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.hospitalname}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            accept="image/*"
                            label="Logo"
                            id="logo"
                            name="logo"
                            type="file"
                            // value={formik.values.logo}
                            // onChange={formik.handleChange}
                            onChange={(e) =>
                                formik.setFieldValue('logo', e.currentTarget.files[0])
                            }
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.logo && formik.errors.logo
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.logo}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Address Line1"
                            id="address1"
                            name="address1"
                            type="text"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.address1 && formik.errors.address1
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.address1}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Address Line2"
                            id="address2"
                            name="address2"
                            type="text"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.address2 && formik.errors.address2
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.address2}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="City"
                            id="city"
                            name="city"
                            type="text"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.city && formik.errors.city
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.city}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Pincode"
                            id="pincode"
                            name="pincode"
                            type="text"
                            value={formik.values.pincode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.pincode && formik.errors.pincode
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.pincode}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Email"
                            id="email"
                            name="email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.email && formik.errors.email
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.email}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Phone Number"
                            id="phone"
                            name="phone"
                            type="tel"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}
                        />
                        {formik.touched.phone && formik.errors.phone
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formik.errors.phone}</div>
                            )
                            : null}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[1rem] my-8 text-sm border rounded-md text-white h-10 w-16"
                    />
                    <Button
                        onClick={() => props.handleModalClose(false)}
                        label="Cancel"
                        className="bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col h-10 w-16"
                    />

                </div>
            </div>
        </form>

    );
};

export default HospitalForm;
