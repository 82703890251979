/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
// import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { convertFromFhir, convertToFhir } from '../../fhir-evolver';
import { getClinicalImpressionById } from '../../api/clinicalImpression';
import { addBundle } from '../../api/bundleRequest';
import Loader from '../../components/Loader';
import PrimaryCare from '../../containers/NurseFunctions/PrimaryCare';

const PrimaryCareEdit = (props) => {
    const params = useParams();
    const [viewPrimary, setViewPrimary] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [arr, setArr] = useState('');

    const navigate = useNavigate();
    // const location = useLocation();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };

    const [snackbarOpen] = useSnackbar(options);
    let clinical = '';
    let ques = '';
    const observation = [];
    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            const clinicalImpression = response.data.entry.find((data) => data.resource.resourceType === 'ClinicalImpression');
            clinical = { resourceType: clinicalImpression.resource.resourceType, id: clinicalImpression.resource.id };
            const questionnaireResponse = response.data.entry.find((data) => data.resource.resourceType === 'QuestionnaireResponse');
            ques = { resourceType: questionnaireResponse.resource.resourceType, id: questionnaireResponse.resource.id };
            const filterData = response.data.entry.filter((data) => data.resource.resourceType === 'Observation');
            for (const data of filterData) {
                observation.push({ resourceType: data.resource.resourceType, id: data.resource.id, identifier: data.resource.identifier[0].value });
            }
            // console.log(observation);
            setArr({ ques, clinical, observation });
            convertFromFhir('PrimaryCare', response).then((output) => {
                console.log('Primary care evolver response', output);
                setViewPrimary(output);
            });
        });
    }, []);

    const onEdit = (obj) => {
        console.log('objobj', obj);
        convertToFhir('PrimaryCare', obj).then(res => {
            console.log('primaryeditobj', res);
            addBundle(res)
                .then((response) => {
                    console.log('Api response', response);
                    setSpinner(true);
                    snackbarOpen('PrimaryCare updated Successfully');
                    setTimeout(() => {
                        navigate(`/nurse/assessment/${obj?.[0].Encounter}`);
                    }, 1000);
                })
                .catch(e => {
                    return e;
                });
        });
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            { viewPrimary && <PrimaryCare handleSubmit={onEdit} viewPrimary = {viewPrimary} isDisabled={false} isEdit={true} type='edit' isNurse = {true} data={arr} isDoctor = {props.isDoctor}/>}
        </>
    );
};

export default PrimaryCareEdit;
