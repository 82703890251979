/* eslint-disable no-console */
import { useEffect, React, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import '../../assets/css/Style.css';
import styled from 'styled-components';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import Edit from '../../assets/images/edit.svg';
import View from '../../assets/images/view.svg';
import { pharmacyCustomStyles } from '../../style';
import { ageCalculation, stringReplace } from '../../utils/common';
import { getEncounter } from '../../api/encounter';
import { convertFromFhirList } from '../../fhir-evolver';
import Loader from '../../components/Loader';

const pharmacyPatient = () => {
    const [spinner, setSpinner] = useState(false);
    const [encounterlistfilter, setEncounterListFilter] = useState([]);
    useEffect(() => {
        setSpinner(true);
        getEncounter('_include=Encounter:*&_pretty=true').then((response) => {
            convertFromFhirList('EncounterPharmacy', response.data.entry).then((res) => {
                const data = res.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setEncounterListFilter(data.filter((data) => data.medicationRequest.length > 0));
                setSpinner(false);
            });
        }).catch((e) => {
            return e;
        });
    }, []);
    const StyledCell = styled.div`
        &.male {
            background: #C8DBDF;
            color: black;
            padding-top:4px;
            padding-bottom:4px;
            padding-left:6px;
            padding-right:6px;
            border-radius: 50%;
            border: 1px solid #D3D3D3;
        }
        &.female {
            background: #DCDBE1;
            color: black;
            padding-top:4px;
            padding-bottom:4px;
            padding-left:9px;
            padding-right:9px;
            border-radius: 50%;
            border: 1px solid #D3D3D3;
        }
        &.other {
            background: #EAE6E3 ;
            color: black;
            padding-top:4px;
            padding-bottom:4px;
            padding-left:7px;
            padding-right:7px;
            border-radius: 50%;
            border: 1px solid #D3D3D3;
        }
    `;
    function getCssClass (value) {
        if (value === 'male') return 'male';
        else if (value === 'female') return 'female';
        return 'other';
    }
    const medicationStatusFind = (data) => {
        console.log('medicationstatus', data);
        if (data.filter((data) => data.status === 'on-hold').length > 0) {
            return 'Partial Delivered';
        } else {
            if (data.filter((data) => data.status === 'completed').length === data.length) {
                return 'Delivered';
            } else if (data.filter((data) => data.status === 'active').length === data.length) {
                return 'Yet to be Delivered';
            } else {
                return 'Partial Delivered';
            }
        }
    };
    const columns = [
        {
            name: 'UHID',
            selector: (row) => row.patient.uhid,
            sortable: true
        },
        {
            name: 'InPatient Id',
            selector: (row) => row.inPatientId,
            width: '150px',
            sortable: true
        },
        {
            name: 'Patient',
            selector: (row) => row.patient.firstName[0].toUpperCase() + ' ' + row.patient.surname.toUpperCase(),
            sortable: true
        },
        {
            name: 'Age',
            selector: (row) => ageCalculation(row.patient.birthDate),
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row.gender,
            sortable: true,
            cell: (row) => (
                <StyledCell className={getCssClass(row.patient.gender)}>
                    {stringReplace(row.patient.gender)}
                </StyledCell>
            )
        },
        {
            name: 'Doctor',
            selector: (row) => row.participant[0].givenName[0].toUpperCase() + ' ' + row.participant[0].family.toUpperCase(),
            sortable: true
        },
        {
            name: 'Floor',
            selector: (row) => row.location[1].name.toUpperCase(),
            sortable: true
        },
        {
            name: 'Ward',
            selector: (row) => row.location[2].name.toUpperCase(),
            sortable: true
        },
        {
            name: 'Room',
            selector: (row) => row.location[3].name.toUpperCase(),
            sortable: true
        },
        {
            name: 'Bed',
            selector: (row) => row.location[4].name.toUpperCase(),
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => medicationStatusFind(row.medicationRequest),
            width: '150px',
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => medicationStatusFind(row.medicationRequest) === 'Delivered',
                    style: {
                        color: '#429C0A'
                    }
                },
                {
                    when: row => medicationStatusFind(row.medicationRequest) === 'Yet to be Delivered',
                    style: {
                        color: '#FF0000'
                    }
                },
                {
                    when: row => medicationStatusFind(row.medicationRequest) === 'Partial Delivered',
                    style: {
                        color: '#F17401'
                    }
                }
            ]
        },
        {
            name: 'Actions',
            selector: (row) => (
                <div>
                    <Link to={`/pharmacy/patient/view/${row.id}`} state={ row }>
                        <button className="px-1">
                            <img className="h-3 w-4" src={View} alt="view" />
                        </button>
                    </Link>
                    <Link to={`/pharmacy/patient/edit/${row.id}`} state={ row }>
                        <button className="px-1">
                            <img className="h-3 w-4" src={Edit} alt="edit" />
                        </button>
                    </Link>
                </div>
            )
        }
    ];

    return (
        <><Loader showSpinner={spinner} />
            {/* <div className="h-screen"> */}
            <div className="bg-background h-full w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center">
                        <Link to={'/home'}>
                            <span className="text-base font-normal px-2 py-2 text-col">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base  font-roboto px-2 py-2">Patients</span>
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <span className="text-3xl font-bold px-2 py-5 text-black">
                        Patients
                    </span>
                    <div className="flex flex-col p-2">
                        <div className="pt-2 relative mx-auto text-col">
                            <input
                                className="shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none"
                                type="search"
                                name="search"
                                placeholder="Search" />
                            <button type="submit" className="absolute right-0 top-0 mt-6 mr-4">
                                <img src={Search} alt="search" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                    <Table rows={encounterlistfilter} columns={columns} isNurse={true} customStyles={pharmacyCustomStyles} />
                </div>
            </div>
            {/* </div> */}
        </>
    );
};

export default pharmacyPatient;
