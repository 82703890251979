import React from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Medications from '../containers/InitialAssessment/Fields/Medications';
import Handover from '../containers/ProgressNotes/Handover';
import CheifComplaints from '../containers/ProgressNotes/CheifComplaints';
import Diagnosis from '../containers/ProgressNotes/Diagnosis';
import Investigations from '../containers/ProgressNotes/Investigations';
import SpecialInstruction from '../containers/ProgressNotes/SpecialInstruction';
import Prognosis from '../containers/ProgressNotes/Prognosis';
import VitalSigns from '../containers/ProgressNotes/VitalSigns';
import { convertToFhir } from '../fhir-evolver';
import { addBundle } from '../api/bundleRequest';
import { useSnackbar } from 'react-simple-snackbar';
import { SnackbarStyles } from '../components/SnackbarStyles';

const ShortNotesForm = () => {
    const [openSnackbar] = useSnackbar(SnackbarStyles);
    const location = useLocation();
    const navigate = useNavigate();
    const admissionDetails = location?.state;
    const patientDetails = admissionDetails ? admissionDetails.patient : '';
    const participantDetails = admissionDetails?.participant;
    const formikState = useFormik({
        initialValues: {
            patient: patientDetails?.id,
            practitioner: participantDetails?.[0].id,
            encounter: admissionDetails?.id,
            Popup: [],
            handover: {
                HandoverFrom: '',
                HandoverTo: '',
                SpecialInstruction: ''
            },
            cheifcomplaints: {
                CheifComplaints: ''
            },
            prognosis: {
                Prognosis: '',
                ExplainedTo: ''
            },
            DiagnosisPopup: [],
            InvestigationPopup: [],
            InstructionPopup: []
        },
        onSubmit: values => {
            // eslint-disable-next-line no-console
            console.log('inputValues', values);
            convertToFhir('MedicationrequestBundle', values).then((output) => {
                addBundle(output)
                    .then(res => {
                        // eslint-disable-next-line no-console
                        console.log('progress final result', res);
                        openSnackbar('Progress note Created Successfully');
                        navigate('/patient/admission-list');
                    });
            });
        }
    });

    const updateForm = (data) => {
        formikState.values.Popup = data;
    };

    const updateDiagnosisForm = (data) => {
        formikState.values.DiagnosisPopup = data;
    };

    const updateInvestigationForm = (data) => {
        formikState.values.InvestigationPopup = data;
    };

    const updateInstructionForm = (data) => {
        formikState.values.InstructionPopup = data;
    };

    return (
        <div className='flex flex-col w-full bg-[#F5F5F5] gap-y-4 mb-16'>
            <form className="w-full flex flex-col gap-y-4" onSubmit={formikState.handleSubmit}>
                <Handover formikState={formikState}/>
                <CheifComplaints formikState={formikState}/>
                <Diagnosis updateData={(data) => updateDiagnosisForm(data)} formikState={formikState}/>
                <Investigations updateData={(data) => updateInvestigationForm(data)}/>
                <Medications updateData={(data) => updateForm(data)} />
                <SpecialInstruction updateData={(data) => updateInstructionForm(data)}/>
                <Prognosis formikState={formikState}/>
                <VitalSigns />
                {/* <Link to='/patient/admission-list'> */}
                <div className='flex justify-end'>
                    <button type='submit' className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>Sign Later</button>
                    <button type='submit' className='w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>Sign & Exit</button>
                </div>
                {/* </Link> */}
            </form>
        </div>
    );
};

export default ShortNotesForm;
