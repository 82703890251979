/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InitialAssessment from '../InitialAssessment/InitialAssessment';
import { convertFromFhir } from '../../fhir-evolver';
import { getClinicalImpressionById } from '../../api/clinicalImpression';
import { toInitialAssessmentForm } from '../../fhir-evolver/resources/ClinicalImpression';

const InitialAssessmentView = (props) => {
    const params = useParams();
    const [viewAssessment, setViewAssessment] = useState('');
    const [patientDetails, setPatientDetails] = useState('');
    const [encounter, setEncounter] = useState('');

    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            // console.log('clinical', response);
            convertFromFhir('ClinicalImpression', response.data.entry).then((res) => {
                // console.log('clinicalfhir', res);
                setEncounter(res?.[0].encounter);
                setPatientDetails(res?.[0].patient);
                toInitialAssessmentForm(res).then((res) => {
                    setViewAssessment(res);
                }).catch(e => {
                    return e;
                });
            });
        });
    }, []);

    return (
        <>
            { viewAssessment && <InitialAssessment encounter= { encounter } viewData= { viewAssessment } patient = {patientDetails} isDisabled={true} isView={true} isNurse = {props.isNurse}/>}
        </>
    );
};

export default InitialAssessmentView;
