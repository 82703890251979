export const toFhir = (obj) => {
    return Promise.resolve({
        resourceType: 'MedicationDispense',
        meta: {
            profile: [
                'http://3.109.111.166:8080/fhir/StructureDefinition/MedicationDispense'
            ]
        },
        status: obj.status,
        medicationCodeableConcept: {
            text: obj.Med
        },
        medicationReference: {
            reference: 'Medication/275'
        },
        subject: {
            reference: `Patient/${obj.patient}`
        },
        context: {
            reference: `Encounter/${obj.encounter}`
        },
        performer: {
            actor: `Practitioner/${obj.practitioner}`
        },
        authorizingPrescription: [{
            reference: `MedicationRequest/${obj.medicationRequest}`
        }],
        quantity: { value: obj.quantityGiven },

        dosageInstruction: [
            {
                timing: {
                    repeat: {
                        when: [
                            obj.Food
                        ],
                        boundsPeriod: {
                            start: obj.StartFormat,
                            end: obj.EndFormat
                        }
                    }
                },
                additionalInstruction: [{
                    coding: [
                        {
                            display: obj.Days
                        }
                    ]
                }],
                route: {
                    coding: [
                        {
                            system: 'http://snomed.info',
                            code: 26643006,
                            display: obj.Route
                        }
                    ]
                },
                method: {
                    coding: [
                        {
                            system: 'http://snomed.info/id/',
                            code: 420360002,
                            display: obj.Reg
                        }
                    ]
                },
                doseAndRate: [
                    {
                        doseRange: {
                            low: {
                                value: obj.Dosage,
                                unit: obj.Du
                            },
                            high: {
                                value: obj.Dosage,
                                unit: obj.Du
                            }
                        }
                    }
                ]
            }
        ]
    });
};
