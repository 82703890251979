/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProgressNotesForm from '../../forms/ProgressNotes';
import { convertFromFhir } from '../../fhir-evolver';
import { getClinicalImpressionById, getClinicalImpression } from '../../api/clinicalImpression';
// import { generateProgressNotesFormik } from '../../utils/ProgressNotesFormik';

const ProgressNotesView = () => {
    const params = useParams();
    const [viewProgressNotes, setViewProgressNotes] = useState('');
    const [viewPrimary, setViewPrimary] = useState('');

    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            convertFromFhir('Progress-Notes', response).then((res) => {
                console.log('viewProgressNotes', res);
                setViewProgressNotes(res);
            });
        });
        getClinicalImpression('?_sort=-_id&identifier=PrimaryCare&_count=1&_include=*').then((primaryCareResource) => {
            convertFromFhir('PrimaryCare', primaryCareResource).then((resolved) => {
            // // eslint-disable-next-line no-console
            //     console.log('resolved', resolved);
                setViewPrimary(resolved);
            });
        });
    }, []);
    return (
        <>
            { viewProgressNotes && <ProgressNotesForm viewProgressNotes = {viewProgressNotes} isView = {true} isDisabled={true} primary = {viewPrimary}/>}
        </>
    );
};
export default ProgressNotesView;
