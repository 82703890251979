import React from 'react';
import NurseContainer from '../../components/NurseContain/NurseContainer';
import Data from '../../utils/json/Functionfields.json';
import VentilationType from '../../components/NurseContain/VentilationType';

function VentilationParams ({ isDisabled, ...props }) {
    return (
        <div>
            <VentilationType data={Data.VentilationType} formikState={props.formikState} val={props.formikState.values.VentilationType} isDoctor={props.isDoctor} isDisabled = {isDisabled}/>
            <NurseContainer data={Data.Tidal} formikState={props.formikState} val={props.formikState.values.Tidal} unit={'ml'} isDoctor={props.isDoctor} isDisabled = {isDisabled}/>
            <NurseContainer data={Data.Fio2} formikState={props.formikState} val={props.formikState.values.Fio2} unit={'%'} isDoctor={props.isDoctor} isDisabled = {isDisabled}/>
            <NurseContainer data={Data.PEEP} formikState={props.formikState} val={props.formikState.values.PEEP} isDoctor={props.isDoctor} isDisabled = {isDisabled}/>
            <NurseContainer data={Data.Airway} formikState={props.formikState} val={props.formikState.values.Airway} isDoctor={props.isDoctor} isDisabled = {isDisabled}/>
            <NurseContainer data={Data.RateOfRespiration} formikState={props.formikState} val={props.formikState.values.RateOfRespiration} unit={'Per min'} isDoctor={props.isDoctor} isDisabled = {isDisabled}/>
        </div>
    );
}

export default VentilationParams;
