import { patientExtensionMap } from '../../utils/common.js';
// import { config } from '../../../utils/config.js';

export const toFhir = (obj) => {
    return Promise.resolve({
        resourceType: 'Patient',
        meta: {
            profile: [
                'http://3.109.121.35:8080/fhir/StructureDefinition/EhrPatient'
            ]
        },
        identifier: [
            {
                system: 'UHID',
                value: obj.uhid
            }
        ],
        name: [
            {
                given: obj.firstName,
                family: obj.surName,
                text: obj.firstName + ' ' + obj.surName
            }
        ],
        active: true,
        gender: obj.gender,
        maritalStatus: {
            text: obj.maritalStatus
        },
        managingOrganization: {
            type: 'Organization',
            reference: `Organization/${3}`
        },
        telecom: [
            {
                value: obj.telecom
            }
        ],
        birthDate: obj.birthDate,
        address: [{
            use: 'home',
            line: [obj.resaddress1, obj.resaddress2],
            city: obj.city,
            // district: obj.district,
            state: obj.state,
            postalCode: obj.postalCode,
            country: obj.country,
            period: {}
        }, {
            use: 'work',
            line: [obj.officeAddress1, obj.officeAddress2],
            city: obj.city1,
            // district: obj.district1,
            state: obj.state1,
            postalCode: obj.postalCode1,
            country: obj.country1,
            period: {}
        }],

        extension: [
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Guardianship',
                valueCode: obj.guardianName
            },
            {
                url: 'http://hl7.org/fhir/StructureDefinition/patient-religion',
                valueCode: obj.religion
            },
            {
                url: 'http://hl7.org/fhir/StructureDefinition/patient-nationality',
                valueCode: obj.nationality
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Occupation',
                valueCode: obj.occupation
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Bloodgroup',
                valueCode: obj.bloodGroup
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Education',
                valueCode: obj.education
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Povertylineno',
                valueCode: obj.BPLNo
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Rationcardno',
                valueCode: obj.rationCard
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Aadharno',
                valueCode: obj.aadharNumber
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Annualincome',
                valueCode: obj.annualIncome
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Email',
                valueCode: obj.email
            },
            {
                url: 'http://3.109.121.35:8080/fhir/StructureDefinition/GuardianType',
                valueCode: obj.guardianType
            }
        ]
    });
};

export const fromFhir = (data) => {
    const obj = {};
    obj.id = data.id;
    obj.firstName = data.name[0].given;
    obj.surname = data.name[0].family;
    obj.nameText = obj.firstName + ' ' + obj.surname;
    obj.active = true;
    obj.gender = data.gender;
    obj.maritalStatus = data.maritalStatus ? data.maritalStatus.text || 'single' : 'single';
    obj.telecom = data.telecom[0].value;
    obj.birthDate = data.birthDate;
    obj.resaddress1 = data.address[0].line[0];
    obj.resaddress2 = data.address[0].line[1];
    obj.city = data.address[0].city;
    obj.uhid = data.identifier[0].value;
    // obj.district = data.address[0].district;
    // obj.state = data.address[0].state;
    obj.state = data.address[0].state;
    obj.postalCode = data.address[0].postalCode;
    obj.country = data.address[0].country;
    obj.lastUpdated = data.meta.lastUpdated;
    const values = data.identifier
        ? data.identifier.map(item => {
            const data = item.system === 'UHID' ? item.value : '';
            return data;
        })
        : '';
    obj.uhid = values;
    if (data.address[1]) {
        if (data.address[1].line) {
            obj.officeAddress1 = data.address[1].line[0] || null;
            obj.officeAddress2 = data.address[1].line[1] || null;
        }
        obj.city1 = data.address[1].city;
        // obj.district1 = data.address[1].district;
        obj.state1 = data.address[1].state;
        obj.postalCode1 = data.address[1].postalCode;
        obj.country1 = data.address[1].country;
    }

    const promises = data.extension.map(extObj => {
        const extName = extObj.url.substring(extObj.url.lastIndexOf('/') + 1, extObj.url.length);
        if (extName in patientExtensionMap) obj[patientExtensionMap[extName]] = extObj.valueCode || extObj.valueBoolean || extObj.valueString;
        else obj[extName] = extObj.valueCode;
        return Promise.resolve();
    });
    return Promise.all(promises)
        .then(() => Promise.resolve(obj));
};

export const fromFhirList = (dataList) => {
    const promises = dataList.map(data => {
        return fromFhir(data.resource);
    });
    return Promise.all(promises).then((list) => Promise.resolve(list));
};
