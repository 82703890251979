import React from 'react';

const Footer = () => {
    // const today = new Date();
    // const year = today.getFullYear();
    return (
        <footer className="bg-gray text-center lg:text-left fixed bottom-0 left-0 right-0">
            <div className="text-gray-700 text-center p-4 ">
            Copyright © 2023&nbsp;
                <a className="text-blue" href="https://www.clustrex.com/">Clustrex Data Private Limited.</a>&nbsp;All Right Reserved.
            </div>
        </footer>
    );
};

export default Footer;
