import { convertToFhir } from '../../index.js';
export const toFhir = (data) => {
    try {
        const promises = [];
        const BundleRequest = {};
        BundleRequest.resourceType = 'Bundle';
        BundleRequest.type = 'transaction';
        BundleRequest.entry = [];
        data.Bundle = true;
        const entry = BundleRequest.entry;
        const references = [];
        for (let i = 0; i < data.Popup.length; i++) {
            const requestData = data.Popup[i];
            requestData.patient = data.patient;
            requestData.practitioner = data.practitioner;
            requestData.encounter = data.encounter;
            convertToFhir('MedicationRequest', requestData).then((output) => {
                const transform = {
                    request: {
                        method: 'POST'
                    },
                    fullUrl: `urn:uuid:MedicationRequest${i}`,
                    resource: output
                };
                entry.push(transform);
                references.push({ reference: `urn:uuid:MedicationRequest${i}` });
            });
        }

        entry.push({
            fullUrl: 'urn:uuid:main',
            resource: {
                resourceType: 'MedicationRequest',
                basedOn: references
            },
            request: {
                method: 'POST'
            },
            subject: {
                reference: `Patient/${data.patient}`
            },
            encounter: {
                reference: `Encounter/${data.encounter}`
            },
            authoredOn: '2022-12-06T09:59:09.449+00:00',
            recorder: {
                reference: `Practitioner/${data.practitioner}`
            },
            requester: {
                reference: `Practitioner/${data.practitioner}`
            },
            performer: {
                reference: `Practitioner/${data.practitioner}`
            }

        }
        );

        return Promise.all(promises).then(() => Promise.resolve(BundleRequest));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
