/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';

const DropdownSearchWithLazyload = ({
    values,
    defaultValue,
    name,
    InputclassName,
    DropdownClassName,
    placeholder,
    Inputid,
    onChange,
    disabled
}) => {
    const [showDropDown, setshowDropDown] = useState(false);
    const [defaultValueState, setdefaultValueState] = useState(defaultValue || []);
    const [menuClick, setmenuClick] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [options, setOptions] = useState([]);
    const [reloadFlag, setReloadFlag] = useState(false);
    const chunkSize = 10;
    useEffect(() => {
        (async () => {
            if (values !== '' && values !== null && values !== undefined) {
                const result = await chuckArray(values, chunkSize);
                setOptions(result);
                AppendNewList(result, 0);
            }
        })();
        // setdefaultValueState(defaultValue);
    }, []);

    const chuckArray = (values, chunkSize) => {
        return new Promise((resolve) => {
            const tempArray = [];
            for (let i = 0; i < values.length; i += chunkSize) {
                const chunk = values.slice(i, i + chunkSize);
                tempArray.push(chunk);
            }
            resolve(tempArray);
        });
    };

    const HandleScrollList = () => {
        const element = document.getElementById('element');
        let lastScrollTop = 0;
        if (element.scrollTop < lastScrollTop) {
            return;
        }
        lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop;
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            setCurrentIndex(Number(currentIndex) + 1);
            AppendNewList(options, currentIndex);
        }
    };

    const HandleSearch = async (event) => {
        if (event.target.value !== '') {
            const filterValue = values.filter((items) => items.value.includes(event.target.value));
            const chunkResult = await chuckArray(filterValue, chunkSize);
            setOptions(chunkResult);
            document.getElementById('element').innerHTML = '';
            AppendNewList(chunkResult, 0);
        } else {
            const chunkResult = await chuckArray(values, chunkSize);
            setOptions(chunkResult);
            document.getElementById('element').innerHTML = '';
            AppendNewList(chunkResult, 0);
        }
    };
    // Append li tag
    const AppendNewList = (options, index) => {
        for (let i = 0; i < options[index].length; i++) {
            const newList = document.createElement('li');
            newList.appendChild(document.createTextNode(options[index][i].label));
            newList.addEventListener('click', function () {
                HandleSelectOption(options[index][i]);
            });
            newList.className = 'py-2 px-2 cursor-pointer hover:bg-iat hover:text-white';
            document.getElementById('element').appendChild(newList);
        }
    };

    const HandleSelectOption = (value) => {
        const selectedValueTemp = defaultValueState;
        selectedValueTemp.push(value);
        HandleSendValues(selectedValueTemp);
        setdefaultValueState(selectedValueTemp);
        setshowDropDown(false);
    };

    const HandleSendValues = (value) => {
        onChange(value);
    };

    const removeSelectedItems = (index) => {
        const ArrayTemp = defaultValueState;
        ArrayTemp.splice(index, 1);
        setdefaultValueState(ArrayTemp);
        setReloadFlag(!reloadFlag);
        HandleSendValues(ArrayTemp);
    };
    return (
        <>
            <div
                className="relative"
            >
                <div className='w-full grid grid-cols-1 gap-4 bg-[#F3F3F3] p-3'>
                    {defaultValueState.map((items, index) => (<p key={index} className='h-[20px] w-full overflow-hidden text-[11px] relative border-[1px] border-[#E3E3E3] px-2 '>{items.label}<button type='button' className='absolute right-0 top-0' onClick={() => removeSelectedItems(index)} disabled = {disabled}>x</button></p>))}
                    <input
                        name={name}
                        id={Inputid || 'search-input-tag'}
                        className={`${InputclassName}`}
                        placeholder={placeholder}
                        onFocus={() => setshowDropDown(true)}
                        onInput={HandleSearch}
                        onBlur={() => (!menuClick ? setshowDropDown(false) : null)}
                        disabled = {disabled}
                    />
                </div>

                <ul
                    className={`list-group absolute border-1 bg-white max-h-[35vh] overflow-y-scroll z-50 min-w-[100%] w-auto ${!showDropDown ? 'hidden' : ''} hide-scroll-bar ${DropdownClassName}`}
                    id="element"
                    onScroll={() => HandleScrollList()}
                    onMouseOver={() => setmenuClick(true)}
                    onMouseLeave={() => setmenuClick(false)}
                >
                </ul>
            </div>

        </>
    );
};

export default DropdownSearchWithLazyload;
