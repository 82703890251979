import React from 'react';

function Radio ({ isDisabled, ...props }) {
    return (
        <div className='relative px-[14px] py-[10px] font-"roboto" flex'>
            <label className='self-center text-[14px] text-[#7E7E7E] font-[500] w-[200px] leading-[15px]'>{props.title}</label>
            <div className='w-[200px] flex justify-between'>
                <label className='font-"roboto" w-[100px] font-[400] text-[14px] leading-[15px] text-[#757575] flex items-center'>
                    <input
                        className='self-center peer mr-[5px]'
                        name={props.name}
                        type='radio'
                        onChange={props.formikState.handleChange}
                        value='Present'
                        checked={props.checked}
                        disabled={isDisabled}
                    />
                    Present
                </label>
                <label className='font-"roboto" w-[100px] font-[400] text-[14px] leading-[15px] text-[#757575] flex items-center'>
                    <input
                        className='self-center peer mr-[5px]'
                        name={props.name}
                        type='radio'
                        onChange={props.formikState.handleChange}
                        value='Absent'
                        checked={!props.checked}
                        disabled={isDisabled}
                    />
                    Absent
                </label>
            </div>
        </div>
    );
}

export default Radio;
