import { AsyncLocalStorage } from './AsyncLocalStorage';

export const getUserCredFromLocal = () => {
    return AsyncLocalStorage.getItem('userCreds')
        .then(res => Promise.resolve(JSON.parse(res)))
        .catch(err => Promise.reject(err));
};

export const setUserCredsToLocal = (creds) => {
    return AsyncLocalStorage.setItem('userCreds', JSON.stringify(creds))
        .then(res => Promise.resolve())
        .catch(err => Promise.reject(err));
};
