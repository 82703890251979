import React from 'react';
import Ifield from './Ifield';
import TField from './TField';
// import Radio from './Radio';
import RoundsDoc from './RoundsDoc';
import TwoRadioYes from './TwoRadioYes';
import TwoRadioNo from './TwoRadioNo';
import ThreeRadio from './ThreeRadio';
import WeaningRadio from './WeaningRadio';
import BedSoreRadio from './BedSoreRadio';
import HeadElevatedRadio from './HeadElevatedRadio';

function Handover ({ isDisabled, ...props }) {
    return (
        <div>
            <div className='bg-white'>
                { props.isDoctor
                    ? <div className='h-[45px] items-center bg-iat flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div className='px-[14px] w-[47.5%]'>Given by</div>
                        <div className='px-[14px] w-[47.5%]'>Taken by</div>
                    </div>
                    : <div className='h-[45px] items-center bg-tgray flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div className='px-[14px] w-[47.5%]'>Given by</div>
                        <div className='px-[14px] w-[47.5%]'>Taken by</div>
                    </div> }
                <div className='flex justify-between'>
                    <Ifield title={'Given By'} id={'GivenBy'} formikState={props.formikState} val={props.formikState.values.GivenBy} isDisabled = {isDisabled}/>
                    <Ifield title={'Taken By'} id={'TakenBy'} formikState={props.formikState} val={props.formikState.values.TakenBy} isDisabled = {isDisabled}/>
                </div>
            </div>
            <div className='bg-white'>
                { props.isDoctor
                    ? <div className='h-[45px] items-center bg-iat flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div className='px-[14px] w-[47.5%]'>Completed Tasks</div>
                        <div className='px-[14px] w-[47.5%]'>Pending Tasks</div>
                    </div>
                    : <div className='h-[45px] items-center bg-tgray flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div className='px-[14px] w-[47.5%]'>Completed Tasks</div>
                        <div className='px-[14px] w-[47.5%]'>Pending Tasks</div>
                    </div> }
                <div className='flex justify-between'>
                    <TField title={'Completed Tasks'} id={'CompletedTasks'} formikState={props.formikState} val={props.formikState.values.CompletedTasks} isDisabled = {isDisabled}/>
                    <TField title={'Pending Tasks'} id={'PendingTasks'} formikState={props.formikState} val={props.formikState.values.PendingTasks} isDisabled = {isDisabled}/>
                </div>
            </div>
            <div className='bg-white'>
                {props.isDoctor
                    ? <div className='bg-iat h-[45px] items-center px-[14px] flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div>Handover checklist</div>
                    </div>
                    : <div className='bg-tgray h-[45px] items-center px-[14px] flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div>Handover checklist</div>
                    </div> }
                <div>
                    <TwoRadioNo title={' 1. Admission & Discharge Works'} val={props.formikState.values.ADW} out='block' id={'ADW'} name={'ADW.data'} formikState={props.formikState} value1={'Done'} value2={'Incomplete'} type='text' placeholder="Write here !" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 2. Administration of Medicines & injections'} val={props.formikState.values.AMI} out='block' id={'AMI'} name={'AMI.data'} formikState={props.formikState} value1={'Done'} value2={'Not Done'} type='text' placeholder="Write here !" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 3. Diet given to the Patient'} val={props.formikState.values.Diet} out='block' id={'Diet'} name={'Diet.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Write here !" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 4. I.V.Access, Latheterisation, O2 Port & Nasal Canula Checked & All working perfect '} val={props.formikState.values.IVA} out='block' id={'IVA'} name={'IVA.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Write where the problem Found" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 5. Health education & Physiotherapy given'} val={props.formikState.values.HEP} out='hidden' id={'HEP'} name={'HEP.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} select='true' width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 6. Followed all orders of the doctor'} val={props.formikState.values.Order} out='block' id={'Order'} name={'Order.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Write Missing / Unfollowed Orders" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 7. Sent all Ordered tests'} id={'Sent'} name={'Sent.data'} val={props.formikState.values.Sent} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Pending tests if any" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 8. Bed sheet of the patient changed'} val={props.formikState.values.Bed} out='block' id={'Bed'} name={'Bed.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Why ?" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 9. Passed Stool '} id={'Psl'} name={'Psl.data'} val={props.formikState.values.Psl} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} type='date' width="w-[20%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 10. Air Mattress Given(Chronically bed ridden)'} val={props.formikState.values.Air} out='block' id={'Air'}name={'Air.data'} formikState={props.formikState} value1={'Present'} value2={'Absent'} type='date' width="w-[20%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 11. Attention to Pressure points'} id={'Pressure'} name={'Pressure.data'} val={props.formikState.values.Pressure} out='block' formikState={props.formikState} value1={'Normal'} value2={'Abnormal'} type='text' placeholder="Write abnormal sites" width="w-[85%]" isDisabled = {isDisabled}/>
                    <RoundsDoc title={' 12. Rounds with doctor'} id={'Rounds'} name={'Rounds.data'} val={props.formikState.values.Rounds} out='block' formikState={props.formikState} value1={'Done'} value2={'Not done'} type='text' select='true' width="w-[85%]" isDisabled = {isDisabled}/>
                    <ThreeRadio title={' 13. Collected all reports'} id={'Reports'} name={'Reports.data'} val={props.formikState.values.Reports} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} value3={'No tests ordered by doctor'} placeholder="Notice pending reports" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <div className='pt-[40px] relative font-"roboto" w-[100%] flex justify-between px-[14px]'>
                        <label className='self-center text-[16px] text-[#7E7E7E] font-[500] w-[15%] leading-[30px] '> Duty Doctor </label>
                        <input
                            id="dutyDoctor.dutyDoctor"
                            name="dutyDoctor.dutyDoctor"
                            type="text"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.dutyDoctor.dutyDoctor}
                            placeholder="Enter Here"
                            disabled={isDisabled}
                            className="h-[38px] text-[15px] self-center text-col border-[1px] w-[82%] border-[#E3E3E3] text-input bg-gray outline-none px-2"
                        />
                    </div>
                    <div className='pt-[40px] pb-[20px] relative font-"roboto" w-[100%] flex justify-between px-[14px]'>
                        <label className='self-center text-[16px] text-[#7E7E7E] font-[500] w-[15%] leading-[30px] '>Remarks if any</label>
                        <input
                            id="remarks.remarks"
                            name="remarks.remarks"
                            type="text"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.remarks.remarks}
                            placeholder="Enter Text"
                            className="h-[38px] text-[15px] self-center border-[1px] w-[82%] border-[#E3E3E3] text-input bg-gray outline-none text-col px-2"
                            disabled={isDisabled}
                        />
                    </div>
                </div>
            </div>
            <div className='bg-white'>
                {props.isDoctor
                    ? <div className='bg-iat h-[45px] items-center px-[14px] flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div>Nursing Handover</div>
                    </div>
                    : <div className='bg-tgray h-[45px] items-center px-[14px] flex w-full justify-between py-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>
                        <div>Nursing Handover</div>
                    </div> }
                <div>
                    <ThreeRadio title={' 1. Nebulisation'} val={props.formikState.values.Nebulisation} out='block' id={'Nebulisation'} name={'Nebulisation.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} value3={'Not Required / Not Ordered'} type='text' placeholder="Write Here" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioYes title={' 2. Ionotropes support'} val={props.formikState.values.ionotropeSupport} out='block' id={'ionotropeSupport'} name={'ionotropeSupport.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Write rate of infusion" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioYes title={' 3. Sedation'} val={props.formikState.values.sedation} out='block' id={'sedation'} name={'sedation.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Write the drug and rate of infusion" width="w-[85%]" isDisabled = {isDisabled}/>
                    <HeadElevatedRadio title={' 4. Head elevated '} val={props.formikState.values.headElevated} out='block' id={'headElevated'} name={'headElevated.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text-both' placeholder="Remarks" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioYes title={' 5. Eye care given'} val={props.formikState.values.eyeCareGiven} out='block' id={'eyeCareGiven'} name={'eyeCareGiven.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioYes title={' 6. Eye lubricant given 2nd hrly'} val={props.formikState.values.eyeLubricant} out='block' id={'eyeLubricant'} name={'eyeLubricant.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 7. All central, cvp, peripheral iv lines are checked'} id={'centralCvp'} name={'centralCvp.data'} val={props.formikState.values.centralCvp} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 8. ET cuff pressure checked'} val={props.formikState.values.etCuff} out='block' id={'etCuff'}name={'etCuff.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Remarks" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 9. RT position is checked and confirmed in position '} id={'rtPosition'} name={'rtPosition.data'} val={props.formikState.values.rtPosition} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Remarks" width="w-[40%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 10. Urinary cath checked in position only'} val={props.formikState.values.urinaryCathChecked} out='block' id={'urinaryCathChecked'}name={'urinaryCathChecked.data'} formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Remarks" width="w-[40%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 11. Urinary insertion site is cleaned'} id={'urinaryInsertion'} name={'urinaryInsertion.data'} val={props.formikState.values.urinaryInsertion} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} type='text' placeholder="Remarks" width="w-[85%]" isDisabled = {isDisabled}/>
                    <WeaningRadio title={' 12. Weaning trial'} id={'weaningTrail'} name={'weaningTrail.data'} val={props.formikState.values.weaningTrail} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} value3={'Not planned yet'} value4={'Not fit for wean off.'} type='text' width="w-[85%]" placeholder="Remarks" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 13. Mouth care given'} id={'mouthCare'} name={'mouthCare.data'} val={props.formikState.values.mouthCare} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioNo title={' 14. Back care given'} id={'backCare'} name={'backCare.data'} val={props.formikState.values.backCare} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <BedSoreRadio title={' 15. Bed sore'} id={'bedSore'} name={'bedSore.data'} name2={'bedSore.data2'} val={props.formikState.values.bedSore} out='block' formikState={props.formikState} value1={'Present'} value2={'Absent'} value3={'Yes'} value4={'No'} placeholder="Remarks" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioYes title={' 16. Urinary cath fixed at thigh'} id={'urinaryCathFixed'} name={'urinaryCathFixed.data'} val={props.formikState.values.urinaryCathFixed} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                    <TwoRadioYes title={' 17. BT transfusion'} id={'btTransfusion'} name={'btTransfusion.data'} val={props.formikState.values.btTransfusion} out='block' formikState={props.formikState} value1={'Yes'} value2={'No'} placeholder="Remarks" type="text" width="w-[85%]" isDisabled = {isDisabled}/>
                </div>
            </div>
        </div>
    );
}

export default Handover;
