import React from 'react';
import TextArea from '../../components/TextAreaBox';
import OneField from '../../components/OneField';

function History (props) {
    // const [val, setVal] = useState([]);
    // const handleAdd = (i) => {
    //     const abc = [...val, {}];
    //     setVal(abc);
    //     props.formikState.values.History.OperativeProcedures.splice(i + 1, 0, {
    //         OperativeProcedures: '',
    //         Date: ''
    //     });
    // };
    return (
        // <div>
        //     <div className='h-[45px] bg-iat flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
        //       History ( In Details )
        //     </div>
        //     {props.formikState.values.History.OperativeProcedures.map((data, i) => {
        //         return (
        //             <div className='px-[1%] w-[100%] flex py-2.5 bg-white' key={i}>
        //                 <div className='w-[50%] flex justify-between pr-[2%]'>
        //                     <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
        //                 Operative procedures & dates performed :
        //                     </div>
        //                     <div className='w-[76%]'>
        //                         <input
        //                             className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
        //                             type='text'
        //                             placeholder= "if any write here"
        //                             value={props.formikState.values.History.OperativeProcedures[i].OperativeProcedures}
        //                             name={`History.OperativeProcedures[${i}].OperativeProcedures`}
        //                             onChange={props.formikState.handleChange}
        //                             onBlur={props.formikState.handleBlur}
        //                         />
        //                     </div>
        //                 </div>
        //                 <div className='w-[50%] flex justify-between pl-[2%]'>
        //                     <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
        //                 Date :
        //                     </div>
        //                     <div className='w-[70%]'>
        //                         <input
        //                             className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
        //                             type='text'
        //                             placeholder={props.place2}
        //                             value={props.formikState.values.History.OperativeProcedures[i].Date}
        //                             name={`History.OperativeProcedures[${i}].Date`}
        //                             onChange={props.formikState.handleChange}
        //                             onBlur={props.formikState.handleBlur}
        //                         />
        //                     </div>
        //                     <div className='w-[20%] flex justify-between pl-[2%]'>
        //                         <button className={'border border-iat w-32 h-10 bg-iat text-white'} type='button' onClick={() => handleAdd(i)}>+ Add</button>
        //                     </div>
        //                 </div>
        //             </div>
        //         );
        //     })}
        //     <div className='bg-white py-2.5'>
        //         <TextArea field1='Chief complaint/ admitting reason ' onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur} value={props.formikState.values.History.ChiefComplaint} name="History.ChiefComplaint"/>
        //         <TextArea field1='History of present illness' onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur} value={props.formikState.values.History.PresentIllness} name="History.PresentIllness"/>
        //     </div>
        // </div>
        <div>
            <div className='h-[45px] bg-iat flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            History ( In Details )
            </div>
            <div className='bg-white py-2.5'>
                <OneField field1='Operative procedures & dates performed' value={props.formikState.values.History.OperativeProcedures} name="History.OperativeProcedures" id="History.OperativeProcedures" onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>
                <TextArea field1='Chief complaint/ admitting reason' onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur} value={props.formikState.values.History.ChiefComplaint} name="History.ChiefComplaint"/>
                <TextArea field1='History of present illness' onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur} value={props.formikState.values.History.PresentIllness} name="History.PresentIllness"/>
            </div>
        </div>
    );
}

export default History;
