import React from 'react';

function RoundsDoc ({ isDisabled, ...props }) {
    return (
        <div>
            <div className='pt-[20px] relative px-[14px] font-"roboto" w-[100%] flex justify-between'>
                <label className='self-center text-[16px] text-[#7E7E7E] font-[500] w-[25%] leading-[30px]'>{props.title}</label>
            </div>
            <div className='flex flex-row ml-[30px] h-[38px] w-[100%]'>
                <label className='self-center font-"roboto" w-[8%] font-[400] text-[14px] leading-[15px] text-[#757575]'>
                    <input className='self-center' name={props.name} type='radio' onChange={props.formikState.handleChange} value={props.value1} disabled={isDisabled}/><span className='ml-1'>{props.value1}</span></label>
                <label className='self-center font-"roboto" w-[8%] font-[400] text-[14px] leading-[15px] text-[#757575]'>
                    <input className='self-center pl-2' name={props.name} type='radio' onChange={props.formikState.handleChange} value={props.value2} disabled={isDisabled}/><span className='ml-1'>{props.value2}</span>
                </label>
                {props.val.data === props.value2 && <input
                    className={`${props.out} h-[38px] text-[15px] self-center border-[1px] w-[80.5%] border-[#E3E3E3] text-input text-col bg-gray outline-none px-2`}
                    id={`${props.id}.remarks`}
                    name={`${props.id}.remarks`}
                    type={props.type}
                    placeholder='Why ? Name of the Doctor who is taking the rounds'
                    onChange={props.formikState.handleChange}
                    value={props.val.remarks}
                    disabled={isDisabled}
                />
                }
            </div>
            <div className='w-[100%] flex flex-row'>
                <div className='w-[18.5%]'></div>
                {(props.val.data === props.value2 && props.select === 'true') && <select required
                    className={'h-[38px] text-[#8F8F8F] text-[15px] self-center border-[1px] mt-[10px] w-[80.5%] border-[#E3E3E3] text-input text-col bg-gray outline-none px-2'}
                    name={'Rounds.sel'}
                    id={'Rounds.sel'}
                    onChange={props.formikState.handleChange}
                    value={props.formikState.values.Rounds.sel}
                    disabled={isDisabled}
                >
                    <option value="name">Name of the doctor</option>
                    {
                        // educ.occupation.map((data, i) => (
                        //     <option value={educ.occupation[i].value} key={i}>{educ.occupation[i].label}</option>
                        // ))
                    }
                </select>
                }
            </div>
        </div>
    );
}

export default RoundsDoc;
