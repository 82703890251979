/* eslint-disable no-console */
import React, { useState } from 'react';
const DropdownWithSearch = ({
    values,
    defaultValue,
    name,
    InputclassName,
    DropdownClassName,
    placeholder,
    searchInput,
    changeCurrentPage,
    id,
    onChange,
    disabled
}) => {
    const [showDropDown, setshowDropDown] = useState(false);
    const [defaultValueState, setdefaultValueState] = useState(defaultValue || []);
    const [menuClick, setmenuClick] = useState(false);
    const [reloadFlag, setReloadFlag] = useState(false);
    const [searchInputState, setsearchInputState] = useState('');

    const HandleScrollList = () => {
        const element = document.getElementById('element');
        let lastScrollTop = 0;
        if (element.scrollTop < lastScrollTop) {
            return;
        }
        lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop;
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            changeCurrentPage(searchInputState);
        }
    };

    const HandleSelectOption = (value) => {
        const selectedValueTemp = defaultValueState;
        selectedValueTemp.push(value);
        HandleSendValues(selectedValueTemp);
        setdefaultValueState(selectedValueTemp);
        setshowDropDown(false);
    };

    const HandleSendValues = (value) => {
        onChange(value);
    };

    const removeSelectedItems = (index) => {
        const ArrayTemp = defaultValueState;
        ArrayTemp.splice(index, 1);
        setdefaultValueState(ArrayTemp);
        HandleSendValues(ArrayTemp);
        setReloadFlag(!reloadFlag);
    };

    return (
        <div
            className="relative"
        >
            <div className='w-full grid grid-cols-1 gap-4 bg-[#F3F3F3] p-3'>
                {defaultValueState.map((items, index) => (<p key={index} className='h-[20px] w-full overflow-hidden text-[15px] relative border-[1px] border-[#E3E3E3] px-2 '><span>{`${index + 1}. ${items.code} - ${items.name}`}</span><button type='button' onClick={() => removeSelectedItems(index) } disabled = {disabled} className='absolute right-0 top-0'>x</button></p>))}
                <input
                    name={name}
                    id={id || 'search-input-tag'}
                    className={`${InputclassName}`}
                    placeholder={placeholder}
                    onFocus={() => setshowDropDown(true)}
                    onInput={(event) => { setsearchInputState(event.target.value); searchInput(event.target.value); }}
                    onBlur={() => (!menuClick ? setshowDropDown(false) : null)}
                    disabled = {disabled}
                />
            </div>
            {/* <input
                defaultValue={defaultValueState}
                name={name}
                id={id || 'search-input-tag'}
                className={`${InputclassName}`}
                placeholder={placeholder}
                onFocus={() => setshowDropDown(true)}
                onInput={(event) => { setsearchInputState(event.target.value); searchInput(event.target.value); }}
                onChange={onChange}
                onBlur={() => (!menuClick ? setshowDropDown(false) : null)}
            /> */}
            {showDropDown
                ? (
                    <ul
                        className={`list-group absolute border-1 bg-white max-h-[35vh] overflow-y-scroll z-50 w-full hide-scroll-bar ${DropdownClassName}`}
                        id="element"
                        onScroll={() => HandleScrollList()}
                        onMouseOver={() => setmenuClick(true)}
                        onMouseLeave={() => setmenuClick(false)}
                    >
                        {values !== '' && values !== null && values !== undefined
                            ? (
                                values.map((items) => (
                                    <li
                                        className="py-2 px-2 cursor-pointer hover:bg-iat hover:text-white"
                                        key={items.code}
                                        onClick={() => {
                                            HandleSelectOption(items);
                                        }}
                                    >
                                        {`${items.code} - ${items.name}`}
                                    </li>
                                ))
                            )
                            : (
                                <></>
                            )}
                    </ul>
                )
                : (
                    <></>
                )}
        </div>
    );
};

export default DropdownWithSearch;
