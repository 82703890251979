/* eslint-disable no-console */
// import { obsrvationExtensionMap } from '../../utils/common.js';

import { convertFromFhirList, convertToFhir } from '../../index.js';
import { fromCompleteEncounterFhirList } from '../Encounter/index.js';

export const toFhir = async (obj) => {
    const diagnosticReport = {
        resourceType: 'DiagnosticReport',
        status: obj.status || 'registered',
        // conclusionCode: [{ text: obj.observation }],
        code: {
            text: obj.investigation
        },
        subject: {
            reference: `Patient/${obj.patientId}`
        },
        encounter: {
            reference: `Encounter/${obj.encounterId}`
        },
        effectiveDateTime: obj.date,
        resultsInterpreter: [{
            reference: `Practitioner/${obj.practitioner || 363}`
        }]
    };
    // Check if id is present
    if (obj.id) {
        diagnosticReport.id = obj.id;
    }
    if (obj.observation) {
        diagnosticReport.conclusionCode = {
            text: obj.observation
        };
    }
    return Promise.resolve(diagnosticReport);
};

export const fromFhir = (data) => {
    try {
        data = data.resource;
        const diagnosticReport = {
            patientId: data.subject.reference.split('/')[1],
            encounterId: data.encounter.reference.split('/')[1],
            practitioner: data.resultsInterpreter[0].reference.split('/')[1],
            investigation: data.code?.text || '',
            status: data?.status,
            dateTime: data?.effectiveDateTime || data?.meta.lastUpdated
        };
        if (data.conclusion) {
            diagnosticReport.subRow = JSON.parse(data.conclusion);
        }
        if (data.conclusionCode) {
            diagnosticReport.observation = data.conclusionCode.text;
        }
        return Promise.resolve(diagnosticReport);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toFhirBundle = async (data) => {
    const BundleRequest = {
        resourceType: 'Bundle',
        type: 'transaction',
        entry: []
    };
    const diagnosticReportResource = {
        request: {
            method: 'PUT',
            url: `DiagnosticReport/${data.id}`
        }
    };
    const diagnosticReport = await toFhir(data);
    diagnosticReport.media = [];
    for (const media of data.media) {
        media.patientId = data.patientId;
        media.encounterId = data.encounterId;
        media.createdDateTime = data.date;
        const mediaURN = `urn:uuid:Media:${media.title}`;
        const mediaResource = {
            request: {
                method: 'POST',
                url: 'Media'
            },
            fullUrl: mediaURN,
            resource: await convertToFhir('Media', media)
        };
        diagnosticReport.media.push({
            link: {
                type: 'Media',
                reference: mediaURN
            }
        });
        BundleRequest.entry.push(mediaResource);
    }
    diagnosticReportResource.resource = diagnosticReport;
    BundleRequest.entry.push(diagnosticReportResource);
    return Promise.resolve(BundleRequest);
};

export const fromFhirList = async (data) => {
    try {
        const fromFhirReports = [];
        let encounters = data.filter((res) => res.resource.resourceType === 'Encounter');
        let patient = data.filter((res) => res.resource.resourceType === 'Patient');
        let practitioners = data.filter((res) => res.resource.resourceType === 'Practitioner');
        let mediaFiles = data.filter((res) => res.resource.resourceType === 'Media');
        encounters = await fromCompleteEncounterFhirList(encounters);
        patient = await convertFromFhirList('Patient', patient);
        practitioners = await convertFromFhirList('Practitioner', practitioners);
        mediaFiles = await convertFromFhirList('Media', mediaFiles);

        const diagnosticReports = data.filter((res) => res.resource.resourceType === 'DiagnosticReport');
        for (const report of diagnosticReports) {
            const diagnosticReport = {
                id: report.resource.id,
                patientId: report.resource.subject.reference.split('/')[1],
                encounterId: report.resource.encounter.reference.split('/')[1],
                practitionerId: report.resource.resultsInterpreter ? report.resource.resultsInterpreter[0].reference.split('/')[1] : null,
                investigation: report.resource.code.text,
                status: report.resource.status,
                patient: {},
                encounter: {},
                date: report?.resource?.effectiveDateTime || report?.resource?.meta.lastUpdated,
                media: []
            };
            if (report.resource.media) {
                for (const file of report.resource.media) {
                    const mediaFile = mediaFiles.find((res) => res.id === file.link.reference.split('/')[1]);
                    diagnosticReport.media.push(mediaFile);
                }
            }
            if (report.resource.conclusionCode) {
                diagnosticReport.observation = report.resource.conclusionCode[0].text;
            }
            diagnosticReport.patient = patient.find((res) => res.id === diagnosticReport.patientId);
            diagnosticReport.encounter = encounters.find((res) => res.id === diagnosticReport.encounterId);
            diagnosticReport.practitioner = practitioners.find((res) => res.id === diagnosticReport.practitionerId);
            fromFhirReports.push(diagnosticReport);
        }
        return Promise.resolve(fromFhirReports);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
