/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
// import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { convertFromFhir, convertToFhir } from '../../fhir-evolver';
import { getClinicalImpressionById } from '../../api/clinicalImpression';
import { addBundle } from '../../api/bundleRequest';
import Loader from '../../components/Loader';
import Station from '../../containers/NurseFunctions/Station';

const StationEdit = (props) => {
    const params = useParams();
    const [viewSecondary, setViewSecondary] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [arr, setArr] = useState('');

    const navigate = useNavigate();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };

    const [snackbarOpen] = useSnackbar(options);
    const observation = [];
    const risk = [];
    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            const clinicalImpression = response.data.entry.find((data) => data.resource.resourceType === 'ClinicalImpression');
            const clinical = { resourceType: clinicalImpression.resource.resourceType, id: clinicalImpression.resource.id };
            const questionnaireResponse = response.data.entry.find((data) => data.resource.resourceType === 'QuestionnaireResponse');
            const ques = { resourceType: questionnaireResponse.resource.resourceType, id: questionnaireResponse.resource.id };
            const observationData = response.data.entry.filter((data) => data.resource.resourceType === 'Observation');
            for (const data of observationData) {
                observation.push({ resourceType: data.resource.resourceType, id: data.resource.id });
            }
            const riskData = response.data.entry.filter((data) => data.resource.resourceType === 'RiskAssessment');
            for (const data of riskData) {
                risk.push({ resourceType: data.resource.resourceType, id: data.resource.id, identifier: data.resource.identifier[0].value });
            }
            console.log('risk', risk);
            setArr({ ques, clinical, observation, risk });
            convertFromFhir('SecondaryCare', response).then((res) => {
                setViewSecondary(res);
            });
        });
    }, []);

    const onEdit = (obj) => {
        console.log('stationedit', obj);
        convertToFhir('SecondaryCare', obj).then(res => {
            addBundle(res)
                .then((response) => {
                    setSpinner(true);
                    snackbarOpen('SecondaryCare updated Successfully');
                    setTimeout(() => {
                        navigate(`/nurse/assessment/${obj?.[0].Encounter}`);
                    }, 1000);
                })
                .catch(e => {
                    return e;
                });
        });
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            { viewSecondary && <Station handleSubmit={onEdit} viewSecondary = {viewSecondary} isDisabled={false} isEdit={true} type='edit' isNurse = {true} data={arr} isDoctor = {props.isDoctor}/>}
        </>
    );
};

export default StationEdit;
