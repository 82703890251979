import { useState } from 'react';

const HamburgerState = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const openClose = () => {
        setIsNavOpen(!isNavOpen);
    };
    return {
        isNavOpen,
        openClose
    };
};

export default HamburgerState;
