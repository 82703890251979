import React from 'react';
import Title from '../Title';
import addict from '../.././../utils/json/AnyAddictionStatus.json';
import built from '../.././../utils/json/Built.json';
import skin from '../.././../utils/json/SkinComplexion.json';
import sleep from '../.././../utils/json/Sleep.json';
import bladder from '../.././../utils/json/BladderHabit.json';
import diet from '../.././../utils/json/Diet.json';
import height from '../.././../utils/json/Height.json';
import nourishment from '../.././../utils/json/Nourishment.json';
import bowel from '../.././../utils/json/BowelHabit.json';

function PersonalHo (props) {
    const apt = [
        {
            label: 'Normal',
            value: 'Normal'
        },
        {
            label: 'Reduced',
            value: 'Reduced'
        },
        {
            label: 'Loss of apetite',
            value: 'Loss of apetite'
        },
        {
            label: 'Polyphogia',
            value: 'Polyphogia'
        },
        {
            label: 'Hyperphogia',
            value: 'Hyperphogia'
        }
    ];
    return (
        <div>
            <Title title={'Personal H/o'} isNurse = {props.isNurse}/>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Personal H/o :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex flex-col w-[49%]'>
                            <div className='flex gap-x-4'>
                                <select
                                    className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                    name="PersonalHo.addict"
                                    id="PersonalHo.addict"
                                    onInput={(event) => event.target.value === '' ? props.formikState.setFieldValue('PersonalHo.addictDays', '') : null }
                                    onChange={props.formikState.handleChange}
                                    disabled={props.disabled}
                                    value={props.formikState.values.PersonalHo.addict}>
                                    <option value="">Any Addiction Status</option>
                                    {
                                        addict.map((data, i) => (
                                            <option value={addict[i].value} key={i}>{addict[i].label}</option>
                                        ))
                                    }
                                </select>
                                {props.formikState.values.PersonalHo.addict !== '' ? <input type={'number'} name="PersonalHo.addictDays" id="PersonalHo.addictDays" onChange={props.formikState.handleChange} required className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]' placeholder={`Please specify number of days they addicted for '${props.formikState.values.PersonalHo.addict}'?`} value={props.formikState.values.PersonalHo.addictDays} /> : null}
                            </div>
                            {/* <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.built"
                                id="PersonalHo.built"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.built}>
                                <option value="">Built</option>
                                {
                                    built.map((data, i) => (
                                        <option value={built[i].value} key={i}>{built[i].label}</option>
                                    ))
                                }
                            </select> */}
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.diet"
                                id="PersonalHo.diet"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.PersonalHo.diet}>
                                <option value="">Diet</option>
                                {
                                    diet.map((data, i) => (
                                        <option value={diet[i].value} key={i}>{diet[i].label}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.height"
                                id="PersonalHo.height"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.PersonalHo.height}>
                                <option value="">Height</option>
                                {
                                    height.map((data, i) => (
                                        <option value={height[i].value} key={i}>{height[i].label}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.complexion"
                                id="PersonalHo.complexion"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.PersonalHo.complexion}>
                                <option value="">Skin Complexion</option>
                                {
                                    skin.map((data, i) => (
                                        <option value={skin[i].value} key={i}>{skin[i].label}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.sleep"
                                id="PersonalHo.sleep"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.PersonalHo.sleep}>
                                <option value="">Sleep</option>
                                {
                                    sleep.map((data, i) => (
                                        <option value={sleep[i].value} key={i}>{sleep[i].label}</option>
                                    ))
                                }
                            </select>
                            {/* <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.bladder"
                                id="PersonalHo.bladder"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.bladder}>
                                <option value="">Bladder Habit</option>
                                {
                                    bladder.map((data, i) => (
                                        <option value={bladder[i].value} key={i}>{bladder[i].label}</option>
                                    ))
                                }
                            </select> */}
                        </div>
                        <div className='flex flex-col w-[49%]'>
                            {/* <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.diet"
                                id="PersonalHo.diet"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.diet}>
                                <option value="">Diet</option>
                                {
                                    diet.map((data, i) => (
                                        <option value={diet[i].value} key={i}>{diet[i].label}</option>
                                    ))
                                }
                            </select> */}
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.built"
                                id="PersonalHo.built"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.PersonalHo.built}>
                                <option value="">Built</option>
                                {
                                    built.map((data, i) => (
                                        <option value={built[i].value} key={i}>{built[i].label}</option>
                                    ))
                                }
                            </select>
                            {props.formikState.values.PersonalHo.diet === 'Others' &&
                                <input
                                    className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                    id='PersonalHo.dietInput'
                                    name='PersonalHo.dietInput'
                                    type='text'
                                    placeholder='Write here'
                                    disabled={props.disabled}
                                    onChange={props.formikState.handleChange}
                                    value={props.formikState.values.PersonalHo.dietInput}
                                />
                            }
                            {/* <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.height"
                                id="PersonalHo.height"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.height}>
                                <option value="">Height</option>
                                {
                                    height.map((data, i) => (
                                        <option value={height[i].value} key={i}>{height[i].label}</option>
                                    ))
                                }
                            </select> */}
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.nourishment"
                                id="PersonalHo.nourishment"
                                disabled={props.disabled}
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.nourishment}>
                                <option value="">Nourishment</option>
                                {
                                    nourishment.map((data, i) => (
                                        <option value={nourishment[i].value} key={i}>{nourishment[i].label}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.bowel"
                                id="PersonalHo.bowel"
                                disabled={props.disabled}
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.bowel}>
                                <option value="">Bowel Habit</option>
                                {
                                    bowel.map((data, i) => (
                                        <option value={bowel[i].value} key={i}>{bowel[i].label}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.apt"
                                id="PersonalHo.apt"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.PersonalHo.apt}>
                                <option value="">Apetite</option>
                                {
                                    apt.map((data, i) => (
                                        <option value={apt[i].value} key={i}>{apt[i].label}</option>
                                    ))
                                }
                            </select>
                            <select
                                className='w-full text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="PersonalHo.bladder"
                                id="PersonalHo.bladder"
                                disabled={props.disabled}
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.PersonalHo.bladder}>
                                <option value="">Bladder Habit</option>
                                {
                                    bladder.map((data, i) => (
                                        <option value={bladder[i].value} key={i}>{bladder[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalHo;
