/* eslint-disable no-console */
import React from 'react';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const DetailedPainAssessment = ({ formikState, onChange, onBlur, ...props }) => {
    // console.log('formikpain', formikState);
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Detailed Pain Assessment</span>
            </div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className='flex flex-col w-full px-6'>
                    <div className='grid grid-cols-c3 gap-[1%] items-center'>
                        <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                            <RadioButton
                                label="Present"
                                name="detailedPainAssessment.painassessment"
                                id="detailedPainAssessment.present"
                                onChange={onChange}
                                value='present'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painassessment === 'present'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="Absent"
                                id="detailedPainAssessment.absent"
                                name="detailedPainAssessment.painassessment"
                                onChange={onChange}
                                value='absent'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painassessment === 'absent'}
                                disabled={props.isDisabled}
                            />
                        </div>
                        {formikState.values.detailedPainAssessment.painassessment === 'present' &&
                    <div className='flex flex-row gap-x-4'>
                        <span className='text-sm text-col'>Pain rating scale: </span>
                        <div className="flex flex-row flex-wrap text-col font-roboto text-sm gap-x-4">
                            <RadioButton
                                label="NO HURT"
                                name="detailedPainAssessment.painrate"
                                id="0"
                                onChange={onChange}
                                value='0'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painrate === '0'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="HURTS LITTLE BIT"
                                id="2"
                                name="detailedPainAssessment.painrate"
                                onChange={onChange}
                                value='2'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painrate === '2'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="HURTS LITTLE MORE"
                                id="4"
                                name="detailedPainAssessment.painrate"
                                onChange={onChange}
                                value='4'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painrate === '4'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="HURTS EVEN MORE"
                                id="6"
                                name="detailedPainAssessment.painrate"
                                onChange={onChange}
                                value='6'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painrate === '6'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="HURTS WHOLE LOT"
                                id="10"
                                name="detailedPainAssessment.painrate"
                                onChange={onChange}
                                value='10'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painrate === '10'}
                                disabled={props.isDisabled}
                            />
                            <RadioButton
                                label="HURTS WORST"
                                id="10"
                                name="detailedPainAssessment.painrate"
                                onChange={onChange}
                                value='10'
                                onBlur={onBlur}
                                checked={formikState.values.detailedPainAssessment.painrate === '10'}
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailedPainAssessment;
