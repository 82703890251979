import React from 'react';
import px from '../.././../utils/json/Px.json';

function Prognosis (props) {
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
            Px (Prognosis)<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Px (Prognosis) :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-[49%] text-[#8F8F8F] h-[47px] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                name="Prognosis.Prognosis"
                                id="Prognosis.Prognosis"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.Prognosis.Prognosis}>
                                {
                                    px.px.map((data, i) => (
                                        <option value={px.px[i]} key={i}>{px.px[i]}</option>
                                    ))
                                }
                            </select>
                            <select required
                                className='w-[49%] text-[#8F8F8F] h-[47px] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] font-[400] text-[13px] leading-[15px]'
                                name="Prognosis.PrognosisInput"
                                id="Prognosis.PrognosisInput"
                                onChange={props.formikState.handleChange}
                                disabled={props.disabled}
                                value={props.formikState.values.Prognosis.PrognosisInput}>
                                <option value="">Select here</option>
                                {
                                    px[props.formikState.values.Prognosis.Prognosis].list.map((data, i) => (
                                        <option value={px[props.formikState.values.Prognosis.Prognosis].list[i]} key={i}>{px[props.formikState.values.Prognosis.Prognosis].list[i]}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Prognosis;
