import React, { useState } from 'react';

const InstructionPopup = ({ prefillData, ...props }) => {
    const [popUpvalues, setPopupValues] = useState({
        givenby: '',
        instruction: ''
    });

    const HandleInputValues = (name, value) => {
        setPopupValues({ ...popUpvalues, [name]: value });
    };

    const handlerSubmit = (e) => {
        e.preventDefault();
        props.sendValue(popUpvalues);
    };
    return (
        <div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Given By :</label>
                        </div>
                        <input
                            id="givenby"
                            name="givenby"
                            type="text"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverFrom}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.givenby}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Special Instruction :</label>
                        </div>
                        <input
                            id="instruction"
                            name="instruction"
                            type="text"
                            onChange={(event) =>
                                HandleInputValues(event.target.name, event.target.value)
                            }
                            // value={props.formikState.values.handover.HandoverTo}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            defaultValue={prefillData.instruction}
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    {prefillData.state === 1
                        ? <></>
                        : <button type='submit' className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[0.5rem] my-8 text-sm border rounded-md text-white' onClick={handlerSubmit}>Submit</button>
                    }
                    {/* <button type='submit' className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[0.5rem] my-8 text-sm border rounded-md text-white'>Submit</button> */}
                    <button type='button' className='bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col' onClick={() => props.handleModalClose(false)}>Cancel</button>

                </div>
            </div>
        </div>
    );
};

export default InstructionPopup;
