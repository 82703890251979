import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import { useNavigate } from "react-router-dom";

import { TextField } from '../components/TextField';
import Button from '../components/Button';

const FloorForm = (props) => {
    const addFunc = (value) => {
        props.onAdd(value);
    };

    const editFunc = (value) => {
        props.onEdit(value);
    };

    // const navigate = useNavigate();

    const formikSate = useFormik({
        initialValues: {
            name: props.data?.resource.name || '',
            code: 'lvl',
            type: 'si',
            id: '152'

        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required *')
        }),
        onSubmit: props.isEdit ? editFunc : addFunc
    });

    return (
        <form onSubmit={formikSate.handleSubmit}>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Floor Name"
                            id="name"
                            name="name"
                            type="text"
                            onChange={formikSate.handleChange}
                            value={formikSate.values.name}
                            onBlur={formikSate.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}

                        />
                        {formikSate.touched.name && formikSate.errors.name
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikSate.errors.name}</div>
                            )
                            : null}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[1rem] my-8 text-sm border rounded-md text-white h-10 w-16"
                        disabled={props.isDisabled}
                    />
                    <Button
                        onClick={() => props.handleModalClose(false)}
                        label="Cancel"
                        className="bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col h-10 w-16"
                    />

                </div>
            </div>
        </form>

    );
};

export default FloorForm;
