/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PrimaryCare from '../../containers/NurseFunctions/PrimaryCare';
import { convertFromFhir } from '../../fhir-evolver';
import { getClinicalImpressionById } from '../../api/clinicalImpression';
// import { generatePrimaryCareView } from '../../utils/PrimaryCareFormikView';

const PrimaryCareView = (props) => {
    const params = useParams();
    const [viewPrimary, setViewPrimary] = useState('');

    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            console.log('getresponseprimary', response);
            convertFromFhir('PrimaryCare', response).then((output) => {
                console.log('primary care evolver', output);
                setViewPrimary(output);
            });
        });
    }, []);
    return (
        <>
            { viewPrimary && <PrimaryCare viewPrimary = {viewPrimary} isView = {true} isDisabled = {true} isDoctor = {props.isDoctor}/>}
        </>
    );
};
export default PrimaryCareView;
