import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import { editPatientData, getPatientId } from '../../api/patient';
import { convertToFhir, convertFromFhir } from '../../fhir-evolver/index';
import PatientRegistration from '../../forms/Patient';
import Loader from '../../components/Loader';
import Arrow1 from '../../assets/images/arrow1.svg';

const PatientUpdate = (props) => {
    const [spinner, setSpinner] = useState(false);
    const [patient, setPatient] = useState('');

    const params = useParams();
    useEffect(() => {
        setSpinner(true);
        getPatientId(`${params.id}`).then((response) => {
            convertFromFhir('Patient', response.data).then((res) => {
                setPatient(res);
                setSpinner(false);
            }).catch(e => {
                return e;
            });
        });
    }, []);

    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const [snackbarOpen] = useSnackbar(options);
    const location = useLocation();
    const navigate = useNavigate();
    const id = location.state ? location.state.id : params.id;
    const onEdit = (obj) => {
        convertToFhir('Patient', obj).then(res => {
            res.id = id;
            editPatientData(id, res)
                .then((response) => {
                    setSpinner(true);
                    snackbarOpen('Patient updated Successfully');
                    setTimeout(() => {
                        navigate('/patient');
                    }, 1000);
                })
                .catch(e => {
                    return e;
                });
        });
    };

    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className="bg-gray h-auto w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center">
                        <Link to="/home">
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base px-2 py-2 font-roboto">Edit Patient</span>
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                        Edit Patient Details
                    </span>
                </div>
                <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                    <PatientRegistration
                        viewData={location.state ? location.state : patient}
                        handleSubmit={onEdit} isDisabled={false} buttonName="Cancel"
                        isEdit={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default PatientUpdate;
