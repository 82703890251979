import React, { useState, useEffect } from 'react';
import Admission from '../../forms/Admission';
import Arrow1 from '../../assets/images/arrow1.svg';
import { useLocation, Link, useParams } from 'react-router-dom';
import { convertFromFhirList } from '../../fhir-evolver/index';
import { getEncounter } from '../../api/encounter';

const ViewAdmission = (props) => {
    const [encounter, setEncounter] = useState('');
    const location = useLocation();
    const params = useParams();
    useEffect(() => {
        if (!location.state && params.id) {
            getEncounter(`_id=${params.id}&_include=*`).then((response) => {
                convertFromFhirList('Encounter', response.data.entry).then((res) => {
                    setEncounter(res);
                }).catch(e => {
                    return e;
                });
            });
        }
    }, []);
    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            display: 'none'
        }),
        indicatorSeparator: base => ({
            ...base,
            display: 'none'
        }),
        control: base => ({
            ...base,
            background: 'white',
            // boxShadow: 'none',
            border: 'none'
        })
    };
    return (
        <div>
            <div className='bg-gray h-auto w-full'>
                <div className='flex flex-row justify-between '>
                    <div className='flex items-center'>
                        <Link to='/home'>
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt='arrow' />
                        <span className='text-black font-normal text-base px-2 py-2'>View Admission</span>
                    </div>
                </div>
                <div className='flex flex-row justify-between'>
                    <span className='text-2xl font-bold font-montserrat mx-auto pb-2 text-black '>
                       View Admission Details
                    </span>
                </div>
                <div className='bg-white ml-6 mr-6 flex flex-row justify-between'>
                    <Admission
                        viewData={location.state ? location?.state : encounter?.[0]}
                        type={'view'}
                        isDisabled={true} style={{
                            backgroundColor: 'white',
                            appearance: 'none'
                        }}
                        display='none'
                        isView={true}
                        customStyles = {customStyles}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewAdmission;
