import axios from '../utils/axios';

export const patientAdd = (body) => {
    return axios.post('/prod/fhir/Patient', JSON.stringify(body)).then((response) => {
        return response;
    });
};

// export const getPatientData = () => {
//     return axios.get('/prod/fhir/Patient?organization=3&_count=100').then((response) => {
//         return response;
//     });
// };
export const getPatientData = (params) => {
    let url = '/prod/fhir/Patient';
    if (params) {
        url += `?${params}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};

export const editPatientData = (id, body) => {
    return axios.put(`/prod/fhir/Patient/${id}`, body).then((response) => {
        return response;
    });
};

export const deletePatientData = (id) => {
    return axios.delete(`/prod/fhir/Patient/${id}`).then((response) => {
        return response;
    });
};

export const getUhid = () => {
    return axios.get('/prod/uhid_gen').then((response) => {
        return response;
    });
};

export const getPatientId = (id) => {
    let url = '/prod/fhir/Patient';
    if (id) {
        url += `/${id}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};
