import React from 'react';
export default function Hamburger (props) {
    return (
        <div>
            <nav>
                <section>
                    <div className="px-2 py-[16.5px] space-y-1 bg-[#0D8393] cursor-pointer" onClick={() => props.action()}>
                        <span className="block w-5 h-[3px] bg-white rounded "></span>
                        <span className="block w-5 h-[3px] bg-white rounded "></span>
                        <span className="block w-5 h-[3px] bg-white rounded "></span>
                    </div>
                </section>

            </nav>
        </div>
    );
}
