import React from 'react';
import Select from 'react-select';

import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const Accessories = ({ formikState, onChange, onBlur, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    const opt = [{ value: '6.0 m.m', label: '6.0 m.m' }, { value: '7.0 m.m', label: '7.0 m.m' }, { value: '8.0 m.m', label: '8.0 m.m' }, { value: '9.0 m.m', label: '9.0 m.m' }, { value: '10.0 m.m', label: '10.0 m.m' }];
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Accessories</span>
            </div>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">ET-Size :</label>
                            </div>
                            <Select
                                name="accessories.etSize.etsize"
                                isDisabled={props.isDisabled}
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('accessories.etSize.etsize', value.value);
                                }}
                                value = {
                                    opt.filter(option =>
                                        option.label === formikState.values.accessories.etSize.etsize)
                                }
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                options={[{ value: '6.0 m.m', label: '6.0 m.m' }, { value: '7.0 m.m', label: '7.0 m.m' }, { value: '8.0 m.m', label: '8.0 m.m' }, { value: '9.0 m.m', label: '9.0 m.m' }, { value: '10.0 m.m', label: '10.0 m.m' }]}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Date :</label>
                            </div>
                            <input
                                id="accessories.etSize.etDate"
                                name="accessories.etSize.etDate"
                                disabled = {props.isDisabled}
                                type="date"
                                onChange={onChange}
                                value={formikState.values.accessories.etSize.etDate}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            />
                        </div>
                    </div>
                </div>
                <hr className="my-4 h-px bg-gray border-0"></hr>
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className='flex flex-col w-[49%] px-6'>
                        <div className='grid grid-cols-c4 gap-[1%] items-center'>
                            <span className='flex flex-row text-col font-roboto text-sm'>CVP-Line :</span>
                            <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                                <RadioButton
                                    label="Established"
                                    id="accessories.cvpLine.established"
                                    name="accessories.cvpLine.cvp"
                                    onChange={onChange}
                                    data={formikState.values.accessories.cvpLine.cvp}
                                    value='established'
                                    onBlur={onBlur}
                                    checked={formikState.values.accessories.cvpLine.cvp === 'established'}
                                    disabled = {props.isDisabled}
                                />
                                <RadioButton
                                    label="Not done"
                                    id="accessories.cvpLine.notdone"
                                    name="accessories.cvpLine.cvp"
                                    data={formikState.values.accessories.cvpLine.cvp}
                                    onChange={onChange}
                                    value='not done'
                                    onBlur={onBlur}
                                    checked={formikState.values.accessories.cvpLine.cvp === 'not done'}
                                    disabled = {props.isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Date :</label>
                            </div>
                            <input
                                id="accessories.cvpLine.cvpDate"
                                name="accessories.cvpLine.cvpDate"
                                type="date"
                                onChange={onChange}
                                value={formikState.values.accessories.cvpLine.cvpDate}
                                disabled = {props.isDisabled}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Site :</label>
                            </div>
                            <Select
                                id="site"
                                name="accessories.cvpLine.site"
                                onChange={(value) => {
                                    formikState.setFieldValue('accessories.cvpLine.site', value.value);
                                }}
                                value = {
                                    [{ value: 'Jugular Central Line', label: 'Jugular Central Line' }, { value: 'Subclavian Central Line', label: 'Subclavian Central Line' }, { value: 'Femoral Central Line', label: 'Femoral Central Line' }, { value: 'Peripheral Line', label: 'Peripheral Line' }].filter(option =>
                                        option.label === formikState.values.accessories.cvpLine.site)
                                }
                                isSearchable={true}
                                isDisabled={props.isDisabled}
                                defaultValue={formikState.values.accessories.cvpLine.site}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                options={[{ value: 'Jugular Central Line', label: 'Jugular Central Line' }, { value: 'Subclavian Central Line', label: 'Subclavian Central Line' }, { value: 'Femoral Central Line', label: 'Femoral Central Line' }, { value: 'Peripheral Line', label: 'Peripheral Line' }]}
                            ></Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Doctor :</label>
                            </div>
                            <input
                                disabled = {props.isDisabled}
                                name="accessories.cvpLine.doctor"
                                onChange={onChange}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 w-full text-sm"
                                value={formikState.values.accessories.cvpLine.doctor}
                                type="text"
                            />
                        </div>
                    </div>
                </div>
                <hr className="my-4 h-px bg-gray border-0"></hr>
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className='flex flex-col w-[100%]  px-6'>
                        <div className='grid grid-cols-c3 gap-[1%]'>
                            <span className='flex flex-row text-col font-roboto text-sm'>ARTERAL Line :</span>
                            <div className='flex flex-row text-col font-roboto text-sm gap-x-4'>
                                <div className='flex flex-row text-col font-roboto text-sm gap-x-4 my-3'>
                                    <RadioButton
                                        label='Present'
                                        title='arteral'
                                        name='accessories.arteralLine.arteral'
                                        id='accessories.arteralLine.present'
                                        onChange={onChange}
                                        data={formikState.values.accessories.arteralLine.arteral}
                                        value='present'
                                        onBlur={onBlur}
                                        checked={formikState.values.accessories.arteralLine.arteral === 'present'}
                                        disabled = {props.isDisabled}
                                    />

                                    <RadioButton
                                        label='Absent'
                                        id='accessories.arteralLine.absent'
                                        name='accessories.arteralLine.arteral'
                                        onChange={onChange}
                                        value='absent'
                                        data={formikState.values.accessories.arteralLine.arteral}
                                        onBlur={onBlur}
                                        checked={formikState.values.accessories.arteralLine.arteral === 'absent'}
                                        disabled = {props.isDisabled}
                                    />

                                </div>
                                {formikState.values.accessories.arteralLine.arteral === 'present' &&
                        <input className='text-col font-roboto bg-gray  box-border border-graycol focus:outline-none w-full text-sm px-1 mt-0' name='accessories.arteralLine.arteralText' onChange={onChange}
                            value={formikState.values.accessories.arteralLine.arteralText}
                            onBlur={onBlur} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-4 h-px bg-gray border-0"></hr>
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">CXR-PA/AP :</label>
                            </div>
                            <input
                                id="accessories.cxr.cxr"
                                name="accessories.cxr.cxr"
                                type="text"
                                disabled = {props.isDisabled}
                                onChange={onChange}
                                value={formikState.values.accessories.cxr.cxr}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                placeholder='Write finding by doctor on duty'
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Repost :</label>
                            </div>
                            <input
                                id="accessories.cxr.cxrRepost"
                                name="accessories.cxr.cxrRepost"
                                type="text"
                                disabled = {props.isDisabled}
                                onChange={onChange}
                                value={formikState.values.accessories.cxr.cxrRepost}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            />
                        </div>
                    </div>
                    <div className='flex flex-col w-[49%] px-6'>
                        <div className='grid grid-cols-c4 gap-[1%] items-center'>
                            <div></div>
                            <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                                <RadioButton
                                    label="Yes"
                                    name="accessories.cxr.cxrgroup"
                                    id="accessories.cxr.yes"
                                    onChange={onChange}
                                    value='yes'
                                    onBlur={onBlur}
                                    checked={formikState.values.accessories.cxr.cxrgroup === 'yes'}
                                    disabled = {props.isDisabled}
                                />
                                <RadioButton
                                    label="No"
                                    id="accessories.cxr.no"
                                    name="accessories.cxr.cxrgroup"
                                    onChange={onChange}
                                    value='no'
                                    onBlur={onBlur}
                                    checked={formikState.values.accessories.cxr.cxrgroup === 'no'}
                                    disabled = {props.isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-4 h-px bg-gray border-0"></hr>
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">ECG :</label>
                            </div>
                            <input
                                id="accessories.ecg.ecg"
                                name="accessories.ecg.ecg"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.accessories.ecg.ecg}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                placeholder='Write findings by doctor'
                                disabled = {props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Repost :</label>
                            </div>
                            <input
                                id="accessories.ecg.ecgRepost"
                                name="accessories.ecg.ecgRepost"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.accessories.ecg.ecgRepost}
                                disabled = {props.isDisabled}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            />
                        </div>
                    </div>
                    <div className='flex flex-col w-[49%] px-6'>
                        <div className='grid grid-cols-c4 gap-[1%] items-center'>
                            <div></div>
                            <div className="flex flex-row text-col font-roboto text-sm gap-x-4">
                                <RadioButton
                                    label="Yes"
                                    name="accessories.ecg.ecggroup"
                                    id="yes"
                                    onChange={onChange}
                                    value='yes'
                                    onBlur={onBlur}
                                    checked={formikState.values.accessories.ecg.ecggroup === 'yes'}
                                    disabled = {props.isDisabled}
                                />
                                <RadioButton
                                    label="No"
                                    id="no"
                                    name="accessories.ecg.ecggroup"
                                    onChange={onChange}
                                    value='no'
                                    onBlur={onBlur}
                                    checked={formikState.values.accessories.ecg.ecggroup === 'no'}
                                    disabled = {props.isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-4 h-px bg-gray border-0"></hr>
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Misc :</label>
                            </div>
                            <input
                                id="accessories.misc.misc"
                                name="accessories.misc.misc"
                                type="text"
                                onChange={onChange}
                                value={formikState.values.accessories.misc.misc}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                                placeholder='Write Significantly Important reports/ Findings / Symptoms'
                                disabled = {props.isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accessories;
