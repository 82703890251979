export const fromFhir = (data) => {
    try {
        data = data.data;
        const result = {};
        for (const item of data.item) {
            if (item.type === 'group') {
                result[item.text] = {};
                result[item.text].linkId = item.linkId || null;
                for (const nestedItem of item.item) {
                    if (nestedItem.type === 'group') {
                        result[item.text][nestedItem.text] = {};
                        result[item.text][nestedItem.text].linkId = nestedItem.linkId || null;
                        for (const nestedItemII of nestedItem.item) {
                            result[item.text][nestedItem.text][nestedItemII.text] = nestedItemII.linkId;
                        }
                    } else {
                        result[item.text][nestedItem.text] = nestedItem.linkId;
                    }
                }
            } else {
                result[item.text] = item.linkId;
            }
        }
        return Promise.resolve(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
