// import { obsrvationExtensionMap } from '../../utils/common.js';

// import { convertFromFhirList, convertToFhir } from '../../index.js';

export const toFhir = async (obj) => {
    // eslint-disable-next-line no-console
    console.log(obj, 'values');
    const media = {
        resourceType: 'Media',
        status: 'completed',
        type: {
            coding:
            [{
                system: 'http://terminology.hl7.org/CodeSystem/media-type',
                code: 'image',
                display: 'Image'
            }]
        },
        createdDateTime: obj.createdDateTime,
        subject: {
            reference: `Patient/${obj.patientId}`
        },
        encounter: {
            reference: `Encounter/${obj.encounterId}`
        },
        content: {
            // data: obj.data,
            url: obj.data,
            size: obj.size,
            title: obj.title,
            creation: obj.createdDateTime
        }
    };
    return Promise.resolve(media);
};

export const toMediaFhirList = async (obj) => {
    const mediaListPromise = [];
    for (const media of obj.media) {
        media.patientId = obj.patientId;
        media.encounterId = obj.encounterId;
        media.createdDateTime = obj.date;
        mediaListPromise.push(toFhir(media));
    }
    return Promise.all(mediaListPromise);
};

export const fromMediaFhir = async (obj) => {
    const data = {
        id: obj.id,
        status: obj.status,
        createdDateTime: obj.createdDateTime,
        patientId: obj.subject.reference.split('/')[1],
        encounterId: obj.encounter.reference.split('/')[1],
        url: obj.content.url,
        size: obj.content.size,
        title: obj.content.title,
        creation: obj.content.creation
    };
    return Promise.resolve(data);
};

export const fromMediaFhirList = async (dataList) => {
    const promises = dataList.map(data => {
        return fromMediaFhir(data.resource);
    });
    return Promise.all(promises).then((list) => Promise.resolve(list));
};
