import React from 'react';
// import { useNavigate } from 'react-router-dom';
import nurse from '../../assets/images/nurse.svg';
import useAuth from '../../hooks/useAuth';

const Nurse = () => {
    const { auth } = useAuth();
    const username = auth.user.username;
    // const navigate = useNavigate();

    return (
        <div className='bg-start w-full h-screen flex flex-col justify-center items-center  bg-cover bg-no-repeat bg-center' >
            <h1 className='text-6xl font-extrabold font-Montserrat text-[#000000] text-center mb-10'>Welcome!</h1>
            <div className='flex justify-center'>
                <img src={nurse} alt='nurse' />
            </div>
            <h1 className='text-5xl font-bold font-Montserrat text-[#000000] text-center mt-10'>
                {username}
            </h1>
        </div>
    );
};

export default Nurse;
