/* eslint-disable no-console */
import React from 'react';

const Handover = (props) => {
    // const data = [
    //     {
    //         id: 1,
    //         HandoverFrom: 'Dr.Vadhithya',
    //         HandoverTo: 'Dr.Arnold',
    //         Shift: 'Morning (A)',
    //         Date: '26/12/2022',
    //         Time: '8.00 am',
    //         SpecialInstruction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod'
    //     },
    //     {
    //         id: 2,
    //         HandoverFrom: 'Dr.Akash',
    //         HandoverTo: 'Dr.Kategreen',
    //         Shift: 'Afternoon (B)',
    //         Date: '26/12/2022',
    //         Time: '4.00 pm',
    //         SpecialInstruction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod'
    //     }
    // ];

    // sample data
    // {
    //     HandoverFrom: '',
    //     HandoverTo: '',
    //     SpecialInstruction: ''
    // }
    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Handover</div>
            </div>
            <div className='flex flex-col flex-wrap w-full px-4 py-4'>
                <table className="table-auto border border-slate-300 w-full">
                    <thead>
                        <tr>
                            <th className='border border-slate-300 text-left text-sm p-2'>Hand over From</th>
                            <th className='border border-slate-300 text-left text-sm p-2'>Hand over To</th>
                            {/* <th className='border border-slate-300 text-left text-sm p-2'>Shift</th>
                            <th className='border border-slate-300 text-left text-sm p-2'>Date</th>
                            <th className='border border-slate-300 text-left text-sm p-2'>Time</th> */}
                            <th className='border border-slate-300 text-left text-sm p-2'>Special Instruction</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.formikState.values.Handover.map((item, index) => (
                            <tr key={index} className='border border-slate-300 py-5'>
                                <td className='text-sm p-2 border border-slate-300 text-col'>{item.HandoverFrom}</td>
                                <td className='text-sm p-2 border border-slate-300 text-col'>{item.HandoverTo}</td>
                                {/* <td className='text-sm p-2 border border-slate-300 text-col'>{item.Shift}</td>
                                <td className='text-sm p-2 border border-slate-300 text-col'>{item.Date}</td>
                                <td className='text-sm p-2 border border-slate-300 text-col'>{item.Time}</td> */}
                                <td className='text-sm p-2 border border-slate-300 text-col'>{item.SpecialInstruction}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Hand over from :</label>
                        </div>
                        <input
                            id={'Handover.HandoverFrom'}
                            name={'Handover.HandoverFrom'}
                            disabled={props.isDisabled}
                            type="text"
                            onChange={(e) => {
                                props.from(e.target.value);
                            }}
                            // value={props.formikState.values.Handover[0].HandoverFrom}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Hand over to :</label>
                        </div>
                        <input
                            id={'Handover.HandoverTo'}
                            name={'Handover.HandoverTo'}
                            type="text"
                            disabled={props.isDisabled}
                            onChange={(e) => {
                                props.to(e.target.value);
                            }}
                            // value={props.formikState.values.Handover[0].HandoverTo}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full pl-6 pr-8">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Special Instruction If Any :</label>
                        </div>
                        <input
                            id={'Handover.SpecialInstruction'}
                            name={'Handover.SpecialInstruction'}
                            type="text"
                            disabled={props.isDisabled}
                            onChange={(e) => {
                                props.si(e.target.value);
                            }}
                            // value={props.formikState.values.Handover[0].SpecialInstruction}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Handover;
