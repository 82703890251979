/* eslint-disable no-console */
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import React, { useState } from 'react';
import { FiCamera, FiUpload } from 'react-icons/fi';
import CaptureImg from '../../../components/CaptureImg';
import MultipleFileUpload from '../../../components/MultiplefileUpload';
const UploadDoc = ({ formikState, colourStyles, btnClassName, patientId, ...props }) => {
    const [uploadDoc, setUploadDoc] = useState(false);
    const [CapImgSrc, setCapImgSrc] = useState('');
    const [files, setFiles] = useState([]);
    const [s3Files, setS3Files] = useState([]);
    const HandleCLoseUploadDoc = () => {
        setFiles([]);
        setS3Files([]);
        setCapImgSrc('');
        setUploadDoc(false);
    };

    if (props.files) {
        setFiles(files);
    }

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
        });
    };

    const HandleSubmitFile = async () => {
        props.submittedFiles(s3Files, files);
        setUploadDoc(false);
        setCapImgSrc('');
    };
    return (
        <>
            <Dialog open={uploadDoc} maxWidth="xl">
                <DialogTitle>
                    Upload Document
                </DialogTitle>
                <DialogContent>
                    <div className=' mx-10 '>
                        {CapImgSrc && <img src={CapImgSrc} className='h-[30vh] w-full' />}
                    </div>
                    <div className='flex justify-center my-5'>
                        {!CapImgSrc && <CaptureImg CaptureImgInnerText={<FiCamera />} btnClassName={'border-[1px] p-3 mx-2'} ImgSrc={(srcLink) => { setCapImgSrc(srcLink); }} ImgFile={async (file) => {
                            if (Array.from(file.keys()).length === 0) {
                                setFiles([]);
                            } else {
                                for (const pair of file.entries()) {
                                    const fileBase64 = await getBase64(pair[1]);
                                    const fileName = pair[1].name;
                                    const fileFormat = pair[1].name.split('.').pop();
                                    setFiles([...files, { title: fileName, data: fileBase64, format: fileFormat, size: pair[1].size }]);
                                    setS3Files([...s3Files, { name: `lab/${patientId}/${fileName}`, content: fileBase64.split('base64,')[1] }]);
                                };
                            }
                        }} />}
                        <MultipleFileUpload fileList={async (file) => {
                            const filesTemp = [];
                            const filesTempS3 = [];
                            for (const pair of file.entries()) {
                                const fileBase64 = await getBase64(pair[1]);
                                const fileName = pair[1].name;
                                const fileFormat = pair[1].name.split('.').pop();
                                filesTempS3.push({ name: `lab/${patientId}/${fileName}`, content: fileBase64.split('base64,')[1] });
                                filesTemp.push({ title: fileName, data: fileBase64, format: fileFormat, size: pair[1].size });
                            }
                            setS3Files([...s3Files, ...filesTempS3]);
                            setFiles([...files, ...filesTemp]);
                        }} className='p-3 my-4' btnClassName={'border-[1px] p-3 mx-2'} />
                    </div>
                    <div className='text-center'>
                        <button type='button' className='px-6 py-2 w-[196px] mx-2 text-white bg-[#1663AA] text-base font-medium' onClick={HandleSubmitFile}>Submit</button>
                        <button type='button' className='px-6 py-2 w-[196px] mx-2 h-[38px] border-2 main font-"roboto" font-[500] text-[14px] leading-[15px] text-[#A41B28] active:border-[#BF1C2B] active:bg-gradient active:text-white active' onClick={HandleCLoseUploadDoc}>Close</button>
                    </div>

                </DialogContent>
            </Dialog>
            <button type='button' onClick={() => setUploadDoc(true)} title='Upload document' className={btnClassName} disabled={props.buttonDisabled}><FiUpload /></button>
        </>
    );
};
export default UploadDoc;
