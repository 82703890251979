/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as dayjs from 'dayjs';
import { useSnackbar } from 'react-simple-snackbar';
import Loader from '../../components/Loader';
import '../../assets/css/Style.css';
import Arrow1 from '../../assets/images/arrow1.svg';
import { stringReplace } from '../..//utils/common';
import UploadDoc from './Actions/UploadDoc';
import { convertToFhir } from '../../fhir-evolver';
import { addBundle } from '../../api/bundleRequest';
import { FaTrash } from 'react-icons/fa';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';
import { UploadObservationFile } from '../../api/lab';
import Select from 'react-select';
import LabPatientStatus from '../../utils/json/LabPatientStatus.json';

const History = ({ ...props }) => {
    const [spinner, setSpinner] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [s3UploadFiles, sets3UploadFiles] = useState([]);
    const [obsFileInfo, setObsFileInfo] = useState([]);
    const [testStatus, setTestStatus] = useState(0);
    const navigate = useNavigate();
    const { state } = useParams();
    useEffect(() => {
        console.log('state', location.state);
        if (state === 'view') setObsFileInfo(location.state.media);
        setTestStatus(location.state.status === 'registered' ? 1 : location.state.status === 'partial' ? 2 : 3);
    }, []);
    const location = useLocation();
    console.log('state', location.state);
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };

    const handleClose = () => {
        navigate('/lab/patient');
    };

    const [open] = useSnackbar(options);
    const formikState = useFormik({
        initialValues: {
            media: [],
            status: location.state?.status || '',
            observation: location.state?.observation || ''
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            console.log('labinput', values);
            const s3Links = await UploadObservationFile(s3UploadFiles);
            obsFileInfo.map((data, index) => (data.data = s3Links[index]));
            formikState.values.media = obsFileInfo;
            values.id = location.state.id;
            values.patientId = location.state.patient.id;
            values.investigation = location.state.investigation;
            values.encounterId = location.state.encounter.id;
            values.date = dayjs().format('YYYY-MM-DDTHH:mm:ssZ');
            convertToFhir('DiagnosticReportBundle', values).then((res) => {
                console.log('evol', res);
                setSpinner(true);
                return addBundle(res);
            }).then(() => {
                setSpinner(false);
                navigate('/lab/patient');
                open('Report was uploaded successfully.');
            });
        }
    });
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };

    const gender = stringReplace(location.state.patient.gender);
    const patientName = `${location.state.patient.firstName[0]} ${location.state.patient.surname}`;
    const data = location.state;
    let practitionerName = '';
    if (location.state.practitioner) {
        practitionerName = `${location.state.practitioner.givenName[0]} ${location.state.practitioner.family}`;
    }
    const dateFormat = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'

    });

    const removeFileFromFormik = (index) => {
        const FormTempFileInfoArray = obsFileInfo;
        FormTempFileInfoArray.splice(index, 1);
        setObsFileInfo(FormTempFileInfoArray);
        const FormTemps3FileInfoArray = s3UploadFiles;
        FormTemps3FileInfoArray.splice(index, 1);
        sets3UploadFiles(FormTemps3FileInfoArray);
        setRefreshFlag(!refreshFlag);
    };

    const convertFileSizetoKB = (bytes, decimals = 2) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

        let i = 0;

        for (i; bytes > 1024; i++) {
            bytes /= 1024;
        }

        return parseFloat(bytes.toFixed(decimals)) + ' ' + units[i];
    };

    const HandleObsFileUpload = (s3File, fileInfo) => {
        sets3UploadFiles(s3File);
        setObsFileInfo(fileInfo);
    };

    const PreviewUploadedFile = (imageSrc) => {
        document.getElementById('view-img').href = imageSrc;
        document.getElementById('view-img').click();
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            {console.log('status check', location.state.status)}
            <form className="w-full flex flex-col gap-y-4" onSubmit={formikState.handleSubmit}>
                <div className="h-screen">
                    <div className="bg-background h-full w-full px-4">
                        <div className="flex flex-row justify-between py-2">
                            <div className="flex items-center">
                                <Link to={'/home'}>
                                    <span className="text-base font-normal px-2 py-2 text-col">
                                        Home
                                    </span>
                                </Link>
                                <img src={Arrow1} alt="arrow" />
                                <span className="text-black font-normal text-base  font-roboto px-2 py-2">TestPrescribed</span>
                            </div>

                            <div className="flex items-center gap-x-10">
                                <span className="font-normal text-base  font-roboto px-2 py-2 text-sm text-col">Date: {dateFormat}</span>
                                <Link to='/lab/pasthistory' state={location}>
                                    <button className=' px-14 py-2 text-white bg-[#1663AA] text-base font-medium' disabled={true}>View History</button>
                                </Link>
                            </div>
                        </div>
                        <div className='bg-[#1663AA] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                            <span className='text-white text-base font-roboto '>Prescribed tests</span>
                            <div className='flex flex-row gap-x-6'>
                                <span className='text-sm text-white'> {patientName} | {gender} | {location.state.patient.uhid[0]} | {location.state.encounter.inPatientId}</span>
                            </div>
                        </div>
                        <div className='bg-white p-6'>

                            <section className="grid grid-cols-2 gap-4">
                                <div className='flex my-3'>
                                    <label className='text-lg'>Tests:</label>
                                    <p className='mx-5 text-lg font-semibold'>{data.investigation}</p>
                                </div>

                                <div className='flex my-3'>
                                    <label className='text-lg'>Test Prescribed By:</label>
                                    <p className='mx-5 text-lg font-semibold'>{practitionerName}</p>
                                </div>

                            </section>
                            <section className='py-7'>
                                <div className='grid grid-cols-2 w-[50%] my-5'>
                                    <label className='text-col text-left'>Observation: </label>
                                    <textarea className='w-[100%] min-h-[10vh] border-[1px] min-w-[17rem] bg-gray' onChange={formikState.handleChange} name='observation' id='observation' onInput={formikState.handleChange} disabled={state === 'view' || false}>{formikState.values.observation}</textarea>
                                </div>
                                <div className='grid grid-cols-2 w-[50%] my-5'>
                                    <label className='text-col text-left'>Status: </label>
                                    <Select
                                        name="status"
                                        isSearchable={true}
                                        onChange={(value) => {
                                            formikState.setFieldValue('status', value.value);
                                        }}
                                        value={
                                            LabPatientStatus
                                                ? LabPatientStatus.find((option) => option.value === formikState.values.status)
                                                : ''}
                                        options={LabPatientStatus}
                                        className="text-col  bg-dark font-roboto bg-gray focus:outline-none h-10 min-w-[15rem] text-sm w-[100%]"
                                        styles={colourStyles}
                                        isDisabled={state === 'view' || false}
                                    >
                                    </Select>
                                </div>
                                <div className='grid grid-cols-2 w-[50%] my-5'>
                                    <label className='text-col'>Upload document</label>
                                    <div>
                                        <UploadDoc patientId={location.state.patient.uhid[0]} buttonDisabled={state === 'view' ? 'true' : false} colourStyles={colourStyles} btnClassName={'mr-3 border-[1px] p-2 border-[#E3E3E3]'} formikState={formikState} submittedFiles={(s3File, fileInfo) => HandleObsFileUpload(s3File, fileInfo)} />
                                    </div>
                                </div>
                            </section>
                            {/* Test status section */}
                            <section className='flex justify-center py-5'>
                                <button className='border-2 border-gray-400 bg-[#1663AA] text-white rounded-tl-full rounded-bl-full rounded border-rad rounded-br-lg shadow-lg px-10 py-3' disabled>Sample Initialized</button>
                                <button className={`border-2 border-gray ${testStatus > 1 ? ' bg-[#1663AA] text-white' : 'bg-gray text-black'} rounded-tr-lg rounded-tr-lg  shadow-lg px-10 rounded-lt-xl py-3`} disabled>Sample Proccessed</button>
                                <button className={`border-2 border-gray ${testStatus > 2 ? ' bg-[#1663AA] text-white' : 'bg-gray text-black'}  shadow-lg rounded-tr-full rounded-br-full px-10 rounded-lt-xl py-3`} disabled>Report Generated</button>
                            </section>
                            {/* Uploaded file list */}
                            {obsFileInfo.length > 0
                                ? (
                                    <><p className='my-4'>Documents: </p><table className='table-custom my-4 w-[500px]'>
                                        <thead className='!text-center'>
                                            <tr>
                                                <td>File name</td>
                                                <td>Size</td>
                                                <td>Actions</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {obsFileInfo.map((items, index) => (
                                                <tr key={index}>
                                                    <td className='p-2'>{items.title}</td>
                                                    <td className='p-2'>{convertFileSizetoKB(items.size)}</td>
                                                    <td className='!text-center p-2'>
                                                        <button type='button' className='text-md text-sky-900 mx-1' onClick={() => PreviewUploadedFile(items.url || items.data)}>
                                                            <AiFillEye />
                                                        </button>
                                                        {state !== 'view'
                                                            ? <button type='button' className='text-md text-red-500 mx-1' onClick={() => removeFileFromFormik(index)} disabled={state === 'view' ? 'true' : false}>
                                                                <FaTrash />
                                                            </button>
                                                            : <></>}

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table></>)
                                : <></>}
                            <div className='text-center'>
                                {state !== 'view' ? <button className='px-14 py-2 text-white bg-[#1663AA] text-base font-medium'>Submit</button> : <></>}
                                <button type='button' className='ml-3 bg-gray border-graycol py-2 px-14  text-base font-sm' onClick={handleClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* anchor tag to view uploaded image */}
                <a href='#' id='view-img' hidden download='preview.png'></a>
            </form >
        </>
    );
};

export default History;
