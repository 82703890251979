import React from 'react';

const CriticalReport = () => {
    return (
        <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            <div className='flex'>Critical Report</div>
        </div>
    );
};

export default CriticalReport;
