/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import ProgressNotesForm from '../../forms/ProgressNotes';
import { convertToFhir, convertFromFhirList, convertFromFhir } from '../../fhir-evolver';
import { addBundle } from '../../api/bundleRequest';
// import Loader from '../../components/Loader';
import { getEncounter } from '../../api/encounter';
import { getClinicalImpressionByEncounter } from '../../api/clinicalImpression';
import { toInitialAssessmentForm } from '../../fhir-evolver/resources/ClinicalImpression';

const ProgressNotesAdd = () => {
    // const [spinner, setSpinner] = useState(false);
    const [encounter, setEncounter] = useState([]);
    const [viewPrimary, setViewPrimary] = useState('');
    const [viewAssessment, setViewAssessment] = useState('');
    const navigate = useNavigate();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const [snackbarOpen] = useSnackbar(options);
    const params = useParams();

    useEffect(() => {
        getEncounter(`_id=${params.id}&_include=Encounter:*&_pretty=true`).then((response) => {
            // console.log('getencounter', response);
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                // console.log('fhirencounter', res);
                setEncounter(res);
            });
        });
        getClinicalImpressionByEncounter(`${params.id}&identifier=PrimaryCare&_include=*`).then((response) => {
            convertFromFhir('PrimaryCare', response).then((output) => {
                // console.log('primarycare evolver', output);
                setViewPrimary(output);
            });
        });
        getClinicalImpressionByEncounter(`${params.id}&identifier=InitialAssessment&_include=*`).then((response) => {
            convertFromFhir('ClinicalImpression', response.data.entry).then((res) => {
                toInitialAssessmentForm(res).then((res) => {
                    setViewAssessment(res);
                }).catch(e => {
                    return e;
                });
            });
        });
    }, []);

    const onAdd = (obj) => {
        console.log('addobjprogressNote', obj);
        convertToFhir('Progress-Notes', obj).then((output) => {
            addBundle(output).then((httpResponse) => {
                // setSpinner(true);
                console.log('addbundleapiprogress', httpResponse);
                if (!httpResponse.data.issue) {
                    console.log('success');
                    snackbarOpen('ProgressNotes Created Successfully');
                    navigate('/patient/admission-list');
                } else {
                    console.log('errior', httpResponse.data.issue);
                    snackbarOpen('Something went wrong');
                }
                // setSpinner(false);
            });
        }).catch((e) => console.log('fhirerror', e));
    };
    return (
        <>
            {/* {console.log('check state', encounter)} */}
            {/* <Loader showSpinner={spinner} /> */}
            <ProgressNotesForm handleSubmit={onAdd} isDisabled={false} isAdd={true} type='add' encounter={encounter} primary = {viewPrimary} assessment = {viewAssessment}/>
        </>
    );
};

export default ProgressNotesAdd;
