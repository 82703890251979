/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Station from '../../containers/NurseFunctions/Station';
import { convertFromFhir } from '../../fhir-evolver';
import { getClinicalImpressionById } from '../../api/clinicalImpression';

const StationView = (props) => {
    const params = useParams();
    const [viewSecondary, setViewSecondary] = useState('');
    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            // eslint-disable-next-line no-console
            console.log('HTTP response Secondary Care', response);
            convertFromFhir('SecondaryCare', response).then((output) => {
                // eslint-disable-next-line no-console
                console.log('Evolver Response Secondary Care', output);
                setViewSecondary(output);
            });
        });
    }, []);
    return (
        <>
            { viewSecondary && <Station viewSecondary = {viewSecondary} isView = {true} isDisabled={true} isDoctor = {props.isDoctor}/>}
        </>
    );
};
export default StationView;
