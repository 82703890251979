import React from 'react';
import StationView from '../../containers/NurseFunctions/StationView';

const StationDoctorView = () => {
    return (
        <StationView isDoctor = {true} />
    );
};

export default StationDoctorView;
