/* eslint-disable no-console */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Datepicker from '../components/Datepicker';
import Select from 'react-select';
import state from '../utils/json/State.json';
import { ageCalculation, stringReplace } from '../utils/common';

import { TextField } from '../components/TextField';
import Button from '../components/Button';
import RadioButton from '../components/RadioButton';

import Nationality from '../utils/json/Nationalities.json';
import Gender from '../utils/json/Gender.json';
import Income from '../utils/json/Income.json';
import Education from '../utils/json/Education.json';
import Bloodgroup from '../utils/json/Bloodgroup.json';
import Marriagestatus from '../utils/json/Marriagestatus.json';
import Religion from '../utils/json/Religion.json';
import Occupation from '../utils/json/Occupation.json';
import { getUhid } from '../api/patient';
import Country from '../utils/json/Country.json';
import CancelModal from '../components/CancelModal';

const PatientRegistration = (props) => {
    const [isShowing, setIsShowing] = useState(false);
    const navigate = useNavigate();
    const toggle = () => {
        props.isView ? navigate('/patient') : setIsShowing(!isShowing);
    };
    const moveBack = () => {
        navigate('/patient');
    };
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3',
            color: '#757575'
        })
    };
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const pinCode = /^[0-9]{6}/;
    const submitHandler = (data, { setSubmitting }) => {
        getUhid().then((response) => {
            formikState.values.uhid = response.data.toString();
            props.handleSubmit(formikState.values);
            setSubmitting(false);
        });
        console.log('formikValue', formikState.values);
    };
    const dates = props.viewData?.birthDate?.split('T')[0];
    // let ist = true;
    // useEffect(() => {
    //     if (ist) {
    //         getUhid().then((response) => {
    //             setuhid(response.data);
    //         });
    //         ist = false;
    //     }
    // }, []);

    const patientName = props.viewData ? props.viewData?.firstName + ' ' + props.viewData?.surname : '';
    const name = patientName.toUpperCase();
    const gender = props.viewData ? props.viewData?.gender : '';
    const patientGender = stringReplace(gender);
    const age = props.viewData ? props.viewData?.birthDate : '';
    const patientAge = ageCalculation(age);
    const patientUhid = props.viewData ? props.viewData?.uhid : '';
    const formikState = useFormik({
        initialValues: {
            firstName: props.viewData?.firstName?.[0] || '',
            surName: props.viewData?.surname || '',
            birthDate: props.viewData?.birthDate ? new Date(dates) : '',
            maritalStatus: props.viewData?.maritalStatus || '',
            guardianName: props.viewData?.guardianName || '',
            guardianType: props.viewData?.guardianType || '',
            religion: props.viewData?.religion || '',
            nationality: props.viewData?.nationality || 'Indian',
            education: props.viewData?.education || '',
            occupation: props.viewData?.occupation || '',
            bloodGroup: props.viewData?.bloodGroup || '',
            BPLNo: props.viewData?.BPLNo || '',
            rationCard: props.viewData?.rationCard || '',
            telecom: props.viewData?.telecom || '',
            annualIncome: props.viewData?.annualIncome || '',
            aadharNumber: props.viewData?.aadharNumber || '',
            email: props.viewData?.email || '',
            gender: props.viewData?.gender || '',
            resaddress1: props.viewData?.resaddress1 || '',
            resaddress2: props.viewData?.resaddress2 || '',
            city: props.viewData?.city || '',
            state: props.viewData?.state || '',
            country: props.viewData?.country || 'India',
            postalCode: props.viewData?.postalCode || '',
            officeAddress1: props.viewData?.officeAddress1 || '',
            officeAddress2: props.viewData?.officeAddress2 || '',
            city1: props.viewData?.city1 || '',
            state1: props.viewData?.state1 || '',
            country1: props.viewData?.country1 || 'India',
            postalCode1: props.viewData?.postalCode1 || '',
            active: true,
            uhid: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            firstName: Yup.string().required('Required *'),
            surName: Yup.string().required('Required *'),
            birthDate: Yup.date().required('Required *'),
            maritalStatus: Yup.string().required('Required *'),
            guardianName: Yup.string().required('Required *'),
            guardianType: Yup.string().required('Required'),
            nationality: Yup.string().required('Required *'),
            bloodGroup: Yup.string().required('Required *'),
            telecom: Yup.string().matches(phoneRegExp, 'Invalid').min(10, 'to short')
                .max(10, 'to long').required('Required *'),
            aadharNumber: Yup.string().matches(phoneRegExp, 'Invalid').min(12, 'to short').max(12, 'to long'),
            email: Yup.string().email('Invalid email'),
            gender: Yup.string().required('Required *'),
            resaddress1: Yup.string().required('Required *'),
            city: Yup.string().required('Required *'),
            state: Yup.mixed().required('Required *'),
            country: Yup.string().required('Required *'),
            postalCode: Yup.string().length(6).matches(pinCode, 'Invalid').required('Required *'),
            postalCode1: Yup.string().length(6).matches(pinCode, 'Invalid')
        }),
        onSubmit: submitHandler
    });

    return (
        <form className="w-full flex flex-col gap-y-4 mb-10" onSubmit={formikState.handleSubmit}>
            {props.viewData
                ? <div className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                    <span className='text-white text-base font-roboto '>{name} | {patientGender} | {patientAge}</span>
                    <span className='text-white text-base font-roboto '> {patientUhid}</span>
                </div>
                : <div className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-6 flex flex-row items-center h-12">
                    {/* <span className="text-white text-lg font-roboto ">UHID: {uhid}</span> */}
                    <span className="text-white text-lg font-roboto ">Patient Particulars</span>
                </div>
            }
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Patient Name"
                            id="firstName"
                            name="firstName"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.firstName}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={true}
                            style={props.style}
                        />
                        {formikState.touched.firstName && formikState.errors.firstName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.firstName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Surname"
                            id="surname"
                            name="surName"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.surName}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={true}
                            style={props.style}
                        />
                        {formikState.touched.surName && formikState.errors.surName
                            ? (
                                <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.surName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">DOB:</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Datepicker
                                id="birthDate"
                                name="birthDate"
                                dateFormat="dd-MMM-yyyy"
                                style={props.style}
                                isDisabled={props.isDisabled}
                                // eslint-disable-next-line no-mixed-operators
                                selected={formikState.values.birthDate && new Date(formikState.values.birthDate) || null}
                                value={formikState.values.birthDate}
                                onChange={newDate => {
                                    formikState.setFieldValue('birthDate', newDate);
                                }}
                            />
                        </div>
                        {formikState.touched.birthDate && formikState.errors.birthDate
                            ? (<div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.birthDate}</div>)
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Marriage status</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="maritalStatus"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('maritalStatus', value.value);
                                }}
                                value={
                                    Marriagestatus
                                        ? Marriagestatus.find((option) => option.value === formikState.values.maritalStatus)
                                        : ''}
                                options={Marriagestatus}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                // styles={colourStyles}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.maritalStatus && formikState.errors.maritalStatus
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.maritalStatus}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%]">
                            <div className="flex flex-row">
                                <span className="flex flex-row text-black font-roboto text-sm">Relation</span>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <div className="flex flex-row flex-wrap text-col font-roboto text-sm gap-x-8 ">
                                <RadioButton
                                    label="Father"
                                    name="guardianType"
                                    id="father"
                                    onChange={formikState.handleChange}
                                    value='father'
                                    onBlur={formikState.handleBlur}
                                    checked={formikState.values.guardianType === 'father'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="Husband"
                                    id="husband"
                                    name="guardianType"
                                    onChange={formikState.handleChange}
                                    value='husband'
                                    onBlur={formikState.handleBlur}
                                    checked={formikState.values.guardianType === 'husband'}
                                    disabled={props.isDisabled}
                                />
                                <RadioButton
                                    label="Guardian"
                                    id="guardian"
                                    name="guardianType"
                                    onChange={formikState.handleChange}
                                    value='guardian'
                                    onBlur={formikState.handleBlur}
                                    checked={formikState.values.guardianType === 'guardian'}
                                    disabled={props.isDisabled}
                                />
                            </div>

                        </div>
                        {formikState.touched.firstName && formikState.errors.firstName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.firstName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Relation Name"
                            id="guardianName"
                            name="guardianName"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.guardianName}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={true}
                            style={props.style}
                        />
                        {formikState.touched.guardianName && formikState.errors.guardianName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.guardianName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <label className="text-black font-roboto text-sm">Religion :</label>
                            <Select
                                name="religion"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('religion', value.value);
                                }}
                                value={
                                    Religion
                                        ? Religion.find((option) => option.value === formikState.values.religion)
                                        : ''}
                                options={Religion}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Nationality</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="nationality"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('nationality', value.value);
                                }}
                                value={
                                    Nationality
                                        ? Nationality.find((option) => option.value === formikState.values.nationality)
                                        : ''}
                                options={Nationality}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.nationality && formikState.errors.nationality
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.nationality}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <label className="text-black font-roboto text-sm">Education :</label>
                            <Select
                                name="education"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('education', value.value);
                                }}
                                value={
                                    Education
                                        ? Education.find((option) => option.value === formikState.values.education)
                                        : ''}
                                options={Education}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <label className="text-black font-roboto text-sm">Occupation :</label>
                            <Select
                                name="occupation"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('occupation', value.value);
                                }}
                                value={
                                    Occupation
                                        ? Occupation.find((option) => option.value === formikState.values.occupation)
                                        : ''}
                                options={Occupation}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Blood group</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="bloodGroup"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('bloodGroup', value.value);
                                }}
                                value={
                                    Bloodgroup
                                        ? Bloodgroup.find((option) => option.value === formikState.values.bloodGroup)
                                        : ''}
                                options={Bloodgroup}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.bloodGroup && formikState.errors.bloodGroup
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.bloodGroup}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="APL/BPL No"
                            id="BPLNo"
                            name="BPLNo"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.BPLNo}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={false}
                            style={props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Ration Card"
                            id="ration"
                            name="rationCard"
                            type="text"
                            value={formikState.values.rationCard}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={false}
                            style={props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[49%] px-6">
                        <TextField
                            label="Mobile Number"
                            id="mobile"
                            name="telecom"
                            type="tel"
                            value={formikState.values.telecom}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={true}
                            style={props.style}
                        />
                        {formikState.touched.telecom && formikState.errors.telecom
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.telecom}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <label className="text-black font-roboto text-sm">Annual Income :</label>
                            <Select
                                name="annualIncome"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('annualIncome', value.value);
                                }}
                                value={
                                    Income
                                        ? Income.find((option) => option.value === formikState.values.annualIncome)
                                        : ''}
                                options={Income}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Aadhar Number"
                            id="adhar"
                            name="aadharNumber"
                            type="text"
                            value={formikState.values.aadharNumber}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            // isMandatory={true}
                            style={props.style}
                        />
                        {formikState.touched.aadharNumber && formikState.errors.aadharNumber
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.aadharNumber}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Email"
                            id="email"
                            name="email"
                            type="text"
                            value={formikState.values.email}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory={false}
                            style={props.style}
                        />
                        {formikState.touched.email && formikState.errors.email
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.email}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Gender</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="gender"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('gender', value.value);
                                }}
                                value={
                                    Gender
                                        ? Gender.find((option) => option.value === formikState.values.gender)
                                        : ''}
                                options={Gender}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                isDisabled={props.isDisabled}
                                styles={props.isView ? (props.customStyles) : (colourStyles)}
                            >
                            </Select>
                        </div>
                        {formikState.touched.gender && formikState.errors.gender
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.gender}</div>
                            )
                            : null}
                    </div>
                </div>

            </div>
            <div className="flex flex-row flex-wrap gap-[2%] w-full">
                <div className="flex flex-col gap-y-3 gap-x-6 w-[49%]">
                    <div className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-1 p-6  flex flex-row">
                        <span className="text-white text-lg font-roboto p-2 ">Residential Address (Required)</span>
                    </div>
                    <div className="flex flex-col gap-y-3 gap-x-6">
                        <div className="flex px-6 flex-col ">
                            <TextField
                                label="Address Line 1"
                                id="resaddress1"
                                name="resaddress1"
                                type="text"
                                value={formikState.values.resaddress1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory={true}
                                style={props.style}
                            />
                            {formikState.touched.resaddress1 && formikState.errors.resaddress1
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.resaddress1}</div>
                                )
                                : null}
                        </div>
                        <div className="flex px-6 flex-col">
                            <TextField
                                label="Address Line 2"
                                id="resaddress2"
                                name="resaddress2"
                                type="text"
                                value={formikState.values.resaddress2}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                style={props.style}
                            />
                        </div>
                        <div className="flex px-6 flex-col">
                            <TextField
                                label="City"
                                id="city"
                                name="city"
                                type="text"
                                value={formikState.values.city}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory={true}
                                style={props.style}
                            />
                            {formikState.touched.city && formikState.errors.city
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.city}</div>
                                )
                                : null}
                        </div>
                        <div className='flex px-6 flex-col'>
                            <div className="grid grid-cols-c4 gap-[1%] items-center ">
                                <div className="flex flex-row">
                                    <label className="text-black font-roboto text-sm">Country</label>
                                    <span className="text-error px-1">*</span>
                                    <span>:</span>
                                </div>
                                <Select
                                    name="country"
                                    isSearchable={true}
                                    onChange={(value) => {
                                        formikState.setFieldValue('country', value.value);
                                    }}
                                    value={
                                        Country
                                            ? Country.find((option) => option.value === formikState.values.country)
                                            : ''}
                                    options={Country}
                                    className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                    isDisabled={props.isDisabled}
                                    styles={props.isView ? (props.customStyles) : (colourStyles)}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className='flex px-6 flex-col'>
                            <div className="grid grid-cols-c4 gap-[1%] items-center">
                                <div className="flex flex-row">
                                    <label className="text-black font-roboto text-sm">State</label>
                                    <span className="text-error px-1">*</span>
                                    <span>:</span>
                                </div>
                                <Select
                                    name="state"
                                    isSearchable={true}
                                    onChange={(value) => {
                                        formikState.setFieldValue('state', value.value);
                                    }}
                                    value={
                                        state
                                            ? state.find((option) => option.value === formikState.values.state)
                                            : ''}
                                    options={state}
                                    className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                    isDisabled={props.isDisabled}
                                    styles={props.isView ? (props.customStyles) : (colourStyles)}
                                >
                                </Select>
                            </div>
                            {formikState.touched.state && formikState.errors.state
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.state}</div>
                                )
                                : null}
                        </div>
                        <div className="flex px-6 flex-col">
                            <TextField
                                label="Pin-code"
                                id="pincode"
                                name="postalCode"
                                type="text"
                                value={formikState.values.postalCode}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory={true}
                                style={props.style}
                            />
                            {formikState.touched.postalCode && formikState.errors.postalCode
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.postalCode}</div>
                                )
                                : null}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-y-3 gap-x-6 w-[49%] ">
                    <div className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-1 p-6 flex flex-row">
                        <span className="text-white text-lg font-roboto p-2">Office Address (Optional)</span>
                    </div>
                    <div className="flex flex-col gap-y-3 gap-x-6">
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="Address Line 1"
                                id="officeAddress1"
                                name="officeAddress1"
                                type="text"
                                value={formikState.values.officeAddress1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory={false}
                                style={props.style}
                            />
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="Address Line 2"
                                id="officeAddress2"
                                name="officeAddress2"
                                type="text"
                                value={formikState.values.officeAddress2}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory={false}
                                style={props.style}
                            />
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="City"
                                id="city1"
                                name="city1"
                                type="text"
                                value={formikState.values.city1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory={false}
                                style={props.style}
                            />
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <div className="grid grid-cols-c4 gap-[1%] items-center ">
                                <div className="flex flex-row ">
                                    <label className="text-black font-roboto text-sm">Country :</label>
                                </div>
                                <Select
                                    name="country1"
                                    isSearchable={true}
                                    onChange={(value) => {
                                        formikState.setFieldValue('country1', value.value);
                                    }}
                                    value={
                                        Country
                                            ? Country.find((option) => option.value === formikState.values.country1)
                                            : ''}
                                    options={Country}
                                    className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                    isDisabled={props.isDisabled}
                                    styles={props.isView ? (props.customStyles) : (colourStyles)}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <div className="grid grid-cols-c4 gap-[1%] items-center">
                                <div className="flex flex-row">
                                    <label className="text-black font-roboto text-sm">State :</label>
                                </div>
                                <Select
                                    name="state1"
                                    isSearchable={true}
                                    onChange={(value) => {
                                        formikState.setFieldValue('state1', value.value);
                                    }}
                                    value={
                                        state
                                            ? state.find((option) => option.value === formikState.values.state1)
                                            : ''}
                                    options={state}
                                    className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                    isDisabled={props.isDisabled}
                                    styles={props.isView ? (props.customStyles) : (colourStyles)}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="Pin-code"
                                id="pincode1"
                                name="postalCode1"
                                type="text"
                                value={formikState.values.postalCode1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                isMandatory={false}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                style={props.style}
                            />
                            {formikState.touched.postalCode1 && formikState.errors.postalCode1
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.postalCode1}</div>
                                )
                                : null}
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[4rem] my-8 text-sm border rounded-md text-white h-12"
                        disabled={props.isDisabled}
                        style={{ display: props.display }}

                    />
                    <button type='button' className="bg-gray border-graycol py-[0.5rem] px-[4rem] my-8 text-sm  border rounded-md text-col h-12"
                        onClick={toggle}>{props.buttonName}
                    </button>
                    <CancelModal
                        isShowing={isShowing}
                        hide={toggle}
                        alertMsg='Are you sure you want to close without saving?'
                        back={moveBack}
                    />
                </div>
            </div>
        </form>
    );
};

export default PatientRegistration;
