import React from 'react';
import PrimaryCareView from '../../containers/NurseFunctions/PrimaryCareView';

const PrimaryCareDoctorView = () => {
    return (
        <PrimaryCareView isDoctor = {true}/>
    );
};

export default PrimaryCareDoctorView;
