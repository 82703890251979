/* eslint-disable no-console */
import React, { useState } from 'react';
// import Select from 'react-select';
import DropdownSearchWithLazyload from '../../../components/DropdownSearchWithLazyload';
import option from '../.././../utils/json/Investigation.json';

function Investigations (props) {
    const [options, setOption] = useState(option);
    // const customStyles = {
    //     option: (provided, state) => ({
    //         ...provided,
    //         borderBottom: '2px dotted green',
    //         color: state.isSelected ? 'yellow' : 'black',
    //         backgroundColor: state.isSelected ? 'green' : 'white'
    //     }),
    //     control: (provided) => ({
    //         ...provided,
    //         outline: 'none',
    //         background: '#F3F3F3'
    //     })
    // };
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
                (Ix) Investigations<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>(Ix) Investigations :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between items-center'>
                            <div className='w-[49%]'>
                                {/* <Select
                                    name={'Investigations.Investigations'}
                                    id={'Investigations.Investigations'}
                                    onChange={(e) => {
                                        props.formikState.values.Investigations.Investigations = e;
                                    }}
                                    options={options}
                                    isMulti={true}
                                    styles={customStyles}
                                /> */}
                                <DropdownSearchWithLazyload placeholder='search' onChange={(e) => {
                                    props.formikState.values.Investigations.Investigations = e;
                                    console.log('investigation', props.formikState.values);
                                }} InputclassName={'w-full bg-gray outline-0 pl-3'} values={options} disabled={props.disabled} defaultValue={props.formikState.values.Investigations.Investigations}/>
                            </div>
                            <input
                                className='w-[calc(49%-140px)] text-[#8F8F8F] pl-[10px] border-[1px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="Investigations.InvestigationsInput"
                                id="Investigations.InvestigationsInput"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Investigations.InvestigationsInput}
                                type='text'
                                placeholder='Write here'
                            />
                            <button type='button' className={props.isNurse ? 'w-[120px] h-[38px] bg-gradient mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'w-[120px] h-[38px] bg-bgrad mx-2.5 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}
                                onClick={() => {
                                    const deletVal = [...option];
                                    deletVal.splice(0, 0, {
                                        label: String(props.formikState.values.Investigations.InvestigationsInput),
                                        value: String(props.formikState.values.Investigations.InvestigationsInput)
                                    }
                                    );
                                    setOption(deletVal);
                                }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Investigations;
