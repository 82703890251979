/* eslint-disable no-console */
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import React, { useEffect, useState } from 'react';

const MultiInputForm = ({ sectionTitle, formik, isDisabled, ...props }) => {
    const [formCount, setformCount] = useState(formik.values[props.formikArrayTitle]);
    const [refreshFlag, setRefreshFlag] = useState(true);
    const sample = Object.assign({}, formik.values[props.formikArrayTitle][0]);
    for (const key in sample) {
        sample[key] = '';
    };
    useEffect(() => {}, [refreshFlag]);
    const addForm = () => {
        formik.values[props.formikArrayTitle].push(sample);
        setformCount(formik.values[props.formikArrayTitle]);
        setRefreshFlag(!refreshFlag);
    };
    const removeForm = (index) => {
        console.log(formCount);
        if (formCount.length !== 1) {
            formik.values[props.formikArrayTitle].pop();
            setformCount(formik.values[props.formikArrayTitle]);
            setRefreshFlag(!refreshFlag);
        }
    };
    return (
        <>
            {props.isDoctor
                ? <div className='h-[45px] items-center bg-iat flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    {sectionTitle}
                </div>
                : <div className='h-[45px] items-center bg-tgray flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    {sectionTitle}
                </div> }
            <div className='py-4'>
                {formik
                    ? formCount.map((data, index) => (
                        props.fields.map((items, innerindex) => (
                            <div className='flex flex-row'
                                key={index.toString() + innerindex.toString()}
                            >
                                <div className="flex flex-row w-1/3 justify-around items-center my-3">
                                    <label className='flex flex-col justify-around w-[200px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>{items.label}</label>
                                    <div className='w-[350px] justify-end flex items-center'>
                                        {items.type === 'text'
                                            ? (
                                                <input
                                                    className={`h-[38px] pl-1 w-full text-input bg-gray border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] ${props.InputClassName}`}
                                                    type='text'
                                                    id={`${props.formikArrayTitle}[${[index]}].${[items.id]}`}
                                                    name={`${props.formikArrayTitle}[${[index]}].${[items.id]}`}
                                                    onChange={formik.handleChange}
                                                    disabled={isDisabled}
                                                    defaultValue={formik.values[props.formikArrayTitle][index][items.id]} />
                                            )
                                            : (
                                                <input
                                                    className={`h-[38px] pl-1 w-full text-input bg-gray border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] ${props.InputClassName}`}
                                                    type='number'
                                                    id={`${props.formikArrayTitle}[${[index]}].${[items.id]}`}
                                                    name={`${props.formikArrayTitle}[${[index]}].${[items.id]}`}
                                                    onChange={formik.handleChange}
                                                    disabled={isDisabled}
                                                    defaultValue={formik.values[props.formikArrayTitle][index][items.id]} />
                                            )}

                                        <div className='absolute text-right p-[10px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>{items.InputPostfix}</div>
                                    </div>
                                </div>
                                {innerindex < Number(props.dateCount)
                                    ? <><div className="flex flex-row w-1/3 justify-around items-center">
                                        <label className='flex flex-col items-center w-[calc(100%-173px)] justify-around font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Time :</label>
                                        <div className='relative flex'>
                                            <input
                                                className={`h-[38px] w-[173px] text-input bg-gray pl-1 border-[1px] border-[#E3E3E3]  outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]  ${props.InputTimeClassName}`}
                                                id={'time'}
                                                name={'time'}
                                                type='time'
                                                onChange={formik.handleChange}
                                                disabled={isDisabled}
                                                defaultValue={data.time} />
                                        </div>
                                    </div><div className="flex flex-row w-1/3 items-center">
                                        <label className='flex flex-col items-center justify-around w-[calc(90%-173px)] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Date :</label>
                                        <input
                                            className={`h-[38px] pl-1 w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3]  outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] ${props.InputDateClassName}`}
                                            id={'date'}
                                            name={'date'}
                                            type='date'
                                            onChange={formik.handleChange}
                                            disabled={isDisabled}
                                            defaultValue={data.date} />
                                        <button type='button' className='items-center flex justify-center text-fc border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1 mr-[0.75rem]' disabled={isDisabled} onClick={() => addForm()}>
                                            <FaPlus />
                                        </button>
                                        <button type='button' className='items-center text-fc justify-center flex border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1' disabled={isDisabled} onClick={() => removeForm(index)}>
                                            <FaMinus />
                                        </button>
                                    </div></>
                                    : <></>}

                            </div>

                        ))
                    ))
                    : null}

            </div>
        </>
    );
};
export default MultiInputForm;
