import React from 'react';
import Select from 'react-select';
import shift from '../../utils/json/ShiftTimes.json';
import Checkbox from '../Check';

const InvestigationChecklist = ({ formikState, onChange, onBlur, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Investigation Check List</span>
            </div>
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Date :</label>
                        </div>
                        <input
                            id="investigationCheckList.investigationDate"
                            name="investigationCheckList.investigationDate"
                            type="date"
                            onChange={onChange}
                            value={formikState.values.investigationCheckList.investigationDate}
                            className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Shift :</label>
                        </div>
                        <Select
                            name="investigationCheckList.investigationShift"
                            isSearchable={true}
                            onChange={(value) => {
                                formikState.setFieldValue('investigationCheckList.investigationShift', value.value);
                            }}
                            value={
                                shift.find((option) => option.value === formikState.values.investigationCheckList.investigationShift)}
                            options = {shift}
                            className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                            styles={colourStyles}
                            isDisabled={props.isDisabled}
                        >
                        </Select>
                    </div>
                </div>
            </div>
            <div className='flex flex-col flex-wrap w-full px-6'>
                <table className="table-auto border border-slate-300 w-[60%]">
                    <thead>
                        <tr>
                            <th className='border border-slate-300 '></th>
                            <th className='border border-slate-300 text-sm text-left p-2'>Done</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>Pending</th>
                            <th className='border border-slate-300 text-sm text-left p-2'>Not Ordered</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='border border-slate-300'>
                            <td className='text-sm p-2 border border-slate-300 text-col'>Lab</td>
                            <td className='text-sm border border-slate-300 p-2'> <Checkbox
                                id="done"
                                name="investigationCheckList.lab.done"
                                onChange={onChange}
                                value='done'
                                checked={formikState.values.investigationCheckList.lab.done === 'done' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="pending"
                                name="investigationCheckList.lab.pending"
                                onChange={onChange}
                                value='pending'
                                checked= {formikState.values.investigationCheckList.lab.pending === 'pending' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="not ordered"
                                name="investigationCheckList.lab.notordered"
                                onChange={onChange}
                                value='not ordered'
                                checked={formikState.values.investigationCheckList.lab.notordered === 'not ordered' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                        </tr>
                        <tr className='border border-slate-300'>
                            <td className='text-sm border border-slate-300 p-2 text-col'>ECG</td>
                            <td className='text-sm border border-slate-300 p-2'> <Checkbox
                                id="done"
                                name="investigationCheckList.ecg.done"
                                onChange={onChange}
                                value='done'
                                checked={formikState.values.investigationCheckList.ecg.done === 'done' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="pending"
                                name="investigationCheckList.ecg.pending"
                                onChange={onChange}
                                value='pending'
                                checked={formikState.values.investigationCheckList.ecg.pending === 'pending' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="not ordered"
                                name="investigationCheckList.ecg.notordered"
                                onChange={onChange}
                                value= 'not ordered'
                                checked={formikState.values.investigationCheckList.ecg.notordered === 'not ordered' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                        </tr>
                        <tr className='border border-slate-300'>
                            <td className='text-sm border border-slate-300 p-2 text-col'>X-Ray</td>
                            <td className='text-sm border border-slate-300 p-2'> <Checkbox
                                id="done"
                                name="investigationCheckList.xray.done"
                                onChange={onChange}
                                value='done'
                                checked={formikState.values.investigationCheckList.xray.done === 'done' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="pending"
                                name="investigationCheckList.xray.pending"
                                onChange={onChange}
                                value='pending'
                                checked={formikState.values.investigationCheckList.xray.pending === 'pending' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="not ordered"
                                name="investigationCheckList.xray.notordered"
                                onChange={onChange}
                                value='not ordered'
                                checked={formikState.values.investigationCheckList.xray.notordered === 'not ordered' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                        </tr>
                        <tr className='border border-slate-300'>
                            <td className='text-sm border border-slate-300 p-2 text-col'>Echo</td>
                            <td className='text-sm border border-slate-300 p-2'> <Checkbox
                                id="done"
                                name="investigationCheckList.echo.done"
                                onChange={onChange}
                                value='done'
                                checked={formikState.values.investigationCheckList.echo.done === 'done' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="pending"
                                name="investigationCheckList.echo.pending"
                                onChange={onChange}
                                value='pending'
                                checked={formikState.values.investigationCheckList.echo.pending === 'pending' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="not ordered"
                                name="investigationCheckList.echo.notordered"
                                onChange={onChange}
                                value='not ordered'
                                checked={formikState.values.investigationCheckList.echo.notordered === 'not ordered' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                        </tr>
                        <tr className='border border-slate-300'>
                            <td className='text-sm border border-slate-300 p-2 text-col'>ESG</td>
                            <td className='text-sm border border-slate-300 p-2'> <Checkbox
                                id="done"
                                name="investigationCheckList.esg.done"
                                onChange={onChange}
                                value='done'
                                checked={formikState.values.investigationCheckList.esg.done === 'done' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="pending"
                                name="investigationCheckList.esg.pending"
                                onChange={onChange}
                                value='pending'
                                checked={formikState.values.investigationCheckList.esg.pending === 'pending' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                            <td className='text-sm border border-slate-300 p-2'><Checkbox
                                id="not ordered"
                                name="investigationCheckList.esg.notordered"
                                onChange={onChange}
                                value='not ordered'
                                checked={formikState.values.investigationCheckList.esg.notordered === 'not ordered' ? true : null}
                                disabled={props.isDisabled}
                                onBlur={onBlur}
                            /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default InvestigationChecklist;
