import React from 'react';

const Dropdown = ({ label, isMandatory, ...props }) => {
    return (
        isMandatory
            ? <div className="grid grid-cols-c4 gap-[1%] items-center">
                <div className="flex flex-row">
                    <label className="font-roboto text-black text-sm" htmlFor={props.id || props.name}>{label}</label>
                    <span className="text-error px-1">*</span>
                    <span>:</span>
                </div>
                <select {...props} />
            </div>
            : <div className="grid grid-cols-c4 gap-[1%] items-center">
                <label className="font-roboto text-black text-sm" htmlFor={props.id || props.name}>{label} :</label>
                <select {...props} />
            </div>

    );
};

export { Dropdown };
