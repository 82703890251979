import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Table from '../../components/Table';
import '../../assets/css/Style.css';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';
import { pharmacyPatientHistoryCustomStyles } from '../../style';
import Button from '../../components/Button';

const PharmacyPatientHistory = () => {
    const currentDate = new Date();
    const dateFormat = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes();
    const navigate = useNavigate();
    const rows = [

        {
            prescribedBy: 'Doctor Name',
            DosageForm: 'Tablet',
            drugName: 'Glucose',
            dosage: '50mg',
            dosageType: 'Oral',
            regimen: '1-0-1',
            noOfDays: '3',
            quantity: '6 Tablets',
            quantityPending: '-',
            status: 'Partial Delivered'
        },
        {
            prescribedBy: 'Doctor Name',
            DosageForm: 'Tablet',
            drugName: 'Cetirizen',
            dosage: '100mg',
            dosageType: 'IV',
            regimen: '1-0-1',
            noOfDays: '1',
            quantity: '6 Tablets',
            quantityPending: '6 Tablets',
            status: 'Delivered'
        },
        {
            prescribedBy: 'Doctor Name',
            DosageForm: 'Tablet',
            drugName: 'Dolo',
            dosage: '650mg',
            dosageType: 'Oral',
            regimen: '1-0-1',
            noOfDays: '2',
            quantity: '6 Tablets',
            quantityPending: '6 Tablets',
            status: 'Not Available'
        }
    ];
    const columns = [
        {

            name: 'S.No',
            selector: (row, index) => index + 1
        },

        {

            name: 'Prescribed by',
            selector: (row) => row.prescribedBy,
            sortable: true
        },
        {

            name: 'Dosage Form',
            selector: (row) => row.DosageForm,
            sortable: true
        },
        {
            name: 'Drug Name',
            selector: (row) => row.drugName,
            sortable: true
        },
        {
            name: 'Dosage',
            selector: (row) => row.dosage,
            sortable: true
        },
        {
            name: 'Dosage Type',
            selector: (row) => row.dosageType,
            sortable: true
        },
        {
            name: 'Regimen',
            selector: (row) => row.regimen,
            sortable: true
        },
        {
            name: 'No.of days',
            selector: (row) => row.noOfDays,
            sortable: true
        },
        {
            name: 'Quantity',
            selector: (row) => row.quantity,
            sortable: true
        },
        {
            name: 'Quantity Pending',
            selector: (row) => row.quantityPending,
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.quantityPending === '-',
                    style: {
                        color: '#0000'
                    }
                },
                {
                    when: row => row.status === 'Delivered',
                    style: {
                        color: '#429C0A'
                    }
                },
                {
                    when: row => row.status === 'Not Available',
                    style: {
                        color: '#FF0000'
                    }
                },
                {
                    when: row => row.status === 'Partial Delivered',
                    style: {
                        color: '#F17401'
                    }
                }
            ]
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.status === 'Delivered',
                    style: {
                        color: '#429C0A'
                    }
                },
                {
                    when: row => row.status === 'Not Available',
                    style: {
                        color: '#FF0000'
                    }
                },
                {
                    when: row => row.status === 'Partial Delivered',
                    style: {
                        color: '#F17401'
                    }
                }
            ]
        }
    ];

    const tableHeadingTag = <div className='flex'><div><span className='font-semibold'>Prescribed Medicine  </span><span className='px-3'>Date: {dateFormat}</span> <span className='mx-3'>Time: {currentTime}</span></div></div>;

    return (
        <div className="h-screen">
            <div className="bg-background h-full w-full">

                <div className="flex flex-row justify-between">
                    <div className="flex flex-row justify-between ">
                        <div className="flex items-center">
                            <Link to={'/home'}>
                                <span className="text-base font-normal px-2 py-2 text-col">
                                    Home
                                </span>
                            </Link>
                            <img src={Arrow1} alt="arrow" />
                            <Link to={'/pharmacy'}>
                                <span className="text-base font-normal px-2 py-2 text-col">Patient List</span>
                            </Link>
                            <img src={Arrow1} alt="arrow" />
                            <span className="text-black font-normal text-base  font-roboto px-2 py-2">History</span>
                        </div>
                    </div>
                    <div className="flex p-2">
                        <div className='pt-2 relative mx-auto text-col'>
                            <input
                                className='shadow border-col bg-white h-11 w-72 px-5 pr-4  text-sm focus:outline-none'
                                type='date'
                                name='datepicker'
                            />
                        </div>
                        <div className="pt-2 relative mx-4 text-col">
                            <input
                                className="shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none"
                                type="search"
                                name="search"
                                placeholder="Search"
                            />
                            <button type="submit" className="absolute right-0 top-0 mt-6 mr-4">
                                <img src={Search} alt="search" />
                            </button>
                        </div>
                        <div>
                            <Button label={'Submit'} className="mt-2 px-[6em] py-[.68em] mx-4 bg-[#ddb660] text-white font-semibold" />
                        </div>

                    </div>
                </div>
                <div className="bg-white rounded px-6 pt-6 ml-2 mr-2 flex flex-col justify-between">
                    <Table rows={rows} columns={columns} isNurse={true} tableHeading={tableHeadingTag} customStyles={pharmacyPatientHistoryCustomStyles} />
                    <Table rows={rows} columns={columns} isNurse={true} tableHeading={tableHeadingTag} customStyles={pharmacyPatientHistoryCustomStyles} />
                    <Table rows={rows} columns={columns} isNurse={true} tableHeading={tableHeadingTag} customStyles={pharmacyPatientHistoryCustomStyles} />
                </div>
                <Button label={'Back'} className={'px-[6em] py-[.68em] mb-4 mx-4 bg-[#ddb660] ml-[1.8em] text-white font-semibold'} onClick={() => navigate('/pharmacy/patient')} />
            </div>
        </div>
    );
};

export default PharmacyPatientHistory;
