import axios from '../utils/axios';

export const getMedicationRequest = (params) => {
    let url = '/prod/fhir/MedicationRequest';
    if (params) {
        url += `?${params}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};

export const editMedicationRequest = (id, body) => {
    return axios.put(`/prod/fhir/MedicationRequest/${id}`, body).then((response) => {
        return response;
    });
};
