import React from 'react';

function Other (props) {
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>
            Other Systemic Examination<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Other Systemic Examination : </div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <input required
                                className='h-[47px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                id='Other.Osystem'
                                name='Other.Osystem'
                                type='text'
                                placeholder='Write here'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Other.Osystem}
                                disabled={props.disabled}
                            />
                        </div>
                    </div>
                </div>
                <div className='bg-white py-[7.5px]'>
                    <div className='flex items-center w-full h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>GIT System :</div>
                        <div className='flex w-[calc(100%-160px)]'>
                            <div className='flex'>
                                <label className='self-center w-[80px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.GITsystem.GITsystem'
                                        type='radio'
                                        id='NAD'
                                        onChange={props.formikState.handleChange}
                                        value='NAD'
                                        checked={props.formikState.values.Other.GITsystem.GITsystem === 'NAD'}
                                        disabled={props.disabled}
                                    />NAD
                                </label>
                                <label className='self-center w-[85px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.GITsystem.GITsystem'
                                        id='Significant'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Significant'
                                        checked={props.formikState.values.Other.GITsystem.GITsystem === 'Significant'}
                                        disabled={props.disabled}
                                    />Significant
                                </label>
                            </div>
                            {props.formikState.values.Other.GITsystem.GITsystem === 'Significant' &&
                        <input required
                            className='h-[47px] w-[calc(100%-150px)] ml-[20px] text-[#8F8F8F] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='Other.GITsystem.GITsystemInput'
                            name='Other.GITsystem.GITsystemInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Other.GITsystem.GITsystemInput}
                            disabled={props.disabled}
                        />
                            }
                        </div>
                    </div>
                </div>
                <div className='bg-white py-[7.5px]'>
                    <div className='flex items-center w-full h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>CVS System :</div>
                        <div className='flex w-[calc(100%-160px)]'>
                            <div className='flex'>
                                <label className='self-center flex w-[80px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.CVSsystem.CVSsystem'
                                        type='radio'
                                        id='NAD'
                                        onChange={props.formikState.handleChange}
                                        value='NAD'
                                        checked={props.formikState.values.Other.CVSsystem.CVSsystem === 'NAD'}
                                        disabled={props.disabled}
                                    />NAD
                                </label>
                                <label className='self-center w-[85px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.CVSsystem.CVSsystem'
                                        id='Significant'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Significant'
                                        checked={props.formikState.values.Other.CVSsystem.CVSsystem === 'Significant'}
                                        disabled={props.disabled}
                                    />Significant
                                </label>
                            </div>
                            {props.formikState.values.Other.CVSsystem.CVSsystem === 'Significant' &&
                        <input required
                            className='h-[47px] w-[calc(100%-150px)] ml-[20px] text-[#8F8F8F] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='Other.CVSsystem.CVSsystemInput'
                            name='Other.CVSsystem.CVSsystemInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Other.CVSsystem.CVSsystemInput}
                            disabled={props.disabled}
                        />
                            }
                        </div>
                    </div>
                </div>
                <div className='bg-white py-[7.5px]'>
                    <div className='flex items-center w-full h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>RESPIRATORY System :</div>
                        <div className='flex w-[calc(100%-160px)]'>
                            <div className='flex'>
                                <label className='self-center w-[80px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.Rsystem.Rsystem'
                                        type='radio'
                                        id='NAD'
                                        onChange={props.formikState.handleChange}
                                        value='NAD'
                                        checked={props.formikState.values.Other.Rsystem.Rsystem === 'NAD'}
                                        disabled={props.disabled}
                                    />NAD
                                </label>
                                <label className='self-center w-[85px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.Rsystem.Rsystem'
                                        id='Significant'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Significant'
                                        checked={props.formikState.values.Other.Rsystem.Rsystem === 'Significant'}
                                        disabled={props.disabled}
                                    />Significant
                                </label>
                            </div>
                            {props.formikState.values.Other.Rsystem.Rsystem === 'Significant' &&
                        <input required
                            className='h-[47px] w-[calc(100%-150px)] ml-[20px] text-[#8F8F8F] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='Other.Rsystem.RsystemInput'
                            name='Other.Rsystem.RsystemInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Other.Rsystem.RsystemInput}
                            disabled={props.disabled}
                        />
                            }
                        </div>
                    </div>
                </div>
                <div className='bg-white py-[7.5px]'>
                    <div className='flex items-center w-full h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>CNS System :</div>
                        <div className='flex w-[calc(100%-160px)]'>
                            <div className='flex'>
                                <label className='self-center w-[80px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.CNSsystem.CNSsystem'
                                        type='radio'
                                        id='NAD'
                                        onChange={props.formikState.handleChange}
                                        value='NAD'
                                        checked={props.formikState.values.Other.CNSsystem.CNSsystem === 'NAD'}
                                        disabled={props.disabled}
                                    />NAD
                                </label>
                                <label className='self-center w-[85px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.CNSsystem.CNSsystem'
                                        id='Significant'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Significant'
                                        checked={props.formikState.values.Other.CNSsystem.CNSsystem === 'Significant'}
                                        disabled={props.disabled}
                                    />Significant
                                </label>
                            </div>
                            {props.formikState.values.Other.CNSsystem.CNSsystem === 'Significant' &&
                        <input required
                            className='h-[47px] w-[calc(100%-150px)] ml-[20px] text-[#8F8F8F] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='Other.CNSsystem.CNSsystemInput'
                            name='Other.CNSsystem.CNSsystemInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Other.CNSsystem.CNSsystemInput}
                            disabled={props.disabled}
                        />
                            }
                        </div>
                    </div>
                </div>
                <div className='bg-white py-[7.5px]'>
                    <div className='flex items-center w-full h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>GUT System :</div>
                        <div className='flex w-[calc(100%-160px)]'>
                            <div className='flex'>
                                <label className='self-center w-[80px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.GUTsystem.GUTsystem'
                                        type='radio'
                                        id='NAD'
                                        onChange={props.formikState.handleChange}
                                        value='NAD'
                                        checked={props.formikState.values.Other.GUTsystem.GUTsystem === 'NAD'}
                                        disabled={props.disabled}
                                    />NAD
                                </label>
                                <label className='self-center w-[85px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.GUTsystem.GUTsystem'
                                        id='Significant'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Significant'
                                        checked={props.formikState.values.Other.GUTsystem.GUTsystem === 'Significant'}
                                        disabled={props.disabled}
                                    />Significant
                                </label>
                            </div>
                            {props.formikState.values.Other.GUTsystem.GUTsystem === 'Significant' &&
                        <input required
                            className='h-[47px] w-[calc(100%-150px)] ml-[20px] text-[#8F8F8F] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='Other.GUTsystem.GUTsystemInput'
                            name='Other.GUTsystem.GUTsystemInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Other.GUTsystem.GUTsystemInput}
                            disabled={props.disabled}
                        />
                            }
                        </div>
                    </div>
                </div>
                <div className='bg-white py-[7.5px]'>
                    <div className='flex items-center w-full h-[47px]'>
                        <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>OTHER (if any )<br/>Systems :</div>
                        <div className='flex w-[calc(100%-160px)]'>
                            <div className='flex'>
                                <label className='self-center w-[80px] flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.Other.OtherInput'
                                        type='radio'
                                        id='NAD'
                                        onChange={props.formikState.handleChange}
                                        value='NAD'
                                        checked={props.formikState.values.Other.Other.OtherInput === 'NAD'}
                                        disabled={props.disabled}
                                    />NAD
                                </label>
                                <label className='self-center w-[85px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                    <input required
                                        className='self-center mr-[2.5px]'
                                        name='Other.Other.OtherInput'
                                        id='Significant'
                                        type='radio'
                                        onChange={props.formikState.handleChange}
                                        value='Significant'
                                        checked={props.formikState.values.Other.Other.OtherInput === 'Significant'}
                                        disabled={props.disabled}
                                    />Significant
                                </label>
                            </div>
                            {props.formikState.values.Other.Other.OtherInput === 'Significant' &&
                        <input required
                            className='h-[47px] w-[calc(100%-150px)] ml-[20px] text-[#8F8F8F] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                            id='Other.Other.Other'
                            name='Other.Other.Other'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Other.Other.Other}
                            disabled={props.disabled}
                        />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Other;
