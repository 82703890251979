import React from 'react';

function AllergicHo (props) {
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
            Allergic H/o<span className='text-[crimson] text-[20px]'><pre>*</pre></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center h-[47px]'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Allergic H/o :<span className='text-[crimson]'>*</span></div>
                    <div className='w-[150px] justify-around flex'>
                        <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input required
                                className='self-center mr-[2.5px]'
                                name='AllergicHo.AllergicHoYesNo'
                                type='radio'
                                id='No'
                                onChange={props.formikState.handleChange}
                                value='No'
                                checked={props.formikState.values.AllergicHo.AllergicHoYesNo === 'No'}
                                disabled={props.disabled}
                            />No
                        </label>
                        <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input required
                                className='self-center mr-[2.5px]'
                                name='AllergicHo.AllergicHoYesNo'
                                id='Yes'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                value='Yes'
                                checked={props.formikState.values.AllergicHo.AllergicHoYesNo === 'Yes'}
                                disabled={props.disabled}
                            />Yes
                        </label>
                    </div>
                    {props.formikState.values.AllergicHo.AllergicHoYesNo === 'Yes' &&
                        <input required
                            className='w-[calc(100%-310px)] h-[47px] pl-[10px] text-[13px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none text-[#8F8F8F] placeholder:text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                            id='AllergicHo.AllergicHoInput'
                            name='AllergicHo.AllergicHoInput'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.AllergicHo.AllergicHoInput}
                            disabled={props.disabled}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default AllergicHo;
