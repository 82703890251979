import React from 'react';

function Pertinent (props) {
    return (
        <div>
            <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>
            Pertinent Clinical Findings<span className='text-[crimson] text-[20px]'></span>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[17.5px] text-[#757575]'>Pertinent Clinical<br/>Findings :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <input required
                                className='h-[47px] text-[#8F8F8F] w-[100%] text-[15px] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-gray outline-none'
                                id='Pertinent'
                                name='Pertinent'
                                type='text'
                                placeholder='Any significant reports or findings outside of this hospitals'
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Pertinent}
                                disabled={props.disabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pertinent;
