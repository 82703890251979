import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { TextField } from '../components/TextField';
import { Dropdown } from '../components/Dropdown';
import Button from '../components/Button';

import Nationality from '../utils/json/Nationality.json';
import Income from '../utils/json/Income.json';
import Education from '../utils/json/Education.json';
import Bloodgroup from '../utils/json/Bloodgroup.json';
import Marriagestatus from '../utils/json/Marriagestatus.json';
import Religion from '../utils/json/Religion.json';
import Occupation from '../utils/json/Occupation.json';
import statesDistricts from '../utils/json/state_city.json';

const Nurse = (props) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const submitHandler = (data, { setSubmitting }) => {
        // if(!props.isAdmission)
        props.handleSubmit(formikState.values);
        // console.log('formik', formikState.values);
        setSubmitting(false);
    };

    const formikState = useFormik({
        initialValues: {
            firstName: props.viewData?.firstName || '',
            surName: props.viewData?.surname || '',
            birthDate: props.viewData?.birthDate || '',
            maritalStatus: props.viewData?.maritalStatus || '',
            guardianName: props.viewData?.guardianName || '',
            religion: props.viewData?.religion || '',
            nationality: props.viewData?.nationality || 'Indian',
            education: props.viewData?.education || '',
            occupation: props.viewData?.occupation || '',
            bloodGroup: props.viewData?.bloodGroup || '',
            BPLNo: props.viewData?.BPLNo || '',
            rationCard: props.viewData?.rationCard || '',
            telecom: props.viewData?.telecom || '',
            annualIncome: props.viewData?.annualIncome || '',
            aadharNumber: props.viewData?.aadharNumber || '',
            emailId: props.viewData?.email || '',
            resaddress1: props.viewData?.resaddress1 || '',
            resaddress2: props.viewData?.resaddress2 || '',
            city: props.viewData?.city || '',
            district: props.viewData?.district || 'Chennai',
            state: props.viewData?.state || 'Tamil Nadu',
            country: props.viewData?.country || 'India',
            postalCode: props.viewData?.postalCode || '',
            officeAddress1: props.viewData?.officeAddress1 || '',
            officeAddress2: props.viewData?.officeAddress2 || '',
            city1: props.viewData?.city1 || '',
            district1: props.viewData?.district1 || 'Chennai',
            state1: props.viewData?.state1 || 'Tamil Nadu',
            country1: props.viewData?.country1 || 'India',
            postalCode1: props.viewData?.postalCode1 || ''
            // active: true
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Required *'),
            surName: Yup.string().required('Required *'),
            birthDate: Yup.date().required('Required *'),
            maritalStatus: Yup.string().required('Required *'),
            guardianName: Yup.string().required('Required *'),
            nationality: Yup.string().required('Required *'),
            bloodGroup: Yup.string(),
            telecom: Yup.string().matches(phoneRegExp, 'Invalid').min(10, 'to short')
                .max(10, 'to long').required('Required *'),
            aadharNumber: Yup.string().min(12, 'to short').max(12, 'to long'),
            emailId: Yup.string(),
            resaddress1: Yup.string().required('Required *'),
            resaddress2: Yup.string().required('Required *'),
            city: Yup.string().required('Required *'),
            district: Yup.string().required('Required *'),
            state: Yup.string().required('Required *'),
            country: Yup.string().required('Required *'),
            postalCode: Yup.string().required('Required *')
        }),
        onSubmit: submitHandler
    });

    return (
        <form className="w-full flex flex-col gap-y-4" onSubmit={formikState.handleSubmit}>
            <div className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] p-6 flex flex-row items-center h-12">
                <span className="text-white text-lg font-roboto ">Patient Particulars</span>
            </div>

            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Patient Name"
                            id="firstName"
                            name="firstName"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.firstName}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.firstName && formikState.errors.firstName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.firstName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Surname"
                            id="surname"
                            name="surName"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.surName}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.surName && formikState.errors.surName
                            ? (
                                <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.surName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="DOB"
                            id="birthDate"
                            name="birthDate"
                            type="date"
                            onChange={formikState.handleChange}
                            value={formikState.values.birthDate}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.birthDate && formikState.errors.birthDate
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.birthDate}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Dropdown
                            label="Marriage status"
                            name="maritalStatus"
                            value={formikState.values.maritalStatus}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        >
                            {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </Dropdown>
                        {formikState.touched.maritalStatus && formikState.errors.maritalStatus
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.maritalStatus}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Father/ Husband/ Guardian name"
                            id="guardian"
                            name="guardianName"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.guardianName}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.guardianName && formikState.errors.guardianName
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.guardianName}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Dropdown
                            label="Religion"
                            name="religion"
                            value={formikState.values.religion}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        >
                            {(Religion || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Dropdown
                            label="Nationality"
                            name="nationality"
                            value={formikState.values.nationality}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        >
                            {(Nationality || []).map(item => (
                                <option
                                    key={item.nationality}
                                    value={item.nationality}

                                >
                                    {item.nationality}
                                </option>
                            ))}
                        </Dropdown>
                        {formikState.touched.nationality && formikState.errors.nationality
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.nationality}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Dropdown
                            label="Education"
                            name="education"
                            value={formikState.values.education}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        >
                            {(Education || []).map(item => (
                                <option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6" >
                        <Dropdown
                            label="Occupation"
                            name="occupation"
                            value={formikState.values.occupation}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        >
                            {(Occupation || []).map(item => (
                                <option
                                    key={item.OccupationName}
                                    value={item.OccupationName}
                                >
                                    {item.OccupationName}
                                </option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Dropdown
                            label="Blood group"
                            name="bloodGroup"
                            value={formikState.values.bloodGroup}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        >
                            {(Bloodgroup || []).map(item => (
                                <option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </Dropdown>
                        {formikState.touched.bloodGroup && formikState.errors.bloodGroup
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.bloodGroup}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="APL/BPL No"
                            id="BPLNo"
                            name="BPLNo"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.BPLNo}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Ration Card"
                            id="ration"
                            name="rationCard"
                            type="text"
                            value={formikState.values.rationCard}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        />
                    </div>
                    <div className="flex flex-col w-[49%] px-6">
                        <TextField
                            label="Mobile Number"
                            id="mobile"
                            name="telecom"
                            type="tel"
                            value={formikState.values.telecom}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.telecom && formikState.errors.telecom
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.telecom}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Dropdown
                            label="Annual Income"
                            name="annualIncome"
                            value={formikState.values.annualIncome}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            disabled={props.isDisabled}
                            isMandatory = {false}
                            style = {props.style}
                        >
                            {(Income || []).map(item => (
                                <option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </Dropdown>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Aadhar Number"
                            id="adhar"
                            name="aadharNumber"
                            type="text"
                            value={formikState.values.aadharNumber}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.aadharNumber && formikState.errors.aadharNumber
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.aadharNumber}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Email"
                            id="email"
                            name="emailId"
                            type="text"
                            value={formikState.values.emailId}
                            onChange={formikState.handleChange}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            disabled={props.isDisabled}
                            isMandatory = {true}
                            style = {props.style}
                        />
                        {formikState.touched.emailId && formikState.errors.emailId
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.emailId}</div>
                            )
                            : null}
                    </div>
                </div>

            </div>
            <div className="flex flex-row flex-wrap gap-[2%] w-full">
                <div className="flex flex-col gap-y-3 gap-x-6 w-[49%]">

                    <div className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] py-1 p-6  flex flex-row h-12">
                        <span className="text-white text-lg font-roboto p-2 ">Residential Address (Required)</span>
                    </div>
                    <div className="flex flex-col gap-y-3 gap-x-6">
                        <div className="flex px-6 flex-col ">
                            <TextField
                                label="Address Line 1"
                                id="resaddress1"
                                name="resaddress1"
                                type="text"
                                value={formikState.values.resaddress1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                            />
                            {formikState.touched.resaddress1 && formikState.errors.resaddress1
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.resaddress1}</div>
                                )
                                : null}
                        </div>
                        <div className="flex px-6 flex-col">
                            <TextField
                                label="Address Line 2"
                                id="resaddress2"
                                name="resaddress2"
                                type="text"
                                value={formikState.values.resaddress2}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                            />
                            {formikState.touched.resaddress2 && formikState.errors.resaddress2
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.resaddress2}</div>
                                )
                                : null}
                        </div>
                        <div className="flex px-6 flex-col">
                            <TextField
                                label="City"
                                id="city"
                                name="city"
                                type="text"
                                value={formikState.values.city}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                            />
                            {formikState.touched.city && formikState.errors.city
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.city}</div>
                                )
                                : null}
                        </div>
                        <div className="flex px-6 flex-col">
                            <Dropdown
                                label="Country"
                                name="country"
                                value={formikState.values.country}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                            >
                                {Object.keys(statesDistricts.countries).map(element => (
                                    <option
                                        key={element}
                                        value={statesDistricts.countries[element]}
                                    >
                                        {statesDistricts.countries[element]}
                                    </option>
                                ))}
                            </Dropdown>
                            {formikState.touched.country && formikState.errors.country
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.country}</div>
                                )
                                : null}

                        </div>
                        <div className="flex px-6 flex-col">
                            <Dropdown
                                label="State"
                                name="state"
                                value={formikState.values.state}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm" >

                                {Object.keys(statesDistricts[formikState.values.country].states)
                                    .map(element =>
                                        <option
                                            key={element}
                                            value={statesDistricts[formikState.values.country].states[element]}
                                        >
                                            {statesDistricts[formikState.values.country].states[element]}
                                        </option>
                                    )}

                            </Dropdown>
                            {formikState.touched.state && formikState.errors.state
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.state}</div>
                                )
                                : null}

                        </div>
                        <div className="flex px-6 flex-col">
                            <Dropdown
                                label="District"
                                name="district"
                                value={formikState.values.district}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm" >

                                {Object.keys(statesDistricts[formikState.values.state].districts).map(element =>
                                    <option
                                        key={element}
                                        value={statesDistricts[formikState.values.state].districts[element]}
                                    >
                                        {statesDistricts[formikState.values.state].districts[element]}
                                    </option>
                                )}

                            </Dropdown>
                            {formikState.touched.district && formikState.errors.district
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.district}</div>
                                )
                                : null}
                        </div>

                        <div className="flex px-6 flex-col">
                            <TextField
                                label="Pin-code"
                                id="pincode"
                                name="postalCode"
                                type="text"
                                value={formikState.values.postalCode}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {true}
                                style = {props.style}
                            />
                            {formikState.touched.postalCode && formikState.errors.postalCode
                                ? (
                                    <div className="flex w-full text-sm text-error font-roboto justify-end">{formikState.errors.postalCode}</div>
                                )
                                : null}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-y-3 gap-x-6 w-[49%] ">
                    <div className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] py-1 p-6 flex flex-row h-12">
                        <span className="text-white text-lg font-roboto p-2">Office Address (Optional)</span>
                    </div>
                    <div className="flex flex-col gap-y-3 gap-x-6">
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="Address Line 1"
                                id="officeAddress1"
                                name="officeAddress1"
                                type="text"
                                value={formikState.values.officeAddress1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {false}
                                style = {props.style}
                            />
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="Address Line 2"
                                id="officeAddress2"
                                name="officeAddress2"
                                type="text"
                                value={formikState.values.officeAddress2}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {false}
                                style = {props.style}
                            />
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="City"
                                id="city1"
                                name="city1"
                                type="text"
                                value={formikState.values.city1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                isMandatory = {false}
                                style = {props.style}
                            />
                        </div>

                        <div className="flex pr-6 pl-6 flex-col">
                            <Dropdown
                                label="Country"
                                name="country1"
                                value={formikState.values.country1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                                disabled={props.isDisabled}
                                isMandatory = {false}
                                style = {props.style}
                            >
                                {Object.keys(statesDistricts.countries).map(element => (
                                    <option
                                        key={element}
                                        value={statesDistricts.countries[element]}
                                    >
                                        {statesDistricts.countries[element]}
                                    </option>
                                ))}
                            </Dropdown>
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <Dropdown
                                label="State"
                                name="state1"
                                value={formikState.values.state1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                disabled={props.isDisabled}
                                isMandatory = {false}
                                style = {props.style}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm" >
                                {Object.keys(statesDistricts[formikState.values.country1].states)
                                    .map(element =>
                                        <option
                                            key={element}
                                            value={statesDistricts[formikState.values.country1].states[element]}
                                        >
                                            {statesDistricts[formikState.values.country1].states[element]}
                                        </option>
                                    )}

                            </Dropdown>
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <Dropdown
                                label="District"
                                name="district1"
                                value={formikState.values.district1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                disabled={props.isDisabled}
                                isMandatory = {false}
                                style = {props.style}
                                cssClass = {props.cssClass}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm " >
                                {Object.keys(statesDistricts[formikState.values.state1].districts).map(element =>
                                    <option
                                        key={element}
                                        value={statesDistricts[formikState.values.state1].districts[element]}
                                    >
                                        {statesDistricts[formikState.values.state1].districts[element]}
                                    </option>
                                )}
                            </Dropdown>
                        </div>
                        <div className="flex pr-6 pl-6 flex-col">
                            <TextField
                                label="Pin-code"
                                id="pincode1"
                                name="postalCode1"
                                type="text"
                                value={formikState.values.postalCode1}
                                onChange={formikState.handleChange}
                                onBlur={formikState.handleBlur}
                                isMandatory = {false}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                                disabled={props.isDisabled}
                                style = {props.style}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#BF1C2B] to-[#810000] px-[4rem] my-8 text-sm border rounded-md text-white h-12"
                        disabled={props.isDisabled}
                    />
                    <Link to="/patient">
                        <Button
                            label="Cancel"
                            className="bg-gray border-graycol py-[0.5rem] px-[4rem] my-8 text-sm  border rounded-md text-col h-12"
                        />
                    </Link>
                </div>
            </div>
        </form>
    );
};

export default Nurse;
