/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
// import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { convertFromFhir, convertToFhir } from '../../fhir-evolver';
import { getClinicalImpressionById, getClinicalImpression } from '../../api/clinicalImpression';
import { addBundle } from '../../api/bundleRequest';
import Loader from '../../components/Loader';
import ProgressNotesForm from '../../forms/ProgressNotes';
// import { generateProgressNotesFormikView } from '../../utils/ProgressNotesFormikView';

const ProgressNotesEdit = (props) => {
    const params = useParams();
    const [viewProgressNotes, setViewProgressNotes] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [resIdMap, setResIdMap] = useState({});
    const [viewPrimary, setViewPrimary] = useState('');
    const navigate = useNavigate();
    console.log('residmap', resIdMap);
    // const location = useLocation();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };

    const [snackbarOpen] = useSnackbar(options);
    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            const resourceMap = {};
            resourceMap.DiagnosticReport = [];
            for (const entry of response.data.entry) {
                switch (entry.resource.resourceType) {
                case 'ClinicalImpression': resourceMap.ClinicalImpression = entry.resource;
                    break;
                case 'DiagnosticReport': resourceMap.DiagnosticReport.push(entry.resource);
                    break;
                case 'ServiceRequest': resourceMap.ServiceRequest = entry.resource;
                    break;
                case 'Condition': resourceMap.Condition = entry.resource;
                    break;
                case 'Patient' : resourceMap.Patient = entry.resource;
                    break;
                case 'Encounter': resourceMap.Encounter = entry.resource;
                    break;
                default: console.log('Unhandled resource Type', entry.resource.resourceType);
                }
            }
            setResIdMap(resourceMap);
            convertFromFhir('Progress-Notes', response).then((res) => {
                res.type = 'edit';
                setViewProgressNotes(res);
            });
        });
        getClinicalImpression('?_sort=-_id&identifier=PrimaryCare&_count=1&_include=*').then((primaryCareResource) => {
            convertFromFhir('PrimaryCare', primaryCareResource).then((resolved) => {
                setViewPrimary(resolved);
            });
        });
    }, []);

    const onEdit = (obj) => {
        console.log('formik', obj);
        convertToFhir('Progress-Notes', obj).then(output => {
            console.log('Progress notesedit evolver', output);
            // return;
            // // eslint-disable-next-line no-unreachable
            // return;
            // eslint-disable-next-line no-unreachable
            addBundle(output)
                .then((response) => {
                    console.log('HTTP Response', response);
                    setSpinner(true);
                    if (!response.data.issue) {
                        snackbarOpen('ProgressNotes updated Successfully');
                        setTimeout(() => {
                            navigate('/patient/admission-list');
                        }, 1000);
                    } else {
                        alert('Error while sending request');
                        console.log('erroredit', response.data.issue);
                    }
                })
                .catch(e => {
                    return e;
                });
        });
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            {viewProgressNotes && <ProgressNotesForm viewProgressNotes = {viewProgressNotes} handleSubmit={onEdit} isDisabled= {!!props.isNurse }isEdit={true} type='edit' resourceIdMap={resIdMap} primary = {viewPrimary} />}
        </>
    );
};

export default ProgressNotesEdit;
