import React, { useState, useEffect } from 'react';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { RiFahrenheitLine } from '@react-icons/all-files/ri/RiFahrenheitLine';
import { RiCelsiusLine } from '@react-icons/all-files/ri/RiCelsiusLine';
import { handleUncontrolledChange } from '../../utils/common';

function Temperature ({ isDisabled, ...props }) {
    const [value, setVal] = useState(props.val);
    const currentDate = new Date();
    useEffect(() => {
        props.formikState.setFieldValue(props.data.id.toString(), value);
    }, [value]);
    const handleAdd = () => {
        const dateFormat = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
        const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes();
        const sampleData = {
            fare: 0,
            cel: -17.8,
            time: currentTime,
            date: dateFormat
        };
        setVal([...value, sampleData]);
    };

    const handleDelete = () => {
        if (value.length > 1) {
            value.pop();
            setVal([...value]);
        }
    };

    const fahrenheitCelsiusMath = (e) => {
        const givenVal = e.target.value;
        const field = e.target.id.split('.');
        if (e.target.name === 'Temperature.cel') {
            const fahrenheit = (givenVal * 1.8) + 32;
            value[field[1]].fare = fahrenheit.toFixed(1);
            value[field[1]].cel = givenVal;
            e.target.parentNode.parentNode.children[0].children[0].value = fahrenheit.toFixed(1);
        } else {
            const celsius = (givenVal - 32) / 1.8;
            value[field[1]].cel = celsius.toFixed(1);
            value[field[1]].fare = givenVal;
            e.target.parentNode.parentNode.children[1].children[0].value = celsius.toFixed(1);
        }
        props.formikState.handleChange(e);
        setVal([...value]);
    };
    return (
        <div>
            { props.isDoctor
                ? <div className='h-[45px] items-center bg-iat flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    {props.data.title}:
                </div>
                : <div className='h-[45px] items-center bg-tgray flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                    {props.data.title}:
                </div>}
            <div className='flex bg-white'>
                <div className='w-full my-[5px]'>
                    {value.map((data, i) => {
                        return (
                            <div className='bg-white px-[16px] py-[10px] flex justify-between' key={i}>
                                <div className="flex flex-row w-1/3 justify-around items-center">
                                    <div className='w-[350px] flex justify-around items-center'>
                                        <div className='w-[45%] justify-end flex items-center'>
                                            <input
                                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                type='number'
                                                id={`${props.data.id}.${i}.fare`}
                                                name={`${props.data.id}.fare`}
                                                onChange={(e) => fahrenheitCelsiusMath(e)}
                                                defaultValue={data.fare}
                                                disabled={isDisabled}
                                            />
                                            <div className='mr-[5px] absolute font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] px-2'><RiFahrenheitLine/></div>
                                        </div>
                                        <div className='w-[45%] justify-end flex items-center'>
                                            <input
                                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                type='number'
                                                id={`${props.data.id}.${i}.cel`}
                                                name={`${props.data.id}.cel`}
                                                onChange={(e) => fahrenheitCelsiusMath(e)}
                                                defaultValue={data.cel}
                                                disabled={isDisabled}
                                            />
                                            <div className='mr-[5px] absolute font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575] px-2'><RiCelsiusLine/></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row w-1/3 justify-around items-center">
                                    <label className='flex flex-col items-center w-[calc(100%-173px)] justify-around font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Time :</label>
                                    <div className='relative flex'>
                                        <input
                                            className='h-[38px] w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3] text-center outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                            id={`${props.data.id}.${i}.time`}
                                            name={`${props.data.id}.${i}.time`}
                                            type='time'
                                            onChange={e => handleUncontrolledChange(value, setVal, e)}
                                            defaultValue={data.time}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-1/3 items-center">
                                    <label className='flex flex-col items-center justify-around w-[calc(90%-173px)] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Date :</label>
                                    <input
                                        className='h-[38px] w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3] text-center outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                        id={`${props.data.id}.${i}.date`}
                                        name={`${props.data.id}.${i}.date`}
                                        type='date'
                                        onChange={e => handleUncontrolledChange(value, setVal, e)}
                                        defaultValue={data.date}
                                        disabled={isDisabled}
                                    />
                                </div>
                                <button type='button' className='items-center flex justify-center text-fc border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1 mr-[0.75rem]' disabled={isDisabled} onClick={() => handleAdd(i)}>
                                    <FaPlus/>
                                </button>
                                <button type='button' className='items-center text-fc justify-center flex border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1' disabled={isDisabled} onClick={() => handleDelete(i)}>
                                    <FaMinus/>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Temperature;
