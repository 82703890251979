/* eslint-disable no-unreachable */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import InitialAssessment from '../InitialAssessment/InitialAssessment';
import { convertFromFhir, convertToFhir } from '../../fhir-evolver';
import { getClinicalImpressionById } from '../../api/clinicalImpression';
import { addBundle } from '../../api/bundleRequest';
import { toInitialAssessmentForm } from '../../fhir-evolver/resources/ClinicalImpression';
import Loader from '../../components/Loader';

const InitialAssessmentEdit = (props) => {
    const params = useParams();
    const [viewAssessment, setViewAssessment] = useState('');
    const [patientDetails, setPatientDetails] = useState('');
    // const [resourceType, setResourceType] = useState([]);
    const [encounter, setEncounter] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [arr, setArr] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const general = (val) => {
        const data = val.url.split('/');
        const value = data[data.length - 1];
        return value;
    };
    const observation = [];
    let ques = '';
    let encount = '';
    const condition = [];
    const diagnostic = [];
    let clinical = '';
    let patient = '';

    const [snackbarOpen] = useSnackbar(options);
    useEffect(() => {
        getClinicalImpressionById(`${params.id}`).then((response) => {
            // const values = response.data.entry.map((index) => ({ resourceType: index.resource.resourceType, id: index.resource.id }));
            // setResourceType(values);
            const clinicalImpression = response.data.entry.find((data) => data.resource.resourceType === 'ClinicalImpression');
            clinical = { resourceType: clinicalImpression.resource.resourceType, id: clinicalImpression.resource.id };
            const Encounter = response.data.entry.find((data) => data.resource.resourceType === 'Encounter');
            encount = { resourceType: Encounter.resource.resourceType, id: Encounter.resource.id };
            const Patient = response.data.entry.find((data) => data.resource.resourceType === 'Patient');
            patient = { resourceType: Patient.resource.resourceType, id: Patient.resource.id };
            const filterData = response.data.entry.filter((data) => data.resource.resourceType === 'Observation');
            for (const data of filterData) {
                if (data.resource.extension.find((val) => (general(val)) === 'AddedParams')) {
                    observation.push({ resourceType: 'VitalParameters', id: data.resource.id });
                } else {
                    observation.push({ resourceType: 'GeneralCondition', id: data.resource.id });
                }
            }
            const questionnaireResponse = response.data.entry.find((data) => data.resource.resourceType === 'QuestionnaireResponse');
            ques = { resourceType: questionnaireResponse.resource.resourceType, id: questionnaireResponse.resource.id };
            const Condition = response.data.entry.filter((data) => data.resource.resourceType === 'Condition');
            const ConLength = { conditionLength: Condition.length };
            for (const data of Condition) {
                condition.push({ resourceType: data.resource.resourceType, id: data.resource.id });
            }
            const DiagnosticReport = response.data.entry.filter((data) => data.resource.resourceType === 'DiagnosticReport');
            const DiagnosticLength = { diagnosticLength: DiagnosticReport.length };
            for (const data of DiagnosticReport) {
                diagnostic.push({ resourceType: data.resource.resourceType, id: data.resource.id });
            }
            setArr({ observation, ques, encount, condition, diagnostic, ConLength, DiagnosticLength, clinical, patient });
            convertFromFhir('ClinicalImpression', response.data.entry).then((res) => {
                setEncounter(res?.[0].encounter);
                setPatientDetails(res?.[0].patient);
                toInitialAssessmentForm(res).then((res) => {
                    setViewAssessment(res);
                }).catch(e => {
                    return e;
                });
            });
        });
    }, []);

    const id = location.state ? location.state.id : params?.id;

    const onEdit = (obj) => {
        console.log('editinput', obj);
        // return obj;
        convertToFhir('ClinicalImpression', obj).then(res => {
            console.log('initialedit', res);
            res.id = id;
            addBundle(res)
                .then((output) => {
                    console.log('initialeditapi', output);
                    setSpinner(true);
                    snackbarOpen('InitialAssessment updated Successfully');
                    setTimeout(() => {
                        navigate('/patient/admission-list');
                    }, 1000);
                })
                .catch(e => {
                    return e;
                });
        });
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            { viewAssessment && <InitialAssessment encounter= { encounter } viewData= { viewAssessment } patient = {patientDetails} handleSubmit={onEdit} isDisabled={false} isEdit={true} type='edit' data={arr} isNurse = {props.isNurse}/>}
        </>
    );
};

export default InitialAssessmentEdit;
