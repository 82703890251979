import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import PatientRegistration from '../../forms/Patient';
import Arrow1 from '../../assets/images/arrow1.svg';
import { convertFromFhir } from '../../fhir-evolver/index';
import { getPatientId } from '../../api/patient';
import Loader from '../../components/Loader';

const ViewPatient = (props) => {
    const [patient, setPatient] = useState('');
    const [spinner, setSpinner] = useState(false);

    const params = useParams();
    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            display: 'none'
        }),
        indicatorSeparator: base => ({
            ...base,
            display: 'none'
        }),
        control: base => ({
            ...base,
            background: 'white',
            boxShadow: 'none',
            border: 'none'
        })
    };

    useEffect(() => {
        setSpinner(true);
        getPatientId(`${params.id}`).then((response) => {
            convertFromFhir('Patient', response.data).then((res) => {
                setPatient(res);
                setSpinner(false);
            }).catch(e => {
                return e;
            });
        });
    }, []);
    return (
        <div>
            <Loader showSpinner={spinner} />
            <div className="bg-gray h-auto w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center">
                        <Link to="/home">
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base px-2 py-2 font-roboto">View Patient</span>
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                        View Patient Details
                    </span>
                </div>
                <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                    <PatientRegistration
                        viewData={location.state ? location.state : patient}
                        isDisabled={true}
                        style={{
                            backgroundColor: 'white',
                            appearance: 'none'
                        }}
                        display='none'
                        buttonName="Back"
                        isView={true}
                        customStyles={customStyles}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewPatient;
