import axios from '../utils/axios';

export const addEncounter = (body) => {
    return axios.post('/prod/fhir/Encounter', JSON.stringify(body)).then((response) => {
        return 'Encounter created successfully';
    });
};

export const getEncounter = (params) => {
    let url = '/prod/fhir/Encounter';
    if (params) {
        url += `?${params}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};

export const editEncounter = (id, body) => {
    return axios.put(`/prod/fhir/Encounter/${id}`, body).then((response) => {
        return response;
    });
};

export const deleteEncounter = (id) => {
    return axios.delete(`/prod/fhir/Encounter/${id}`).then((response) => {
        return response;
    });
};

export const getEncounterId = (id) => {
    let url = '/prod/fhir/Encounter';
    if (id) {
        url += `/${id}`;
    }
    return axios.get(url).then((response) => {
        return response;
    });
};
