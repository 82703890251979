/* eslint-disable no-console */
import React, { useState } from 'react';
import View from '../../assets/images/view.svg';
import { FaPlusSquare } from 'react-icons/fa';
// import Edit from '../../assets/images/edit.svg';
// import Delete from '../../assets/images/delete.svg';
// import ProgressTable from '../../components/ProgressTable';
import InvestigationPopup from './InvestigationPopup';
import Modal from '../../components/Modal';
// import Edit from '../../assets/images/edit.svg';
import Delete from '../../assets/images/delete.svg';

const Investigations = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [medicationPrefillValue, setMedicationPrefillValue] = useState({ state: 0 });
    const [val, setVal] = props.formikState.values.Investigation ? useState(props.formikState.values.Investigation) : useState([]);
    const [refreshFlag, setRefreshFlag] = useState();

    const getInvestigationData = (data) => {
        setVal(val => [...val, data]);
        const finalData = val;
        finalData.push(data);
        props.updateData(finalData);
        setShowModal(false);
    };
    const handleCloseModal = () => {
        if (showModal) {
            setShowModal(false);
        }
    };
    const addSubrow = (i) => {
        const TempList = val;
        if (TempList[i].subRow !== undefined && TempList[i].subRow.length > 0) {
            TempList[i].subRow.push({
                doctor: '',
                date: '',
                reportStatus: 'registered',
                instruction: '',
                editable: true
            });
        } else {
            TempList[i].subRow = [{
                instruction: '',
                doctor: '',
                date: '',
                reportStatus: 'registered',
                editable: true
            }];
        }
        setVal(TempList);
        setRefreshFlag(!refreshFlag);
    };

    const delSubrow = (i, j) => {
        const TempList = val;
        if (TempList[i].subRow !== undefined && TempList[i].subRow.length > 0) {
            TempList[i].subRow.splice(j, 1);
        } else {
            TempList[i].subRow.splice(j, 1);
        }
        setVal(TempList);
        setRefreshFlag(!refreshFlag);
    };

    const HandleInputValues = (name, value, i, j) => {
        val[i].subRow[j][name] = value;
        setVal([...val]);
    };
    return (
        <><div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            <div className='flex'>Ix (Investigations)</div>
        </div><div className='flex justify-end px-6'><button type='button' className='bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[4rem] my-4 text-[16px] border text-white font-[500] h-12'onClick={() => { setShowModal(true); setMedicationPrefillValue({}); setMedicationPrefillValue({ ...medicationPrefillValue, state: 0 }); }} disabled={props.isDisabled}>+ Add Investigation</button></div>
        {showModal && <Modal
            heading="Investigation"
            handleModalClose={handleCloseModal}
            body={<InvestigationPopup
                handleModalClose={handleCloseModal}
                formikState={props.formikState}
                prefillData={medicationPrefillValue}
                quantityVal={1}
                sendValue={(data) => getInvestigationData(data)}
            />}
        />}
        {/* <ProgressTable data={data} /> */}
        <div className='bg-white px-3 py-[20px]'>
            <table className='w-full border-[#B9B9B9] border-[1px]'>
                <thead>
                    <tr>
                        <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>S.No</th>
                        <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Investigations</th>
                        <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>By doctor</th>
                        <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Date & Time</th>
                        {/* <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Time </th> */}
                        <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Report Status</th>
                        <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        val.length > 0
                            ? val.map((data, i) => (
                                <>
                                    <tr key={i}>
                                        {console.log('investidation', data)}
                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' rowSpan={data.subRow ? data.subRow.length + 1 : null}>{ i + 1}</td>
                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.investigation}>{data?.investigation}</td>
                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.doctor}>{data?.doctor}</td>
                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={
                                            new Date(data.date).toLocaleString('en-GB', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                                hourCycle: 'h12'

                                            })
                                        }>{new Date(data.date).toLocaleString('en-GB', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                                hourCycle: 'h12'

                                            })
                                            }</td>
                                        {/* <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.time}>{data.time}</td> */}
                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.reportStatus}>{data.reportStatus}</td>
                                        <td className='border-[#B9B9B9] w-[100px] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                            <div className='flex flex-row'>
                                                <button
                                                    type="button"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="view"
                                                    className="px-1"
                                                >
                                                    <img className="h-3 w-4" src={View} alt="view" />
                                                </button>
                                                <button className="px-1" type='button' onClick={() => addSubrow(i)}>
                                                    <FaPlusSquare/>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        data.subRow
                                            ? data.subRow.map((items, j) => (
                                                <>
                                                    <tr>
                                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' ><input id="instruction"
                                                            name="instruction"
                                                            type="text"
                                                            placeholder='Enter Notes...'
                                                            onChange={(event) =>
                                                                HandleInputValues(event.target.name, event.target.value, i, j)
                                                            }
                                                            value={items.instruction}
                                                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'><input id="doctor"
                                                            name="doctor"
                                                            type="text"
                                                            placeholder='Enter Doctor'
                                                            onChange={(event) =>
                                                                HandleInputValues(event.target.name, event.target.value, i, j)
                                                            }
                                                            value={items.doctor}
                                                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' ><input id="date"
                                                            name="date"
                                                            type="datetime-local"
                                                            onChange={(event) => {
                                                                const d = new Date(event.target.value).toLocaleString('en-GB', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    hour12: true,
                                                                    hourCycle: 'h12'

                                                                });
                                                                // eslint-disable-next-line no-console
                                                                console.log(d);
                                                                HandleInputValues(event.target.name, event.target.value, i, j);
                                                            }}
                                                            value={items.date}
                                                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col text-center"/></td>
                                                        {/* <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' ><input id="time"
                                                            name="time"
                                                            type="time"
                                                            onChange={(event) =>
                                                                HandleInputValues(event.target.name, event.target.value, i, j)
                                                            }
                                                            value={items.time}
                                                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full text-sm text-col text-center"/></td> */}
                                                        <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' ></td>
                                                        <td className='border-[#B9B9B9] w-[100px] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>
                                                            <div className='flex flex-row'>
                                                                {/* <button
                                                                    type="button"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="edit"
                                                                    className="px-1"
                                                                >
                                                                    <img className="h-3 w-4" src={Edit} alt="Edit" />
                                                                </button> */}
                                                                <button className="px-1" type='button' onClick={() => delSubrow(i, j)}>
                                                                    <img className="h-3 w-4" src={Delete} alt="Delete" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            ))
                                            : <></>

                                    }
                                </>
                            ))
                            : <tr><td></td><td></td><td className=' text-center  font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>No Data Available</td></tr>
                    }
                </tbody>
            </table>
        </div>
        </>
    );
};

export default Investigations;
