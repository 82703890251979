import React from 'react';
import dept from '../.././../utils/json/Department.json';

function Referral (props) {
    return (
        <div>
            <div className='h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            Referral
            </div>
            {/* <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center justify-between'>
                    <div className='w-[160px] py-[15px] self-start font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Referral :</div>
                    <div className='w-[calc(100%-160px)] justify-between flex'>
                        <div className='flex w-full justify-between'>
                            <select required
                                className='w-[49%] text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="Referral.DrName"
                                id="Referral.DrName"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Referral.DrName}
                            >
                                <option value="Referral Dr.Name" defaultValue={'Referral Dr.Name'}>Referral Dr.Name</option>
                            </select>
                            <select required
                                className='w-[49%] text-[#8F8F8F] pl-[10px] border-[1px] mb-[15px] outline-none border-[#E3E3E3] text-input bg-[#F3F3F3] h-[47px] font-[400] text-[13px] leading-[15px]'
                                name="Referral.Dept"
                                id="Referral.Dept"
                                onChange={props.formikState.handleChange}
                                value={props.formikState.values.Referral.Dept}
                            >
                                {
                                    dept.map((data, i) => (
                                        <option value={dept[i].value} key={i}>{dept[i].label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='flex items-center'>
                    <div className='w-[160px]'></div>
                    <div className='w-[calc(100%-160px)] justify-around flex'>
                        <textarea required
                            className='h-[100px] pl-[10px] text-[#8F8F8F] pt-[10px] w-[100%] text-[15px] self-center border-[1px] border-[#E3E3E3] text-input bg-gray outline-none'
                            id='Referral.data'
                            name='Referral.data'
                            type='text'
                            placeholder='Write a reason'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Referral.data}
                        />
                    </div>
                </div>
            </div> */}
            <div className= {props.isDischarge ? 'flex flex-row flex-wrap  gap-y-3 gap-x-[2%] pt-4 bg-white pb-4' : 'flex flex-row flex-wrap  gap-y-3 gap-x-[2%] pt-4'}>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Referred From :</label>
                        </div>
                        <input
                            id="Referral.ReferredFrom"
                            name="Referral.ReferredFrom"
                            type="text"
                            onChange={props.formikState.handleChange}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            value={props.formikState.values.Referral.ReferredFrom}
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Referred To :</label>
                        </div>
                        <input
                            id="Referral.ReferredTo"
                            name="Referral.ReferredTo"
                            type="text"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Referral.ReferredTo}
                            disabled={props.isDisabled}
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                        />
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Referred to Department :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="Referral.Department"
                            id="Referral.Department"
                            disabled={props.isDisabled}
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Referral.Department}
                        >
                            {
                                dept.map((data, i) => (
                                    <option value={dept[i].value} key={i}>{dept[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Referral Reason :</label>
                        </div>
                        <input
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            id='Referral.Data'
                            name='Referral.Data'
                            type='text'
                            placeholder='Write a reason'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Referral.Data}
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full  pl-6 pr-8">
                    <div className="grid grid-cols-c3 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Special Instruction (If Any) :</label>
                        </div>
                        <input
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            id='Referral.Instruction'
                            name='Referral.Instruction'
                            type='text'
                            placeholder='Write here'
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.Referral.Instruction}
                            disabled={props.isDisabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Referral;
