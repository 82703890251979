export const toFhir = (data) => {
    const result = {
        resourceType: 'RiskAssessment',
        identifier: [
            {
                system: 'Secondary Care',
                value: data.identifier
            }
        ],
        subject: {
            reference: `Patient/${data.Patient}`
        },
        encounter: {
            reference: `Encounter/${data.Encounter}`
        },
        prediction: {
            outcome: {
                text: data.assessment || ''
            },
            rationale: JSON.stringify(data.criteria)
        }
    };
    return Promise.resolve(result);
};

export const fromFhir = (data) => {
    const identifier = data.resource.identifier[0].value || 'None';
    const result = {
        [identifier]: JSON.parse(data.resource.prediction[0].rationale)
    };
    return Promise.resolve(result);
};
