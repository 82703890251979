/* eslint-disable no-console */
import React from 'react';

function Medications (props) {
    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Medication List<span className='text-[crimson] text-[20px]'><pre>*</pre></span></div>
            </div>
            <div className='bg-white px-3 py-[20px]'>
                <table className='w-full border-[#B9B9B9] border-[1px]'>
                    <thead>
                        <tr>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Tablet Name</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Regime</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Dosage</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Type</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Start Date</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>End Date</th>
                            <th className='border-[#B9B9B9] border-[1px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#000000] h-[40px]'>Administered</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.primary?.Medication
                                ? props.primary.Medication.map((data, i) => (
                                    <>
                                        {/* {console.log('medicationdata', data)} */}
                                        <tr key={i++}>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Medication}>{data.Medication}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Regimen}>{data.Regimen}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Dosage}>{data.Dosage + ' ' + data.Unit}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Route}>{data.Route}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.StartDate}>{new Date(data.StartDate).toString().substring(0, 15)}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.End}>{data.End === '' ? '-' : new Date(data.End).toString().substring(0, 15)}</td>
                                            <td className='border-[#B9B9B9] text-center border-[1px] font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]' value={data.Status}>{data.Status}</td>
                                        </tr>
                                    </>
                                ))
                                : <tr><td></td><td></td><td></td><td className=' text-end  font-"roboto" font-[400] text-[13px] leading-[15px] text-[##5C5C5C] h-[40px]'>No Data Available</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Medications;
