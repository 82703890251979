import React from 'react';
// import { getClinicalImpression, getClinicalImpressionById } from '../../api/clinicalImpression';
// import { getClinicalImpression } from '../../api/clinicalImpression';
// import { convertFromFhir } from '../../fhir-evolver';
const VitalSigns = ({ assessment, primary }) => {
    // eslint-disable-next-line no-console
    // console.log('vitalsigns', primary?.HeartRate?.[0].data);
    // let primaryCare = {};
    // getClinicalImpression('?_sort=-_id&identifier=PrimaryCare&_count=1&_include=*').then((primaryCareResource) => {
    //     convertFromFhir('PrimaryCare', primaryCareResource).then((resolved) => {
    //         // eslint-disable-next-line no-console
    //         console.log('resolved', resolved);
    //         primaryCare = resolved;
    //     });
    // });
    return (
        <div>
            <div className='h-[45px] items-center bg-iat justify-between flex w-full p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                <div className='flex'>Vital Signs</div>
            </div>
            <div className="flex flex-col flex-wrap w-full mt-2">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Weight (kg) :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {assessment?.NutritionalStatus?.Parameter.Weight}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Height (cm) :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {assessment?.NutritionalStatus?.Parameter.HeightCentimeter}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">BMI (kg/m2) :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {assessment?.NutritionalStatus?.Parameter.BMI
                                }
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Heart Rate :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.HeartRate?.map((data) => data.data)}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Systolic Blood Pressure :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.BP?.map((data) => data?.SystolicBP?.map((val) => val.data))}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Diastolic Blood Pressure :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.BP?.map((data) => data?.DiastolicBP?.map((val) => val.data))}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Temperature :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.Temperature?.map((data) => data.fare)}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Respiratory Rate :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                { primary?.RespiratoryRate }
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Urine Output :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.IntakeOutput?.map((value) => value.urineOuput)}
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">IV Access :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.IVaccess?.map((value) => value.data)}
                            </span>
                        </div>
                    </div>
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Peripheral Pulses :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Spo2 :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.Spo2?.map((value) => value.data)}
                            </span>
                        </div>
                    </div>
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Blood Sugar (F) :</label>
                            </div>
                            <span className="text-sm text-col text-center">
52
                            </span>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Blood Sugar (R) :</label>
                            </div>
                            <span className="text-sm text-col text-center">
60
                            </span>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">AVPU :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Trauma :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Mobility :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Oxygen Supplementation :</label>
                            </div>
                            <span className="text-sm text-col text-center">
moderate
                            </span>
                        </div>
                    </div> */}

                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Intake :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Output :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Insulin :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                {primary?.Insulin?.map((value) => value.data)}
                            </span>
                        </div>
                    </div>
                    {/* <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Vital Taken  Time :</label>
                            </div>
                            <span className="text-sm text-col text-center">

                            </span>
                        </div>
                    </div> */}
                    <div className="flex flex-col w-[32%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Remarks :</label>
                            </div>
                            <span className="text-sm text-col text-center">
                                { primary?.remarks?.remarks }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VitalSigns;
