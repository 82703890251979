import React from 'react';

const RadioButton = ({ label, value, onChange, name, id, onBlur, checked, disabled }) => {
    return (
        <label className='flex gap-x-2'>
            <input type="radio" value={value} name={name} id={id} onChange={onChange} onBlur={onBlur} checked={checked} disabled={disabled}/>
            <p className='text-xs'>{label}</p>
        </label>
    );
};

export default RadioButton;
