import React from 'react';
import Title from '../Title';
import Tone from '../../../utils/json/Tone.json';
import Power from '../../../utils/json/Power.json';
import Reflexes from '../../../utils/json/Reflexes.json';
import Plantar from '../../../utils/json/Plantar.json';
import Gait from '../../../utils/json/Gait.json';
import Disorder from '../../../utils/json/Disorder.json';

const CNS = (props) => {
    return (
        <div>
            <Title title={'CNS'} isNurse = {props.isNurse} />
            <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%] py-4">
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Right Tone : </label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.RtTone"
                            id="CNS.RtTone"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.RtTone}
                        >
                            {
                                Tone.map((data, i) => (
                                    <option value={Tone[i].value} key={i}>{Tone[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Left Tone :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.LtTone"
                            id="CNS.LtTone"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.LtTone}
                        >
                            {
                                Tone.map((data, i) => (
                                    <option value={Tone[i].value} key={i}>{Tone[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Right Power :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.RtPower"
                            id="CNS.RtPower"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.RtPower}
                        >
                            {
                                Power.map((data, i) => (
                                    <option value={Power[i].value} key={i}>{Power[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Left Power :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.LtPower"
                            id="CNS.LtPower"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.LtPower}
                        >
                            {
                                Power.map((data, i) => (
                                    <option value={Power[i].value} key={i}>{Power[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Right Reflexes :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.RtReflexes"
                            id="CNS.RtReflexes"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.RtReflexes}
                        >
                            {
                                Reflexes.map((data, i) => (
                                    <option value={Reflexes[i].value} key={i}>{Reflexes[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Left Reflexes :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.LtReflexes"
                            id="CNS.LtReflexes"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.LtReflexes}
                        >
                            {
                                Reflexes.map((data, i) => (
                                    <option value={Reflexes[i].value} key={i}>{Reflexes[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Right Plantar :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.RtPlantar"
                            id="CNS.RtPlantar"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.RtPlantar}
                        >
                            {
                                Plantar.map((data, i) => (
                                    <option value={Plantar[i].value} key={i}>{Plantar[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Left Plantar :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.LtPlantar"
                            id="CNS.LtPlantar"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.LtPlantar}
                        >
                            {
                                Plantar.map((data, i) => (
                                    <option value={Plantar[i].value} key={i}>{Plantar[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Right Gait :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.RtGait"
                            id="CNS.RtGait"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.RtGait}
                        >
                            {
                                Gait.map((data, i) => (
                                    <option value={Gait[i].value} key={i}>{Gait[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Left Gait :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.LtGait"
                            id="CNS.LtGait"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.LtGait}
                        >
                            {
                                Gait.map((data, i) => (
                                    <option value={Gait[i].value} key={i}>{Gait[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Right Movement Disorder :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.RtMovementDisorder"
                            id="CNS.RtMovementDisorder"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.RtMovementDisorder}
                        >
                            {
                                Disorder.map((data, i) => (
                                    <option value={Disorder[i].value} key={i}>{Disorder[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex flex-col w-[49%]  px-6">
                    <div className="grid grid-cols-c4 gap-[1%] items-center">
                        <div className="flex flex-row">
                            <label className="text-col font-roboto text-sm">Left Movement Disorder :</label>
                        </div>
                        <select
                            className=" bg-gray box-border border-[#E3E3E3]  border-[1px] focus:outline-none h-10 w-full px-1 text-sm text-col"
                            name="CNS.LtMovementDisorder"
                            id="CNS.LtMovementDisorder"
                            onChange={props.formikState.handleChange}
                            value={props.formikState.values.CNS.LtMovementDisorder}
                        >
                            {
                                Disorder.map((data, i) => (
                                    <option value={Disorder[i].value} key={i}>{Disorder[i].label}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CNS;
