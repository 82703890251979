import { Route } from 'react-router-dom';

import PatientDetailUpdate from '../containers/Nurse/PatientDetailUpdate';
import PatientDetailView from '../containers/Nurse/PatientDetailView';
import PatientDetail from '../containers/Nurse/PatientDetail';
import PatientList from '../containers/Nurse/PatientList';
import Nurse from '../containers/Nurse/Nurse';
import React from 'react';
import PrivateRoute from '../hooks/PrivateRoute';
import { ROLE } from '../utils/roles';
import NurseFunctions from '../containers/NurseFunctions';
// import Station from '../containers/NurseFunctions/Station';
// import PrimaryCare from '../containers/NurseFunctions/PrimaryCare';
import NurseAssessment from '../containers/NurseFunctions/NurseAssessment';
import NurseAssessmentView from '../containers/NurseFunctions/NurseAssessmentView';
import NurseAssessmentEdit from '../containers/NurseFunctions/NurseAssessmentEdit';
import StationView from '../containers/NurseFunctions/StationView';
import PrimaryCareView from '../containers/NurseFunctions/PrimaryCareView';
import PrimaryCareEdit from '../containers/NurseFunctions/PrimaryCareEdit';
import PrimaryCareAdd from '../containers/NurseFunctions/PrimaryCareAdd';
import StationEdit from '../containers/NurseFunctions/StationEdit';
import StationAdd from '../containers/NurseFunctions/StationAdd';
import ProgressNotesNurseView from '../containers/NurseFunctions/ProgressNotesNurseView';
import ProgressNotesNurseEdit from '../containers/NurseFunctions/ProgressNotesNurseEdit';

const NurseRoutes = (
    <Route element={<PrivateRoute roles={[ROLE.nurse.name]} />} >
        <Route path="nurse" element={<Nurse />} />
        <Route path="nurse/patient" element={<PatientList />} />
        <Route path="nurse/patient-detail/:id" element={<PatientDetail />} />
        <Route path="nurse/patient-detail/update/:id" element={<PatientDetailUpdate />} />
        <Route path="nurse/patient-detail/view/:id" element={<PatientDetailView />} />
        <Route path="nurse/assessment/:id" element={<NurseAssessment />} />
        <Route path="nurse/assessment/view/:id" element={<NurseAssessmentView />} />
        <Route path="nurse/assessment/edit/:id" element={<NurseAssessmentEdit />} />
        <Route path="nurse/progress/view/:id" element={<ProgressNotesNurseView />} />
        <Route path="nurse/progress/edit/:id" element={<ProgressNotesNurseEdit />} />
        <Route path="functions" element={<NurseFunctions text='SecondaryCare'/>} >
            <Route path="primary-care/:id" element={<PrimaryCareAdd />} />
            <Route path="primary-care/view/:id" element={<PrimaryCareView />} />
            <Route path="primary-care/edit/:id" element={<PrimaryCareEdit />} />
            <Route path="station" element={<StationAdd />} />
            <Route path="station/view/:id" element={<StationView />} />
            <Route path="station/edit/:id" element={<StationEdit />} />
        </Route>
    </Route>
);

export default NurseRoutes;
