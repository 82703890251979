import React from 'react';

import Checkbox from '../Check';
import RadioButton from '../../containers/NurseFunctions/RadioNurse';

const RestraintAssessment = ({ formikState, onBlur, onChange, ...props }) => {
    const currentDate = new Date();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes();
    // useEffect(() => {
    //     formikState.setFieldValue('restraintAssessment', []);
    // });
    const addNewField = () => {
        formikState.setFieldValue('restraintAssessment', [
            ...formikState.values.restraintAssessment,
            {
                skinintact: {},
                pulsepresent: '',
                foodoffered: '',
                positionchange: '',
                hygiene: '',
                privacy: '',
                restraintreleased: '',
                nurseId: '',
                restraintremark: ''
            }
        ]);
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Restrain Assessment</span>
            </div>

            <div className='flex flex-row flex-wrap  justify-between pl-6'>
                <span className='text-sm text-col'>Time: {currentTime}</span>
                <button className={props.isDoctor ? 'border border-iat w-32 h-10 bg-iat text-white' : 'border border-[#991622] w-32 h-10 bg-[#991622] text-white'} type='button' onClick={addNewField}>Add</button>;
            </div>
            {formikState.values.restraintAssessment.map((restraintAssessment, index) => (
                <div key={index}>
                    <div className='flex flex-col flex-wrap w-full px-6'>
                        <table className="table-auto border border-slate-300 w-[60%]">
                            <thead>
                                <tr>
                                    <th className='border border-slate-300 text-left text-sm p-2'>Patient Assessment and Needs</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm p-2 border border-slate-300 text-col'>Skin intact under restraint</td>
                                    <td className='text-sm text-col p-2'> <Checkbox
                                        label="Healthy"
                                        // id="restrainAssessment.healthy"
                                        // name="restrainAssessment.skinintact"
                                        id="healthy"
                                        name={`restraintAssessment[${index}].skinintact.healthy`}
                                        onChange={onChange}
                                        value='healthy'
                                        checked={formikState.values.restraintAssessment[index].skinintact.healthy?.[0] === 'healthy' ? true : null}
                                        disabled={props.isDisabled}
                                        onBlur={onBlur} /></td>
                                    <td className='text-sm text-col  p-2'> <Checkbox
                                        label="Redness"
                                        id="redness"
                                        name={`restraintAssessment[${index}].skinintact.redness`}
                                        onChange={onChange}
                                        value= 'redness'
                                        checked={formikState.values.restraintAssessment[index].skinintact.redness?.[0] === 'redness' ? true : null}
                                        disabled={props.isDisabled}
                                        onBlur={onBlur} /></td>
                                    <td className='text-sm text-col p-2'> <Checkbox
                                        label="Marks"
                                        id="marks"
                                        name={`restraintAssessment[${index}].skinintact.marks`}
                                        onChange={onChange}
                                        value= 'marks'
                                        checked={formikState.values.restraintAssessment[index].skinintact.marks?.[0] === 'marks' ? true : null}
                                        disabled={props.isDisabled}
                                        onBlur={onBlur} /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Pulse Present</td>
                                    <td className=' '> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="restrainAssessment.pulsepresent"
                                        name={`restraintAssessment[${index}].pulsepresent`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].pulsepresent === 'yes'} /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`restraintAssessment[${index}].pulsepresent`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].pulsepresent === 'no'} /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`restraintAssessment[${index}].pulsepresent`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].pulsepresent === 'refused'} /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Fluid/Food Offered</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="restraintAssessment.foodoffered"
                                        name={`restraintAssessment[${index}].foodoffered`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].foodoffered === 'yes'} /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`restraintAssessment[${index}].foodoffered`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].foodoffered === 'no'} /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`restraintAssessment[${index}].foodoffered`}
                                        onChange={onChange}
                                        value='refused'
                                        disabled={props.isDisabled}
                                        onBlur={onBlur}
                                        checked={formikState.values.restraintAssessment[index].foodoffered === 'refused'} /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Position change/Range of motion exercise offered</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="restrainAssessment.positionchange"
                                        name={`restraintAssessment[${index}].positionchange`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].positionchange === 'yes'} /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`restraintAssessment[${index}].positionchange`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].positionchange === 'no'} /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`restraintAssessment[${index}].positionchange`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].positionchange === 'refused'} /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Hygiene</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Maintained"
                                        id="maintained"
                                        // name="restraintAssessment.hygiene"
                                        name={`restraintAssessment[${index}].hygiene`}
                                        onChange={onChange}
                                        value='maintained'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].hygiene === 'maintained'} /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="Not Maintained"
                                        id="not maintained"
                                        name={`restraintAssessment[${index}].hygiene`}
                                        onChange={onChange}
                                        value='not maintained'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].hygiene === 'not maintained'} /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Dirty"
                                        id="dirty"
                                        name={`restraintAssessment[${index}].hygiene`}
                                        onChange={onChange}
                                        value='dirty'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].hygiene === 'dirty'} /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Provided Privacy</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="restraintAssessment.privacy"
                                        name={`restraintAssessment[${index}].privacy`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].privacy === 'yes'} /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`restraintAssessment[${index}].privacy`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].privacy === 'no'} /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`restraintAssessment[${index}].privacy`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].privacy === 'refused'} /></td>
                                </tr>
                                <tr className='border border-slate-300'>
                                    <td className='text-sm border border-slate-300 p-2 text-col'>Restraint Released</td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Yes"
                                        id="yes"
                                        // name="restraintAssessment.restraintreleased"
                                        name={`restraintAssessment[${index}].restraintreleased`}
                                        onChange={onChange}
                                        value='yes'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].restraintreleased === 'yes'} /></td>
                                    <td className='text-sm text-col  p-2'> <RadioButton
                                        label="No"
                                        id="no"
                                        name={`restraintAssessment[${index}].restraintreleased`}
                                        onChange={onChange}
                                        value='no'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].restraintreleased === 'no'} /></td>
                                    <td className='text-sm text-col p-2'> <RadioButton
                                        label="Refused"
                                        id="refused"
                                        name={`restraintAssessment[${index}].restraintreleased`}
                                        onChange={onChange}
                                        value='refused'
                                        onBlur={onBlur}
                                        disabled={props.isDisabled}
                                        checked={formikState.values.restraintAssessment[index].restraintreleased === 'refused'} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-col w-full px-6 py-4">
                        <div className="grid grid-cols-c3 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Nurse Initial and ID :</label>
                            </div>
                            <input
                                id={`restraintAssessment[${index}].nurseId`}
                                name={`restraintAssessment[${index}].nurseId`}
                                type="text"
                                onChange={onChange}
                                value={formikState.values.restraintAssessment[index].nurseId}
                                disabled={props.isDisabled}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col" />
                        </div>
                    </div>
                    <div className="flex flex-col w-full  px-6">
                        <div className="grid grid-cols-c3 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Remarks if any :</label>
                            </div>
                            <input
                                id={`restraintAssessment[${index}].restraintremark`}
                                name={`restraintAssessment[${index}].restraintremark`}
                                type="text"
                                onChange={onChange}
                                value={formikState.values.restraintAssessment[index].restraintremark}
                                disabled={props.isDisabled}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col" />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RestraintAssessment;
