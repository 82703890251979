/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import TwoField from '../../components/TwoField';
import { getDoctor } from '../../api/doctor';
import { getLocation } from '../../api/location';
import { convertFromFhirList } from '../../fhir-evolver';
import Select from 'react-select';
import Category from '../../utils/json/Category.json';
import Datepicker from '../../components/Datepicker';

function RegistrationDetails (props) {
    const [consultantOption, setConsultantOption] = useState([]);
    const [floor, setFloor] = useState('');
    const [ward, setWard] = useState('');
    const [room, setRoom] = useState('');
    const [bed, setBed] = useState('');

    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3',
            color: '#F2F2F2'
        })
    };

    const dropdownJsonFormatHandler = (response) => {
        const intialValue = { label: 'Select', value: '' };
        const list = [];
        if (response.data.entry) {
            for (const item of response.data.entry) {
                list.push({ label: item.resource.name, value: item.resource.id });
            }
            list.unshift(intialValue);
            return list;
        }
    };

    useEffect(() => {
        const intialValue = { label: 'Select', value: '' };
        const allPhysicianlist = [];
        getDoctor().then((response) => convertFromFhirList('PractitionerRole', response.data.entry))
            .then((response) => {
                for (const item of response) {
                    allPhysicianlist.push({ label: `${item.givenName} ${item.family}`, value: item.id });
                }
                allPhysicianlist.unshift(intialValue);
                setConsultantOption(allPhysicianlist);
            });

        getLocation('152').then((response) => {
            const data = dropdownJsonFormatHandler(response);
            setFloor(data);
        });

        if (props.location) {
            // eslint-disable-next-line no-console
            console.log('locatrion', props.location);
            getLocation(props.location?.[1].id).then((response) => {
                const data = dropdownJsonFormatHandler(response);
                console.log('datasadas', data);
                setWard(data);
            });

            getLocation(props.location?.[2].id).then((response) => {
                const data = dropdownJsonFormatHandler(response);
                setRoom(data);
            });
            getLocation(props.location?.[3].id).then((response) => {
                const data = dropdownJsonFormatHandler(response);
                setBed(data);
            });
        }
    }, [props]);

    const wardHandler = (e) => {
        const id = e.value;
        props.formikState.values.RegistrationDetails.Floor = id;
        props.formikState.values.RegistrationDetails.Ward = '';
        if (id) {
            getLocation(id).then((response) => {
                const wardData = dropdownJsonFormatHandler(response);
                setWard(wardData);
            });
        }
        setRoom();
        setBed();
    };

    const roomHandler = (e) => {
        const id = e.value;
        props.formikState.values.RegistrationDetails.Ward = id;
        if (id) {
            getLocation(id).then((response) => {
                const roomData = dropdownJsonFormatHandler(response);
                setRoom(roomData);
            });
        }
        setBed();
    };

    const bedHandler = (e) => {
        const id = e.value;
        props.formikState.values.RegistrationDetails.Room = id;
        if (id) {
            getLocation(id).then((response) => {
                const bedData = dropdownJsonFormatHandler(response);
                setBed(bedData);
            });
        }
    };

    return (
        <div>
            <div className='h-[45px] bg-iat flex w-full items-center px-[1%] py-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
                Registration Details
                {/* <span className='text-[crimson] text-[20px]'><pre>*</pre></span> */}
            </div>
            <div className='bg-white py-2.5'>
                <TwoField field1='UHID' field2='IP NO'name1='RegistrationDetails.UHID' name2='RegistrationDetails.IPNo' val1={props.formikState.values.RegistrationDetails.UHID} val2={props.formikState.values.RegistrationDetails.IPNo} onChange={props.formikState.handleChange} onBlur={props.formikState.handleBlur}/>

                {/* <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[100%] flex justify-between'>
                        <div className='w-[10%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            Treating/ Admission Department :
                        </div>
                        <div className='w-[88.5%]'>
                            <input
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                                type='text'
                                placeholder='Treatment'
                            />
                        </div>
                    </div>
                </div>

                <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[100%] flex justify-between'>
                        <div className='w-[10%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            Ward name/number :
                        </div>
                        <div className='w-[88.5%]'>
                            <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select>
                        </div>
                    </div>
                </div> */}
                <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[50%] flex justify-between pr-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                        Treating/ Admission Department :
                        </div>
                        <div className='w-[76%]'>
                            <input
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                                type='text'
                                placeholder='Treatment'
                                name= 'RegistrationDetails.Department'
                                value= {props.formikState.values.RegistrationDetails.Department}
                                onChange={props.formikState.handleChange}
                                onBlur={props.formikState.handleBlur}
                            />
                        </div>
                    </div>
                    <div className='w-[50%] flex justify-between pl-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            Treating Doctors/ Consultants :
                        </div>
                        <div className='w-[76%]'>
                            {/* <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select> */}
                            <Select
                                name="RegistrationDetails.Doctor"
                                isSearchable={true}
                                onChange={(value) => {
                                    props.formikState.setFieldValue('RegistrationDetails.Doctor', value.value);
                                }}
                                value={
                                    consultantOption
                                        ? consultantOption.find((option) => option.value === props.formikState.values.RegistrationDetails.Doctor)
                                        : ''}
                                options={consultantOption}
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[50%] flex justify-between pr-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                           Floor
                        </div>
                        <div className='w-[76%]'>
                            {/* <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select> */}
                            <Select
                                name="RegistrationDetails.Floor"
                                isSearchable={true}
                                onChange={(e) => wardHandler(e)}
                                value={
                                    floor
                                        ? floor.find((option) => option.value === props.formikState.values.RegistrationDetails.Floor)
                                        : ''}
                                options={floor}
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className='w-[50%] flex justify-between pl-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                           Ward
                        </div>
                        <div className='w-[76%]'>
                            {/* <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select> */}
                            <Select
                                name="RegistrationDetails.Ward"
                                isSearchable={true}
                                onChange={(e) => roomHandler(e)}
                                value={
                                    ward
                                        ? ward.find((option) => option.value === props.formikState.values.RegistrationDetails.Ward)
                                        : ''}
                                options={ward}
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[50%] flex justify-between pr-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            Room :
                        </div>
                        <div className='w-[76%]'>
                            {/* <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select> */}
                            <Select
                                name="RegistrationDetails.Room"
                                isSearchable={true}
                                onChange={(e) => bedHandler(e)}
                                value={
                                    room
                                        ? room.find((option) => option.value === props.formikState.values.RegistrationDetails.Room)
                                        : ''}
                                options={room}
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className='w-[50%] flex justify-between pl-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                           Bed number :
                        </div>
                        <div className='w-[76%]'>
                            {/* <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select> */}
                            <Select
                                name="RegistrationDetails.Bed"
                                isSearchable={true}
                                onChange={(value) => {
                                    props.formikState.setFieldValue('RegistrationDetails.Bed', value.value);
                                }}
                                value={
                                    bed
                                        ? bed.find((option) => option.value === props.formikState.values.RegistrationDetails.Bed)
                                        : ''}
                                options={bed}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[50%] flex justify-between pr-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            Date of admission :
                        </div>
                        <div className='w-[76%]'>
                            {/* <input
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-[#F3F3F3] outline-none'
                                type='text'
                            /> */}
                            <Datepicker
                                id='RegistrationDetails.Date'
                                name='RegistrationDetails.Date'
                                dateFormat="dd-MMM-yyyy"
                                // eslint-disable-next-line no-mixed-operators
                                selected={props.formikState.values.RegistrationDetails.Date && new Date(props.formikState.values.RegistrationDetails.Date) || null}
                                onChange={newDate => {
                                    props.formikState.setFieldValue('RegistrationDetails.Date', newDate);
                                }}
                                onBlur={props.formikState.handleBlur}
                            />
                        </div>
                    </div>
                    <div className='w-[50%] flex justify-between pl-[2%]'>
                        <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                           Time :
                        </div>
                        <div className='w-[76%]'>
                            <input
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-[#F3F3F3] outline-none'
                                type='time'
                                id='RegistrationDetails.Time'
                                name='RegistrationDetails.Time'
                                step='1'
                                value= {props.formikState.values.RegistrationDetails.Time}
                                onChange={props.formikState.handleChange}
                                onBlur={props.formikState.handleBlur}

                            />
                        </div>
                    </div>
                </div>

                <div className='px-[1%] w-[100%] flex py-2.5'>
                    <div className='w-[100%] flex justify-between'>
                        <div className='w-[10%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            Category :
                        </div>
                        <div className='w-[88.5%]'>
                            {/* <select
                                className='h-[38px] w-full text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input required bg-[#F3F3F3] outline-none'
                            >
                            </select> */}
                            <Select
                                name="RegistrationDetails.Category"
                                isSearchable={true}
                                onChange={(value) => {
                                    props.formikState.setFieldValue('RegistrationDetails.Category', value.value);
                                }}
                                value={
                                    Category
                                        ? Category.find((option) => option.value === props.formikState.values.RegistrationDetails.Category)
                                        : ''}
                                options={Category}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                            >
                            </Select>
                        </div>
                    </div>
                </div>

                <div className='w-[50%] flex justify-between'>
                    <div className='w-[20%] self-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575] pl-[2%]'>
                            MLC :
                    </div>
                    <div className='w-[76%] flex justify-between'>
                        <div className='w-[30%] flex'>
                            <label className='self-center font-"roboto" flex font-[400] text-[14px] leading-[15px] text-[#757575] ml-0.5'>
                                <input
                                    className='self-center mr-[2.5px]'
                                    type='radio'
                                    name='RegistrationDetails.MLC'
                                    id='no'
                                    value='no'
                                    onChange={props.formikState.handleChange}
                                    onBlur={props.formikState.handleBlur}
                                    checked={props.formikState.values.RegistrationDetails.MLC === 'no'}
                                />No
                            </label>
                            <label className='self-center ml-[45px] flex items-center font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                                <input
                                    className='self-center mr-[2.5px]'
                                    type='radio'
                                    name='RegistrationDetails.MLC'
                                    id='yes'
                                    value='yes'
                                    onChange={props.formikState.handleChange}
                                    onBlur={props.formikState.handleBlur}
                                    checked={props.formikState.values.RegistrationDetails.MLC === 'yes'}
                                />Yes
                            </label>
                        </div>
                        {props.formikState.values.RegistrationDetails.MLC === 'yes' &&
                        <div className='w-[70%]'>
                            <input
                                className='h-[38px] w-[92%] text-[15px] text-[#8F8F8F] pl-[10px] self-center border-[1px] border-[#E3E3E3] text-input bg-[#F3F3F3] outline-none'
                                type='text'
                                id='RegistrationDetails.MLCText'
                                name='RegistrationDetails.MLCText'
                                value= {props.formikState.values.RegistrationDetails.MLCText}
                                onChange={props.formikState.handleChange}
                                onBlur={props.formikState.handleBlur}
                            />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegistrationDetails;
