import React from 'react';
import StationEdit from '../../containers/NurseFunctions/StationEdit';

const StationDoctorEdit = () => {
    return (
        <StationEdit isDoctor = {true} />
    );
};

export default StationDoctorEdit;
