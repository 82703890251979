import React from 'react';
import Title from '../Title';
import Check from '../Check';

function FamilyHo (props) {
    return (
        <div>
            <Title title={'Family H/o'} isNurse = {props.isNurse}/>
            <div className='bg-white px-3 py-[20px]'>
                <div className='flex items-center'>
                    <div className='w-[160px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>Family H/o :</div>
                    <div className='w-[150px] justify-around flex'>
                        <label className='self-center flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='FamilyHo.HYN'
                                type='radio'
                                id='No'
                                onChange={props.formikState.handleChange}
                                checked={props.formikState.values.FamilyHo.HYN === 'No'}
                                value='No'
                                disabled={props.disabled}
                            />No
                        </label>
                        <label className='self-center flex font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>
                            <input
                                className='self-center mr-[2.5px]'
                                name='FamilyHo.HYN'
                                id='Yes'
                                type='radio'
                                onChange={props.formikState.handleChange}
                                checked={props.formikState.values.FamilyHo.HYN === 'Yes'}
                                value='Yes'
                                disabled={props.disabled}
                            />Yes
                        </label>
                    </div>
                    {props.formikState.values.FamilyHo.HYN === 'Yes' &&
                <>
                    <Check formikState={props.formikState} id='FamilyHo.HTN' val="Yes" label='HTN' name='FamilyHo.HTN' checked={props.isView ? props.formikState.values.FamilyHo.HTN === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.DM2' val="Yes" label='DM2' name='FamilyHo.DM2' checked={props.isView ? props.formikState.values.FamilyHo.DM2 === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.CAD' val="Yes" label='CAD' name='FamilyHo.CAD' checked={props.isView ? props.formikState.values.FamilyHo.CAD === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.ASTHMA' val="Yes" label='ASTHMA' name='FamilyHo.ASTHMA' checked={props.isView ? props.formikState.values.FamilyHo.ASTHMA === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.TB' val="Yes" label='TB' name='FamilyHo.TB' checked={props.isView ? props.formikState.values.FamilyHo.TB === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.OBESITY' val="Yes" label='OBESITY' name='FamilyHo.OBESITY' checked={props.isView ? props.formikState.values.FamilyHo.OBESITY === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.DYSLIPIDEMIA' val="Yes" label='DYSLIPIDEMIA' name='FamilyHo.DYSLIPIDEMIA' checked={props.isView ? props.formikState.values.FamilyHo.DYSLIPIDEMIA === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.SCD' val="Yes" label='SCD' name='FamilyHo.SCD' checked={props.isView ? props.formikState.values.FamilyHo.SCD === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.CVA' val="Yes" label='CVA(stroke)' name='FamilyHo.CVA' checked={props.isView ? props.formikState.values.FamilyHo.CVA === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.Others' val="Yes" label='Others' name='FamilyHo.Others' checked={props.isView ? props.formikState.values.FamilyHo.Others === 'Yes' : null} disabled={props.disabled}/>
                    <Check formikState={props.formikState} id='FamilyHo.NotSignificant' val="Yes" label='Not Significant' name='FamilyHo.NotSignificant' checked={props.isView ? props.formikState.values.FamilyHo.NotSignificant === 'Yes' : null} disabled={props.disabled}/>
                </>
                    }
                </div>
                {props.formikState.values.FamilyHo.HYN === 'Yes' &&
            <input
                className='h-[47px] mt-[10px] ml-[180px] pl-[10px] text-[13px] self-center border-[1px] w-[calc(100%-180px)] border-[#E3E3E3] text-input bg-gray outline-none placeholder:text-[#8F8F8F] text-[#8F8F8F] font-[400] leading-[15px] placeholder:font-[400] placeholder:text-[13px] placeholder:leading-[15px]'
                id='FamilyHo.FamilyHoInput'
                name='FamilyHo.FamilyHoInput'
                type='text'
                placeholder='Write Here'
                onChange={props.formikState.handleChange}
                value={props.formikState.values.FamilyHo.FamilyHoInput}
            />
                }
            </div>
        </div>
    );
}

export default FamilyHo;
