import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import { TextField } from '../components/TextField';
import Button from '../components/Button';
import { getLocation } from '../api/location';

const WardForm = (props) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    const [floor, setFloor] = useState('');

    const dropdownJsonFormatHandler = (response) => {
        const intialValue = { label: 'Select', value: '' };
        const list = [];
        for (const item of response.data.entry) {
            list.push({ label: item.resource.name, value: item.resource.id });
        }
        list.unshift(intialValue);
        return list;
    };
    useEffect(() => {
        getLocation('152').then((response) => {
            const data = dropdownJsonFormatHandler(response);
            setFloor(data);
        });
    }, []);

    const addFunc = (value) => {
        // console.log(value);
        props.onAdd(value);
    };

    const editFunc = (value) => {
        // console.log('data', value);
        props.onEdit(value);
    };

    const floorData = props.data.resource?.partOf.reference;
    const floorId = floorData.substr(9, 11);
    const formikState = useFormik({
        initialValues: {
            id: floorId || '',
            name: props.data.resource?.name || '',
            code: 'wa',
            type: 'lvl'
        },
        validationSchema: Yup.object({
            id: Yup.string().required('Required *'),
            name: Yup.string().required('Required *')
        }),
        onSubmit: props.isEdit ? editFunc : addFunc
    });

    return (
        <form onSubmit={formikState.handleSubmit}>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-black font-roboto text-sm">Floor No</label>
                                <span className="text-error px-1">*</span>
                                <span>:</span>
                            </div>
                            <Select
                                name="id"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('id', value.value);
                                }}
                                value={
                                    floor
                                        ? floor.find((option) => option.value === formikState.values.id)
                                        : ''}
                                options={floor}
                                className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 text-sm w-full"
                                styles= {colourStyles}
                            >
                            </Select>
                        </div>
                        {formikState.touched.id && formikState.errors.id
                            ? (
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{formikState.errors.id}</div>
                            )
                            : null}
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Ward Name"
                            id="name"
                            name="name"
                            type="text"
                            onChange={formikState.handleChange}
                            value={formikState.values.name}
                            onBlur={formikState.handleBlur}
                            className="text-col font-roboto bg-gray  box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            isMandatory={true}
                            style={props.style}

                        />
                        {formikState.touched.name && formikState.errors.name
                            ? (
                                <div className="flex w-full justify-end text-error font-roboto text-sm">{formikState.errors.name}</div>
                            )
                            : null}
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[1rem] my-8 text-sm border rounded-md text-white h-10 w-16"
                        disabled={props.isDisabled}
                    />
                    <Button
                        onClick={() => props.handleModalClose(false)}
                        label="Cancel"
                        className="bg-gray border-graycol py-[0.5rem] px-[0.5rem] my-8 text-sm  border rounded-md text-col h-10 w-16"
                    />

                </div>
            </div>
        </form>

    );
};

export default WardForm;
