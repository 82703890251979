import { zeroAdder } from './common';
export const generatePrimaryCare = () => {
    const currentDate = new Date();
    const dateFormat = currentDate.getFullYear() + '-' + zeroAdder((currentDate.getMonth() + 1)) + '-' + zeroAdder(currentDate.getDate());
    const currentTime = zeroAdder(currentDate.getHours()) + ':' + zeroAdder(currentDate.getMinutes());
    const primaryCare = {
        HeartRate: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Medication: [],
        BP: [{
            SystolicBP: [
                {
                    data: 0,
                    time: currentTime,
                    date: dateFormat
                }
            ],
            DiastolicBP: [
                {
                    data: 0,
                    time: currentTime,
                    date: dateFormat
                }
            ]
        }],
        IVaccess: [
            {
                data: '',
                time: currentTime,
                date: dateFormat
            }
        ],
        RespiratoryRate: 0,
        PeripheralPulse:
        {
            CCA: '',
            RA: '',
            BA: '',
            DPA: '',
            PTA: '',
            FA: '',
            PA: '',
            RFD: '',
            RRD: '',
            Remarks: ''
        },
        Spo2: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        RBS: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Insulin: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Temperature: [
            {
                fare: 0,
                cel: -17.8,
                time: currentTime,
                date: dateFormat
            }
        ],
        HemoDynamicRemarks: [
            {
                data: ''
            }
        ],
        VentilationType: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Tidal: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Fio2: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        PEEP: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Airway: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        RateOfRespiration: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        GivenBy:
        {
            data: '',
            time: currentTime,
            date: dateFormat
        },
        TakenBy:
        {
            data: '',
            time: currentTime,
            date: dateFormat
        },
        CompletedTasks:
        {
            data: '',
            stime: currentTime,
            etime: '',
            date: dateFormat
        },
        PendingTasks:
        {
            data: '',
            stime: currentTime,
            etime: '',
            date: dateFormat
        },
        ADW:
        {
            data: '',
            remarks: ''
        },
        AMI:
        {
            data: '',
            remarks: ''
        },
        Diet:
        {
            data: '',
            remarks: ''
        },
        IVA:
        {
            data: '',
            remarks: ''
        },
        HEP:
        {
            data: '',
            sel: ''
        },
        Order:
        {
            data: '',
            sel: ''
        },
        Sent:
        {
            data: '',
            remarks: ''
        },
        Bed:
        {
            data: ''
        },
        Psl:
        {
            data: '',
            remarks: ''
        },
        Air:
        {
            data: '',
            remarks: ''
        },
        Pressure:
        {
            data: '',
            remarks: ''
        },
        Rounds:
        {
            data: '',
            remarks: '',
            sel: ''
        },
        Reports:
        {
            data: '',
            remarks: ''
        },
        dutyDoctor: {
            dutyDoctor: ''
        },
        remarks: {
            remarks: ''
        },
        Nebulisation:
        {
            data: '',
            remarks: ''
        },
        ionotropeSupport:
        {
            data: '',
            remarks: ''
        },
        sedation:
        {
            data: '',
            remarks: ''
        },
        headElevated:
        {
            data: '',
            remarks: ''
        },
        eyeCareGiven:
        {
            data: '',
            remarks: ''
        },
        eyeLubricant:
        {
            data: '',
            remarks: ''
        },
        centralCvp:
        {
            data: '',
            remarks: ''
        },
        etCuff:
        {
            data: '',
            remarks: ''
        },
        rtPosition:
        {
            data: '',
            remarks: ''
        },
        urinaryCathChecked:
        {
            data: '',
            remarks: ''
        },
        urinaryInsertion:
        {
            data: '',
            remarks: ''
        },
        weaningTrail:
        {
            data: '',
            remarks: ''
        },
        mouthCare:
        {
            data: '',
            remarks: ''
        },
        backCare:
        {
            data: '',
            remarks: ''
        },
        bedSore:
        {
            data: '',
            remarks: '',
            data2: ''
        },
        urinaryCathFixed:
        {
            data: '',
            remarks: ''
        },
        btTransfusion:
        {
            data: '',
            remarks: ''
        },
        PH: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        PCo2: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        PO2: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Hco3: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Lactate: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        Saturation: [
            {
                data: 0,
                time: currentTime,
                date: dateFormat
            }
        ],
        SpecialInstruction: {
            SpecialInstruction: ''
        },
        IntakeOutput: [{
            date: dateFormat,
            time: currentTime,
            intraFluids: '',
            ivAntibiotics: '',
            oralFluid: '',
            urineOuput: '',
            gastricAspiration: '',
            stoolPassed: '',
            balance: '',
            remark: ''
        }]
    };
    return primaryCare;
};
