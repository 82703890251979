import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import downArrow from '../../src/assets/images/downarrow.svg';
import rightarrow from '../../src/assets/images/rightarrow.svg';

const Sidebar = (props) => {
    // const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    // const [show, setShow] = useState(false);

    const [isActive, setIsActive] = useState(null);

    // const links = [
    //     { name: 'Vitals', link: '' },
    //     { name: 'Social History', link: '' },
    //     { name: 'Surgical History', link: '' },
    //     { name: 'Diagnosis', link: '' },
    //     { name: 'Medication', link: '' }
    // ];

    const link = [
        { name: 'Patients', link: '/patient' },
        { name: 'Admissions', link: '/patient/admission-list' },
        { name: 'Registration', link: '/patient/register' }
    ];

    const handleClick = (index) => {
        localStorage.setItem('selectedIndex', index);
        props.action();
        setIsActive(index);
    };

    useEffect(() => {
        setIsActive(localStorage.getItem('selectedIndex') ? Number(localStorage.getItem('selectedIndex')) : null);
    }, []);

    return (
        <div className="container">
            <button
                type="button"
                className="border-b bg-primary items-center px-2 py-3 text-roboto bg-primary w-full text-white font-normal"
                onClick={() => setToggle(!toggle)}
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">Patient</span>
                    </div>
                    <div>
                        <img src={toggle ? downArrow : rightarrow} alt="arrow" />
                    </div>
                </div>
            </button>
            {toggle && (
                <div className="dropdown-wrapper">
                    <ul className="dropdown-menu">
                        {link.map((menu, index) => (
                            <Link
                                key={index}
                                to={menu.link}
                            >
                                <li
                                    className={`${isActive === index ? 'bg-primary' : ''} flex items-center px-2 py-3 pl-11 border-b bg-primary shadow-2xl w-full text-white text-roboto font-normal hover:bg-primary no-underline`}
                                    onClick={() => handleClick(index)}
                                >
                                    {menu.name}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )}
            {/* <button
                type="button"
                className="border-b bg-primary items-center px-2 py-3 text-roboto  bg-primary w-full text-white font-normal"
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">Appointment</span>
                    </div>
                    <div>
                        <img src={rightarrow} alt="rightarrow" />
                    </div>
                </div>
            </button> */}
            {/* <button
                type="button"
                className="border-b bg-primary items-center px-2 py-3 text-roboto bg-primary w-full text-white font-normal"
                onClick={() => setOpen(!open)}
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">Patient Visit</span>
                    </div>
                    <div>
                        <img src={open ? downArrow : rightarrow} alt="arrow" />
                    </div>
                </div>
            </button>
            {open && (
                <div className="dropdown-wrapper">
                    <ul className="dropdown-menu">
                        {links.map((menu, index) => (
                            <Link
                                key={index}
                                to={menu.link}
                            >
                                <li
                                    className={`${isActive === index ? 'bg-primary' : ''} flex items-center px-2 py-3 pl-11 border-b bg-primary shadow-2xl w-full text-white text-roboto font-normal hover:bg-primary no-underline`}
                                    onClick={() => handleClick(index)}
                                >
                                    {menu.name}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )} */}
            {/* <button
                type="button"
                className="border-b bg-primary items-center px-2 py-3  bg-primary w-full text-white font-normal"
                onClick={() => setShow(!show)}
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">User Management</span>
                    </div>
                    <div>
                        <img src={show ? downArrow : rightarrow} alt="arrow" />
                    </div>
                </div>
            </button>
            {show && (
                <div className="dropdown-wrapper">
                    <ul className="dropdown-menu">
                        {menus.map((menu, index) => (
                            <Link
                                key={index}
                                to={menu.link}
                            >
                                <li
                                    className={`${isActive === index ? 'bg-primary' : ''} flex items-center px-2 py-3 pl-11 border-b bg-primary shadow-2xl w-full text-white text-roboto font-normal hover:bg-primary no-underline`}
                                    onClick={() => handleClick(index)}
                                >
                                    {menu.name}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )} */}
        </div>
    );
};

export default Sidebar;
