import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import PatientDetailForm from '../../forms/PatientDetail';
import Arrow1 from '../../assets/images/arrow1.svg';
import Search from '../../assets/images/search.svg';

const PatientDetailUpdate = () => {
    const location = useLocation();
    return (
        <div className='bg-gray h-screen w-full'>
            <div className='flex flex-row justify-between '>
                <div className='flex items-center p-2'>
                    <Link to='/home'>
                        <span className='text-base font-normal px-2 py-2 text-col font-roboto'>
                            Home
                        </span>
                    </Link>
                    <img src={Arrow1} alt='arrow' />
                    <Link to='/nurse/patient'>
                        <span className='text-black font-normal text-base px-2 py-2 font-roboto'>Patient List</span>
                    </Link>
                    <img src={Arrow1} alt='arrow' />
                    <span className='text-black font-normal text-base px-2 py-2 font-roboto'>Patient Details</span>
                </div>
            </div>
            <div className='flex flex-row justify-between'>
                <span className='text-3xl font-bold px-2 py-5 text-black'>
                    Patient Details
                </span>
                <div className='flex flex-row p-2 gap-x-4'>
                    <div className='pt-2 relative mx-auto text-col '>
                        <input
                            className='shadow border-col bg-white h-11 w-72 px-5 pr-4  text-sm focus:outline-none'
                            type='date'
                            name='datepicker'
                        />
                    </div>
                    <div className='pt-2 relative mx-auto text-col'>
                        <input
                            className='shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none'
                            type='search'
                            name='search'
                            placeholder='Search'
                        />
                        <button type='submit' className='absolute right-0 top-0 mt-6 mr-4'>
                            <img src={Search} alt='search'/>
                        </button>
                    </div>
                </div>
            </div>
            <div className='bg-white ml-6 mr-6 flex flex-row justify-between'>
                <PatientDetailForm editData={location.state} isDisabled={false}/>
            </div>
        </div>
    );
};

export default PatientDetailUpdate;
