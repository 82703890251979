export const toFhir = (obj) => {
    // eslint-disable-next-line no-console
    console.log('medicationevolveobj', obj);
    return Promise.resolve({
        resourceType: 'MedicationRequest',
        meta: {
            profile: [
                'http://3.109.121.35:8080/fhir/StructureDefinition/MedicationRequest'
            ]
        },
        status: obj.status,
        intent: 'order',
        priority: 'routine',
        medicationReference: {
            reference: 'Medication/275'
        },
        subject: {
            reference: `Patient/${obj.patient}`
        },
        encounter: {
            reference: `Encounter/${obj.encounter}`
        },
        authoredOn: obj.StartFormat,
        recorder: {
            reference: `Practitioner/${obj.practitioner}`
        },
        requester: {
            reference: `Practitioner/${obj.practitioner}`
        },
        performer: {
            reference: `Practitioner/${obj.practitioner}`
        },
        medicationCodeableConcept: {
            text: obj.Med
        },

        dosageInstruction: [
            {
                timing: {
                    repeat: {
                        when: [
                            obj.Food
                        ],
                        boundsPeriod: {
                            start: obj.StartFormat,
                            end: obj.EndFormat
                        }
                    }
                },
                additionalInstruction: [{
                    coding: [
                        {
                            display: obj.Days
                        }
                    ]
                }],
                route: {
                    coding: [
                        {
                            system: 'http://snomed.info',
                            code: 26643006,
                            display: obj.Route
                        }
                    ]
                },
                method: {
                    coding: [
                        {
                            system: 'http://snomed.info/id/',
                            code: 420360002,
                            display: obj.Reg
                        }
                    ]
                },
                doseAndRate: [
                    {
                        doseRange: {
                            low: {
                                value: obj.Dosage,
                                unit: obj.Du
                            },
                            high: {
                                value: obj.Dosage,
                                unit: obj.Du
                            }
                        }
                    }
                ]
            }
        ],
        dispenseRequest: {
            initialFill: {
                quantity: { value: obj.Quantity }
            },
            quantity: { value: obj.QuantityPending }
        }
    });
};

export const fromFhir = (data) => {
    const obj = {};
    obj.id = data.id;
    obj.status = data.status;
    obj.patient = data.subject.reference.split('/')[1];
    obj.practitioner = data?.requester?.reference?.split('/')[1];
    obj.encounter = data.encounter.reference.split('/')[1];
    obj.Route = data?.dosageInstruction[0].route.coding[0].display;
    obj.Reg = data?.dosageInstruction[0].method.coding[0].display;
    obj.dosageRange = data?.dosageInstruction[0].doseAndRate[0].doseRange.low.value + ' ' + data.dosageInstruction[0].doseAndRate[0].doseRange.low.unit;
    obj.Dosage = data?.dosageInstruction[0].doseAndRate[0].doseRange.low.value;
    obj.Du = data?.dosageInstruction[0].doseAndRate[0].doseRange.low.unit;
    obj.Days = data?.dosageInstruction[0].additionalInstruction[0].coding[0].display;
    obj.Med = data?.medicationCodeableConcept.text;
    obj.StartFormat = data?.dosageInstruction[0].timing.repeat.boundsPeriod.start;
    obj.EndFormat = data?.dosageInstruction[0].timing.repeat.boundsPeriod.end;
    obj.Food = data?.dosageInstruction[0]?.timing?.repeat?.when[0];
    obj.Quantity = data?.dispenseRequest?.initialFill.quantity.value;
    obj.QuantityPending = data?.dispenseRequest?.quantity.value;
    return Promise.resolve(obj);
};
