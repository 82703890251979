import React from 'react';
import Select from 'react-select';
import Ventilated from '../../utils/json/VentilatedPatient.json';

const VentilatedPatient = ({ formikState, onChange, ...props }) => {
    const colourStyles = {
        control: base => ({
            ...base,
            border: 0,
            boxShadow: 'none',
            background: '#F3F3F3'
        })
    };
    return (
        <>
            <div className={props.isDoctor ? 'bg-iat p-3 flex flex-row items-center justify-between' : 'bg-tgray p-3 flex flex-row items-center justify-between'}>
                <span className='text-white text-base font-roboto '>Ventilated Patient Activity Chart</span>
            </div>
            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Time :</label>
                            </div>
                            <input
                                name="ventilatedPatient.time"
                                type="time"
                                onChange={onChange}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                disabled={props.isDisabled}
                                value={formikState.values.ventilatedPatient.time}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Eye Care :</label>
                            </div>
                            <Select
                                name="ventilatedPatient.eyecare"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('ventilatedPatient.eyecare', value.value);
                                }}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                                options={Ventilated.Eyecare}
                                value={
                                    Ventilated.Eyecare
                                        ? Ventilated.Eyecare.find((option) => option.value === formikState.values.ventilatedPatient.eyecare)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Oral Care :</label>
                            </div>
                            <Select
                                name="ventilatedPatient.oralcare"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('ventilatedPatient.oralcare', value.value);
                                }}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                                options={Ventilated.Oralcare}
                                value={
                                    Ventilated.Oralcare
                                        ? Ventilated.Oralcare.find((option) => option.value === formikState.values.ventilatedPatient.oralcare)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Perineal Care :</label>
                            </div>
                            <Select
                                name="ventilatedPatient.perinealcare"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('ventilatedPatient.perinealcare', value.value);
                                }}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                                options={Ventilated.Perinealcare}
                                value={
                                    Ventilated.Perinealcare
                                        ? Ventilated.Perinealcare.find((option) => option.value === formikState.values.ventilatedPatient.perinealcare)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Back Care :</label>
                            </div>
                            <Select
                                name="ventilatedPatient.backcare"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('ventilatedPatient.backcare', value.value);
                                }}
                                options={Ventilated.Backcare}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                                value={
                                    Ventilated.Backcare
                                        ? Ventilated.Backcare.find((option) => option.value === formikState.values.ventilatedPatient.backcare)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Position Change :</label>
                            </div>
                            <Select
                                name="ventilatedPatient.positionchange"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('ventilatedPatient.positionchange', value.value);
                                }}
                                options={Ventilated.Positionchange}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                                value={
                                    Ventilated.Positionchange
                                        ? Ventilated.Positionchange.find((option) => option.value === formikState.values.ventilatedPatient.positionchange)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Bedsore Status :</label>
                            </div>
                            <Select
                                name="ventilatedPatient.bedsorestatus"
                                isSearchable={true}
                                onChange={(value) => {
                                    formikState.setFieldValue('ventilatedPatient.bedsorestatus', value.value);
                                }}
                                options={Ventilated.Bedsorestatus}
                                className="text-col font-roboto bg-gray focus:outline-none h-10 text-sm w-full"
                                styles={colourStyles}
                                isDisabled={props.isDisabled}
                                value={
                                    Ventilated.Bedsorestatus
                                        ? Ventilated.Bedsorestatus.find((option) => option.value === formikState.values.ventilatedPatient.bedsorestatus)
                                        : ''}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c4 gap-[1%] items-center">
                            <div className="flex flex-row">
                                <label className="text-col font-roboto text-sm">Remarks :</label>
                            </div>
                            <input
                                id="ventilatedPatient.ventilatedremarks"
                                name="ventilatedPatient.ventilatedremarks"
                                type="text"
                                onChange={onChange}
                                disabled={props.isDisabled}
                                value={formikState.values.ventilatedPatient.ventilatedremarks}
                                className=" bg-gray box-border border-graycol focus:outline-none h-10 w-full px-1 text-sm text-col"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VentilatedPatient;
