export const SnackbarStyles = {
    position: 'bottom-center',
    style: {
        background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
        border: '#00A2B7',
        color: 'white',
        fontFamily: 'roboto',
        fontSize: '20px',
        textAlign: 'center'
    }
};
