import axios from '../utils/axios';

export const addInitialAssessment = (body) => {
    return axios.post('/prod/fhir/', JSON.stringify(body)).then((response) => {
        return response;
    });
};

export const getDataWithOutFilter = (pageNumber) => {
    return axios.get(`/prod/fhir/icd10?text=&page=${pageNumber}`).then((response) => {
        return response.data;
    });
};

export const getDataWithFilter = (searchField, searchValue) => {
    return axios.get(`/prod/fhir/icd10?${searchField}=${searchValue}`).then((response) => {
        return response.data;
    });
};
