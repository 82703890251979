/* eslint-disable no-console */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import BloodTransfusion from '../../components/NurseContain/BloodTransfusion';
import Accessories from '../../components/NurseContain/Accessories';
import PeripheralLine from '../../components/NurseContain/PeripheralLine';
import DvtRisk from '../../components/NurseContain/DvtRisk';
import DetailedPainAssessment from '../../components/NurseContain/DetailedPainAssessment';
import RiskScoreCalculation from '../../components/NurseContain/RiskScoreCalculation';
import RestraintAssessment from '../../components/NurseContain/RestraintAssessment';
import BredenRiskAssessment from '../../components/NurseContain/BredenRiskAssessment';
import VentilatedPatient from '../../components/NurseContain/VentilatedPatient';
import PressureUlcerAssessment from '../../components/NurseContain/PressureUlcerAssessment';
import InstructionByDoctor from '../../components/NurseContain/InstructionByDoctor';
import PainAssessment from '../../components/NurseContain/PainAssessment';
import BiomedicalEquipment from '../../components/NurseContain/BiomedicalEquipment';
import InvestigationChecklist from '../../components/NurseContain/InvestigationChecklist';
import OldReport from '../../components/NurseContain/OldReport';
import HandwashChart from '../../components/NurseContain/HandwashChart';
import AldreteScore from '../../components/NurseContain/AldreteScore';
import PhlebitisScore from '../../components/NurseContain/PhlebitisScore';
import MewsScore from '../../components/NurseContain/Mews';
import secondaryCareJson from '../../utils/json/SecondaryCareFormik.json';

const Station = (props) => {
    // console.log('Station properties', props);
    // console.log('editdatadispaly', props.viewSecondary);
    const location = useLocation();
    let encounterId = '';
    if (location?.state?.investigations) {
        encounterId = location?.state?.encounter?.id;
    } else {
        encounterId = location?.state?.id;
    }
    const patientId = location?.state?.patient?.id;
    // console.log('location', location);
    if (!patientId || !encounterId) {
        // eslint-disable-next-line no-console
        console.log('Missing crucial params', ['patientId', patientId], ['encounterId', encounterId]);
        // alert('Missing crucial params check patient ID and encounter ID');
    }

    const submitHandler = (data, { setSubmitting }) => {
        formikState.values.type = props.type;
        formikState.values.Patient = patientId;
        formikState.values.Encounter = encounterId;
        const val = props.type === 'add' ? data : [data, props.data];
        // console.log('secval', props.type);
        props.handleSubmit(val);
        setSubmitting(false);
    };

    let inputData = '';
    if (props.isView || props.isEdit) {
        inputData = props.viewSecondary;
    } else {
        inputData = Object.assign({}, secondaryCareJson);
    }

    const formikState = useFormik({
        initialValues: inputData,
        onSubmit: submitHandler
    });
    return (
        <form className="w-full flex flex-col gap-y-4 bg-white" onSubmit={formikState.handleSubmit}>
            <div className="flex flex-row justify-between">
                <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                        Secondary Care
                </span>
            </div>
            <BloodTransfusion formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <Accessories formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <PeripheralLine formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <DvtRisk formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <DetailedPainAssessment formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <RiskScoreCalculation formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled = {props.isDisabled}/>
            <RestraintAssessment formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <BredenRiskAssessment formikState={formikState} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <VentilatedPatient formikState={formikState} onChange={formikState.handleChange} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <PressureUlcerAssessment formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <InstructionByDoctor />
            <PainAssessment formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <BiomedicalEquipment formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <InvestigationChecklist formikState={formikState} onChange={formikState.handleChange} isDoctor={props.isDoctor} onBlur={formikState.handleBlur} isDisabled={props.isDisabled}/>
            <OldReport formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <HandwashChart formikState={formikState} onChange={formikState.handleChange} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <AldreteScore formikState={formikState} onBlur={formikState.handleBlur} isDoctor={props.isDoctor} isDisabled={props.isDisabled} />
            <PhlebitisScore formikState={formikState} onChange={formikState.handleChange} isDoctor={props.isDoctor} isDisabled={props.isDisabled} />
            <MewsScore formikState={formikState} onChange={formikState.handleChange} isDoctor={props.isDoctor} isDisabled={props.isDisabled}/>
            <div className='w-full pl-[20px] pt-[50px] pb-[250px] bg-white flex justify-end'>
                <button type='submit' className={props.isDoctor ? 'w-[120px] h-[38px] bg-iat px-10 py-2 mx-2.5 border-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]' : 'w-[120px] h-[38px] bg-gradient px-10 py-2 mx-2.5 border-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'}>Save</button>
            </div>
        </form>

    );
};

export default Station;
