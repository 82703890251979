/* eslint-disable no-undef */
import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Search from '../../assets/images/search.svg';

import '../../assets/css/Style.css';
import Arrow1 from '../../assets/images/arrow1.svg';
import { FiUpload } from 'react-icons/fi';

const PastHistory = () => {
    const inputFile = useRef(null);
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const formikState = useFormik({
        initialValues: {
            date: ''
        }
    });
    const location = useLocation();

    const data = [location.state];

    return (
        <form className="w-full flex flex-col gap-y-4" onSubmit={formikState.handleSubmit}>
            <div className="h-screen">
                <div className="bg-background h-full w-full px-4">
                    <div className="flex flex-row justify-between py-2">
                        <div className="flex items-center">
                            <Link to={'/home'}>
                                <span className="text-base font-normal py-2 text-col">
                                Home
                                </span>
                            </Link>
                            <img src={Arrow1} alt="arrow"/>
                            <span className="text-black font-normal text-base  font-roboto px-2 py-2">TestPrescribed</span>
                        </div>

                        <div className="flex items-center gap-x-10">
                            <input type="date" className='text-col font-roboto bg-white box-border border-gray focus:outline-none h-10 text-sm py-2 px-12' placeholder='Select Date' name='date'></input>
                            <div className="pt-2 relative mx-auto text-gray-600">
                                <input
                                    className="shadow border-[#757575] bg-white h-10 w-48 px-5 pr-16 text-sm focus:outline-none"
                                    type="search"
                                    name="search"
                                    placeholder="Search"
                                />
                                <button type="submit" className="absolute right-0 top-0 mt-5 mr-4">
                                    <img src={Search} />
                                </button>
                            </div>
                            <button className=' px-20 py-2 text-white bg-[#1663AA] text-base font-medium'>Submit</button>
                        </div>
                    </div>
                    <div className='bg-[#1663AA] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                        <span className='text-white text-base font-roboto '>Prescribed tests</span>
                        <div className='flex flex-row gap-x-6'>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                        </div>
                    </div>
                    <div className='bg-white p-6'>
                        <table className="table-auto border border-slate-300 w-full">
                            <thead>
                                <tr>
                                    <th className='border border-slate-300 text-left p-2'>S.NO</th>
                                    <th className='border border-slate-300 text-left p-2'>Tests</th>
                                    <th className='border border-slate-300 text-left p-2'>Date</th>
                                    <th className='border border-slate-300 text-left p-2'>Time</th>
                                    <th className='border border-slate-300 text-left p-2'>Test Prescribed By</th>
                                    <th className='border border-slate-300 text-left p-2'>Uploaded</th>
                                    <th className='border border-slate-300 text-left p-2'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className='border border-slate-300'>
                                        <td className='text-sm p-2 border border-slate-300 text-col counterCell'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col text-right'>
                                            <input
                                                type="file"
                                                name='file'
                                                accept="image/png, image/jpeg, application/pdf"
                                                ref={inputFile}
                                                style={{ display: 'none' }}
                                                onChange={(event) => {
                                                    setFieldValue('file', event.currentTarget.files[0]);
                                                }}
                                            />
                                            <button onClick={onButtonClick}><FiUpload /></button>
                                        </td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>
                                            <select name="report" id="report" className='w-full h-10 bg-white'>
                                                <option value="Sample Initiated" className='text-red'>Sample Initiated</option>
                                                <option value="Sample Processing" className='text-green'>Sample Processing</option>
                                                <option value="Report Generated" className='text-blue'>Report Generated</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className='bg-[#1663AA] to-[#00a2b7] p-3 flex flex-row items-center justify-between'>
                        <span className='text-white text-base font-roboto '>Prescribed tests</span>
                        <div className='flex flex-row gap-x-6'>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                            <span className='text-sm text-white'></span>
                        </div>
                    </div>
                    <div className='bg-white p-6'>
                        <table className="table-auto border border-slate-300 w-full">
                            <thead>
                                <tr>
                                    <th className='border border-slate-300 text-left p-2'>S.NO</th>
                                    <th className='border border-slate-300 text-left p-2'>Tests</th>
                                    <th className='border border-slate-300 text-left p-2'>Date</th>
                                    <th className='border border-slate-300 text-left p-2'>Time</th>
                                    <th className='border border-slate-300 text-left p-2'>Test Prescribed By</th>
                                    <th className='border border-slate-300 text-left p-2'>Uploaded</th>
                                    <th className='border border-slate-300 text-left p-2'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index} className='border border-slate-300'>
                                        <td className='text-sm p-2 border border-slate-300 text-col counterCell'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'></td>
                                        <td className='text-sm p-2 border border-slate-300 text-col text-right'>
                                            <input
                                                type="file"
                                                name='file'
                                                accept="image/png, image/jpeg, application/pdf"
                                                ref={inputFile}
                                                style={{ display: 'none' }}
                                                onChange={(event) => {
                                                    setFieldValue('file', event.currentTarget.files[0]);
                                                }}
                                            />
                                            <button onClick={onButtonClick}><FiUpload /></button>
                                        </td>
                                        <td className='text-sm p-2 border border-slate-300 text-col'>
                                            <select name="report" id="report" className='w-full h-10 bg-white'>
                                                <option value="Sample Initiated" className='text-red'>Sample Initiated</option>
                                                <option value="Sample Processing" className='text-green'>Sample Processing</option>
                                                <option value="Report Generated" className='text-blue'>Report Generated</option>
                                            </select>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='w-full pl-[20px] pt-[50px] pb-[250px] bg-white'>
                <Link to = '/lab/patient'>
                    <button type='button' className='w-[120px] h-[38px] bg-[#1663AA] to-[#00a2b7] px-10 py-2 mx-2.5 border-2 font-"roboto" font-[500] text-[16px] leading-[15px] text-[#FFFFFF]'>Back</button>
                </Link>
            </div>
        </form>
    );
};

export default PastHistory;
