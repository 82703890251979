export const generateProgressNotesFormik = () => {
    // eslint-disable-next-line no-console
    // console.log('patientId', patientId, encounterId, participantId);
    return {
        // Patient: patientId,
        // Practitioner: participantId,
        // Encounter: encounterId,
        Medication: [],
        Handover: [],
        CheifComplaints: '',
        Prognosis: {
            Prognosis: '',
            ExplainedTo: ''
        },
        Diagnosis: [],
        Investigation: [],
        SpecialInstruction: [],
        Referral: {
            ReferredFrom: '',
            ReferredTo: '',
            Department: '',
            Data: '',
            Instruction: ''
        }
    };
};
