import React from 'react';

function Title (props) {
    return (
        <div className={props.isNurse ? 'h-[45px] bg-tgray flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]' : 'h-[45px] bg-iat flex w-full items-center p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'}>
            {props.title}
        </div>
    );
}

export default Title;
