/* eslint-disable no-console */
// import { booleanMap } from '../../utils/common.js';
import { getQuestionnaire } from '../../../api/questionnaire.js';
import { fromFhir as fromQuestionnaireFhir } from '../Questionnaire/index.js';

export const fromFhir = async (data) => {
    try {
        let questionnaire = {};
        // eslint-disable-next-line no-console
        switch (data.questionnaireType) {
        case 'ClinicalImpression': await getQuestionnaire('/386').then((httpResponse) => {
            fromQuestionnaireFhir(httpResponse).then((output) => {
                questionnaire = output;
            });
        });
            break;
        case 'PrimaryCare': await getQuestionnaire('/1762').then((httpResponse) => {
            fromQuestionnaireFhir(httpResponse).then((output) => {
                questionnaire = output;
            });
        });
            break;
        case 'SecondaryCare': await getQuestionnaire('/1875').then((httpResponse) => {
            fromQuestionnaireFhir(httpResponse).then((output) => {
                questionnaire = output;
            });
        });
            break;
        // eslint-disable-next-line no-console
        default: console.log('Invalid switch key is passed...');
            await getQuestionnaire('/386').then((httpResponse) => {
                fromQuestionnaireFhir(httpResponse).then((output) => {
                    questionnaire = output;
                    // console.log('questinarer', questionnaire);
                });
            });
            break;
        }
        const response = [];
        for (const item of data.resource.item) {
            const element = {};
            if (item.text) {
                element.question = item.text;
            } else {
                for (const heading of Object.values(questionnaire)) {
                    for (const question in heading) {
                        if (heading[question] === item.linkId) {
                            element.question = question;
                        }
                        if ((heading[question]).constructor === ({}).constructor) {
                            for (const nestedquestion in heading[question]) {
                                if (heading[question][nestedquestion] === item.linkId) {
                                    element.question = nestedquestion;
                                }
                            }
                        }
                    }
                }
            }
            element.answer = [];
            element.linkId = item.linkId;
            if (item.answer) {
                for (const answer of item.answer) {
                    element.answer.push(Object.values(answer)[0]);
                }
            }
            response.push(element);
        }
        return Promise.resolve(response);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};

export const toFhir = async (data) => {
    try {
        let questionnaire = {};
        const questionnaireResponseFhir = {};
        switch (data.questionnaireType) {
        case 'ClinicalImpression': await getQuestionnaire('/386').then((httpResponse) => {
            fromQuestionnaireFhir(httpResponse).then((output) => {
                questionnaire = output;
                questionnaireResponseFhir.questionnaire = 'http://3.109.111.166:8080/fhir/Questionnaire/386';
            });
        });
            break;
        case 'PrimaryCare': await getQuestionnaire('/1762').then((httpResponse) => {
            fromQuestionnaireFhir(httpResponse).then((output) => {
                questionnaire = output;
                questionnaireResponseFhir.questionnaire = 'http://3.109.111.166:8080/fhir/Questionnaire/1762';
            });
        });
            break;
        case 'SecondaryCare': await getQuestionnaire('/1875').then((httpResponse) => {
            fromQuestionnaireFhir(httpResponse).then((output) => {
                questionnaire = output;
                questionnaireResponseFhir.questionnaire = 'http://3.109.111.166:8080/fhir/Questionnaire/1875';
            });
        });
        }
        questionnaireResponseFhir.item = [];
        questionnaireResponseFhir.resourceType = 'QuestionnaireResponse';
        for (const key1 in data) {
            if (!(key1 in questionnaire)) {
                continue;
            }
            if (key1 === 'Consents') {
                questionnaireResponseFhir.item.push(
                    {
                        linkId: questionnaire[key1].linkId,
                        answer: {
                            valueString: data[key1]
                        }
                    }
                );
                continue;
            }
            for (const key2 in data[key1]) {
                if (!(key2 in questionnaire[key1])) {
                    continue;
                }
                // eslint-disable-next-line no-console
                // console.log('key1', key1, 'key2', key2, 'data', data[key1][key2]);
                if (!data[key1][key2]) {
                    data[key1][key2] = '';
                    // lets see, this might induce problems later, lets see
                }
                if ((data[key1][key2]).constructor === ({}).constructor) {
                    for (const key3 in data[key1][key2]) {
                        const itemObject = {
                            linkId: questionnaire[key1][key2][key3],
                            answer: {
                                valueString: data[key1][key2][key3]
                            }
                        };
                        // console.log('itemObject', itemObject);
                        questionnaireResponseFhir.item.push(itemObject);
                    }
                    continue;
                }

                const itemObject = {
                    linkId: questionnaire[key1][key2],
                    answer: {
                        valueString: data[key1][key2]
                    }
                };
                questionnaireResponseFhir.item.push(itemObject);
            }
        }
        return Promise.resolve(questionnaireResponseFhir);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
