import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import useAuth from './useAuth';
// import { ROLE } from "../utils/roles";

const PrivateRoute = ({ roles }) => {
    const location = useLocation();
    const { auth } = useAuth();

    return (
        auth?.user && roles.includes(auth.user.role)
            ? <Outlet />
            : <Navigate to={`/${auth.user.role}`} state={{ from: location }} />
    );
};

export default PrivateRoute;
