/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { FaMinus } from '@react-icons/all-files/fa/FaMinus';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { handleUncontrolledChange } from '../../utils/common';

function BloodPressure ({ isDisabled, ...props }) {
    const systolicTitle = props.title[0] || 'no title';
    const diastolicTitle = props.title[1] || 'no title';
    const unit = props.unit || 'no unit';
    const currentDate = new Date();
    const dateFormat = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
    const currentTime = currentDate.getHours() + ':' + currentDate.getMinutes().toFixed(2);
    const sampleData = {
        SystolicBP: [
            {
                data: 0,
                date: dateFormat,
                time: currentTime
            }
        ],
        DiastolicBP: [
            {
                data: 0,
                date: dateFormat,
                time: currentTime
            }
        ]
    };
    const [value, setVal] = useState(props.val);
    useEffect(() => {
        props.formikState.setFieldValue('BP', value);
    }, [value]);
    const handleAdd = () => {
        setVal([...value, sampleData]);
    };
    const handleDelete = () => {
        if (value.length > 1) {
            value.pop();
            setVal([...value]);
        }
    };
    return (
        <div>
            { props.isDoctor
                ? <div className='h-[45px] items-center bg-iat flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            Systemic Blood pressure
                </div>
                : <div className='h-[45px] items-center bg-tgray flex w-full px-[16px] p-2 font-"roboto" font-[500] text-[16px] leading: [15px] text-[#FFFFFF]'>
            Systemic Blood pressure
                </div> }
            <div className='flex bg-white'>
                <div className='w-full my-[5px]'>
                    { value.map((data, i) => {
                        return (
                            <div className='bg-white px-[16px] py-[10px] flex flex-col justify-between' key={i}>
                                <div className='flex flex-row'>
                                    <div className="flex flex-row w-1/3 justify-around items-center">
                                        <label className='flex flex-col justify-around w-[200px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>{systolicTitle.title}:</label>
                                        <div className='w-[350px] justify-end flex items-center'>
                                            <input
                                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                type='number'
                                                id={`${systolicTitle.id}.${i}.data`}
                                                name={`${systolicTitle.id}.${i}.data`}
                                                onChange={e => handleUncontrolledChange(value, setVal, e)}
                                                defaultValue={data.SystolicBP[0].data}
                                                disabled = { isDisabled }
                                            />
                                            <div className='absolute text-right p-[15px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>{unit}</div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-1/3 justify-around items-center">
                                        <label className='flex flex-col items-center w-[calc(100%-173px)] justify-around font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Time :</label>
                                        <div className='relative flex'>
                                            <input
                                                className='h-[38px] w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3] text-center outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                id={`${systolicTitle.id}.${i}.time`}
                                                name={`${systolicTitle.id}.${i}.time`}
                                                type='time'
                                                onChange={e => handleUncontrolledChange(value, setVal, e)}
                                                defaultValue={data.SystolicBP[0].time}
                                                disabled = { isDisabled }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-1/3 items-center">
                                        <label className='flex flex-col items-center justify-around w-[calc(90%-173px)] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Date :</label>
                                        <input
                                            className='h-[38px] w-[173px] text-input bg-gray border-[1px] border-[#E3E3E3] text-center outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                            id={`${systolicTitle.id}.${i}.date`}
                                            name={`${systolicTitle.id}.${i}.date`}
                                            type='date'
                                            onChange={e => handleUncontrolledChange(value, setVal, e)}
                                            defaultValue={data.SystolicBP[0].date}
                                            disabled = { isDisabled }
                                        />
                                    </div>
                                    <button type='button' className='items-center flex justify-center text-fc border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1 mr-[0.75rem]' disabled = {isDisabled} onClick={() => handleAdd()}>
                                        <FaPlus/>
                                    </button>
                                    <button type='button' className='items-center text-fc justify-center flex border-2 h-[30px] w-[30px] p-[7.5px] self-center ml-1' disabled = {isDisabled} onClick={() => handleDelete()}>
                                        <FaMinus/>
                                    </button>
                                </div>
                                <div className='flex flex-row pt-4'>
                                    <div className="flex flex-row w-1/3 justify-around items-center">
                                        <label className='flex flex-col justify-around w-[200px] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>{diastolicTitle.title}</label>
                                        <div className='w-[350px] justify-end flex items-center'>
                                            <input
                                                className='h-[38px] w-full text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                type='number'
                                                id={`${diastolicTitle.id}.${i}.data`}
                                                name={`${diastolicTitle.id}.${i}.data`}
                                                onChange={e => handleUncontrolledChange(value, setVal, e)}
                                                defaultValue={data.DiastolicBP[0].data}
                                                disabled = { isDisabled }
                                            />
                                            <div className='absolute text-right mr-[15px] font-"roboto" font-[400] text-[14px] leading-[15px] text-[#757575]'>{unit}</div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-1/3 justify-around items-center">
                                        <label className='flex flex-col items-center w-[calc(100%-173px)] justify-around font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Time :</label>
                                        <div className='relative flex'>
                                            <input
                                                className='h-[38px] w-[173px] text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                                id={`${diastolicTitle.id}.${i}.time`}
                                                name={`${diastolicTitle.id}.${i}.time`}
                                                type='time'
                                                onChange={e => handleUncontrolledChange(value, setVal, e)}
                                                defaultValue={data.DiastolicBP[0].time}
                                                disabled = { isDisabled }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-1/3 items-center">
                                        <label className='flex flex-col items-center justify-around w-[calc(90%-173px)] font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'>Date :</label>
                                        <input
                                            className='h-[38px] w-[173px] text-input bg-gray text-center border-[1px] border-[#E3E3E3] outline-none font-"roboto" font-[500] text-[14px] leading-[15px] text-[#757575]'
                                            id={`${diastolicTitle.id}.${i}.date`}
                                            name={`${diastolicTitle.id}.${i}.date`}
                                            type='date'
                                            onChange={e => handleUncontrolledChange(value, setVal, e)}
                                            defaultValue={data.DiastolicBP[0].date}
                                            disabled = { isDisabled }
                                        />
                                    </div>
                                    <div className='w-[90px]'></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default BloodPressure;
