/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import InitialAssessment from '../InitialAssessment/InitialAssessment';
import { convertToFhir, convertFromFhirList } from '../../fhir-evolver';
import { addInitialAssessment } from '../../api/initialAssessment';
import Loader from '../../components/Loader';
import { getEncounter } from '../../api/encounter';

const InitialAssessmentAdd = () => {
    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();
    const options = {
        position: 'bottom-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center'
        }
    };
    const [snackbarOpen] = useSnackbar(options);

    const params = useParams();
    const [encounter, setEncounter] = useState('');

    useEffect(() => {
        getEncounter('_include=Encounter:*&_pretty=true').then((response) => {
            convertFromFhirList('Encounter', response.data.entry).then((res) => {
                const encounterData = res.filter((el) => el.id === params.id);
                console.log('apiresponse', encounterData);
                setEncounter(encounterData?.[0]);
            });
        }).catch((e) => {
            return e;
        });
    }, []);

    const onAdd = (obj) => {
        convertToFhir('ClinicalImpression', obj).then((output) => {
            addInitialAssessment(output)
                .then(res => {
                    setSpinner(true);
                    snackbarOpen('Initial Assessment Created Successfully');
                    navigate('/patient/admission-list');
                });
        });
    };
    return (
        <>
            <Loader showSpinner={spinner} />
            <InitialAssessment handleSubmit={onAdd} isDisabled={false} isAdd={true} type='add' encounter={encounter}/>
        </>
    );
};

export default InitialAssessmentAdd;
