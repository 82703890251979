/* eslint-disable no-console */
import { encounterExtensionMap, locationMap, parseExtension } from '../../utils/common.js';
import { fromFhir as fromPatientFhir } from '../Patient/index.js';
import { fromFhir as fromPractitionerFhir } from '../Practitioner/index.js';
import { fromFhir as fromLocationFhir } from '../Location/index.js';

export const toEncounterFhir = (obj) => {
    const convertDate = new Date(obj.admissionDate).toISOString().split('.')[0];
    const startDate = convertDate.split('T')[0] + 'T' + obj.admissionTime;
    return Promise.resolve(
        {
            resourceType: 'Encounter',
            meta: {
                profile: [
                    'http://3.109.121.35:8080/fhir/StructureDefinition/EhrEncounter'
                ]
            },
            identifier: [
                {
                    system: 'inPatient',
                    value: obj.inPatientId
                }
            ],
            status: 'arrived',
            subject: {
                reference: `Patient/${obj.patient}`
            },
            participant: [
                {
                    individual: {
                        reference: `Practitioner/${obj.consultants}`,
                        type: 'Practitioner'
                    }
                }
            ],
            period: {
                start: startDate
            },
            extension: [
                {
                    url: 'http://hl7.org/fhir/StructureDefinition/Category',
                    valueCode: obj.category
                },
                {
                    url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Mlc',
                    valueBoolean: obj.group === 'yes'
                },
                {
                    url: 'http://3.109.121.35:8080/fhir/StructureDefinition/Mlcno',
                    valueCode: obj.MlcText
                },
                {
                    url: 'http://3.109.121.35:8080/fhir/StructureDefinition/TreatingDepartment',
                    valueCode: obj.department
                },
                {
                    url: 'http://3.109.121.35:8080/fhir/StructureDefinition/OperativeProcedure',
                    valueCode: obj.procedure
                }

            ],
            location: [
                {
                    location: {
                        reference: `Location/${obj.hospital}`,
                        type: 'si'
                    }
                },
                {
                    location: {
                        reference: `Location/${obj.floorNo}`,
                        type: 'lvl'
                    }
                },
                {
                    location: {
                        reference: `Location/${obj.wardType}`,
                        type: 'wa'
                    }
                },
                {
                    location: {
                        reference: `Location/${obj.roomNo}`,
                        type: 'ro'
                    }
                },
                {
                    location: {
                        reference: `Location/${obj.bedNo}`,
                        type: 'bd'
                    }
                }
            ]
        }
    );
};

// export const fromEncounterFhir = (data) => {
//     const obj = {};
//     obj.id = data.id;
//     obj.patient = data.subject.reference.split('/')[1];
//     obj.practitioner = data.participant[0].individual.reference.split('/')[1];
//     obj.admissionDate = data.period.start.split('T')[0];
//     obj.admissionTime = data.period.start.split('T')[1];
//     data.identifier?.map(item => {
//         obj.inPatientId = item.system === 'inPatient' ? item.value : '';
//         return 0;
//     });
//     const promises = data.extension.map(extObj => {
//         const extName = extObj.url.substring(extObj.url.lastIndexOf('/') + 1, extObj.url.length);
//         if (extName in encounterExtensionMap) {
//             obj[encounterExtensionMap[extName]] = extObj.valueCode || extObj.valueBoolean || extObj.valueString;
//         } else obj[extName] = extObj.valueCode || extObj.valueBoolean || extObj.valueString;
//         return Promise.resolve();
//     });
//     data.location.forEach(element => {
//         obj[locationMap[element.location.type]] = element.location.reference.split('/')[1];
//     });
//     return Promise.all(promises)
//         .then(() => Promise.resolve(obj));
// };

// export const fromEncounterFhirList = (dataList) => {
//     const promises = dataList.map(data => {
//         return fromEncounterFhir(data.resource);
//     });
//     return Promise.all(promises).then((list) => Promise.resolve(list)).catch(e => {
//         console.log('error: ', e);
//     });
// };

export const fromEncounterIdFhir = (data) => {
    const obj = {};
    obj.id = data.id;
    obj.patient = data.subject.reference.split('/')[1];
    obj.practitioner = data.participant[0].individual.reference.split('/')[1];
    obj.admissionDate = data.period.start.split('T')[0];
    obj.admissionTime = data.period.start.split('T')[1];
    obj.inPatientId = data.identifier ? data.identifier[0]?.value : '';
    obj.uhid = data.identifier ? data.identifier[1]?.value : '';
    const promises = data.extension.map(extObj => {
        const extName = extObj.url.substring(extObj.url.lastIndexOf('/') + 1, extObj.url.length);
        if (extName in encounterExtensionMap) {
            obj[encounterExtensionMap[extName]] = extObj.valueCode || extObj.valueBoolean || extObj.valueString;
        } else obj[extName] = extObj.valueCode || extObj.valueBoolean || extObj.valueString;
        return Promise.resolve();
    });

    data.location.forEach(element => {
        obj[locationMap[element.location.type]] = element.location.reference.split('/')[1];
    });

    return Promise.all(promises)
        .then(() => Promise.resolve(obj));
};

export const fromEncounterFhirList = (dataList) => {
    const promises = dataList.map(data => {
        return fromEncounterIdFhir(data.resource);
    });
    return Promise.all(promises).then((list) => Promise.resolve(list)).catch(e => {
        console.log('error: ', e);
    });
};

export const fromCompleteEncounterFhirList = (data) => {
    try {
        const splitData = { Patient: [], Location: [], Practitioner: [] };
        const finalData = []; const promises = [];
        // splittingData
        for (const element of data) {
            const resourceType = element.resource.resourceType;
            if (resourceType in splitData) {
                splitData[resourceType].push(element);
            } else {
                splitData[resourceType] = [element];
            }
        }

        for (const encounter of splitData.Encounter) {
            const resource = encounter.resource;
            const id = resource.id;
            const splitperiod = resource.period.start.split('T');
            const transformedEncounter = {
                id,
                status: resource.status,
                admissionDate: splitperiod[0],
                admissionTime: splitperiod[1],
                lastUpdated: resource.meta.lastUpdated,
                patientId: resource.subject.reference.split('/')[1],
                inPatientId: resource.identifier ? resource.identifier[0]?.value : '',
                uhid: resource.identifier ? resource.identifier[1]?.value : '',
                location: [],
                participant: []
            };
            // location
            const locationList = resource.location;
            for (const eachResource of locationList) {
                const locationid = eachResource.location.reference.split('/')[1];
                const foundlocation = splitData.Location.find((loc) => loc.resource.id === locationid);
                if (foundlocation) {
                    const locationPromise = fromLocationFhir(foundlocation).then(res => transformedEncounter.location.push(res));
                    promises.push(locationPromise);
                }
            }

            // extensions
            const extensionsArray = resource.extension;
            for (const e of extensionsArray) {
                const extensionPromise = parseExtension(e).then((res) => {
                    const ename = res[0]; const evalue = res[1];
                    transformedEncounter[encounterExtensionMap[ename]] = evalue;
                });
                promises.push(extensionPromise);
            }

            // practitioner
            const participantArray = resource.participant;
            for (const p of participantArray) {
                const participantid = p.individual.reference.split('/')[1];
                const foundparticipant = splitData.Practitioner.find(prac => prac.resource.id === participantid);
                if (foundparticipant) {
                    const participantPromise = fromPractitionerFhir(foundparticipant.resource).then(res => transformedEncounter.participant.push(res));
                    promises.push(participantPromise);
                }
            }

            // patient
            const patientid = resource.subject.reference.split('/')[1];
            const foundPatient = splitData.Patient.find(patient => patient.resource.id === patientid);
            if (foundPatient) {
                const patientPromise = fromPatientFhir(foundPatient.resource).then((res) => { transformedEncounter.patient = res; }).catch(e => console.log(e));
                promises.push(patientPromise);
            }

            finalData.push(transformedEncounter);
        }
        return Promise.all(promises).then(() => Promise.resolve(finalData));
    } catch (e) {
        console.log(e);
    }
};
