export const toFhir = (inputData) => {
    try {
        const result = {
            resourceType: 'Observation',
            status: 'final',
            identifier: [
                {
                    system: 'PrimaryCare',
                    value: inputData.name
                }
            ],
            effectiveDateTime: inputData.input.date.toISOString(),
            subject: {
                reference: 'Patient/' + inputData.patient
            },
            encounter: {
                reference: 'Encounter/' + inputData.encounter
            },
            issued: new Date().toISOString()
        };
        if (inputData.input.quantity) {
            result.valueQuantity = {
                value: inputData.input.data || 0
            };
        } else {
            result.valueString = inputData.input.data || '';
        }

        return Promise.resolve(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
};
