import React from 'react';

function Check (props) {
    return (
        <div className='flex'>
            <input
                className='p-[7px] font-"roboto" text-[14px] font-[400] leading-[14px] text-[#000000]'
                type='checkbox'
                name={props.name}
                id={props.id}
                onChange={props.formikState.handleChange}
                value={props.val}
                checked={props.checked}
                disabled={props.disabled}
            />
            <label htmlFor={props.id} className='p-[7px] font-"roboto" text-[14px] font-[500] leading-[14px] text-[#000000]'>{props.label}</label>
        </div>
    );
}

export default Check;
