/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Arrow1 from '../../assets/images/arrow1.svg';
import { getPatientData } from '../../api/patient';
import { convertFromFhirList } from '../../fhir-evolver/index';
import Select from 'react-select';
import Loader from '../../components/Loader';
import { config } from '../../utils/config';
const CheckPatient = () => {
    const [spinner, setSpinner] = useState(false);
    const [patientlist, setPatientList] = useState([]);
    const [patientlistoption, setPatientListOption] = useState([]);
    const [patient, setPatient] = useState(null);
    const [required, setRequired] = useState('Required *');

    const navigate = useNavigate();

    useEffect(() => {
        // const selectHereLabel = { label: 'Select here', value: null };
        let allPatientList = [];
        getPatientData(`organization=${config.organizationID}&_count=100`).then((response) => {
            setSpinner(true);
            convertFromFhirList('Patient', response.data.entry).then((res) => {
                setPatientList(res);
                for (const item of res) {
                    const dob = new Date(item.birthDate).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    });
                    allPatientList.push({ label: item.firstName + ' ' + item.surname + ' - ' + dob, value: item.id });
                }
                setSpinner(false);
                allPatientList = allPatientList.sort((a, b) =>
                    a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1
                );
                // allPatientList.unshift(selectHereLabel);
                setPatientListOption(allPatientList);
            }).catch(e => {
                return e;
            });
        });
    }, []);

    const getPatientHandler = (id) => {
        let patient = null;
        if (id) {
            patient = patientlist.find(obj => obj.id === id);
            setRequired('');
        }
        setPatient(patient);
    };

    const submitHandler = () => {
        patient !== null ? navigate(`/patient/admission/${patient.id}`, { state: patient }) && setSpinner(true) : setRequired('Required *');
    };

    return (
        <div >
            <Loader showSpinner={spinner} />
            <div className="bg-gray h-screen w-full">
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center">
                        <Link to="/home">
                            <span className="text-base font-normal pl-3 pr-2 py-2 text-col font-roboto">
                                Home
                            </span>
                        </Link>
                        <img src={Arrow1} alt="arrow" />
                        <span className="text-black font-normal text-base px-2 py-2">Admission</span>
                    </div>
                    <div className="flex flex-row gap-x-4 mt-4 px-6">
                        <Link to={'/patient/register'} state={{ isAdmission: true }}>
                            <button className="w-60 p-2 h-11 bg-gradient-to-t from-[#006E82] to-[#00a2b7] border border-blue text-base text-extrabold text-white">Add Patient</button>
                        </Link>
                    </div>
                </div>
                <div className="flex justify-center">
                    <span className="text-3xl font-bold px-2 py-5 text-black">
                        Patient Admission
                    </span>
                </div>
                <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-row gap-x-[2%] items-center mt-6 mb-4 mx-4  gap-y-3 justify-center">
                            <div className="flex flex-col w-[49%] px-6 " >
                                <Select
                                    label="Select Patient"
                                    name="patient"
                                    isSearchable={true}
                                    onChange={e => getPatientHandler(e.value)}
                                    options={patientlistoption}
                                    className="text-col bg-gray box-border border-graycol focus:outline-none h-10 text-sm"
                                    placeholder="Select here"
                                >
                                </Select>
                                <div className='flex w-full justify-end text-error font-roboto text-sm'>{required}</div>
                            </div>
                            <div className="flex mb-4">
                                <button className="w-40 p-2 h-11 mt-2 bg-gradient-to-t from-[#006E82] to-[#00a2b7] border border-blue text-base text-extrabold text-white" onClick={submitHandler}>Submit</button>
                                {/* <Link to={'/patient/register'} state={{ isAdmission: true }}>
                                    <button className="w-60 p-2 h-11 bg-gradient-to-t from-[#006E82] to-[#00a2b7] border border-blue text-base text-extrabold text-white">Add Patient</button>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckPatient;
